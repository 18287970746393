import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import  {lbl_amt, lbl_dur, lbl_flow_fee_obj, lbl_flow_fee_duration} from '../../../helpers/product_helper';
import {dd_value} from '../../../actions/common_actions';
import {updateLoanProduct} from '../../../actions/loan_product_actions';
import {listScoreModels} from '../../../actions/score_model_actions';
class EditProduct extends FlowComponent{

	state = {
		save_btn_style: "disable_div"
	}

	componentWillMount(){
		this.setState({
			id : this.props.product.id,
			product_name : this.props.product.product_name,
			cs_model_code : this.props.product.cs_model_code,
			penalty_amount : this.props.product.penalty_amount
		})
    const request = {country_code: this.country_code};
    listScoreModels(this.req(request))
    .then((response) => {
      if(!response){return };
        if(response.status === "success"){
            this.getSelectOptionsFromResult(response.data, "score_model", ["model_code", "model_name"]);
        }else{
          alert(response.message + "\n" + JSON.stringify(response.data));  
        }
      }
    );
	}

	
	handleSave = (event) => {
		/*this.state.product_name
		this.state.cs_model_code
		this.state.penalty_amount*/
		const request = {"loan_product": this.state};
  		updateLoanProduct(this.req(request))
         .then((response) => {
          if(!response){return };
              if(response.status === "success"){
                
                alert(response.message);
                window.location.reload();
    			//this.setState({toList: true})
                
              }else{
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          ); 
	}

	handleValueChange = (event) => {
		if(event.target.value){
			this.setState({save_btn_style: "show_div"});
			
		}else{
			this.setState({save_btn_style: "disable_div"});
		}
		this.handleSelfChangeState(event);
		
	}

	render(){

		/*if(this.state.toList){
		    return(
		      <listProductContainer />	      
		      );
		 }*/
		return(
			
			
			<div className="container">
				<br/>
				<div className="row">
					<h4><b>Edit Product</b></h4>
				</div>
				<br/>
				<div className="row">
					<div className="col-md-2">
						<label>Data Provider</label>&nbsp;&nbsp;&nbsp;
					</div>
					<div className="col-md-3">
						<label><b>{this.props.product.data_provider}</b></label>
					</div>
					<div className="col-md-2">
						<label>Lender</label>&nbsp;&nbsp;&nbsp;
					</div>
					<div className="col-md-3">
						<label><b>{this.props.product.lender}</b></label>
					</div>		
				</div>
				<div className="row">
					<div className="col-md-2">
						<label>FA Amount</label>&nbsp;&nbsp;&nbsp;
					</div>
					<div className="col-md-3">
						<label><b>{lbl_amt(this.props.product.max_loan_amount, this.currency_code)}</b></label>
					</div>
					<div className="col-md-2">
						<label>FA Duration</label>&nbsp;&nbsp;&nbsp;
					</div>
					<div className="col-md-3">
						<label><b>{lbl_dur(this.props.product.duration, this.props.product.flow_fee_duration, this.props.product.loan_purpose) }</b></label>
					</div>		
				</div>
				<div className="row">
					<div className="col-md-2">
						<label> Fee Type</label>&nbsp;&nbsp;&nbsp;
					</div>
					<div className="col-md-3">
						<label><b>{this.props.product.flow_fee_type}</b></label>
					</div>
					<div className="col-md-2">
						<label> Fee</label>&nbsp;&nbsp;&nbsp;
					</div>
					<div className="col-md-3">
						<label><b>{lbl_flow_fee_obj(this.props.product, this.currency_code)}</b></label>
					</div>	
					
				</div>
				<div className="row">
					<div className="col-md-2">
						<label> Fee For</label>&nbsp;&nbsp;&nbsp;
						</div>
					<div className="col-md-3">
						<label><b>{lbl_flow_fee_duration(this.props.product.flow_fee_duration, this.props.product.duration)}</b></label>
					</div>	
					<div className="col-md-2">
						<label>Status</label>&nbsp;&nbsp;&nbsp;
						</div>
					<div className="col-md-3">
						<label><b>{dd_value(this.props.product.status)}</b></label>
					</div>	
					
				</div><br/>
			

			<div className="row text-labelblue">
                <div className="col-md-3">
                  <span>Product Name</span><font className="text-danger">   *  </font>
                  <input id="product_name" value={this.state.product_name} onChange={this.handleValueChange} className="form-control" type="text" required="required" />                    
                </div>

                <div className="col-md-3">
	              <span>Credit Score Model</span><font className="text-danger">   *  </font>
	              <select id="cs_model_code" value={this.state.cs_model_code} onChange={this.handleValueChange} className="form-control" required="required" >
	                {this.state.score_model_dd}                               
	              </select>                   
                </div>

                <div className="col-md-3">
	                <span>Penalty Amount Per Day ({this.currency_code})</span><font className="text-danger">   *  </font>
	                <input id="penalty_amount" value={this.state.penalty_amount} onChange={this.handleValueChange} className="form-control" type="text" required="required" />   
              </div>
            </div><br/>

            <div className="row">
	            <div className="col-md-8">
	            </div>
	            <div className={`col-md-4 ${this.state.save_btn_style}`}>
	            	<input type="button" value="Save" className="btn btn-success" onClick={this.handleSave} />
	            	</div>
	            </div>
            </div>
			
		

			);
	}

}

export default EditProduct;