import React from 'react';
import { Navigate } from 'react-router-dom';
import { InputGroup, FormControl, FormLabel} from 'react-bootstrap';
import '../../../styles/flow.css';
import FlowContainer from '../../common/container/core/FlowContainer';
import CustPanel from './../component/CustPanel';
import ProductPanel from './../component/ProductPanel';
import {viewLoanApplication, approveLoan} from '../../../actions/loan_appl_actions';
import {dd_value} from '../../../actions/common_actions';
import {check_priv, get} from '../../../helpers/storage_helper';
import LastNFAs from '../../loan_appl/component/LastNFAs';
import { getValueFromLangJson } from '../../../helpers/common_helper';
import withRouter from '../../../Router/Withrouter';
import Softphone from '../../cs_management/container/cs_soft_phone';
import { connect } from 'react-redux';
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';
class ViewLoanApplContainer extends FlowContainer {
	constructor(props) {
        super(props);
	this.state = {viewReady: false, 
					approve_div_style: "hide_div", 
					reject_div_style: "hide_div",
					score_div_style: "hide_div",
					action : "view",
					appr_reason:''};

	this.softphone_is_supported 
	}

	componentWillMount(){
		
		this.viewApplication();
		this.softphone_is_supported =  check_priv('home', 'soft_phone')  && this.props.softPhoneState.is_softphone_supported

	}

	viewApplication = () =>{
		const loan_appl_doc_id = this.props.params.loan_appl_doc_id;
	
		viewLoanApplication(this.req({loan_appl_doc_id}))
         .then((response) => {
          	const loan_appl = response.data; 
          	this.props.setValuesIntoRedux({screen_from:"fa_apply_view",cust_id:loan_appl.cust_id})
          	
          	const can_approve = check_priv('application', 'approval');
			 
 			this.setState({loan_appl,credit_score:response.data.credit_score, 
 				loan_approver_id: response.data.loan_approver_id,
 				viewReady : true,
 				can_approve});  
 		
           });
	}

	componentDidUpdate(prevProps, prevState){

		if(prevState.can_approve != this.state.can_approve){
			this.viewApplication()
		}
	}

	is_user_apprvr(loan_approver_id){

		return loan_approver_id === get('user_id')
	}

handleRejectCheckbox = (event) => {
	this.setState({reject_div_style: "show_div",
							score_div_style: "show_div",
							action: "reject"});
 }
handleApproveCheckbox = (event) => {
	this.setState({score_div_style: "show_div",
							reject_div_style: "hide_div",
							action: "approve"});
}
handleCancelCheckbox = (event) => {
	this.setState({reject_div_style: "show_div",
							score_div_style: "hide_div",
							action: "cancel"});
}
handleSubmit = (event) => {
	const action = this.state.action;
	const appr_reason = this.state.appr_reason;
	if(action == "view"){
		alert("Please choose action ");
	}
	else if(appr_reason == ""){
		alert("Please enter reason");
	} 
	var loan_request = {};
	const loan_appl_doc_id = this.state.loan_appl.loan_appl_doc_id;
	const master_loan_doc_id = this.state.loan_appl.master_loan_doc_id;
	const country_code = this.country_code;
	const credit_score = this.state.credit_score;
	const cust_id = this.state.loan_appl.cust_id;  	
	const product_id = this.state.loan_appl.product_id;
	const task_id = this.state.loan_appl.loan_approver_task_id;
	
	//const disbursal_date = this.state.loan_appl.first_loan_date;
	if(action === "approve"){

		loan_request = {loan_appl_doc_id , master_loan_doc_id , action , credit_score, country_code , cust_id , product_id,appr_reason,task_id};
	}
	else if(action === "reject" || action === "cancel")
	{
		const action_reason_code = this.state.action_reason_code;
		const remarks = this.state.remarks;
		loan_request = {loan_appl_doc_id , master_loan_doc_id , action , credit_score, action_reason_code,remarks, country_code,product_id,cust_id,appr_reason,task_id};
	}

	if(action !="view" && appr_reason != ""){
		approveLoan(this.req({loan_request}))
	        .then((response) => {
          	if(!response){return };
	            if(response.status === "success"){
	            	if(response.data.status === "rejected" || response.data.status === "cancelled" ){
	            		window.location.reload(true);
	            	}
	            	else if(response.data.status === "approved")
	            	{
	            	const loan = response.data.loan;
	            	this.setState({loan: loan});
					if(loan != null ){
						this.setState({viewLoan: true});	
					}else{
	            	this.setState({viewLoan: false ,can_approve:false});
					}
	           		alert(response.message);
	           		}
	           	
	            }else{
	              alert(response.message + "\n" + JSON.stringify(response.data));  
	            }
	          }
			);
	}
}


handleChange = (event) => {
	this.setState({ appr_reason : event.target.value});
	
	/*if(event.target.id == "remarks"){
		this.remarks = event.target.value;
	}else if(event.target.id == "action_reason_code"){
		this.action_reason_code = event.target.value;
	}*/
}

render(){
	
	if(this.state.viewLoan == true)
	{
		return <Navigate to={`/fa/view/${this.state.loan.loan_doc_id}`} />
	}

	if(!this.state.viewReady){
		return(<></>);
	}
	const role_codes = get('role_codes');
	return(

			<div className="container">
				<br/>
				<div className="row topTitle">
					<div className="col-md-8">
						<h4>{getValueFromLangJson('float_advance_appl_id')} : <b>{this.state.loan_appl.loan_appl_doc_id}</b></h4>
					</div>
					<div className="col-md-4">
						<h4>{getValueFromLangJson('status')}: <b>{getValueFromLangJson(this.state.loan_appl.status)}</b></h4>
					</div>
				</div>
				<CustPanel parent="loan_appl"  customer={this.state.loan_appl}/>
				<ProductPanel parent="loan_appl" credit_score={this.state.credit_score} customer={this.state.loan_appl} product={this.state.loan_appl} />
				<br/>
				
				{ this.state.loan_appl.status === 'pending_approval' && this.state.can_approve 	&& this.state.loan_appl.task_approver &&
				<div>
					<LastNFAs cust_id = {this.state.loan_appl.cust_id} last_n_fas ='4' />
					<div className="row mt-3">
						<div className="col-md-6 d-flex align-items-center">	
							<span className={"text-white"}><b>Action : </b></span>
							<label className='d-flex m-0'><input type="radio" name="appr" value="approve" onClick={this.handleApproveCheckbox} /> {getValueFromLangJson('approve')} &nbsp;&nbsp;&nbsp;</label>
							<label className='d-flex m-0'><input type="radio" name="appr" value="reject" onClick={this.handleRejectCheckbox} />   {getValueFromLangJson('reject')} &nbsp;&nbsp;&nbsp;</label>
							{/*<input type="radio" name="appr" value="cancel" onClick={this.handleCancelCheckbox} />  Cancel &nbsp;&nbsp;&nbsp;*/}
						</div>	

					</div>
				
					<div style={{width:'200px'}}>
					<InputGroup>
						<FormLabel><b>Reason :</b></FormLabel>
						<FormControl as="textarea" aria-label="With textarea" rows={5} onChange={(e) => this.handleChange(e)} style={{width:'200px'}}/>
					</InputGroup>
					<br/>
					<div className="">
						<input type="button" className="btn btn-primary" value="Submit" onClick={this.handleSubmit} />&nbsp;&nbsp;
					</div>
					</div>
					<br/>
				</div>   
            }
				
				{this.props.softPhoneState.softPhone && (!this.props.softPhoneState.missed_calls_view)   && (!this.props.softPhoneState.isIncomingCall) && (!this.props.softPhoneState.outgoingCall) && (  this.props.softPhoneState.show_nums) && this.softphone_is_supported&&

				<div className='Call_log_section zoomIn_animation'>
				<Softphone screen_from ={"fa_apply_view"} cust_id={this.state.loan_appl.cust_id?this.state.loan_appl.cust_id:null}/>
					
				</div>


				}
			</div>
	

		);
}
}

const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewLoanApplContainer));


