import { Box, IconButton, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import ListUserContainer from './ListUserContainer';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../MUI/Components/CustomButton';
const UserList = () => {
    const navigate = useNavigate()
    const theme =  useTheme()
    return (
       <Box sx={{mt:5}}>
           <Box display={'flex'} justifyContent={'center'}>
           {/* @ts-ignore */}
            <CustomButton
            // @ts-ignore
            variant="full_width_inter_sm"
            text={'Create User'}
           
            startIcon={<PersonAddAlt1Icon sx={{fontSize:'25px'}}/>}
            onClick={() => navigate('/user/create')}
            style={{
               
                width: '202px',
                height: '34px',
                padding: '0px',
                color: 'white',
                fontSize: '0.9rem',
                fontWeight: '400',
                borderRadius: '10px',
                background: '#3f63f48a',
                display:'flex',
                justifyContent:'center',
                margin:'8px 0px'
            }}
            />
           
           </Box>
           <Box sx={{
               backgroundColor:'#121936'
                ,px:3,pt:2,pb:4}}>
             
           <ListUserContainer/>

           </Box>
       </Box>
    );
};

export default UserList;