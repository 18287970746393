import React from 'react';
import { Modal } from 'react-bootstrap';

const PDFModal = ({ show, onClose, title, pdf_url }) => {
    // const root = "https://dev.flowlocal.net"
    const root = ""
    return (
        <Modal show={show} onHide={onClose} size="lg">
            <Modal.Header className='py-2' closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                
                <div className="modal-body py-0">
                    <object
                        type="application/pdf"
                        className="forpdf"
                        data={`${root}${pdf_url}#toolbar=0&navpanes=0&page=1`}
                        width="100%"
                        height="485vh"
                        allowTransparency="true"
                    >
                        Your browser does not support PDFs. You can download the PDF 
                        <a href={root+pdf_url} target="_blank" rel="noopener noreferrer"> here</a>.
                    </object>
                </div>
                <div className="modal-footer d-flex">
                    <a
                        href={root+pdf_url}
                        className="btn btn-success btn-sm my-0 mx-3"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Download PDF
                    </a>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PDFModal;
