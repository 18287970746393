import React from 'react';
import '../../../styles/flow.css';
import FlowContainer from '../../common/container/core/FlowContainer';
import {Modal,Button } from 'react-bootstrap';
import SearchLoanCriteria from '../../loan_appl/component/SearchLoanCriteria';
import {searchLoan} from '../../../actions/loan_actions';
import {dd_value} from '../../../actions/common_actions';
import {format_date_tbl, lbl_date,page_count,rt_algn, set_id_for_rows, title_case} from '../../../helpers/common_helper';
import {formatNumber, lbl_amt} from './../../../helpers/product_helper';
import ViewTxnContainer from './view_transaction_container';
import {CustID, Product} from '../../common/partial';
import ViewTransactions from '../../loan/component/ViewTransactions';
import $ from 'jquery';
import {Link} from "react-router-dom";
import CommonReactTable from '../../../helpers/commonReact_table';
import { Config, get } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import CommonSearchCriteria from '../../loan_appl/component/CommonSearchCriteria';
import {setScreachedFields} from '../../../Redux/Slices/CommonSearchSlice';
import CommonFilterShow from '../../loan_appl/component/CommonFilterShow';
import withRouter from '../../../Router/Withrouter';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import ExportCsv from '../../common/component/ExportCsv';
import DataGridTable from '../../CommonComponent/table';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CustomTableGridToolbar from '../../CommonComponent/table/components/customTableGridToolbar';

class
LoanSearchContainer extends FlowContainer {
	constructor(props){
	    super(props);
	    this.state = {
	    	search_btn_style : "",
	        data_prvdr_name_list: [],
	        lender_name_list: [],
	        product_name_list: [],
	        searchLoans: {"voided_fa" : "false"},
	        datatable_div_style: "hide_div",
	        search_loan:"show_div",
	        modify_search:"hide_div",
	        toView: false ,
			copy:[],
			rowdatas:[],
			columndatas:[],
			paginate :  Config('paginate') ? Config('paginate') : null,
			export_data:[],
			search_criteria:null,
			total : 0,
			loader : false,
			language_json : get("language_json"),
			consolidation_data:[],
			filter_data : [],
			status : '',
			excepted_filters : ['status', 'req_parameter'],
			manditory_fields : {'disbursed':["disbursal_date__from", "disbursal_date__to"], 
								'ongoing':["disbursal_date__from", "disbursal_date__to"], 
								'Overdue' : ['due_date__from', 'due_date__to'],
								'pending_disbursal' : ['loan_approved_date__from', 'loan_approved_date__to'],
								'settled' : ['paid_date__from', 'paid_date__to'],
								'partially_paid' : ['partially_paid_date__from', 'partially_paid_date__to']},
			__from : '',
			__to : '',
			req_parameter : '',
			page: { page: 0, pageSize: 10 }

	    };
	}

	
    handleSearch = (event) => {	
		this.setState({loader:true})
		let data = this.props.commonSearchedData?.searchLoans ?? {}
		let search_loans = {}
		let exeptedValues = this.state.excepted_filters
		let from = ''
		let to = ''
		Object.keys(data).map((key,i) => {
			if(key.includes('__from')){
				exeptedValues.push(key)
				from  = data[key]?.selected_data?.label ?? ""
			}
			if(key.includes("__to")){
				exeptedValues.push(key)
				to = data[key]?.selected_data?.label ?? ""
			}
			if(data[key]?.selected_data?.value && data[key].type == 'multi_select'){
				search_loans[key] = data[key].selected_data.value.map((val, i) => val.value)
			}else if(data[key].selected_data){
				search_loans[key] = data[key].selected_data.value
			}
		})
		this.setState({excepted_filters:exeptedValues, __from:from,  __to:to})
		var mode=event.target.id; 
		this.setState({copy : []})
		if(this.state.paginate){
			this.setState({paginate : Config('paginate')});
		}
    	this.setState({datatable_div_style: "hide_div",
    					search_btn_style : "disable_div"});
		this.setState({total : 0})

		let validate = this.validation(search_loans);
		if(!search_loans.status && !search_loans.req_parameter){
			window.alert("Please enter a valid search criteria")
			this.setState({loader:false})
		}
		else if(validate){
			window.alert("Please enter the fields " + validate)
			this.setState({loader:false})
		}else{
			this.setState({status:search_loans.status ?? '' })
			this.setState({req_parameter:search_loans.req_parameter ?? ''})
			this.LoanSearch(mode, search_loans)
		}
		
	}

	componentDidUpdate(prevProps, prevState) {
		if (
		  prevState.page !== this.state.page
		) {
		  this.pagination();
		}
	  }

	validation = (search_loan) => {
		let manditory = this.state.manditory_fields?.[search_loan.status] ?? []
		const result = manditory.filter(val => !search_loan[val]).map(item => dd_value(item));
		if(result){
			return result.join(', ');
		}else{
			return ""
		}
		
	}
	
	LoanSearch(mode, search_loans){
		this.setState({loader:true})
    	const loan_search_criteria = search_loans;
		loan_search_criteria.mode=mode;
 		if(Object.keys(loan_search_criteria).length > 0){
			loan_search_criteria.date_field = loan_search_criteria.status == "settled" ? "true" : "false" ;
			loan_search_criteria.addl_total_metrics = true;
			this.setState(state=>state.search_criteria = loan_search_criteria)
			var req_json = this.state.paginate ? {loan_search_criteria, paginate : this.state.paginate} : {loan_search_criteria}
		  searchLoan(this.req(req_json, true))
		        .then((response) => {
		        	this.resp(response)
		        	this.setState({search_btn_style : ""});
	          if(!response){return this.setState({loader : false}, this.handleModifySearch())};
			  if( response.data.results && response.data.results.length == 0) this.setState({loader : false}, this.handleModifySearch())
						   var loanResults = [];
						
							if(response.data.mode == 'view'){
								window.open("/fa/view/"+response.data.loan_doc_id,"_blank")
							}
							else 
							if(response.data.mode == 'search'){
								this.setState(state=>state.rowdatas = response.data.results)
								this.setState(state=>state.consolidation_data = response.data.consolidation_data)
								this.setState(state=>state.columndatas = this.columndatas(this.state.language_json))
								this.loan =  response.data.results;
								
								this.setState({search:true})
								
		           			this.setState({datatable_div_style: "show_div",loanResults: loanResults,search_loan:"hide_div",modify_search:"show_div"});
		           			
							if(this.state.paginate != null){								
								this.setState(state=>state.total=response.data.total_count)	
								this.page_count()
							}
							else{
								this.setState(state=>state.total=response.data.results.length)
							}

							
							let data = set_id_for_rows(response.data.results)

							this.setState((state) => {

								return { copy : data};
							}, ()=>this.setState({loader : false}));

			           	}else{
			          		alert("No results found.");   		
			           	}
		          }
		        );
		}else{
			this.setState({search_btn_style : ""});
			alert("Choose atleast one filter to search Float Advance.")
		}					        
	}

	columndatas (language_json) {
		return[
			{
			  headerName : language_json.fa_id, 
			  field: "loan_doc_id",	
			  align: 'left',headerAlign: 'left',  flex: 1,
			  minWidth:200,
			  renderCell: (params) => <Link className='link-doc-id' to={"/fa/view/"+params.row.loan_doc_id} target="_blank">{params.row.loan_doc_id}</Link>			
			},
			{
			  headerName : language_json.account_num,
			  field: "acc_number",
			  align: 'left',headerAlign: 'left',  flex: 1,
              minWidth:200,
			  renderCell:params => {return params.row.acc_number}
			},
			// {
			//   headerName : "Product",
			//   id: "product_name",
			//   renderCell:row => {return row.product_name}
			// },

			{
			  headerName : language_json.rm,
			  field: "flow_rel_mgr_name",
			  sortable : false,
			  align: 'left',headerAlign: 'left',  flex: 1,
              minWidth:200,

			  renderCell:params => {return params.row.flow_rel_mgr_name}
			},
			{
			  headerName : language_json.biz_name + "|" + language_json.cust_name,
			  field: "biz_name",
			  align: 'left',headerAlign: 'left',  flex: 1,
              minWidth:200,

			  renderCell:params => {return <div className='text-wrap'>{params.row.biz_name + " | " + params.row.cust_name}</div>}
			},
			{
			  headerName : language_json.disb_date,
			  field: "disbursal_date",
			  align: 'left',headerAlign: 'left',  flex: 1,
              minWidth:200,

			  renderCell:params => {return format_date_tbl(params.row.disbursal_date)}
			},
			{
			  headerName : language_json.due_date,
			  field: "due_date",
			  align: 'left',headerAlign: 'left',  flex: 1,
              minWidth:200,

			  renderCell:params => {return format_date_tbl(params.row.due_date)}
			},
			{
			  headerName : language_json.fa_amount, 
			  field: "loan_principal",
			  align: 'left',headerAlign: 'left',  flex: 1,
              minWidth:200,

			  renderCell:params => {return (lbl_amt(params.row.loan_principal))}
			},
			{

			  headerName : language_json.fee ,
			  field: "flow_fee",
			  align: 'left',headerAlign: 'left',  flex: 1,
              minWidth:200,

			  renderCell:params => {return (lbl_amt(params.row.flow_fee))}
			},
			{
				headerName : language_json.paid_amount,
				align: 'left',headerAlign: 'left',  flex: 1,

				field: "paid_amount",
				renderCell:params => {return (lbl_amt(params.row.paid_amount))},
				minWidth:200
			},
			{
				headerName : language_json.paid_date,
				field: "paid_date",
				align: 'left',headerAlign: 'left',  flex: 1,
				minWidth:200,

				renderCell:params => {return params.row.paid_date ? lbl_date(params.row.paid_date) : "Not Paid"}
			},
			{
				headerName : language_json.applied_by,
				field: "applied_by",
				align: 'left',headerAlign: 'left',  flex: 1,
				minWidth:200,

				renderCell:params => {return params.row.loan_applicant_name}
			  },
			  {
				headerName : language_json.channel,
				field: "channel",
				align: 'left',headerAlign: 'left',  flex: 1,
				minWidth:200,

				renderCell:params => dd_value(params.row.channel,'channel'),
			  },
			{
				headerName : language_json.overdue_days,
				field: "overdue_days",
				align: 'left',headerAlign: 'left',  flex: 1,
				minWidth:200,
				renderCell:params => {return <div className='text-center'>{params.row.overdue_days}</div>}
			},
			{
				headerName : "Lead Created By",
				field: "lead_created_by",
				align: 'left',headerAlign: 'left',  flex: 1,
				minWidth:200,

				renderCell:params =>title_case(params.row.lead_created_by),
				sortable : this.state.paginate ? false : true,
			},
			{
				headerName : "Lead Creator Name",
				field: "lead_creator_name",
				renderCell:params =>params.row.lead_creator_name,
				minWidth:200,
				align: 'left',headerAlign: 'left',  flex: 1,
			},
			{
				headerName : language_json.action,
				field: "action",
				minWidth:200,

				align: 'left',headerAlign: 'left',  flex: 1,
				renderCell:params => <ViewTransactions parent="loan_search" loan_doc_id={params.row.loan_doc_id}/>
			},
		  ]
	}

	changeHandler = (e) => {		
		const toFilter = e
		const filterfrom = this.state.rowdatas
		const filteredData = filterfrom.filter(value => {
			return(
				value.loan_doc_id !==null && value.loan_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.acc_number !==null && value.acc_number.includes(toFilter) ||
				value.product_name !==null && value.product_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.flow_rel_mgr_name !==null && value.flow_rel_mgr_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.biz_name !==null && value.biz_name.toLowerCase().includes(toFilter.toLowerCase())	
			)
		})
		this.setState(state=>state.copy = filteredData)		
	}

	viewLoan = (event) => {
		const loan_doc_id = event.target.id;
		window.open("/fa/view/"+loan_doc_id,"_blank");
	}

	viewTxn = (event) => {
		this.setState({loan_doc_id : event.target.id});
		this.setState({modalBox: true});
	}

	handleModalClose = (event) => {
      this.setState({ modalBox: false });
   }
	handleModifySearch = (event) => {
	this.setState({
		search_loan:"show_div",
		modify_search:"hide_div"
	})
	this.setState({paginate : Config('paginate'), copy : [], rowdatas : [], total : 0})
	}	



  getFilterData = (id, data) => {
	this.setState(prevState => ({
        filter_data: {...prevState.filter_data, [id]: data}
    }));
	
  }
	pagination = (page, count, sorted)=>{
			
		this.setState((state) => {
			return {
			  paginate: {
				...state.paginate,
				pagination_count: this.state.page.pageSize,
				pagination_page: this.state.page.page ,
				sorting:sorted,
				total_count : this.state.total
			  },
			};
		  }, ()=>this.LoanSearch('searchbtn', this.state.search_criteria));	
	}

	page_count = ()=>{
			
		const total = this.state.total
		const page_count_mod = (total%this.state.paginate.pagination_count)
		
		if(page_count_mod > 0){
			const page_count = (total/this.state.paginate.pagination_count)
			this.setState({'page_count' : Math.trunc(page_count)+1}, ()=>console.log(this.state.page_count))

		}else{
			const page_count = (total/this.state.paginate.pagination_count)
			this.setState({'page_count' : page_count})
		}
	}

	handleExportCSV = (event) => {
		let loan = JSON.parse(JSON.stringify(this.state.search_criteria));
		let resp = null;
		if(event == 'email') {
			loan.export = 'email'
			resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
		}

		if(event == 'email' && !resp){
			return
		}
		else{
			this.handleCSVapi(loan, event)
		}
	}

	handleCSVapi(loan, action){
		let calling_api = true
		if (action == 'email') calling_api = false 
		var request={loan_search_criteria:loan, paginate : false}
		searchLoan(this.req(request, true, null, calling_api), true)
			.then((response) => {
				this.resp(response)
				if(action == 'email'){
					return
				}
				if(response?.status == "success"){
					this.setState(state=>state.export_data = response.data.results)
				}
			})
	}

	render(){
		const is_server_side = this.state.paginate?true:false
        const consolidation_data = this.state.consolidation_data
		return(
			<div className="container containerTopmargin max-width no-padding">
				<div className={this.state.search_loan}>
					<div className="floatAdvancetitleBox ">
						<Typography className="preHeaderpad no-margin pl-3 text-white" variant='h1' sx={{fontWeight:"500"}}> {this.state.language_json.search_fa_title}</Typography>
					</div>	
				</div>	
				 <div className={this.state.search_loan}>
					<CommonSearchCriteria id = "searchLoans" mode = "loan" onSearch={this.handleSearch} disable_btn = {this.state.calling_api}/>
		           </div>
				<div id="" style={{marginTop:15}}>		
					<div className={this.state.search_loan == 'hide_div' ? '' : 'hide_div'}>
						<div className='my-5 ml2' >
							<span className='fa_header text-white'>Float Advance - </span>
							<span className='audit_kyc_icon_font text-white'>{this.state.status ? dd_value(this.state.status, 'loan_status') : this.state.req_parameter}</span>
							{this.state.__from && this.state.__to && 
							<span className='font_date_for_fa ml-4 text-white size_medium'>({this.state.__from} - {this.state.__to})</span>
							}
						</div>

						<div className={`row ${consolidation_data ? 'mb-5': ''}`}>
						<div className='col-10'>
						{consolidation_data && 
							<div className='row'>
								{consolidation_data.map((item, i)=>
									<div className='col-auto' key={i}>
										<div className='mr-5'>
											<p className='text-white size_medium'>{item.key}</p>
											<b className='text-white audit_kyc_text'>{item.key.toLowerCase().includes('count') ? item.value : formatNumber(item.value)}</b>
											{!item.key.toLowerCase().includes('count') && 
												<span className='text-white audit_kyc_text' >{` ${get('market').currency_code}`}</span>
											}
										</div>
									</div>
								)}
							</div>
						}
						</div>
						<div className={`col-2 d-flex justify-content-end ${consolidation_data ? '': 'text-right'}`}>
						   <div>
							<p className='my-1 text-white mr-3 pointer text-end' style={{fontSize:"15px"}} onClick={(e)=>this.handleModifySearch(e)}>Modify Search </p>
							<Box display={'flex'} justifyContent={'end'}>
									<ExportCsv
									total_count={this.state.total ?? 0} 
									handleExport={this.handleExportCSV} 
									export_csv_datas={  is_server_side ?( this.state.export_data ?? [] ):this.state.copy }
									csvFileName = {'Loans List'}
									paginationMode= { is_server_side?  "server":"client"}
                                    columns={this.state.columndatas??[]}

									/>
							</Box>
							</div> 
						</div>
      				 	</div>


						<div className={this.state.datatable_div_style} >{this.state.copy.length>0&&
						<>
							<div style={{display:this.state.loader ?'none':''}}>{console.log('this.state.copy', this.state.copy)}

							
									<DataGridTable
                                        rows={this.state.copy??[]}
                                        columns={this.state.columndatas}
                                        paginationMode= { is_server_side?  "server":"client"}
                                        sortingMode={"client"}
                                        page={this.state.page}
										rowCount={this.state.total??0}
                                        setpage={(newPage) => this.setState({ page: newPage })}
                                        isLoading={ this.state.loader }
										slots={{ toolbar: CustomTableGridToolbar }}
												slotProps={{
												  toolbar: {
													showQuickFilter: true,
												  },
												}}
                                        
                                    />
									
                                     

							</div>
							</>}
						</div>                                   
					</div>
				</div>
				<div style={{display:this.state.loader ?'':'none'}}>	
								<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
									<div className="product_takeup_loader"></div>
								</div>
							</div>
	
			</div>
			);
	}
}


const mapStateToProps = (state) => {
	return {
	  commonSearchedData: state.CommonSearch,

	};
  };

const mapDispatchToProps ={
    setScreachedFields
  
  };


export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LoanSearchContainer)
);