import { useTheme } from '@mui/material/styles';
import { Box, Typography, Grid, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import CustomInput from '../../MUI/Components/CustomInput';
import CustomButton from '../../MUI/Components/CustomButton';
import { Markets, get_country_master_data } from '../../actions/common_actions';
import { set, get, add_all, sync_master_data } from '../../helpers/storage_helper';
import CustomFlag from '../common/component/CustomFlag';
import ForgetPassword from '../auth/component/ForgetPassword';
import { useEffect } from 'react';
import S3 from 'aws-sdk/clients/s3';
import { userService } from '../../actions/auth_actions';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderSlice } from '../../Redux/Slices/HeaderSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateValuesIntoRedux } from '../../Redux/Slices/CommonSlice';
import { common_login_header, powered_by_text } from '../../helpers/common_helper';
import { bg_box, login_box, login_box_padding, sign_in_button } from '../../styles/common_styles';
import { useForm } from "react-hook-form";
import AdminHome from '../home/AdminHome';

const LOGIN = () => {
  const { watch, control, handleSubmit, formState: { isValid, errors }, setValue } = useForm({ mode: "onChange" });
 
  const theme = useTheme();
  const [Forget_password, setForgetPassword] = useState(false)
  const [jsonData, setjsonData] = useState(false)
  const [login_credentials, setlogin_credentials] = useState({ email: "", password: "" })
  const login_details = useSelector(state => state.HeaderSlice)
  const { admin_roles,brand_info } = useSelector(state => state.common_slice)
  const [loader, setloader] = useState(false)
  const [rememberMe, setrememberMe] = useState(false)
  const dispatch = useDispatch()
  const role_codes = get('role_codes')
  const navigate = useNavigate();
  const location = useLocation()
  

  useEffect(() => {
    
    if(get('access_token')){
      navigate('/')
    }
   
    if (!get('market_details')) {
      if (process.env.REACT_APP_UI_VERSION && process.env.REACT_APP_UI_VERSION != "local") {
        setCountry()
        getJSONfromSDK()
      }
    }
    localStorage.setItem('error_alert', true);
  }, [])


  useEffect(() => {
    if (login_details && login_details.show_login_after_reset_pass) {
      setForgetPassword(false)
      setlogin_credentials({
        ...login_credentials,
        email: login_details.login_credentials.email,
        password: ''
      })
    }
  }, [login_details.show_login_after_reset_pass])

  useEffect(() => {
    setValue('login_email', login_credentials.email);
  }, [login_credentials, setValue]);

  const setCountry = () => {
    let url = window.location.href
    let test_url = "http://13.234.42.35:5555";
    var countryCode = ""
    if (url.startsWith(test_url)) {
      countryCode = "UG";
    } else {
      const startIndex = url.indexOf("https://") + "https://".length;
      const text = url.substring(startIndex);
      const parts = text.split('.');
      countryCode = parts[0];
    }
    var country = {}
    country['country_code'] = (countryCode == "core") ? "ug" : countryCode
    set("market_details", country)
  }

  const getJSONfromSDK = () => {
    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY,
      region: "ap-south-1",
    });
    const getObjectParams = {
      Bucket: process.env.REACT_APP_UI_VERSION == "LIVE" ? "flow-api-config" : "test-bucket-lambda-07",
      Key: process.env.REACT_APP_UI_VERSION == "LIVE" ? "LIVE.json" : "TEST_2.json",
    };
    s3.getObject(getObjectParams, (err, data) => {
      if (err) {
        if (err.code === "NetworkingError") {
          console.error("There is an issue with the network connection to the S3 bucket.");
        }
        if (err.code === "CredentialsError") {
          console.error("Check your AWS credentials (access key ID and secret access key).");
        }
      } else {
        try {
          const jsonData = JSON.parse(data.Body.toString());
          set("jsonData", jsonData)
          setjsonData(jsonData)
        } catch (jsonParseError) {
          console.error("Error parsing JSON data:", jsonParseError);
        }
      }
    });
  }



  const form_submit = (event) => {
    setloader(true)
    const email =  watch('login_email') ??login_credentials.email
    const password = watch('login_password') ??login_credentials.password  
    const uuid = uuidv4();
    const remember_me = rememberMe
    userService.login(email, password, uuid, remember_me)
      .then(response => {
        if (!response) {
          setloader(false)
          return
        }
        if (response.status === "success") {
          add_all(response)
          localStorage.setItem('uuid', JSON.stringify(uuid));

          // if(BrandingConfig[response.data.lender_code]){
          //   const current_branding = BrandingConfig[host_name] || BrandingConfig.default;
          //   dispatch(updateValuesIntoRedux({brand_info : current_branding}))
          // }
          
          dispatch(changeHeaderSlice({ "user_email": login_credentials.email }))
          if (response.data.user?.market_list?.length>1 && admin_roles.includes(response.data.user.role_codes) ) {
            dispatch(changeHeaderSlice({ "markets": response.data.user.market_list }))
            navigate('/select_market')
            setloader(false)
          } else {
            navigate('/',{replace:true})
            set('home',response.data.app_config?.home)
            
          }
        }
      });
  }

  const input_style = {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.custom_colors.secondary_a_400,
        borderRadius: '12px'
      },
    }
  }

  const update_rememberMe = (value) => {
    setrememberMe(value)
  }

  return (
    <Box sx={[bg_box, { backgroundColor: theme.palette.custom_colors.old_primary_900 }]}>
      <Box sx={[login_box, { backgroundColor: theme.palette.primary.main }]}>
        <Box sx={login_box_padding(brand_info?.footer)}>
          {common_login_header()}
          <Box>
            <Typography variant="s_inter_medium_gray" component={'p'} sx={{ textAlign: 'center', marginTop: "44px", mb: 3 }}>  Sign in with Email address</Typography>
          </Box>
          <form onSubmit={handleSubmit(form_submit)}>
            <CustomInput
              autoFocus={true}
              name="login_email"
              control={control}
              sx={input_style}
              variant="outlined" className="default_outlined_variant"
              label= {"Email address"}
              type='email'
              defaultValue={ login_credentials.email} 
              rules={{ required: 'Email is required' }}
              error={!!errors.login_email}
            />
            <Box sx={{ mt: 3, marginBottom: "15px" }}>
              <CustomInput
                name="login_password"
                control={control}
                sx={input_style}
                variant="outlined"
                label="Password"
                type='password'
                rules={{ required: 'password is required' }}
                error={!!errors.login_password}
                className="default_outlined_variant"

              />
            </Box>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={6} lg={6} >
                <Typography variant="s_inter_medium_white" component={'p'} >
                  <Checkbox color="secondary" sx={{ fontSize: "19px" }} onChange={(e) => { update_rememberMe(e.target.checked) }} />
                  Remember me
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}  >
                <Typography variant="s_inter_medium_white" className='pointer' component={'p'} sx={{ textAlign: 'end', pr: 1 }} onClick={() => { navigate('/forgot_password') }} >
                  Forget password?
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ mt: brand_info?.footer ?3:5 }}>
              <CustomButton isLoading={loader} variant="full_width_inter_sm" type={"submit"} component="button" text="Sign in" onClick={form_submit} style={sign_in_button(theme)} isDisabled={!isValid}/>
            </Box>
          
          </form>

        { brand_info?.footer &&
        <Box sx={{mt:2}}>{
        powered_by_text(brand_info?.footer.logo,theme.palette.custom_colors.secondary_a_400 )
         }</Box>
        }

        </Box>
       
      

      </Box>
    </Box>
    
   
  );
};

export default LOGIN;
