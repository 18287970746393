import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import DataGridTable from '../CommonComponent/table';

type TableProps = {
    rows: { [key: string]: any }[];
    page: { page: number; pageSize: number };
    rowCount: number;
    setpage: (page: { pageNumber: number; pageSize: number }) => void;
    loader: boolean;
    columns: { [key: string]: any }[];
    server_time?: any;
};

const HomeScreenTable: React.FC<TableProps> = ({
    rows,
    page,
    rowCount,
    setpage,
    loader,
    columns,
    server_time,
}) => {
    // Memoize the row data to avoid unnecessary re-renders
    const memoizedRows = useMemo(() => rows, [rows]);

    // Memoize the column definitions
    const memoizedColumns = useMemo(() => columns, [columns]);

    return (
        <Box>
            <DataGridTable
                rows={memoizedRows}
                columns={memoizedColumns as any}
                paginationMode="client"
                sortingMode="client"
                page={page}
                rowCount={rowCount ?? 0}
                setpage={setpage}
                isLoading={loader}
                isError={false}
                errorText=""
                noOfColumnsToLoad={10}
                rowHeight={75}
                
            />
        </Box>
    );
};

export default HomeScreenTable;
