import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid'

const CustomTableGridToolbar = (props: any) => {
  return (
    <div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
        <div className='MuiDataGrid-toolbarContainer' style={{ display: 'flex', gap: '8px' }}>
         
            {/* <GridToolbarColumnsButton /> */}
            {/* <GridToolbarFilterButton /> */}
            {/* <GridToolbarDensitySelector /> */}
        </div>
        <GridToolbarQuickFilter />
    </div>
  )
}

export default CustomTableGridToolbar