import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CustomModal from '../../../MUI/Components/CustomModal';
import { get } from '../../../helpers/storage_helper';
import { changeDisbursalStatus } from '../../../actions/loan_actions';
import { lbl_amt, req, rt_algn } from '../../../helpers/common_helper';
import { getListByUrl } from '../../../actions/common_actions';
import { themes } from '../../../context';
import { object } from 'yup';
import FormProvider from '../../CommonComponent/rhfComponents/formProvider';
import { useForm } from 'react-hook-form';
import RHFAutocomplete from '../../CommonComponent/rhfComponents/rhfAutoComplete';
import { RhfOptionsTypeAndKeyCheckProperties } from '../../../helpers/helper';


const ManualDispCapture = ({ open, onClose, loan ,user_list}: { open: boolean,user_list:()=>void, onClose?: () => void, loan: any }) => {

    useEffect(() => {
        // handleDisburseManually()
        get_account_list()
    }, [])

    const [lender_account, setlender_account] = React.useState<any>([]);
    const [cust_account, setcust_account] = React.useState<any>([]);
    const [confirmation_fields, setconfirmation_fields] = useState({
        confirmation: false
    });


    console.log('lender_account', lender_account, cust_account)
    const get_account_list = () => {
        let lender_account = {
            acc_purpose: "disbursement",
            cust_id: loan.cust_id,
            network_prvdr_code: loan.acc_prvdr_code,
            status: "enabled"
        }
        let customer_account = {

            cust_id: loan.cust_id,
            acc_purpose: loan.loan_purpose,
            status: "enabled"
        }
        // @ts-ignore
        getListByUrl("common", "lender_accounts", req({ lender_account }))
            .then((response) => {
                if (response?.status == "success") {
                    const data = response.data.list.map((item: any) => ({
                        name: item.acc_num_disp_txt,
                        id: item.acc_num_disp_txt

                    }))
                    setlender_account(data)
                }
            })
        // @ts-ignore
        getListByUrl("common", "customer_accounts", req({ customer_account }))
            .then((response) => {
                if (response?.status == "success") {

                    const data = response.data.list.map((item: any) => ({
                        name: item.acc_num_disp_txt,
                        id: item.acc_num_disp_txt

                    }))
                    setcust_account(data)
                }
            })

    }


    console.log('lender_account', lender_account)

    const theme = useTheme()

    const getUserInput = (value: any, type: string) => {
        setconfirmation_fields({
            ...confirmation_fields,
            [type]: value
        });
    };

    const method = useForm({
        defaultValues:
        {
            from_acc: lender_account?.length == 1 ? lender_account[0] : "",
            to_acc: cust_account?.length == 1 ? cust_account[0] : "",
            user_confirm: false
        }
    })
    const { formState, watch, setValue, reset } = method

    useEffect(() => {
        reset({
            from_acc: lender_account?.length == 1 ? lender_account[0] : "",
            to_acc: cust_account?.length == 1 ? cust_account[0] : "",
            user_confirm: false
        })
    }, [lender_account, cust_account])

  
    const handle_submit = () => {
        let request = {
            loan_doc_id: loan.loan_doc_id,
            disbursal_status: 'pending_disb_capture',
            loan_status: 'disbursed',
            pre_disb_status: 'pending_mnl_dsbrsl'
        }
        // @ts-ignore
        changeDisbursalStatus(req(request))
            .then((res) => {
                if (res?.status == "success") {
                    alert(res.message)
                    user_list()
                    if(onClose)
                    onClose()
                }
            })
       
    }

    const footer = () => {
        return (
            <Box display={"flex"} alignItems={"center"} sx={{ my: 2 }}>
                <Button
                    sx={{ background: 'gray' }}
                    variant="contained"
                    onClick={onClose}
                >
                    Cancel
                </Button>

                <Button
                    disabled={!enable_submit}
                    variant="contained"
                    onClick={handle_submit}
                    sx={{ backgroundColor: theme.palette.secondary.main, mx: 2 }}>
                    Submit
                </Button>
            </Box>
        )
    }

    const enable_submit = watch('user_confirm') && watch('from_acc') && watch('to_acc')

    return (
        <Box>
            <CustomModal
                show={open}
                onClose={onClose}
                header={"FA Disbursal"}
                body={
                    <FormProvider methods={method}>
                        <Box>
                            {/* Instructions Section */}
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>
                                        {`##For this Account Provider (${loan?.acc_prvdr_code}), Instant Disbursal has not been configured##`}
                                        <br />
                                        <br />
                                        Follow the below steps always to avoid duplicate disbursement.
                                        <br />
                                        <br />
                                        1. Don't close this screen during the entire disbursement process.
                                        <br />
                                        2. Send FA to customer manually using web portal, USSD etc. (If you do not know how to do this, discuss with Tech Support)
                                        <br />
                                        3. Capture the disbursement details on this screen and click "Submit".
                                        <br />
                                        <br />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <RHFAutocomplete
                                        name='from_acc'
                                        label='Disburse From A/C'
                                        options={lender_account}
                                        fullWidth
                                        {...RhfOptionsTypeAndKeyCheckProperties}

                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <RHFAutocomplete
                                        fullWidth
                                        name='to_acc'
                                        label="Customer's A/C"
                                        options={cust_account}
                                        {...RhfOptionsTypeAndKeyCheckProperties}

                                    />

                                </Grid>


                            </Grid>
                            <Box>
                                <Typography sx={{ my: 2 }} component={'p'} >
                                    <Checkbox color="secondary" sx={{ fontSize: "19px" }} onChange={(e) => setValue('user_confirm', e.target.checked)}
                                    />
                                    I have transferred the above Float Advance to the Customer's Account using Third Party Platform .
                                </Typography>

                            </Box>
                        </Box>
                    </FormProvider>
                }
                footer={footer()}
            />

        </Box>
    );
};

export default ManualDispCapture;