import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CSVLink } from 'react-csv';
import { Config } from '../../../helpers/storage_helper';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';

export type ExportCSVProps = {
  total_count?: number | undefined;
  handleExport?: Function;
  export_csv_datas: GridRowsProp; // Adjust type if needed
  csvFileName: string;
  paginationMode?: string;
  columns: GridColDef[];
};

const ExportCsv = ({
  total_count,
  handleExport,
  export_csv_datas,
  csvFileName,
  paginationMode,
  columns,
}: ExportCSVProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const exportcsv = () => {
    if (export_csv_datas.length === 0 && handleExport && paginationMode == 'server') {
      setLoading(true);  
      handleExport(total_count && total_count > Config("export_csv").limit ? 'email' : 'download');
    }
  };
  useEffect(() => {
    if (loading && export_csv_datas.length > 0) {
      setLoading(false); 
    }
  }, [export_csv_datas, loading]);

  const prepareCsvData = () => {
    const csvHeaders = columns.map((col) => ({ label: col.headerName, key: col.field }));

    const csvRows = export_csv_datas.map((row) => {
      const rowData: { [key: string]: any } = {};
      columns.forEach((col) => {
        rowData[col.field] = row[col.field];
      });
      return rowData;
    });

    return { headers: csvHeaders, data: csvRows };
  };

  const csvData = prepareCsvData();

  return (
    <Box display={'flex'} alignItems="center">
      <IconButton
        disabled={loading}
        onClick={exportcsv}
        sx={{ color: theme.palette.text.primary, borderRadius: '0px' }}
      >
        {loading ? (
          <Typography sx={{ color: 'white', fontSize: '15px' }}>Loading...</Typography>
        ) : export_csv_datas.length > 0 ? (
          <CSVLink
            headers={csvData.headers}
            data={csvData.data}
            filename={csvFileName}
            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
          >
            <FileDownloadOutlinedIcon sx={{ fontSize: '20px', color: theme.palette.text.primary }} />
            <Typography component={'span'} sx={{ px: 1, color: theme.palette.text.primary, fontSize: '15px' }}>
              Export as CSV
            </Typography>
          </CSVLink>
        ) : (
          <>
            <FileDownloadOutlinedIcon sx={{fontSize:'15px' }} />
            <Typography component={'span'} variant="body1" sx={{ px: 1 }}>
              Prepare for CSV
            </Typography>
          </>
        )}
      </IconButton>
    </Box>
  );
};

export default ExportCsv;
