import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import CustomInput from '../../../MUI/Components/CustomInput';
import { useTheme } from '@emotion/react';
import CustomButton from '../../../MUI/Components/CustomButton';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { userService } from '../../../actions/auth_actions';
import { useEffect } from 'react';
import { get, set } from '../../../helpers/storage_helper';
import { changeHeaderSlice } from '../../../Redux/Slices/HeaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { bg_box, input_style, login_box } from '../../../styles/common_styles';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { powered_by_text } from '../../../helpers/common_helper';

const ForgetPassword = () => {
    const { watch,control,handleSubmit,formState: { isValid, errors }} = useForm({mode: "onChange"});
    const theme = useTheme()
    const [loader, setloader] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {brand_info} = useSelector(state => state.common_slice);

    useEffect(() => {
        // if (get('otp_id')) {
        //     navigate('/validate_otp')
        //     // setVerification(true)
        // }
        // if (get('otp_verified_token')) {
        //     setResetpassword(true)
        // }
    }, [])


    const send_otp = () => {
        setloader(true)
        const email = watch('email') ?? get('otp_receiver_email')
       
        userService.send_login_otp(email)
            .then((response) => {
                if (!response) {
                    setloader(false)
                    return
                }
                if (response && response.status == "success") {
                    set('otp_id', response.data.otp_id)
                    set('otp_receiver_email', email)

                    dispatch(changeHeaderSlice({ "otp_response": response.data }))

                    // setVerification(true)
                    navigate('/validate_otp')
                    setloader(false)
                }
            })
    }

    const wrapper_box = {
        padding: '40px'

    }
    const sign_in_button = {
        backgroundColor: theme.palette.custom_colors.secondary_500,
        color: theme.palette.custom_colors.secondary_a_50,
        borderRadius: '10px',
    }

    const logo_style = {
        background:'white',
		borderRadius:'50%'
    }

    return (
        <Box sx={[bg_box, { backgroundColor: theme.palette.custom_colors.old_primary_900, }]}>
            <Box sx={[login_box, { backgroundColor: theme.palette.primary.main, }]}>
                <Box sx={wrapper_box} component={'form'} onSubmit={handleSubmit(send_otp)}>
                    <Grid container alignItems="start" justifyContent="space-between" >
                        <Grid item xs={8} lg={8}>
                            <Typography variant="m_poppins_semibold_white" component={'div'} >
                                Forgot password
                            </Typography>
                            <Typography variant="m_inter_regular_gray" component={"p"} >
                                Enter your credentials to continue
                            </Typography>
                        </Grid>
                        <Grid item xs={4} lg={4} className='d-flex justify-content-end'>
                        <Box sx={brand_info?.footer ?logo_style:{} }>

                                <img
                                style={brand_info?.style??{}}
                                src={brand_info?.logo}
                                alt="Flow Logo white"
                                loading="lazy"
                                className='login_logo mt-2'
                            />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box>
                        <Box className='d-flex justify-content-center' sx={{ margin: "54px 0px " }}>
                            <Typography variant="s_inter_medium_gray" sx={{ textAlign: 'center' }}>
                                Enter your email address below and we’ll send you password reset verification code
                            </Typography>
                        </Box>
                        <Box>
                            <Box sx={{ margin: "20px 0px 60px 0px" }}>
                            <CustomInput
                                name="email"
                                control={control}
                                sx={[input_style,{borderColor: theme.palette.custom_colors.secondary_a_400,}]}
                                variant="outlined"
                                label="Email address"
                                type='text'
                                error={!!errors.email}
                                className="default_outlined_variant"
                                id="outlined-basic"
                                rules={{ required: 'Email is required' ,
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                      }
                                }}
                            />
                            </Box>
                        </Box>
                    </Box>
                    <CustomButton type={"submit"} isLoading={loader} component='button' variant="full_width_inter_sm" text={ "Send"} onClick={() => {send_otp()}} style={sign_in_button} isDisabled={!isValid} />
                    { brand_info?.footer &&
                        <Box sx={{mt:2}}>{
                        powered_by_text(brand_info?.footer.logo,theme.palette.custom_colors.secondary_a_400 )
                        }</Box>
                        }
                </Box>
            </Box>
        </Box>

    );
};

export default ForgetPassword;