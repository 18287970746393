import React from 'react';
import Select from 'react-select';
import {Alert,Button,Modal} from 'react-bootstrap';

import FlowComponent from '../../common/component/core/FlowComponent';
import {dd_value} from '../../../actions/common_actions';
import {submitCheckout,fieldVisits,checkLastVisit} from '../../../actions/field_visit_actions';
import {getCustProfile} from '../../../actions/borrower_actions';
import {get} from '../../../helpers/storage_helper';
import {  Navigate } from 'react-router-dom';
import {format_date} from '../../../helpers/common_helper';
import moment from 'moment';
import withRouter from '../../../Router/Withrouter';
class CheckIn extends FlowComponent{
    
    state = {
              server_time : {}, 
              checkout_req :{},
              toSuccess : null,
              timer_sec :0,
              timer : '',
              timer_min:0,
              min_diff :0,
              sec_diff : 0,
              select_value : [],
              checkin_path :"",
              alert_msg :false,
              modal2_show:false
       }

       
     
componentWillMount(){
    alert("Keep this browser window open and continue your other visit activites.\nOnce all the activities are completed comeback to this browser window and checkout this visit")

    this.server_time()
    if(this.state.timer_sec == 0){
            this.refresh_timer()
        }
        else{
            this.setState({timer_sec:0,timer_min:0})
        }
        const queryParams = new URLSearchParams(window.location.search);
        const checkin_path = queryParams.get('type');
        this.setState({checkin_path})
        const id = this.props.params.id
    this.cust_id = this.props.params.cust_id
    getCustProfile(this.req({cust_id : this.cust_id, incl_addr : true}, true)).
        then((response) =>{
            this.resp(response);
             if(!response){return };
             if(response.status === "success"){
                 if(response.data){
                     this.setState({...response.data, cust_id : this.cust_id})
                 }
                
             }
             else{
               alert(response.message  +"\n" + JSON.stringify(response.data));  
             }
        } )
        fieldVisits(this.req({id})).
            then((response)=>{
                if(!response){return };
             if(response.status === "success"){
                const visit_start_time = response.data.visit_start_time
              const sec_diff = response.data.sec_diff
              const min_diff = response.data.min_diff
              this.setState({visit_start_time,timer_sec:sec_diff,timer_min : min_diff})
          }
            })
  }

  tick() {
     
        if(this.state.timer_sec == 0 || this.state.timer_sec != 0 ){
        this.setState({
          timer_sec: this.state.timer_sec + 1,
           });
        if(this.state.timer_sec >= 60){
            this.setState({
                timer_min:this.state.timer_min +1,
                timer_sec : 0
            })
        }
      }else{

        this.refresh_timer()
      }
      // if(this.state.timer_min == 15 && this.state.timer_sec == 0) {
      //   this.setState({btn_style:false})
      // }
    }

    refresh_timer = () =>{
        setInterval(() => this.tick(),1000) 
    }
  
  validate_search(checkout_req) {
     if(checkout_req.remarks && checkout_req.visit_purpose ){
      return true
    }else{
      alert("Please enter required fields")

      return false
    }
    
  }


  handleCheckout = (event) =>{
        this.server_time()
        var checkout_req = this.state.checkout_req;
        if(checkout_req.remarks && checkout_req.visit_purpose){
         checkout_req.id = this.props.params.id;
         checkout_req.cust_id = this.state.cust_id
         submitCheckout(this.req({checkout_req}))
             .then((response) => {
                this.resp(response);
                    if(!response){return };
                    
                 if(response.status === "success"){
                    const  visit = response.data
                    var min_diff = this.state.min_diff
                    var sec_diff = this.state.sec_diff
                    var modal2_show = this.state.modal2_show

                    if(visit.action == "to_success"){
                        alert(response.data.message)
                        const checkin_path = this.state.checkin_path
                        var toSuccess = this.state.toSuccess
                        if(checkin_path == "log_visit"){
                            var toSuccess = false
                        }else{
                         var toSuccess =true
                      }
                    }
                    if(visit.action == "show_error"){
                        min_diff = visit.min_diff
                        sec_diff = visit.sec_diff
                        modal2_show = true
                    }

                    this.setState({toSuccess,min_diff,sec_diff,modal2_show})
                 }
                 else{
                   alert(response.message  +"\n" + JSON.stringify(response.data)); 
                 }
            })
        }else{
            this.setState({modal_show : true})
        }    
  }  
  handleCheckoutChange = (event) =>{
      var checkout_req = this.state.checkout_req
      checkout_req[event.target.id] = event.target.value
      this.setState({checkout_req});
  }
  
  handlePurposeChange = (selectedOptions) =>{
       const select_value = selectedOptions
       selectedOptions = selectedOptions.map((item) => ( item.value));
       if( (selectedOptions.includes('overdue_visit')) && (this.state.is_og_loan_overdue !== 1) )
       {
          alert("Customer do not have an overdue FA");
       }else{
           var checkout_req = this.state.checkout_req
           checkout_req.visit_purpose = selectedOptions
       }
        this.setState({checkout_req, select_value});

  }

    handleWarning = () =>{
        this.setState({modal2_show :false})
    }

  checkoutDelayAlert =() => {
    
  return (
    <>
    <Modal show = {this.state.modal2_show} variant = "danger" >
        <Modal.Header>
            <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
           <h4 >Please ensure all the visit activities are performed before you checkout.<br/>
           You have checked-in just {this.state.min_diff} minutes and {this.state.sec_diff} seconds ago</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleWarning} variant="danger">
            Ok
          </Button>
    </Modal.Footer>
    </Modal>

    </>
  );
}  


render(){
    if (this.state.toSuccess === true) {
      
      return <Navigate to={`/borrower/indiv/view/${this.state.cust_id}`}/> 
    }else if(this.state.toSuccess === false){
      return <Navigate to={"/"}/> 
    }

    return(
        
        
        <div className="container">
            <br/>
                {this.checkoutDelayAlert()}
                {this.vaildationAlert(this.state.modal_show)}       
           
            <h4>Customer Visit for <b>{this.state.cust_name} | {this.state.biz_name}</b> <span className = "float-right">Timer : {this.state.timer_min}m {this.state.timer_sec}s </span></h4>
            <br/>
            <form>
            <div className="rule-form-group  form-group row reset-margin">
                   <label for="" className="col-sm-5 mb-3 label__name font-weight-bold reset-padding"> Cust ID </label>
               <div className="col-sm-6 reset-padding">
                   <div>
                        <label className="" id = "cust_id" >{this.state.cust_id} </label>
                   </div>
                   <div className="control-me"></div>
               </div>
            </div>
             <div className="rule-form-group  form-group row reset-margin">
                   <label for="" className="col-sm-5 mb-3 label__name font-weight-bold reset-padding">Customer Name</label>
               <div className="col-sm-7 reset-padding">
                   <div>
                        <label className="" id = "cust_id" >{this.state.cust_name}</label>
                   </div>
                   <div className="control-me"></div>
               </div>
            </div>
            <div className="rule-form-group form-group row reset-margin">
                   <label for="" className="col-sm-5 mb-3 font-weight-bold  label__name reset-padding">Address</label>
               <div className="col-sm-7 reset-padding">
                   <div>
            <label className="" id = "cust_id">{this.state.cust_addr_txt}</label>
                   </div>
                   <div className="control-me"></div>
               </div>
            
            </div>
             <div className="rule-form-group form-group row reset-margin">
                   <label for="" className="col-sm-5 mb-3 font-weight-bold  label__name reset-padding">Location</label>
               <div className="col-sm-7 reset-padding">
                   <div>
            <label className="" id = "cust_id">{dd_value(this.state.location)}</label>
                   </div>
                   <div className="control-me"></div>
               </div>
            
            </div>
            <div className="rule-form-group form-group row reset-margin">
                   <label for="" className="col-sm-5 mb-3 font-weight-bold  label__name reset-padding">Check In At</label>
               <div className="col-sm-7 reset-padding">
                   <div>
                           <b><label style={{color:'blue'}} className="" id = "visit_start_time">{ format_date(this.state.visit_start_time,true)}</label>
                             </b>      
                   </div>
                   <div className="control-me"></div>
               </div>
            
            </div>

            <div className="rule-form-group form-group row mx-0 mb-3  ">
                   <label for=""  className="col-sm-5 font-weight-bold label__name reset-padding">Visit Purpose<font className="text-danger">   *  </font></label>
               <div className="col-sm-7 reset-padding">
                  
                      <Select onChange={(e) =>{this.handlePurposeChange(e);}}
                              value = {this.state.select_value}
                              options={this.getMultiSelectOptions('visit_purpose')} isMulti/>

                   <div className="control-me"></div>
               </div>
            
            </div>
            <div className="rule-form-group form-group row reset-margin ">
                   <label for="" className="col-sm-5 font-weight-bold label__name reset-padding">Remarks<font className="text-danger">   *  </font></label>
               <div className="col-sm-7 reset-padding">
                   <div>
                   <textarea type="text" id="remarks" className="form-control" onChange={(e) =>{this.handleCheckoutChange(e);}}  maxLength='256'/>
                  </div>
              </div>

            </div>
            {/*<div className="rule-form-group form-group row reset-margin">
            <label class="cameraButton">Take a picture</label>
               <div className="col-sm-7 reset-padding">
                   <div>                     
                           <input type="file" accept="image/*" capture=""/>
                                            
                   </div>
                   <div className="control-me"></div>
               </div>
            
            </div>*/}
            <br/>
            <button type="button" className= "btn btn-primary float-right mr-1 mb-3" onClick={this.handleCheckout} disabled={this.state.calling_api}>Checkout</button>
          <br/>
          <br/>
          </form>

        </div>
        
        )
}

}

export default  withRouter(CheckIn);