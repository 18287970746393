import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {format_date, getValueFromLangJson, title_case} from '../../../helpers/common_helper';
import FileUpload from './FileUpload';
import DatePicker from 'react-datetime';
import {Edit} from '../../common/partial';
import {Button} from "react-bootstrap";
import {resetAppUserPassword, resetInvUserPassword} from "../../../actions/person_actions";
import {get} from '../../../helpers/storage_helper';
import { getCreateUserPrivilege } from '../../../actions/common_actions';
import moment from 'moment';
class Person extends FlowComponent {
   state = {
    comp_name: "person",
    initials:null,
    date: new Date(),
    create_user: false,
    role_codes:[]
  }

  componentWillMount() {
    super.componentWillMount();
    this.load_flow_rel_mgr();
    this.load_territory_manager();
    this.setOptionsListToStateFromAppApi('common', 'list', this.def_req , ['acc_prvdr_code', 'name'], "acc_prvdrs")

    if(window.location.pathname == '/user/create' || this.props.title =='Edit User'){
      getCreateUserPrivilege(this.req()).then((response)=>{
        if(!response){return};
        if(response.status=='success'){
          this.setState({role_codes:response.data})
        }
        else{
          return;
        }
          
      })
    }
  }
  componentWillUnmount(){
    this.props={}
  }

    handleKeyUp = targetId => (event) => {
         this.props.onComponentChange(event,this.props.id);
         const value = event.target.value.charAt(0).toUpperCase();
         document.getElementById(targetId).value = value;
         this.props.data[targetId] = value;
  }

    handleUserCheckboxClick = (event) => {
      if(event.target.checked){
          
          this.setState({create_user: true});
      } else {
        
          this.setState({create_user: false});
          
          
          const keys = ['role', 'report_to'];
          keys.forEach((key) => {
            
              const resetEvent = {
                  target: {
                      id: key,
                      value: ""
                  }
              };
              this.handleChange(resetEvent);
          });
      }
      
      
      event.target.value = event.target.checked;
      this.handleChange(event);
  };


    sendResetPasswordMail =() => {
      var request = null
      if(!this.props.data.bcc_email){
        var request = {email: this.props.data.email_id}
       }
       else{
        var request = {email:this.props.data.email_id ,bcc_email:[this.props.data.bcc_email]}
       }
        
        if(this.props.data.user === 'app_user' || (this.props.data.user === 'investor_user' && !this.props.data.investor_type)){
            resetAppUserPassword(this.req(request)).then((response) => {
                this.resp(response)
                if(response&&response.status=='success')alert('Email sent successfully')
            })
        }
        else if(this.props.data.user === 'investor_user' && this.props.data.investor_type =='bond_investor' ){
          const req ={email:this.props.data.email_id ,bcc_email:[this.props.data.bcc_email] }
            resetInvUserPassword(this.req(request)).then(response => {
                this.resp(response)
                if(response&&response.status=='success')alert('Email sent successfully')
            })
        }
    }


    get_investor_role_codes = (role_key)=>{
      let investor_roles = this.getSelectOptions('role_code', true, null, true).filter((val)=>role_key.includes(val.data_code))
      let options = investor_roles.map((item, index) => <option key={index} value={item.data_code}>{item.data_value}</option>);
      return options;
    }

    changeDropDowm = (event) =>{

      this.setState({under_tm : null, report_to : null},()=> this.handleChange(event))

    }

    is_validate = (e) => {

      let role = e.target.value;

      if(role == 'relationship_manager' && (this.state.flow_territory_mgr?.length <= 1 ||  this.state.flow_territory_mgr_dd?.length <= 1)){
        this.props.chk_validation()
      }
    }


  render(){
    const maxDate = moment().subtract(18, 'years').toDate(); 
    const isValidDate = (current) => {
      return moment(current).isSameOrBefore(maxDate);
    };
    return (
      <div id="" className='date-range'>
          <div className="row floatAdvancetitleBox agreementDateHeaderBox align-items-center no-margin">
            <div className="col-md-4"><h4 className='no-margin headerTitle'>{this.props.title ? this.props.title : ""}</h4></div>

            <div className={`col-md-1 ${this.state.edit_button_style}`}>
              <Edit entity={this.props.entity} onClick={this.handleEdit}/>
            </div>
          </div>
          <div className={`${this.state.div_style} no-padding-input`}>
            <div className="row no-margin labelTopgap align-items-end">
                <div className="col-md-3">
                  <span>First Name</span><font className="text-danger">   *  </font>
                  <input id="first_name" value={this.props.data.first_name}   onChange={this.toUpperHandleChange} type="text" className="upperCase form-control" required="required"/>
                </div>
                <div className="col-md-3">
                  <span>Middle Name</span>
                  <input id="middle_name" onChange={this.toUpperHandleChange}  value={this.props.data.middle_name} className="form-control" type="text"  />
                </div>
                <div className="col-md-3">
                  <span>Last Name</span><font className="text-danger">   *  </font>
                  <input id="last_name" value={this.props.data.last_name}  onChange={this.toUpperHandleChange} className="form-control" type="text" />
                </div>
                {this.props.invstr_key != 'investor' && <div className="col-md-3">
                  <span>Date of birth </span>
                     <DatePicker  
                     isValidDate={isValidDate}
                     value={format_date(this.props.data.dob)} closeOnSelect={true} onChange={(value) => {this.handleDateChange("dob", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                </div>}
                {this.props.invstr_key == 'investor' && 
                <><div className="col-md-3">
                  <span>Email BCC</span><font className="text-danger">   *  </font>
                  <input id="bcc_email" value={this.props.data.bcc_email}  onChange={this.handleChange} className="form-control" type="text" readOnly={this.props.invstr_key =='investor'}  />
                </div>
                  </>}
            </div>
            <div className="row no-margin labelTopgap align-items-end">
              {/* {this.props.invstr_key != 'investor' &&  */}
              <div className="col-md-3">
                <span>Gender</span><font className="text-danger">   *  </font>
                  <select id="gender" value={this.props.data.gender ? this.props.data.gender : 'choose'} defaultValue={this.props.data.gender ? this.props.data.gender : 'choose'} onChange={this.handleChange} className="form-control" type="text" >
                    {this.getSelectOptions("gender")}
                  </select>
              </div>
              {/* } */}
              <div className="col-md-3">
                <span>Email ID</span>
                  { (this.props.id === "owner_person" )  ?
                   <div></div>
                  :<font className="text-danger">   *  </font>
                  }
                  <input id="email_id" value={this.props.data.email_id}  className="form-control"  onChange={this.handleChange} type="text" required="required" />
                </div>
              {this.props.invstr_key == 'investor' && 
              <div class={`col-md-3 ${!this.props.data.user ? '' : 'd-none'}`}>
              <select id='role' name="User Roles" title='Role' onChange={this.handleChange} className="form-control" type="text" required="required">
                  {this.get_investor_role_codes(['bond_investor', 'investor'])}
              </select>
              </div>}
              {this.props.invstr_key != 'investor' &&<div className="col-md-3">
                <span>Mobile Number</span>
                <input id="mobile_num" value={this.props.data.mobile_num}  onChange={this.handleChange} onKeyUp={this.handleMakeCopy("whatsapp")} className="form-control" type="text"/>
              </div>}
             {this.props.invstr_key != 'investor' && <div className="col-md-3">
                <span>Alternate Biz Mobile Number 1</span>
                <input id="alt_biz_mobile_num_1" value={this.props.data.alt_biz_mobile_num_1}  onChange={this.handleChange}  className="form-control" type="text"/>
              </div>  }         
            </div>            
            {this.props.invstr_key != 'investor' && <div className="row no-margin labelTopgap align-items-end">
                 <div className="col-md-3">
              <span>Alternate Biz Mobile Number 2</span>
            <input id="alt_biz_mobile_num_2" value={this.props.data.alt_biz_mobile_num_2}  onChange={this.handleChange}  className="form-control" type="text"/>
            </div>
            <div className="col-md-3">
              <span>WhatsApp Number</span>
              <input id="whatsapp" value={this.props.data.whatsapp}  onChange={this.handleChange}  className="form-control" type="text"/>
            </div>

                <div className="col-md-3">
                  <span>National ID</span>
                  <input type="text" id="national_id" value={this.props.data.national_id} onChange={this.handleChange} className="form-control" required="required"/>
                </div>

                {this.props.invstr_key !== 'investor' && 
                <div class={`col-md-3  `}>
                      <span>Role</span><font className="text-danger">   *  </font>
                      <select id='role' value={this.props.data.role} name="User Roles" onChange={(e) => {this.handleChange(e); this.props.changeRole(true),this.is_validate(e)}} className="form-control" type="text" required="required">
                          <option value="">Choose</option>
                          {this.state.role_codes && this.state.role_codes.length>0 ? this.state.role_codes.map((val,index)=>{
                            return(<option key={index} value={val.data_code}>{title_case(val.data_value)}</option>)
                          }):''}
                      </select>
                </div>
                }

            </div>  }  
            
              {this.props.title == "Business Owner Details" &&
                <div>
                  <div className="row no-margin labelTopgap align-items-center">

                      <div className="col-md-6">
                        <FileUpload id="photo_national_id" label="National ID Photo" type="image" mode={this.props.mode}
                        person_id={this.props.data.id}
                        db_file_name={this.props.data.photo_id_proof}
                        editField={(e)=>this.setEditedFields(e)}
                        onEditUpload={(e) => {this.handleChangeForEdit(e)}} onRemove={()=> window.location.reload()} onCreateUpload={this.handleChange}/>
                      </div>
                  </div>
                  <br/>
                  <div className="row no-margin labelTopgap align-items-center">
                    <div className="col-md-6">
                        <FileUpload id="photo_national_id_back" label="National ID Back Photo" type="image" mode={this.props.mode}
                        person_id={this.props.data.id}
                        db_file_name={this.props.data.photo_id_proof_back}
                        editField={(e)=>this.setEditedFields(e)}
                        onEditUpload={(e) => {this.handleChangeForEdit(e)}} onRemove={()=> window.location.reload()} onCreateUpload={this.handleChange}/>
                    </div>
                  </div>
                  <br/>
                  <div className="row no-margin labelTopgap align-items-center">
                    <div className="col-md-6">
                        <FileUpload id="photo_lc_letter" label="LC Letter Photo" type="image" mode={this.props.mode}
                        person_id={this.props.data.id}
                        db_file_name={this.props.data.photo_lc_letter}
                        editField={(e)=>this.setEditedFields(e)}
                        onEditUpload={(e) => {this.handleChangeForEdit(e)}} onRemove={()=> window.location.reload()} onCreateUpload={this.handleChange}/>
                    </div>
                  </div>
                  <br/>
                  <div className="row no-margin labelTopgap align-items-center">
                    <div className="col-md-6">

                      <FileUpload id="photo_pps" label="Passport Size Photo" person_id={this.props.data.id}
                      db_file_name={this.props.data.photo_pps} type="image"
                      editField={(e)=>this.setEditedFields(e)}
                      mode={this.props.mode} onEditUpload={(e) => {this.handleChangeForEdit(e)}} onRemove={()=> window.location.reload()} onCreateUpload={this.handleChange}/>

                    </div>

                  </div>                   
                   <p className='no-margin labelTopgap' style={{color: "red"}}>Note : Ensure the photos in the National ID, Passport Photo matches with the person in Selfie</p>
                   <div className="row no-margin labelTopgap align-items-center">
                    <div className="col-md-6">

                      <FileUpload id="photo_selfie" label="Selfie with Customer" person_id={this.props.data.id}
                      db_file_name={this.props.data.photo_selfie} type="image"
                      editField={(e)=>this.setEditedFields(e)}
                      mode={this.props.mode} onEditUpload={(e) => {this.handleChangeForEdit(e)}} onRemove={()=> window.location.reload()} onCreateUpload={this.handleChange}/>

                    </div>

                  </div>
              </div>

              }

              {this.props.id === 'person' && <div className="row mb-5 mt-4 ml-2">

                        {/* {this.props.data.user == null && this.props.invstr_key !='investor' && <label className="form-check-label">
                          <input id='create_user' type="checkbox" className="form-check-input" defaultValue={false} value={this.props.data.create_user} onClick={this.handleUserCheckboxClick}/>Create User</label>
                          } */}

                        {this.props.data.user && this.props.data.user !== 'app_user' && <>
                        <div class='col-3'>
                        {(this.props.data.user=='investor_user'&&!this.props.data.investor_type)?<h5 className='sizel'>
                        Investor with App Privileges
                        </h5>:<h5 className='sizel'>{title_case(this.props.data.user)}</h5>
                        }
                      </div>
                        </>
                    }
                    {/* <div class={`col-3 ${this.state.create_user ? '' : 'd-none'} `}>
                      <span>Role</span>
                      <select id='role' value={this.props.data.role} name="User Roles" onChange={(e) => {this.handleChange(e); this.props.changeRole(true),this.is_validate(e)}} className="form-control" type="text" required="required">
                          <option value="">Choose</option>
                          {this.state.role_codes && this.state.role_codes.length>0 ? this.state.role_codes.map((val,index)=>{
                          return(<option key={index} value={val.data_code}>{title_case(val.data_value)}</option>)
                        }):''}
                      </select>
                    </div> */}
                    {this.props.data.create_user == 'true' && 
                      <>
                        {this.props.data.role === 'sales_representative' && 
                            <div className="col-md-3">
                              <span>{getValueFromLangJson("rm_label")}</span>
                                <select className="form-control"  title={getValueFromLangJson("rm_label")} value={this.props.data.report_to} id="report_to" onChange={(e) => { 
                                        this.handleChange(e);
                                      
                                        // this.setState({ selectedSalesOption: e.target.value, selectedTerritoryOption: '' }); // Reset other dropdown
                                    }}>
                                   
                                    {this.state.flow_rel_mgr_dd}
                                </select>
                            </div>
                           }

                        {(this.props.data.role === 'relationship_manager' && this.props.is_validate) &&
                            <div className="col-md-3">
                              <span> Territory Manager</span>
                                <select  className="form-control" title=" Territory Manager" value={this.props.data.report_to} id="report_to" onChange={(e) => { 
                                        this.handleChange(e);
                                  
                                        // this.setState({ selectedTerritoryOption: e.target.value, selectedSalesOption: '' }); // Reset other dropdown
                                    }}>
                                    
                                    {this.state.flow_territory_mgr_dd}
                                </select>
                            </div>
                            
                        }
                      </>
                    }

                <div className={`col-1 ${this.state.save_button_style} justify-content-end`}>
                  {/* <input  onClick={(e)=>this.handleSave(e)} type="button" className="btn btn-success btn-std" value="Save"/> */}
                  <Button variant='success' onClick={(e)=>this.handleSave(e)} disabled={this.state.calling_api}>Save</Button>
                </div>

                  {this.props.data.user && <div class='col-3'>
                      <Button variant='primary' onClick={this.sendResetPasswordMail} disabled={this.state.calling_api}>Send Reset Password E-mail</Button>

                    </div>}


            </div>}


            <br/>
          </div>
      </div>

    );
  }

}
Person.defaultProps = {
                            designation: 'text'
                          }

export default Person;
