import React, { Component } from "react";
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import "./../../../styles/login.css";
import {userService} from "./../../../actions/auth_actions";
import {Navigate} from 'react-router-dom';
import {get, add_all,set, get_item} from  './../../../helpers/storage_helper';
// import {withRouter}  from  'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import S3 from 'aws-sdk/clients/s3';
import { Markets } from "../../../actions/common_actions";
// import Flag from "react-flags";
import { IoIosGlobe } from "react-icons/io";
import SelectMarket from "../../home/SelectMarket";
import { connect } from "react-redux";
import withRouter from "../../../Router/Withrouter";
import { setValuesIntoRedux } from "../../../Redux/Slices/CsSoftphoneSlice";
class Login extends Component {
  constructor(props) {
    super(props);
     
    this.state = {
      email: "",
      password: "",
      //country_code: "",
      uuid : uuidv4(),
      submitted: false,
      loading: false,
      loggedin: false,
      error: '',
      markets:get('markets') ? get('markets'):"",
      country:{
        country_code:"",
        country:''
      },
      admin_roles : ['super_admin', 'ops_admin', 'market_admin', 'it_admin', 'ops_analyst'],
      screen:"market",
      role_codes :null

      
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0 ;
  }

  componentWillMount(){

    if(!get('market_details')){

      if(process.env.REACT_APP_UI_VERSION && process.env.REACT_APP_UI_VERSION!="local"){
        this.setCountry()
        this.getJSONfromSDK()
      }
  
    }
  
    localStorage.setItem('error_alert', true);
    this.loadUser()
  }

  setCountry(){
    let url = window.location.href
    let test_url = "http://13.234.42.35:5555";
    var countryCode=""
    if (url.startsWith(test_url)) {
       countryCode = "UG"; 
    } else {
      const startIndex = url.indexOf("https://") + "https://".length;
      const text = url.substring(startIndex);
      const parts = text.split('.');
       countryCode = parts[0]; 
    }
    var country={}
    country['country_code'] = (countryCode=="core")?"ug": countryCode
    set("market_details",country)  
  }

  getJSONfromSDK(){
    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY,
      region: "ap-south-1",
    });

    const getObjectParams = {
      Bucket: process.env.REACT_APP_UI_VERSION == "LIVE" ? "flow-api-config" : "test-bucket-lambda-07", 
      Key: process.env.REACT_APP_UI_VERSION == "LIVE" ? "LIVE.json" : "TEST_2.json",
    };

    s3.getObject(getObjectParams, (err, data) => {
      if (err) {
        console.error("Error fetching data from S3:", err);

        // Check if the error is due to connectivity issues
        if (err.code === "NetworkingError") {
          console.error(
            "There is an issue with the network connection to the S3 bucket."
          );
        }
        // Check if the error is due to incorrect AWS credentials
        if (err.code === "CredentialsError") {
          console.error(
            "Check your AWS credentials (access key ID and secret access key)."
          );
        }
      } else {
        try {
          console.log('data', data.Body.toString())
          const jsonData = JSON.parse(data.Body.toString());
          set("jsonData",jsonData)
          this.setState({jsonData:jsonData})

         } catch (jsonParseError) {

          console.error("Error parsing JSON data:", jsonParseError);
        }
      }
    });
  }
  getMarketLists(){
    Markets().then((response)=>{
      if(!response){return}
      if(response){
        this.setState({markets:response.data.list})
        set('markets',response.data.list)
      }
  })  

 }

 
 

  handleChange = event => {
    alert("hi")
    this.setState({
      [event.target.id]: event.target.value
    });
    //if(event.target.id == "country_code"){
    //  set('country_code', event.target.value);
    //}
  }
  
  countrySelecthandler(country_code, country) {

    this.setState(prevState => ({
      country: {
        ...prevState.country,
        country_code: country_code,
        country: country,
      },
      show_alert:false
    }),()=>{set("market_details",this.state.country)});

  }
  handleSubmit = event => {

    event.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    const uuid = this.state.uuid;
    // const country_code = this.state.country_code;

    {/*if (country_code == "") {

      alert("Please select the country code");

    } else {
    */}
      this.setState({ loading: true });
      userService.login(email, password, uuid)
      
      .then(response => {
        if (!response) {return}
        if (response.status === "success") {               
            add_all(response)
            this.props.setValuesIntoRedux({is_login:true})
            localStorage.setItem('uuid', JSON.stringify(uuid));

            // this.props.onLogon();
            this.loadUser()
        } else {
            this.setState({ error: response.status, loading: false });
        }
      });
    
    
    //}
  }

  loadUser() {
    
    let role_codes = get('role_codes');
    let screen = null;
    let acc_prvdr_code = null;
    let market = get('market');


    const admin_roles = ['super_admin', 'ops_admin', 'market_admin', 'it_admin', 'ops_analyst']

    
    if(admin_roles.includes(role_codes) ){
      acc_prvdr_code = get_item('acc_prvdr_code');
      if(!market){   
        this.setState({screen:"market"})
      }else  if(!acc_prvdr_code){     
        this.setState({screen:"acc_prvdr_only"})
      }
      if(market && acc_prvdr_code){     
         // Show screen for Super Admin for the 'market' 
          this.props.navigate('/')

        } 
    }
    else{
      this.props.navigate('/')
    }
  
  }

  render() {
    if(!get('role_codes')){
        const { loading, error } = this.state;
        return (
        <div className="Login no-padding" style={{height:'100vh',position:'relative'}}> 
          <div className="loginBox">       
            <div className="text-center">
              <img src="/public/img/Logo/Flow/flow_text_white.png" width="200" alt="/logo_blue.png" className="img-responsive"/>
            </div>           
            <form onSubmit={this.handleSubmit}>
              {/*<select value={this.state.country_code} id="country_code" onChange={this.handleChange} style={{"height":"35px", "width":"100%"}} className="mt-5">
                <option value="">Country Code</option>
                <option value="UGA">Uganda</option>
                <option value="RWA">Rwanda</option>
              </select>*/}
              <div>

              {/* <div  className={`dropdown country_dropDownbg dark_theme_bg w-100 mx-0 my-4 ${this.state.show_alert ? "danger_border":""}`}  >
                                    <div className="dropdown-toggle d-flex align-items-center dropdown_trig justify-content-between px-3 py-3" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    {this.state.country.country_code !="" ? 
                                      <>
                                      <div>
                                      <Flag id="market" name={this.state.country.country_code} format="png" pngSize={48} shiny={false} alt={this.state.country.country_code} basePath="/img/flags"/>
                                      <span className="ml-2" >{this.state.country.country ? this.state.country.country : ""}</span>
                                      </div>
                                      
                                      <span><i className="fa-solid fa-chevron-down"></i></span>
                                      </> 
                                      :
                                      <>
                                      <div>
                                      <IoIosGlobe size={20} style={{margin:'4px 0px'}}/>
                                      <span className="ml-2">{this.state.country.country ?this.state.country.country:'Country' }</span>
                                      </div>
                                 
                                      <span ><i className="fa-solid fa-chevron-down"></i></span>

                                      </>
                                     }
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{width:'inherit'}}>
                                        
                                        { this.state.markets &&   this.state.markets.map((each,idx)=>
                                        <li key={idx} className="country_selector" onClick={()=>this.countrySelecthandler(each.id,each.name)}>
                                            <Flag id="market" name={each.id} format="png" pngSize={48} shiny={false} alt={each.id} basePath="/img/flags"/>
                                            <span style={{paddingLeft:5}}>{each.name}</span>
                                        </li>
                                    )}                          

                                    </ul>                        
              </div> */}
             
              </div>

              <FormGroup controlId="email" bssize="large">
                <Form.Label>Email</Form.Label>
                <FormControl
                  autoFocus
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>

              <FormGroup controlId="password" className="my-3" bssize="large">
                <Form.Label>Password</Form.Label>
                <FormControl
                  value={this.state.password}
                  onChange={this.handleChange}
                  type="password"
                />
              </FormGroup>
              <button bssize="large" type="submit" className="btn btn-primary btn-block my-2 py-3" disabled={!this.validateForm()}>Login</button>
              {loading &&
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" 
                  alt="Image not found" />
              }
              {error &&
                <div className={'alert alert-danger'}>{error}</div>
              }
            </form>
          </div>            
        </div>
      );
      }else{
        if(this.state.admin_roles.includes(get('role_codes')) && this.state.screen != "admin"){
          return <SelectMarket toChange={this.state.screen} action="select" onSelect={()=>this.loadUser()} onClose={()=>{window.location.reload()}}/>
        }
        else{
          return <Navigate to={'/'}/>
        }
      }
  }
}

const mapStateToProps = (state) => {
  return { redux_values: state.reduxValues};
};

const mapDispatchToProps = {
  setValuesIntoRedux
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Login));