import { Box, Grid, useTheme } from '@mui/material';
import React from 'react';
import RhfDatePicker from '../../CommonComponent/rhfComponents/rhfDatePicker';
import { Interface } from 'readline';
import { dd_value } from '../../../actions/common_actions';
import { useForm } from 'react-hook-form';
import moment from 'moment';

interface DateFilterProps {
    fields: { 
        key: string;
        label: string;
      }[];}

const DateFilter: React.FC<DateFilterProps> = ({
    fields
}) => {
    const theme = useTheme()

 
      
    return (
            <Grid container spacing={1}>
                {fields.map((item, idx) => {
                    return (
                        <Grid item xs={12} lg={6}>
                            <RhfDatePicker
                                name={item.key}
                                disableFuture
                                label={item.label}
                                format={'DD MMM YYYY'}
                                sx={{
                                    "& .MuiButtonBase-root": {
                                        svg: {
                                            width: "20px",
                                            height: "20px",
                                            color: (theme) =>
                                                // @ts-ignore
                                            theme.palette.custom_colors.secondary_a_200,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    )
                })}

            </Grid>


    );
};

export default DateFilter;