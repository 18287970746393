import React from 'react';
import {updateStatus} from '../../../../actions/common_actions';
import  {lbl_status, style_status,variant_color,variant_value} from '../../../../helpers/common_helper';
import  {check_priv} from '../../../../helpers/storage_helper';
import FlowComponent from './FlowComponent';
import {dd_value} from '../../../../actions/common_actions';
import { Modal , Button,Badge} from 'react-bootstrap';
class StatusButton extends FlowComponent{
	
	componentWillMount(){
		this.setState({status: this.props.status});
		
		var parent = this.props.parent;
		
		if(!parent){
			var entity = this.props.entity;

			parent = entity.slice(0,-1);
			if(parent == "borrower"){
				parent = "customer";
			}else if(parent == "loan_product"){
				parent = "product";
			}else if(parent == "acc_provider"){
				parent = "acc_prvdr";
			}else if(parent == "master_data_key"){
				parent = "data_key";
			}			

		}


		if(check_priv(parent , 'create')){
	        this.btn_style = false
	    }else{
	        this.btn_style = true
	    }

		if(this.props.hasOwnProperty('btn_disabled')){
			this.btn_style = this.props.btn_disabled
		}

	}


	componentWillReceiveProps(props){
		this.setState({status: props.status});
		
		var parent = props.parent;
		
		if(!parent){
			var entity = props.entity;

			parent = entity.slice(0,-1);
			if(parent == "borrower"){
				parent = "customer";
			}else if(parent == "loan_product"){
				parent = "product";
			}else if(parent == "acc_provider"){
				parent = "acc_prvdr";
			}else if(parent == "master_data_key"){
				parent = "data_key";
			}			

		}


		if(check_priv(parent , 'create')){
	        this.btn_style = false
	    }else{
	        this.btn_style = true
	    }

	}

	updateStatus = (event) => {
	const resp = window.confirm("Are you sure to change the status?");
	if(resp == false){
		return;
	}
	  let status = event.target.value.toLowerCase()+"d"; 
	  const request = {"id":event.target.id,status};
	  if(event.target.name == 'users'){
		  event.target.name = 'persons';
	  }
	  const request_data = {[event.target.name] : request}
	    updateStatus(this.req(request_data))
	       .then((response) => {
	        if(!response){return };
	            if(response.status === "success"){
	              this.setState({
	              	status
	              })
				  if(this.props.onStatusChange){
					this.props.onStatusChange(event.target.id, status)
				  }
				  if(this.props.acc_list){
					this.props.acc_list()
				  }
	              ///window.location.reload();
	            }else{
	              alert(response.message + "\n" + JSON.stringify(response.data));  
	            }
	          }
	        ); 
	      //this.setState({credit_score_factor_id: event.target.id, toView: true});
	   } 

	   render(){
		if(this.props.parent == 'master_data'){
			return (<>
				{<input id={this.props.id} type='button' name={this.props.entity} onClick={this.updateStatus} 
                              value={lbl_status(this.state.status)}  
                              className={style_status(this.state.status) + ' btn-sm'} disabled={this.btn_style}/>
							}
			</>)
		}

		else if(this.props.hasOwnProperty('head')){
			return(<>
			   {this.props.head=='1' &&  <td><Badge className="badge" pill variant={variant_color(this.state.status)}><span>{this.state.show_1l ? variant_value(this.state.status) : dd_value(this.state.status)}</span></Badge></td>}

			   {this.props.head=='2' && <td> <input id={this.props.id} type='button' name={this.props.entity} onClick={this.updateStatus}
                              value={lbl_status(this.state.status)}
                              className={style_status(this.state.status) + ' btn-sm'} disabled={this.btn_style}/>
						</td>}
			</>)
		}
	   	return(
	   		<>
	   		
			{((this.props.hasOwnProperty("hide_action") ? this.props.hide_action :true )) &&
	   		<td>
				<Badge className="badge" pill variant={variant_color(this.state.status)}><span>{this.state.show_1l ? variant_value(this.state.status) : dd_value(this.state.status)}</span></Badge>
			</td>
	      }

			{((this.props.hasOwnProperty("hide_action") ?!this.props.hide_action :true )) &&
	        <td>
				 <input id={this.props.id} type='button' name={this.props.entity} onClick={(e)=>this.updateStatus(e)} 
                              value={lbl_status(this.state.status)}  
                              className={style_status(this.state.status) + ' btn-sm'} disabled={this.btn_style}/>
			</td>
	       }

	        </>
	   		);
	   }
}

export default StatusButton;
