import React from 'react';
import moment from 'moment-timezone';
import { Config, get } from '../../../helpers/storage_helper';

const UpdateTime = ({ timestamp,country_code }) => {
//  let time_zone =  Config('country_time_zone_str')?.(get('market').country_code)
//   moment.tz.setDefault(time_zone);
  
  const specificTime = moment(timestamp);
  
  const CurrentTime = moment();
  
  const timeDifference = CurrentTime.diff(specificTime);
  
  const duration = moment.duration(timeDifference);
  
  const yearsDifference = duration.years();
  const monthsDifference = duration.months();
  const daysDifference = duration.days();
  const hoursDifference = duration.hours();
  const minutesDifference = duration.minutes();
  const secondsDifference = duration.seconds();

  return (
    <div className="message">
      <p className='m-0'>
       {(monthsDifference >0) ?  moment(timestamp).format(" DD MMM YYYY") :
        <> 
        {daysDifference > 0 && `${daysDifference} days ago `}
        
        <>
        {( daysDifference ) ? "":
        <>
        {hoursDifference >0 &&
          <>
         {(hoursDifference > 1 ) ? `${hoursDifference} hours ago` : `${hoursDifference} hour ago`}
         </>
        }
        </>
        }
        </>

        <>{( hoursDifference >0 || daysDifference >0 ) ? "":
        minutesDifference > 0 && `${minutesDifference} minutes ago`
        }
        </>

        <>{(hoursDifference >0 ||minutesDifference >0|| daysDifference>0 ) ? "":
           (secondsDifference > 0 ) && `${secondsDifference} seconds ago`
          }
        </>
        </>
      }
      </p>
    </div>
  );
};

export default UpdateTime;
