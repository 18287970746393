import React, { forwardRef } from 'react';
import {getInboundCallList} from '../../../actions/call_log_actions';
import {capitalize_first_letter, page_count, set_id_for_rows, title_case} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import CommonReactTable from '../../../helpers/commonReact_table';
import { Card ,Button} from 'react-bootstrap';
import InboundCallLog from '../component/inboundCallLog';
import { format } from 'date-fns';
import { Config } from '../../../helpers/storage_helper';
import { Link } from 'react-router-dom';
import { TooltipLabel } from '../../common/partial';
import { BasicReactTable } from '../../../helpers/react_table';
import { RoundOffSplitter } from '../../../helpers/common_helper';
import { Box } from '@mui/material';
import ExportCsv from '../../common/component/ExportCsv';
import DataGridTable from '../../CommonComponent/table';


class InboundCallList extends FlowContainer{


    state ={
        columns :[],
        call_list:[],
        copy :[],
        call_log_list:{},
        call_log_table:"hide_div",
        export_data : [],
        paginate : Config('paginate') ? Config('paginate') : null,
        total:0,
        loader : false,
        page: { page: 0, pageSize: 10 },


    }

    componentWillMount(){
        this.handleCurDate();
    }

    handleCurDate = () =>{

        const date = new Date()
        const cur_date = format(date, 'yyyy-MM-dd')
        const call_log_list = this.state.call_log_list
        call_log_list['start_date'] = cur_date
        call_log_list['end_date'] = cur_date
        this.setState({call_log_list, cur_date : cur_date})
        

    }

    handelDateFormat = (value) => {
        let date = new Date(value)
        let day = String(date.getDate()).padStart(2, "0")
        let month = date.toLocaleString('default', {month : "short"})
        let year = date.getFullYear()
        
        return `${day} ${month} ${year}`
    }

    getConfigValue = (data) => {
        let ait_voice_hangup = Config('ait_voice_hangup_msgs')
        return data && ait_voice_hangup[data] && ait_voice_hangup[data].text ? ait_voice_hangup[data].text : 'NA'
    }

    handleSearch =(event,req_data =null)=>{
        this.setState({loader : true})
        if(event){
            this.setState({call_list:[], total:0, paginate:Config('paginate'), copy_search : JSON.parse(JSON.stringify(this.state.call_log_list))})
        }
        var req_json = this.state.paginate ? {search_criteria:  req_data ? req_data :this.state.call_log_list, paginate : this.state.paginate} : {search_criteria:  req_data ? req_data :this.state.call_log_list}
        getInboundCallList(this.req(req_json))
        .then((response) => {
            this.resp(response)
            if(!response){return this.setState({loader : false, reload:false})};
                if(response.status === "success"){
                        this.resp(response)
                        var columns = this.state.columns
                        if(this.state.paginate != null){								
                            this.setState(state=>state.total=response.data.total_counts)	
                            this.page_count()
                        }
                        else{
                            this.setState(state=>state.total=response.data.logs.length)
                        }
                            columns = [
                                        {  
                                            headerName: 'Date',  
                                            field: 'created_at',
                                            id : 'created_at',
                                            renderCell: (cell) => {return <p className='text-center'>{this.handelDateFormat(cell.value)}</p>},
                                            align: 'center',headerAlign: 'center',  flex: 1
                                        },
                                        {  
                                            headerName: 'Cust ID', 
                                            field : 'cust_id' ,
                                            renderCell: (cell) =>{ return cell.value!="unknown_customer" ?
                                            <Link style={{color: "#008fc1"}} className='text-center' to={"/borrower/indiv/view/"+cell.value} target="_blank"><TooltipLabel full_text={cell.value}  no_reduce={true} /></Link>
                                             : <p className='text-center m-0'>{dd_value(cell.value)}</p> },
                                             align: 'center',headerAlign: 'center',  flex: 1
                                            },{  
                                            headerName:'Mobile No',  
                                            field :'cust_number',
                                            renderCell: (cell) => {return <p className='m-0 text-center'>{cell.value}</p>},
                                            minWidth:170,
                                            align: 'center',headerAlign: 'center',  flex: 1

                                        },{  
                                            headerName: 'Caller Name',  
                                            field :'cs_name',
                                            renderCell: (cell) => {return <p className='text-center'>{cell.value == null ? "NA" :capitalize_first_letter(cell.value)}</p>},
                                            minWidth:200,
                                            align: 'center',headerAlign: 'center',  flex: 1

                                        },
                                        
                                        {  
                                            headerName: 'Caller ID',  
                                            field :'call_agent_number',
                                            renderCell: (cell) => {return <p className='text-center'>{cell.value == null ? "NA" :dd_value(cell.value)}</p>},
                                            minWidth:170,
                                            align: 'center',headerAlign: 'center',  flex: 1

                                        },
                                        {  
                                            headerName: 'Call Direction',
                                            field :'direction',
                                            renderCell: (cell) => {return <p className='text-center'>{cell.value == null ? "NA" : dd_value(cell.value)}</p>},
                                            minWidth:150,
                                            align: 'center',headerAlign: 'center',  flex: 1

                                        },

                                        {  
                                            headerName: 'Call Purpose', 
                                            field :'directory',
                                            renderCell: (cell) => {return <p className='text-center'>{cell.value == null ? "NA" : dd_value(cell.value,cell.value)}</p>},
                                            minWidth:150,
                                            align: 'center',headerAlign: 'center',  flex: 1

                                        },
                                        
                                        {  
                                            headerName: 'Status',  
                                            field : 'status',
                                            renderCell: (cell) => {return <p className='text-center'>{cell.row.status == null ? 
                                                <RoundOffSplitter val={this.getConfigValue(cell.row.hangup_causes)} unit={""} fontSize='' text_color='white' fontWeight='' position={"left"} align_center={true} text={dd_value(cell.row.hangup_causes)} normal_text = {true}/> : 
                                                dd_value(cell.row.status)}</p>
                                            }, 
                                            minWidth:210,
                                            align: 'center',headerAlign: 'center',  flex: 1

                                        },
                                        {
                                            headerName: 'Recording',  
                                            field : 'recording_url',
                                            renderCell: (cell) => {return <div >
                                                <audio controls style={{width:'242px',height:'39px'}}>
                                                <source src={cell.value} type="audio/ogg"/>
                                                </audio>
                                            </div>},
                                            width:250,
                                            align: 'center',headerAlign: 'center',  flex: 1

                                        }]
                    }
                    let data = set_id_for_rows(response.data.logs)
                    this.setState({call_log:"show_div", call_list : data}) 
                    this.setState((state) => {
                        return { columns,copy  : data};
                    }, ()=>this.setState({loader : false, reload:false}));

                              
        })
              
    }

    pagination = (page, count, sorted)=>{
        if(JSON.stringify(this.state.call_log_list) !== JSON.stringify(this.state.copy_search)){
            this.setState({paginate : Config('paginate'), reload:true, call_list : [], total : 0, copy_search : JSON.parse(JSON.stringify(this.state.call_log_list))}, ()=>{
                this.handleSearch()	
            })
        }
        else{
		    this.setState((state) => {
		    	return {
		    	  paginate: {
		    		...state.paginate,
                    pagination_count: this.state.page.pageSize,
                    pagination_page: this.state.page.page ,
		    		sorting:sorted,
                    total_count : this.state.total
		    	  },
		    	};
		      },()=>this.handleSearch());
        }
	}

	page_count = ()=>{
			
        const total = this.state.total
        const page_count_mod = (total%this.state.paginate.pagination_count)
        
        if(page_count_mod > 0){
            const page_count = (total/this.state.paginate.pagination_count)
            this.setState({'page_count' : Math.trunc(page_count)+1}, ()=>console.log(this.state.page_count))

        }else{
            const page_count = (total/this.state.paginate.pagination_count)
            this.setState({'page_count' : page_count})
        }
    }

	handleExportCSV = (event) => {
        let log_list = JSON.parse(JSON.stringify(this.state.call_log_list));
        let resp = null;
        if(event == 'email') {
			log_list.export = 'email'
			resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
		}
		if(event == 'email' && !resp){
            return
		}
        else{
			this.handleCSVapi(log_list, event)
		}
	}

    handleCSVapi(log_list, action){
        let calling_api = true 
        if (action == 'email') calling_api = false 
        getInboundCallList(this.req({search_criteria: log_list}, true, null, calling_api))
			.then((response) => {
                this.resp(response)
				if(action == 'email'){
					return
				}
				this.setState(state => state.export_data = response.data.logs)
			})
    }

    componentDidUpdate(prevProps, prevState) {
        if (
          prevState.page !== this.state.page
        ) {
          this.pagination();
        }
      }
    
                
render(){
    const is_server_side= (this.state.paginate == null )
    return(

        <div className="container containerTopmargin max-width no-padding">            
            <form className="mb-3" autocomplete="off">
                <Card>
                    <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>CS & RMSO Call Logs</h4></Card.Header>
                        <Card.Body style={{padding:15}}>
                            <InboundCallLog  id="call_log_list" onComponentChange={this.handleComponentChange} handleSearch ={(type,req_data)=>this.handleSearch(type,req_data)} start_date = {this.state.call_log_list.start_date} cur_date = {this.state.cur_date}/>   
                          
                        </Card.Body>
                </Card>
            </form>
            {!_.isEmpty(this.state.call_list) ? 
                <div className={`mt-3 ${this.state.call_log}`} >	
                <p className='no-margin text-white'><b>Total Entries : {this.state.total}</b></p>
                    <div>{
                        <div style={{display:!this.state.loader ?'':'none'}}>
                            {/* {this.state.paginate == null ?  */}


                                    
                                    {/* // <CommonReactTable showPag={true} minRow={0} row_data={this.state.call_list !== undefined ? this.state.call_list : []} column_data={this.state.columns} resizable={false} exportCSV={true} csvFile={"call_list"} csvData={this.state.call_list ? this.state.call_list : []} searchData={[{title:'Search Toll Free Call List',...this.state.call_log_list}]}/> 
                                //     <BasicReactTable pagination={this.state.total && this.state.total > 9 ? true : false} row_data={this.state.call_list !== undefined ? this.state.call_list : []} column_data={this.state.columns}  csv_file={true} FName={"call_list"} export_csv_datas={this.state.call_list ? this.state.call_list : []} searchData={[{title:'Search Toll Free Call List',...this.state.call_log_list}]} default_page_size={10}/> 
                                // :   !this.state.reload && */}


                                <Box display={'flex'} justifyContent={'end'}>
                                <ExportCsv
                                total_count={this.state.total ?? 0} 
                                handleExport={this.handleExportCSV} 
                                export_csv_datas={this.state.export_data ?? []}
                                csvFileName = {'call_list'}
                                paginationMode= { is_server_side?  "client":"server"}
                                columns={this.state.columns??[]}

                                />
                                </Box>
                                
                                <DataGridTable
                                        rows={this.state.call_list??[]}
                                        columns={this.state.columns}
                                        paginationMode= { is_server_side?  "client":"server"}
                                        sortingMode={"client"}
                                        page={this.state.page}
                                        rowCount={this.state.total ? this.state.total : (this.state.call_list?.length || 0)}
                                        setpage={(newPage) => this.setState({ page: newPage })}
                                        isLoading={ this.state.loader }
                                        
                                    />
                                    
                                    {/* // <BasicReactTable pagination={this.state.total && this.state.total > 9 ? true : false}  row_data={this.state.call_list !== undefined ? this.state.call_list : []} column_data={this.state.columns}  csv_file={true} FName={"call_list"} export_csv_datas={this.state.export_data ? this.state.export_data : []} searchData={[{title:'Search Toll Free Call List',...this.state.call_log_list}]} filter_data = {this.pagination}  total_page_count = {page_count((this.state.total !== undefined ? this.state.total : 0), this.state.paginate.pagination_count)} defaultPageSize={10} handleExport = {this.handleExportCSV} default_page_size={10} server_side_pagination={this.state.paginate} csv_total_data = {this.state.total !== undefined ? this.state.total : 0}/> */}
                            
                    
                        </div>}
                    </div>					
                     				
                </div>
                :
                <p className='text-center m-0 text-white my-2'>NO DATA</p>
}
<div style={{display:this.state.loader ?'':'none'}}>
							<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
                        			<div className="product_takeup_loader"></div>
							</div>
						</div>
        </div>
        )
    }

}
export default InboundCallList;