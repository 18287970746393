import React, { Component } from 'react';
//import cookie from 'react-cookies';
import {get, remove_all, set_logout,Config} from './../../helpers/storage_helper';
import {touch, touch_async,dd,getListByUrl, getNameList} from '../../actions/common_actions';
import {call_await_fetch, app_path} from '../../helpers/fetch_helper';
import {append_request, filter, hlpr_add_status} from '../../helpers/common_helper';
import { NO_RES_OPT, DEF_OPT, DEF_OBJ_OPT} from '../../constants';
import {userService} from "../../actions/auth_actions";
import {Modal,Button} from 'react-bootstrap';
import {dd_value, searchStmtImports} from '../../actions/common_actions';





  
export default class CommonComponent  extends Component {
  admin_roles = ['super_admin', 'market_admin', 'ops_admin', 'it_admin','ops_analyst']

  constructor() {
    super();

    this.selected = {};
    let access_token = get('access_token');  
    if(access_token == null){
        // alert("You have been signed out. Please login again");
        return;
    }
    let market = get('market');
    if(market == null){
      // alert("It looks like Market is not configured for the logged in user \nOR\n User is not assigned to a Market.");
    }
    else{
      this.country_code =  market.country_code;
      let lender = get('lender_code').find(val =>  val.country_code == this.country_code );
      this.lender_code = lender.lender_code;
      this.currency_code = market.currency_code;
      this.time_zone = market.time_zone;
    }

    let acc_prvdr_code = get('acc_prvdr_code');
    
    if(acc_prvdr_code){
        this.acc_prvdr_code =  acc_prvdr_code;
    }
    this.def_req = {country_code : this.country_code, status: "enabled"}
  }
  
  fillSelect = (response, dd_name, data_holder = ['id', 'name']) => {
        if(!response){return };
        if(response.status === "success"){
            this.getSelectOptionsFromResult(response.data, dd_name, data_holder);
        }else{
          alert(response.message + "\n" + JSON.stringify(response.data));  
        }
  }

  server_time(){
     //var logout = false;
      return touch({country_code : this.country_code, time_zone : this.time_zone})
               .then((response) => {
                   this.resp()
                   if(!response){return false};
                   
                   if(response.status === "success"){
                      this.setState({server_time : response})
                   }
                  }
                ); 
                
    //return logout;
  }

  touch(){
     //var logout = false;
      return touch({country_code : this.country_code, time_zone : this.time_zone})
               .then((response) => {
                   if(!response){return false};
                   
                   if(response.status === "success"){
                      return false
                   }else{
                      // userService.logout();
                      remove_all()
                       set_logout()
                      window.location.reload('/login')
                      return true
                   }
                  }
                ); 
                
    //return logout;
  }

async touch_async(){
     var logout = false;

      const response = await call_await_fetch(app_path + '/touch', {country_code : this.country_code, time_zone : this.time_zone});


      if(!response){return true};

       if(response.status === "success"){
          logout = false
       }else{
          // userService.logout();
          remove_all()
          set_logout()
          alert("Session Expired")
          window.location.reload('/login')
          logout = true
       }

    return logout;
  }


  add_status = (request) => {
   return hlpr_add_status(request)
  }


  resp(response){

    this.setState({'calling_api' : false});

  }
  req(request = null, send_ap_code = true, key = null, calling_api = true){
    
    this.setState({'calling_api' : calling_api});

   console.log('first___>', this.append_request({...request}, this, send_ap_code, key))
   return this.append_request({...request}, this, send_ap_code, key);
  }


 append_request(request, this_comp, send_ap_code, key){
  console.log('request___>', request , this_comp)
  if(request == null){ request = {}}
    // if(!request.hasOwnProperty("country_code") && (get('role_codes') === 'super_admin' || get('role_codes') === 'it_admin' || get('role_codes') === 'ops_analyst' || (Config('global_user') && Config('global_user').includes(get('user_id'))))){
      if(!request.hasOwnProperty("country_code") ){
      request.country_code = this_comp.country_code;
    }
    // if(send_ap_code && !request.hasOwnProperty("acc_prvdr_code")){
      
    //   request.acc_prvdr_code = this_comp.acc_prvdr_code;
    // }
    if(send_ap_code && get('acc_prvdr_code') != null){
      request.acc_prvdr_code = get('acc_prvdr_code');
    }

    else if(!request.hasOwnProperty("acc_prvdr_code") && key != null && request[key].hasOwnProperty("acc_prvdr_code")){
      request.acc_prvdr_code = request[key].acc_prvdr_code;
      delete request[key].acc_prvdr_code;
    }
    if(!request.hasOwnProperty("time_zone")){
      request.time_zone = this_comp.time_zone;
    }
    request = hlpr_add_status(request);  
   /* 
    const market = get('market')
    if(market){
      request.time_zone = market.time_zone;
    }*/
    // console.log(request)
    return request;
}


  setOptionsToStateFromApi = (data_key , request = null, addl_key = null, path = 'admin', default_value = null)=>{
     var state_key = data_key;
     if(request == null){
     request = this.def_req; 
    }
    else if(request.hasOwnProperty('associated_with')){
      state_key = request.associated_with  + "_" + data_key;
    }
    else if(request.hasOwnProperty('report_to')){
      state_key = request.report_to  + "_" + data_key;
    }
    
     getNameList(data_key, this.req(request), path )
         .then((response) => {
          this.resp(response)
          if(!response){return };
              if(response.status === "success"){
                if(addl_key!=null){
                  this.selectdropdownOptions(response.data,state_key, default_value)
                }
                else{
                  this.getSelectOptionsFromResult(response.data, state_key);

                }
              }else{
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          ); 
    }
    selectdropdownOptions(name_list,state_key, default_value = null, data_holders = ["id" , "name"]){
     
      var data= Array.isArray(name_list)? name_list: name_list.list
      if( data.length > 0) {
        var options = [];
       
        const id = data_holders[0];
        const name = data_holders[1];
        options = data.map((item, index) => {
          var obj={}
            var value = item[id];

           obj["label"]=item[name]
           obj["value"]=value
            obj["index"]=index
            
            options.push(obj)
            
        return obj;


   });

   const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));
   if(default_value){
    sortedOptions.unshift(default_value)
   }
   
   this.setState({[state_key]: sortedOptions});

  
  }


    }


setOptionsListToStateFromAppApi = (type, data_key , request = null, data_holders = ["id" , "name"], state_key = null,is_multiselect=null)=>{

    if(state_key == null){
      state_key = data_key;
    }

    getListByUrl(type, data_key, this.req(request))

                  .then((response) => {
                    this.resp(response)
                     if(!response){return };
                     if(response.status === "success"){
                      if(is_multiselect!=null){

                        this.selectdropdownOptions(response.data,state_key)
                        if(data_key=='priv_users'){
                          this.setState({priv_users_datas:response.data.list})

                        }
                      }else{
                        this.getSelectOptionsFromResult(response.data.list, state_key, data_holders);
                      }
                     }else{
                        alert(response.message + "\n" + JSON.stringify(response.data));  
                     }
                      
                  }
                ); 
  }

  getSelectOptionsFromResult = (name_list, state_key = null, data_holders = ["id" , "name"],add_default = true)=>{

      var options = [];

      if(name_list.length > 0) {
          const id = data_holders[0];
          const name = data_holders[1];
          var is_obj = false;
          options = name_list.map((item, index) => {
              var value = item[id];
              if (id == 'obj') {
                  value = JSON.stringify(value);
                  is_obj = true;
              }

              if (item.selected) {
                  this.selected[state_key] = value;
              }
              //alert(JSON.stringify(item.selected));
              return <option key={index} value={value}>{item[name]}</option>;

          });

          state_key += "_dd";
          if (add_default== false) {
              options = [...options];
          } else {
              if (is_obj) {

                  options = [...DEF_OBJ_OPT, ...options];
              } else {

                  options = [...DEF_OPT, ...options]; 
              }

          }
      }else{
               options = NO_RES_OPT;//this.get_db_options(data_key);
      }

      if(state_key != null){
        if(state_key == "operations_auditor_dd" || state_key == "acc_prvdr_dd"){
          this.setState({[state_key]: options.slice(1)});
        }else{
          this.setState({[state_key]: options});

        }
      }
      
      return options;
  }


  handleAlert = () =>{
    this.setState({modal_show : false})
  } 

  vaildationAlert =(show) => {
    
  return (
    <>
      <Modal show = {show} variant = "danger" size="l" >
        <Modal.Header>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
          <h6 className='text-white' style={{fontSize: "12px"}}>Please enter required fields</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleAlert} variant="danger">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
        );
  }  

  setMultiselectOptionOnState(selected_options,id,state_name){
	var arr=[]
   let selectedOptions = selected_options.map((item) => {
	arr.push(item.value)
   });

	let state_data=state_name
	state_data[id] = arr

  if (arr.length === 0) {
       delete state_data[id]; 
  } 

	this.setState({state_data})
  }

  getMultiSelectOptions = (data_key,enabled_options=null)=>{    // Take dropdown values from "master_data" table
    
    const filtered_data = dd(data_key);
    //alert(JSON.stringify(filtered_data));
    var options = [];
    if(filtered_data.length > 0){
    if(enabled_options){ options = filtered_data
      .filter(item => item.status == 'enabled')
      .map((item, index) => (
        { value: item.data_code, label: item.data_value, id : data_key+"_"+index }      ));}   
      else{
      options =  filtered_data.map((item, index) => ({ value: item.data_code, label: item.data_value, id : data_key+"_"+index }));
      }
      return options;
    }else{
       //this.get_db_options(data_key);
    }
  }
  select_custom_styles(){
   var customStyles = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#097afa' :   state.isSelected ? "blue" :'#202940',
        color: state.isSelected ? 'white' : 'white',

        padding:state.isFocused ? '3px 0px 3px 10px' : '3px 0px 3px 10px',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'white',
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? ' ' : '',
        borderTop: state.isFocused ? 0 :0,
        borderLeft:state.isFocused ? 0 : 0,
        borderRight:state.isFocused ? 0 : 0,
        borderBottom: state.isFocused ? "2px solid  #5d616f": "1px solid #5d616f",
        boxShadow: state.isFocused ? 'none' : "",
        }),
        
        input: (provided) => ({
          ...provided,
          color: 'white', 
        }),
      };
    return customStyles;
  }


  getSelectOptions = (data_key, add_default = true, parent_data_code = null,addl_condition=null,status_enabled=false,remove_choose_child = false, default_label = null)=>{    // Take dropdown values from "master_data" table
    const filtered_data = dd(data_key, parent_data_code);

    if(addl_condition !=null){
      return filtered_data;
    }
    //alert(JSON.stringify(filtered_data));
    var options = [];
    if(filtered_data.length > 0){
      if (status_enabled) {
        options = filtered_data
          .filter(item => item.status == 'enabled')
          .map((item, index) => (
            <option key={index} value={item.data_code}>{item.data_value}</option>
          ));
      } else {
        options = filtered_data.map((item, index) => (
          <option key={index} value={item.data_code}>{data_key == 'float_switch_status' ? dd_value(item.data_code ,item.data_code) : item.data_value}</option>
        ));
      }
      if(add_default){
        if(default_label){
          let default_value = [<option key="" value="">{default_label}</option>]
          options = [...default_value, ...options]
        }else{
          options = [...DEF_OPT, ...options];
        }
      }

      if(remove_choose_child || data_key=="cashback_type"){
        options.splice(0,1)
      }

      return options;
    }
    else{
       //this.get_db_options(data_key);
    }
  }

  convertion_amount_Usd(amount,type,activeCurrencyVal,activeCurrency){
    if(!activeCurrencyVal){
      if(amount>0){

        let val=this.remove_commas_on_value(amount)
        return (val.toFixed(2) );
    }else{
        return amount.toFixed(2)
      }
  }
     if(activeCurrencyVal){
       if(amount>0){
        let value = (amount * activeCurrencyVal).toFixed(2)
        var val= this.remove_commas_on_value(value)
        return  (val);
       }
       else{
         return amount;
       }
     }
  }

  remove_commas_on_value(value){

    if(typeof value === "string"){
      var stringWithoutCommas = value.replace(/,/g, "");
      var removeSpace = stringWithoutCommas.split(" ").join('');
      var val = parseFloat(removeSpace); 
      return val;
    }else{
      return value}
   
  }

  getconvertData(convert_arr,csv_data_obj,activeCurrencyVal,activeCurrency){
    convert_arr.map((item,idx)=>{
      if (csv_data_obj.hasOwnProperty(item)) {
      var par_loan_principal = (item === "rm_name") ? csv_data_obj[item] : this.formatCurrencyAmounts(csv_data_obj[item],activeCurrencyVal,activeCurrency);
        csv_data_obj[ dd_value(item) + `(${activeCurrency})`] = par_loan_principal;
      }

    })
    return csv_data_obj
  }

  formatCurrencyAmounts = (value,activeCurrencyVal,activeCurrency=null,addl_key=null) => {
    
      const moneyValue = Math.round(value * (activeCurrencyVal  ? activeCurrencyVal : 1)).toLocaleString();

      var value= this.remove_commas_on_value(moneyValue)
      return value;
    
    
  };

  convert_header_props(data,active_currency,header_names=[]){
    var header = [];
    var data_inserted = false;
    if(data && data.length >0){
     for (let i = 0; i < data.length; i++) {
        data_inserted = false;
        for (let j = 0; j < header_names.length; j++) {
            if ((data[i].accessor === header_names[j])|| (data[i].id === header_names[j])) {
                var name = dd_value(header_names[j]) + "(" + active_currency + ")";
                header.push(name);
                data_inserted = true;
                break;
            }
        }
         if(!data_inserted){
                header.push(data[i].id ? data[i].id : data[i].accessor);
            }
    }
  }

  
    return header;
  }


  convertExportCsvDatas(stateValue,activeCurrencyVal,activeCurrency,convert_arr=null){
    var csv_data_arr = [];
    
    for (let i = 0; i < stateValue.length; i++) {
      var csv_data_obj = { ...stateValue[i] }; 
       
      if(convert_arr!=null && convert_arr.length>0){
        this.getconvertData(convert_arr,csv_data_obj,activeCurrencyVal,activeCurrency)
      }
           
      csv_data_arr.push(csv_data_obj);
    }
  return csv_data_arr

  }

  style_for_react_select(background = true, background_menu_color = '#1A2035'){
    return {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: background ? '#2E3240' : 'transparent', 
        border: background ? '1px solid #596176' : 'none',
        borderRadius: '5px',
        padding:"0 0 0 5px",
        boxShadow:'none'
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: 'white' 
      }),
      menu: (provided, state) => ({
        ...provided,
        backgroundColor: background_menu_color 
      }),
      input: (provided, state) => ({
        ...provided,
        color: 'white',
        fontWeight: 'bold' 
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#2684FF' : '', 
        color: 'white',
        ':hover': {
          backgroundColor: 'gray',
        },
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
      }),

      valueContainer: (provided) => ({
        ...provided,
        maxHeight: '40px', 
        overflowY: 'auto' 
      }),
      };
  }


     }
