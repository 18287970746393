import React from 'react';
import '../../../styles/flow.css';
import { Link } from 'react-router-dom';
import FlowContainer from '../../common/container/core/FlowContainer';
import { searchLoanAppl } from '../../../actions/loan_appl_actions';
import SearchLoanCriteria from '../component/SearchLoanCriteria';
import { dd_value } from '../../../actions/common_actions';
import { RoundOffSplitter, format_date_tbl, getValueFromLangJson, page_count, reduce_text_length } from '../../../helpers/common_helper';
import { CustID, Product } from '../../common/partial';
import $ from 'jquery';
import CommonReactTable from '../../../helpers/commonReact_table';
import Select from "react-select";
import { Config, get } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import MUITable from '../../../MUI/Components/MUITable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataGridTable from '../../CommonComponent/table';
import { Box } from '@mui/material';
import { LightTooltip } from '../../CommonComponent/tooltip';
import {
	GridColDef,
	GridPaginationModel,
	GridRowsProp,
	GridSortModel,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
	GridValidRowModel,
  } from "@mui/x-data-grid";
import ExportCsv from '../../common/component/ExportCsv';
import CustomTableGridToolbar from '../../CommonComponent/table/components/customTableGridToolbar';
class LoanApplSearchContainer extends FlowContainer {
	constructor(props) {
		super(props);
		this.state = {
			search_btn_style: "",
			data_prvdr_name_list: [],
			lender_name_list: [],
			product_name_list: [],
			searchLoanAppl: {},
			datatable_div_style: "hide_div",
			serach_loan_appl: "show_div",
			modify_search: "hide_div",
			viewLoanAppln: false,
			copy: [],
			rowdatas: [],
			columndatas: [],
			paginate: Config('paginate') ? Config('paginate') : null,
			export_data: [],
			search_criteria: null,
			total: 0,
			loader: false,
			language_json: get("language_json"),
			sort: [{ field: "submitted_on", sort: "desc" }],
			page: { page: 0, pageSize: 10 },
		};
		this.column_field = [
			{ header: "fa_appl_id", field: "loan_appl_doc_id", url: "/fa_appl/view/", is_tool_tip: true },
			{ header: "fa_id", field: "loan_doc_id", url: "/fa/view/", is_tool_tip: true },
			{ header: "product_code", field: "product_code" , },
			{ header: "product_name", field: "product_name", is_product: true },
			{ header: "submitted_on", field: "loan_appl_date", format_date: true },
			{ header: "applied_by", field: "loan_applicant_name" },
			{ header: "channel", field: "channel" },
			{ header: "status", field: "status" },
			// { header: "action", field: "cs_result_code", is_tool_tip: true },
		  ];
		

	}

	

	viewApplication = (event) => {
		const loan_appl_doc_id = event.target.id;
		window.open("/fa_appl/view/" + loan_appl_doc_id, "_blank");
	}

	handleModifySearch = (event) => {
		this.setState({
			serach_loan_appl: "show_div",
			modify_search: "hide_div"
		})
		if (this.state.paginate) {
			this.setState({ paginate: Config('paginate'), copy: [], rowdatas: [], total: 0 })
		}
	}

	handleSearch = ({ nativeEvent }) => {

		// var prev_table = $('#loan_list').DataTable();
		// prev_table.destroy();
		this.setState({ copy: [], total: 0, loader: true })
		if (this.state.paginate) {
			this.setState({ paginate: Config('paginate') })
		}
		this.setState({
			datatable_div_style: "hide_div",
			search_btn_style: "disable_div"
		});

		if (!this.state.searchLoanAppl.status && !this.state.searchLoanAppl.req_parameter) {
			window.alert("Please enter a valid search criteria")
			this.setState({ loader: false })
		} else {
			this.LoanApplSearch()

		}

	}
	componentDidUpdate(prevProps, prevState) {
		if (
		//   prevState.sort !== this.state.sort ||
		  prevState.page !== this.state.page
		) {
		  this.pagination();
		}
	  }
	
	LoanApplSearch() {

		const loan_appl_search_criteria = this.state.searchLoanAppl;

		this.setState({ loader: true })
		if (Object.keys(loan_appl_search_criteria).length > 0) {
			this.setState(state => state.search_criteria = loan_appl_search_criteria)
			var req_json = this.state.paginate ? { loan_appl_search_criteria, paginate: this.state.paginate } : { loan_appl_search_criteria }
			
			searchLoanAppl(this.req(req_json, true))
				.then((response) => {
					this.resp(response);
					this.setState({ search_btn_style: "" });
					if (!response) { return this.setState({ loader: false }) };
					if (response.data.results.length == 0) this.setState({ loader: false })
					var loanApplicationResults = [];

					if (!this.state.paginate) {
						response.data.results = response.data
					}

					if (response.data.results.length > 0) {
						this.setState(state => state.rowdatas = response.data.results)
						// this.setState(state=>state.copy = response.data.results)
						this.setState(state => state.columndatas = this.columndatas(this.state.language_json))

						//const result =  response.data;
						this.setState({ search: true })
					
						this.setState({
							loanApplicationResults: loanApplicationResults,
							datatable_div_style: "show_div", serach_loan_appl: "hide_div", modify_search: "show_div"
						});
					
						if (this.state.paginate != null) {
							this.setState(state => state.total = response.data.total_count)
							this.page_count()
						}
						else {
							this.setState(state => state.total = response.data.results.length)
						}
						this.setState((state) => {
							return { copy: response.data.results };
						}, () => this.setState({ loader: false }));
					}
					else {
						alert(getValueFromLangJson('no_data_found'));
					}
				}
				);
		} else {

			this.setState({ search_btn_style: "" });
			alert(getValueFromLangJson('choose_atleast_one_filter_to_search_float_advance_applications'));

		}
	}



	columndatas(language_json) {
		let column_field = [...this.column_field]; 
		if (this.state.searchLoanAppl.status === "approved") {
			column_field.push({ header: "approved", field: "loan_approver_name",is_tool_tip: true });
		}
		return column_field.map((item) => ({
			field: item.field,
			headerName: language_json[item.header],
			align: "left",
			headerAlign: "left",
			flex: 1,
			minWidth: item.field =='product_code' ?230: 190,
			renderCell: (params) => {
				const cellContent = item.is_tool_tip ? (
					<LightTooltip
					sx={{
					  width: "200px",
					  "& .MuiTooltip-arrow": { color: "#fff" },
					}}
					title={dd_value(params.value)}
					arrow
					placement="top"
				  >
					<span style={{ overflow: "hidden" }}>
					{item.url ?
					<Link className="text-center" style={{fontSize:'14px'}} to={`${item.url}${params.value}`} target="_blank">
						{params.value}
					</Link>:
					<span className="text-center">{dd_value(params.value)}</span>

					}
					</span>
				  </LightTooltip>	

					
				) : item.is_product ? (
					<Product loan={params.row} currency_code={this.currency_code} />
				) : item.format_date ? (
					<span className="text-center">{format_date_tbl(params.value, true)}</span>
				) :
				 (
					<span className="text-center">{(item.field=="product_code") ?dd_value(params.row.product_name):dd_value(params.value)}</span>
				);
				return (
					<Box
						sx={{
							// height: "100%",
							display: "flex",
							alignItems: "start",
							justifyContent: "start",
							minWidth: (item.field =='cs_result_code' || item.field == 'loan_approver_name') ?"200px":'auto'
						}}
					>
						{cellContent}
					</Box>
				);
			},
		}));
	}
	
	

	changeHandler = (e) => {
		const toFilter = e
		const filterfrom = this.state.rowdatas
		const filteredData = filterfrom.filter(value => {
			return (
				value.loan_appl_doc_id !== null && value.loan_appl_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.loan_doc_id !== null && value.loan_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.acc_number !== null && value.acc_number.includes(toFilter) ||
				value.product_name !== null && value.product_name.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.status !== null && value.status.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.cs_result_code !== null && value.cs_result_code.toLowerCase().includes(toFilter.toLowerCase())
			)
		})
		this.setState(state => state.copy = filteredData)
	}


	handlePurposeChange = (selected_options, id) => {
		this.setMultiselectOptionOnState(selected_options, id, this.state.searchLoanAppl)

	}
	pagination = (page, count, sorted) => {

		this.setState((state) => {
			return {
				paginate: {
					...state.paginate,
					pagination_count: this.state.page.pageSize,
                    pagination_page: this.state.page.page ,
					// pagination_count: count,
					// pagination_page: page,
					sorting: sorted,
					total_count: this.state.total
				},
			};
		}, () => this.LoanApplSearch());

	}

	page_count = () => {

		const total = this.state.total
		const page_count_mod = (total % this.state.paginate.pagination_count)

		if (page_count_mod > 0) {
			const page_count = (total / this.state.paginate.pagination_count)
			this.setState({ 'page_count': Math.trunc(page_count) + 1 }, () => console.log(this.state.page_count))

		} else {
			const page_count = (total / this.state.paginate.pagination_count)
			this.setState({ 'page_count': page_count })
		}
	}

	handleExportCSV = (event) => {

		let loan_appl = JSON.parse(JSON.stringify(this.state.search_criteria));
		let resp = null;
		if (event == 'email') {
			loan_appl.export = 'email'
			resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
		}
		if (event == 'email' && !resp) {
			return
		} else {
			this.handleCSVapi(loan_appl, event)
		}
	}

	handleCSVapi(loan_appl, action) {
		let calling_api = true
		if (action == 'email') calling_api = false
		var request = { loan_appl_search_criteria: loan_appl, paginate: false }
		searchLoanAppl(this.req(request, true, null, calling_api), true)
			.then((response) => {
				this.resp(response)
				if (action == 'email') {
					return
				}
				this.setState(state => state.export_data = response.data)
			})
	}

		handleSortChange (newSortModel) {

			this.setState({ sort: newSortModel }, () => {
				this.LoanApplSearch()
			});
		};
	
		handlePageChange (newPageModel) {
			this.setState({ page: newPageModel }, () => {
				this.LoanApplSearch()
			});
		};

	render() {
		const { sort, page } = this.state;
		return (
			<div className="container containerTopmargin max-width no-padding">
				<div className="floatAdvancetitleBox">
					<h5 className="preHeaderpad no-margin" style={{ paddingLeft: 15, fontSize: 20 }}>{this.state.language_json.search_fa_appl_title}</h5>
				</div>
				<div className='text-right' style={{ marginTop: this.state.modify_search === "show_div" ? 15 : 0 }}>
					<div className={this.state.modify_search}>
						<button type="button" className="btn btn-md btn-light" id="float_style" onClick={this.handleModifySearch} >{this.state.language_json.modify_search}</button>
					</div>
				</div>
				<div className={`${this.state.serach_loan_appl} mt-4`}>
					<SearchLoanCriteria id="searchLoanAppl" mode="loan_appl"
						onSearch={this.handleSearch}
						search_btn_style={this.state.search_btn_style}
						onComponentChange={this.handleComponentChange}
						onComponentReset={this.handleComponentReset}
						disable_btn={this.state.calling_api}
						handlePurposeChange={(a, b) => { this.handlePurposeChange(a, b) }}
						onComponentElementChange={this.handleComponentElemetChange} />
				</div>
				{this.state.rowdatas.length > 0 &&
					<div className={`${this.state.datatable_div_style}`}>
						{/* <table id ='loan_list' className='list table no-footer'>
                  <thead className={"text-info"}>
                  <tr><th>Float Advance Application ID</th>
                 	  <th>FA ID</th> 
	                  <th>Data Provider Cust ID</th>
	                  <th>Product Code</th>
	                  <th>Product Name</th>
	                  <th>Submitted On</th>
	                  <th>Status</th>
					  <th>Action</th>
                  </tr>
                  </thead>
                  <tbody> 
                 {this.state.loanApplicationResults}
                  </tbody>
                </table> */}
						<div className={`${this.state.paginate ? 'd-flex justify-content-between align-items-center col-md-5' : 'd-flex justify-content-between align-items-center'}`} style={{ padding: 5, marginTop: 15, top: `${this.state.paginate ? '48px' : '0px'}` }}>
							{!this.state.loader && <p className='no-margin text-white'><b>{this.state.language_json.total_entries} : {this.state.total}</b></p>}
							{!this.state.paginate &&
								<div style={{ position: 'relative' }}>
									<i class="fa-solid fa-magnifying-glass search_pos"></i>
									<input placeholder='Search here' className='search_posInput' onChange={(e) => this.changeHandler(e.target.value)}></input>
									{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
								</div>
							}
						</div>


						<div style={{ marginTop: `${this.state.paginate ? '18px' : '0px'}` }}> {this.state.copy.length > 0 && <>
							<div style={{ display: !this.state.loader ? '' : 'none' }}>
								{this.state.paginate == null ?
									
									// <BasicReactTable pagination={true} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} default_page_size={10} defaultSorted={[{ id: "loan_appl_date", desc: true }]} csv_file={true} FName={"FA applications"} export_csv_datas={this.state.copy !== undefined ? this.state.copy : []} searchData={[{ ...this.state.searchLoanAppl, title: 'Search Float Applications' }]} />

									<Box sx={{ mt: 5 }}>
										<DataGridTable
											columns={this.state.columndatas}
											rows={this.state.copy}
											page={{ page: 0, pageSize: Array.isArray(this.state.copy) ? this.state.copy.length : 5 }}
											rowCount={this.state.copy?.length}
											paginationMode={'client'}
											errorText={""}
											isLoading={false}
											isError={false}
											sortingMode={undefined}
											noOfColumnsToLoad={6} />
									</Box>
									:
									<>
									{/* // <BasicReactTable pagination={this.state.copy !== undefined &&  this.state.copy.length > 9 ? true : false}  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} default_page_size={10}  defaultSorted={[{id:"loan_appl_date",desc:true}]} csv_file={true} FName={"FA applications"} export_csv_datas={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.searchLoanAppl,title:'Search Float Applications'}]} filter_data = {this.pagination} server_side_pagination = {this.state.paginate} total_page_count = {page_count(this.state.total, this.state.paginate.pagination_count)} handleExport = {this.handleExportCSV} csv_total_data = {this.state.total !== undefined ? this.state.total : 0} /> */}
                                    <Box display={'flex'} justifyContent={'end'}>
									<ExportCsv 
									total_count={this.state.total ?? 0} 
									handleExport={this.handleExportCSV} 
									export_csv_datas={this.state.export_data ?? []}
									csvFileName = {'FA Applications'}
									paginationMode= { "server"}
                                    columns={this.state.columndatas??[]}

									/>
									</Box>
									
									<Box sx={{mt:2 }}>
										
										<DataGridTable
											rows={this.state.copy}
											columns={this.state.columndatas}
											paginationMode="server"
											sortingMode={"client"}
											page={this.state.page}
											rowCount={this.state.total}
											// sort={this.state.sort}
											setsort={(newSort) => this.setState({ sort: newSort })}
											setpage={(newPage) => this.setState({ page: newPage })}
											isLoading={ this.state.loader }
											// isError={isError}
											// errorText="Error fetching data"
											noOfColumnsToLoad={6}
											slots={{ toolbar: CustomTableGridToolbar }}
												slotProps={{
												  toolbar: {
													showQuickFilter: true,
												  },
												}}
										/>
									</Box>
									</>
								}
							</div>
						</>}
						</div>

					</div>
				}
				<div style={{ display: this.state.loader ? '' : 'none' }}>
					<div style={{ marginTop: "15%" }} > <p className='text-light mt-3 text-center'>Loading...</p>
						<div className="product_takeup_loader"></div>
					</div>
				</div>
			</div>

		);
	}
}
export default LoanApplSearchContainer;
