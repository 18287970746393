import React, { Component } from 'react'
import FlowComponent from '../../common/component/core/FlowComponent'
import moment from 'moment'
import DatePicker from 'react-datetime';
import { BiCalendar } from 'react-icons/bi';
import { check_priv, Config, get } from '../../../helpers/storage_helper';
import { RoundOffSplitter, capitalize_first_letter, getValueFromLangJson, get_path, lbl_amt } from '../../../helpers/common_helper';
import CommonReactTable from '../../../helpers/commonReact_table';
import { TooltipLabel } from '../../common/partial';
import { Tooltip } from 'react-bootstrap';
import { getReport } from '../../../actions/report_actions';
import Select from 'react-select'
import { call_fetch } from '../../../helpers/fetch_helper';
import { dd_value } from '../../../actions/common_actions';
import { BasicReactTable } from '../../../helpers/react_table';
import { list_rec_status, retry } from '../../../actions/loan_actions';

export default class RmRmsCallsReport extends FlowComponent {
    state = {
        start_date : moment().subtract(1,'day'),
        end_date :  moment().subtract(1,'day'),
        current_date : moment().format("DDMMYYYY"),
        column_data : [],
        loader : false,
        csvData : [],
        market: get('market'),
        selected_rm_ids: [],
        loan_status:[],
        task_header: ['day_1', 'day_2', 'day_3', 'day_20', 'commitment_call'],
        fa_status_name : Config("fa_recovery_status"),

    }
    componentWillMount() {

        this.setState({ report_type: this.props.report_type== "rm_visits" ? 'get_rm_visit_report' : 'get_rm_rms_call_report' },()=> this.handleRequest())
        this.load_flow_rel_mgrs()
        this.get_fa_status()
    }

    load_flow_rel_mgrs = ()=>{
        let req = {country_code : this.state.market.country_code, time_zone:this.state.market.time_zone, associated_with:'flow' }
        call_fetch(get_path('kyc') + '/rel_mgr/name_list', req).then((response)=>{
            if(response && response.status == 'success'){
                let options = response.data.map((val)=>({...val, label:val.name, value: val.id }))
                this.setState({flow_rel_mgrs:options})
            }
            
        })
    
    }

      get_fa_status = () => {
        list_rec_status(this.req())
          .then((response) => {
            if (response && response.status === "success") {              
              const status = response.data?.length > 0
                ? response.data.map((status) => ({
                    label: dd_value(status, status),
                    value: status,
                  }))
                : []; 
      
              this.setState({ fa_status: status });
            }
          }) 
      };

    handleRequest = () =>{
        this.setState({loader : true,row_data : [], column_data: []})
        var req = {
            "report_type": this.state.report_type , 
            "start_date":moment(this.state.start_date).format("YYYY-MM-DD"),
            "end_date": moment(this.state.end_date).format("YYYY-MM-DD"),
            "country_code": this.country_code,
            "time_zone": get('market').time_zone,
            "acc_prvdr_code": this.acc_prvdr_code ,
            "rel_mgr_id" : this.state.selected_rm_ids.length > 0 ? this.state.selected_rm_ids.map((val) => val.id) : [],
            "loan_status" : this.state.loan_status.length > 0 ?  this.state.loan_status.map((val)=>val.value) : [],
        }

            getReport(req)
            .then((response) => {
                if(!response) {
                    this.setState({loader : false})
                    return;}
                if(response.status === "success"){
                    const data = response.data;
                    const csv_data = JSON.parse(JSON.stringify(response.data));
                    this.setState({ column_data: this.state.report_type == 'get_rm_visit_report' ?  this.visit_column_datas() : this.calls_column_datas(), row_data: data, all_data : data,loader : false})
                    this.csvDataFormat(csv_data)
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                }
            })
    }

    csvDataFormat = (data) => {
        let rm_visit_task = ['frr_visit', 'wl_visit', 'dn_visit', 'nis_visit'];
        let rm_rms_call_task = ['call_task_rm', 'call_task_rms'];
        let visit = {frr_visit:"First", wl_visit:"Second" , dn_visit:"Third", nis_visit:"Fourth"}
        let mergedArray = [...rm_visit_task, ...rm_rms_call_task];
      
        let keysToCheck = this.state.report_type == 'get_rm_visit_report' ? rm_visit_task : rm_rms_call_task;
      
        let updatedData = (data && data.length>0) && data.map(obj => {
            if(obj.hasOwnProperty('loan_status')){
                obj.loan_status = this.state.fa_status_name[obj.loan_status] ? this.state.fa_status_name[obj.loan_status] : dd_value(obj.loan_status)
            }
          keysToCheck.forEach(key => {
            if(this.state.report_type == "get_rm_visit_report"){
                let visit_val = visit[key]
                obj[`${visit_val} Visit - Status`] = 'NA'
                obj[`${visit_val} Visit - Day`] = 'NA'
            }else{
                if(key == 'call_task_rm'){
                    obj['RM Call Status'] = 'NA'
                }else if(key == 'call_task_rms'){
                    this.state.task_header.forEach((day) =>{
                        if(day != "commitment_call"){
                            obj[`RMS Calls Status (${day} - 10 am)`] = 'NA'
                            obj[`RMS Name (${day} - 10 am)`] = 'NA'
                            obj[`RMS Calls Status (${day} - 4 pm)`] = 'NA'
                            obj[`RMS Name (${day} - 4 pm)`] = 'NA'
                        }
                        
                    })
                }
            }
            if (obj.hasOwnProperty(key)) {
              if (this.state.report_type == 'get_rm_visit_report') {
                let visit_val = visit[key]
                obj[`${visit_val} Visit - Status`] = obj[key].visit_status
                obj[`${visit_val} Visit - Day`] = obj[key].follow_up_days
                delete obj[key]
              } else {
                if (key == 'call_task_rm') {
                  if (obj[key]) {
                    obj['RM Call Status'] =obj[key].status == "FA Recovered" ? `Cancelled(FA Recovered)` : obj[key].status;

                  }
                  delete obj.call_task_rm;
                } else if (key == 'call_task_rms') {
                  let dayKeys = Object.keys(obj.call_task_rms);
                  dayKeys.forEach((dayKey, index) => {
                    if(dayKey != "commitment_call"){
                        if(obj.call_task_rms[dayKey].am){
                            obj[`RMS Calls Status (${dayKey} - 10 am)`] = obj.call_task_rms[dayKey].am.status == "FA Recovered" ? `Cancelled(FA Recovered)` : obj.call_task_rms[dayKey].am.status
                            obj[`RMS Name (${dayKey} - 10 am)`] = (obj.call_task_rms[dayKey].am.status == 'FA Recovered' || obj.call_task_rms[dayKey].am.status == 'Skipped') ? "NA" : obj.call_task_rms[dayKey].am.call_logger_name
                        }
                        if(obj.call_task_rms[dayKey].pm){
                            obj[`RMS Calls Status (${dayKey} - 4 pm)`] = obj.call_task_rms[dayKey].pm.status == "FA Recovered" ? `Cancelled(FA Recovered)` : obj.call_task_rms[dayKey].pm.status
                            obj[`RMS Name (${dayKey} - 4 pm)`] = (obj.call_task_rms[dayKey].pm.status == "FA Recovered" || obj.call_task_rms[dayKey].pm.status == "Skipped") ? "NA" : obj.call_task_rms[dayKey].pm.call_logger_name
                        }
                    }
                   
                  });
      
                  delete obj.call_task_rms;
                }
              }
            }
          });
          return obj;
        });
      
        this.setState({ csvData: updatedData });
      };
      

    monthHandler = (value,key) => {

        if (key === 'start_date') {
            const endDate = this.state.end_date; 
            if (moment(value).isAfter(endDate)) {
                alert("Start date cannot be after the end date!");
                return; 
            }
        } else if (key === 'end_date') {
            const startDate = this.state.start_date;
            if (moment(value).isBefore(startDate)) {
                alert("End date cannot be before the start date!");
                return; 
            }
        }

        this.setState({[key] : value});
    }

    handleStatus = (paid_amt ,os_amt) =>{
        const p_amt = parseFloat(paid_amt) 
        const os_amount = parseFloat(os_amt)
        const amount = os_amount - p_amt
        
        if(p_amt == 0){
            return 'To be Recovered' 
        }
        else if(os_amount > amount ){
            return 'Partial'
        }
    }

    call_tasks_recovery_file(row){
        
        window.open(`/recovery_file/${row.loan_doc_id}`, '_blank');

    }

    calls_column_datas() {
        const column_1 =  [
            

            {
                Header: <p className='pt-4'>Customer Name</p>,
                id: "customer_name",
                sortable: false,
                width :120,
                accessor: row =><p className='no-margin text-white text-center' style={{fontSize:'larger'}} > {capitalize_first_letter(row.cust_name)}</p>,
                

            },
            {
                Header: <p className='pt-4'>Customer ID</p>,
                id: "cust_id",
                sortable: false,
                with:130,
                accessor: row => <p className='no-margin text-white text-center'  > {row.cust_id}</p>

            },
            {
                Header: <p className='pt-4'>Status</p>,
                id: "status",
                sortable: false,
                width : 130,
                accessor: row => <p className='no-margin text-white text-center' style={{fontSize:'larger'}} >{this.state.fa_status_name[row.loan_status] ? this.state.fa_status_name[row.loan_status] : dd_value(row.loan_status)}</p>,
            },
            {
                Header: <p className='text-wrap pt-4'>Outstanding Amount</p>,
                id: "outstanding_amount",
                width : 150,
                sortable: false,
                accessor: row => 
                <div className='d-flex justify-content-end rms_call_report'>
                <p className='no-margin text-white text-right pr-1' style={{fontSize:'larger'}} > {lbl_amt(row.os_amt, get("market").currency_code)}</p>
                <RoundOffSplitter val={<div className='d-flex row justify-content-start'>
                    <div className='col-6'>
                        <p className='m-0 p-0 text-start' style={{fontSize:'10px',fontWeight:'lighter'}}>FA Amount</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{lbl_amt(row.fa_amt, get("market").currency_code)}</p>
                    </div>
                    <div className='col-6'>
                        <p className='m-0' style={{fontSize:'10px',fontWeight:'lighter'}}>Fee</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{lbl_amt(row.fee, get("market").currency_code)}</p>
                    </div>
                    <div className='col-6'>
                        <p className='m-0' style={{fontSize:'10px',fontWeight:'lighter'}}>Penalty</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{lbl_amt(row.penalty, get("market").currency_code)}</p>
                    </div>
                    <div className='col-6'>
                        <p className='m-0' style={{fontSize:'10px' ,fontWeight:'lighter'}}>Paid Amount</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{lbl_amt(row.paid_amt, get("market").currency_code)}</p>
                    </div>
                </div>} unit={""} align_center={false} position={"bottom"} notify={true}/>
                </div>

            },
            {
                Header: <p className='pt-4'>Overdue Days</p>,
                id: "overdue_days",

                sortable: false,
                accessor: row => <div className='d-flex justify-content-center rms_call_report'>
                <p className='no-margin text-white text-center pr-2' style={{fontSize:'larger'}}  > {row.overdue_day ? row.overdue_day : "-"} Day{row.overdue_day && row.overdue_day > 1 ? 's' : ''}</p>
                <RoundOffSplitter val={<div className='d-flex row justify-content-start'>
                    <div className='col-6'>
                        <p className='m-0 p-0 text-start' style={{fontSize:'10px',fontWeight:'lighter'}}>Disbursal Date</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{moment(row.disbursal_date).format("DD MMM YYYY")}</p>
                    </div>
                    <div className='col-6'>
                        <p className='m-0' style={{fontSize:'10px',fontWeight:'lighter'}}>Repayment Date</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{row.paid_date ? moment(row.paid_date).format("DD MMM YYYY") : 'NA'}</p>
                    </div>
                </div>} unit={""} align_center={false} position={"bottom"} notify={true}/>
                </div>,
            },
            {
                Header: <p className='pt-4'>{getValueFromLangJson("rm_label_short")} Name</p>,
                id: "rm_name",
                sortable: false,
                width :120,
                accessor: row => <>{row.call_task_rm ? 
                                     <div>
                                        <p className='no-margin  text-center ' style={{color: row.call_task_rm.status == 'logged' || row.call_task_rm.status == 'FA Recovered' ? '#0FD53BCC' :  row.call_task_rm.status == 'Call Delay' ? '#D5B50F' : 'red',fontSize:'14px',fontWeight:400}} > {capitalize_first_letter(row.call_task_rm.status)}</p>

                                        <p className='no-margin text-center'  > {capitalize_first_letter(row.rm_name)}</p>

                                  </div>
                                  :
                                  <p className='no-margin text-center'  >{capitalize_first_letter(row.rm_name)}</p>}</>


            }]

            const column_2 = this.state.task_header.map((value, i)=>({
                            Header: <div className='' style={{textAlign:'center'}}>
                                <p className='' style={{fontSize:'14px'}} >{value == "commitment_call" ? 'Commitment Date' : dd_value(value)}</p>
                                <p className='text-white' style={{fontWeight:100}} > (RMS)</p>
                                <div className='d-flex ' style={{justifyContent:'space-evenly'}} >
                                <p className='m-0 text-white' style={{fontWeight:100,fontSize:'12px'}}  >{value == "commitment_call" ? "Date": "AM"}</p>
                                <div style={{border:'1px solid gray'}}></div>
                                <p className='m-0 text-white' style={{fontWeight:100,fontSize:'12px'}}  > {value == "commitment_call" ? "Status" : "PM"}</p>
                                </div>
                            </div>,
                        id: value,
                        // sortable: false,
                        width :230,
        
                        accessor: row =>  
                            <div className='d-flex justify-content-around '>

                                {value == "commitment_call" && row.call_task_rms && row.call_task_rms[value] && row.call_task_rms[value].commitment_date  ? <p className='no-margin text-center col-6 d-flex justify-content-center align-items-center'  >{row.call_task_rms[value].commitment_date}</p> :
                                value != 'commitment_call' && row.call_task_rms && row.call_task_rms[value] && row.call_task_rms[value].am ? 
                                <div className='col-6 p-0'>
                                    <p className='no-margin  text-center ' style={{color: row.call_task_rms[value].am.status == 'logged' || row.call_task_rms[value].am.status == 'FA Recovered' ? '#0FD53BCC' :(row.call_task_rms[value].am.status == 'Call Delay' || row.call_task_rms[value].am.status == 'Unanswered') ? '#D5B50F' : 'red',fontSize:'14px',fontWeight:400}} > {capitalize_first_letter(row.call_task_rms[value].am.status)}</p>
                                    {(row.call_task_rms[value].am.status == 'logged' || row.call_task_rms[value].am.status == 'Call Delay' || row.call_task_rms[value].am.status == 'Unanswered') &&
                                        <p className='no-margin text-center'  > {capitalize_first_letter(row.call_task_rms[value].am.call_logger_name)}</p>
                                    } 
                                </div>: <p className='no-margin text-center col-6 d-flex justify-content-center align-items-center'  >NA</p>}
                                {row.call_task_rms && row.call_task_rms[value] && row.call_task_rms[value].pm ? 
                                <div className='col-6 p-0'>
                                    <p className='no-margin  text-center ' style={{color: row.call_task_rms[value].pm.status == 'logged' || row.call_task_rms[value].pm.status == 'FA Recovered' ? '#0FD53BCC' : (row.call_task_rms[value].pm.status == 'Call Delay' || row.call_task_rms[value].pm.status == 'Unanswered') ? '#D5B50F' : 'red',fontSize:'14px',fontWeight:400}} > {capitalize_first_letter(row.call_task_rms[value].pm.status)}</p>
                                    {(row.call_task_rms[value].pm.status == 'logged' || row.call_task_rms[value].pm.status == 'Call Delay' || row.call_task_rms[value].pm.status == 'Unanswered') && 
                                        <p className='no-margin text-center'  > {capitalize_first_letter(row.call_task_rms[value].pm.call_logger_name)}</p>
                                    }
                                </div>: <p className='no-margin text-center col-6 p-0 d-flex justify-content-center align-items-center'  >NA</p>}
                            </div>
                    })
            )
            const column_3 =[{
                Header: <p className='pt-4'>Recovery File</p>,
                id: "call_log",
                sortable: false,
                accessor: row => <div className=' d-flex justify-content-center'> <button className='btn product_takeup_submit px-4  py-3' onClick={() => this.call_tasks_recovery_file(row)} >View File</button> </div>,


            }]

            return [...column_1, ...column_2, ...column_3]
    }

    visit_column_datas() {
        return [
            
            {
                Header: <p className='text-center'>Customer Name</p>,
                id: "cust_name",
                sortable: false,
                accessor: row =><p className='no-margin text-white text-center' style={{fontSize:'larger'}} > {capitalize_first_letter(row.cust_name)}</p>,


            },
            {
                Header: <p className='text-center'>Customer ID</p>,
                id: "cust_id",
                sortable: false,
                accessor: row => <p className='no-margin text-white text-center'  > {row.cust_id}</p>,

            },
            {
                Header: <p className='text-center'>{getValueFromLangJson("rm_label_short")} Name</p>,
                id: "rm_name",
                sortable: false,
                accessor: row => <p className='no-margin text-center'  > {capitalize_first_letter(row.rm_name)}</p>
            }, 
            {
                Header: <p className='text-wrap text-center'>Outstanding Amount</p>,
                id: "os_amt",
                width : 150,
                sortable: false,
                accessor: row => 
                <div className='d-flex justify-content-end rms_call_report'>
                <p className='no-margin text-white text-right pr-1' style={{fontSize:'larger'}} > {lbl_amt(row.os_amt, get("market").currency_code)}</p>
                <RoundOffSplitter val={<div className='d-flex row justify-content-start'>
                    <div className='col-6'>
                        <p className='m-0 p-0 text-start' style={{fontSize:'10px',fontWeight:'lighter'}}>FA Amount</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{lbl_amt(row.fa_amt, get("market").currency_code)}</p>
                    </div>
                    <div className='col-6'>
                        <p className='m-0' style={{fontSize:'10px',fontWeight:'lighter'}}>Fee</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{lbl_amt(row.fee, get("market").currency_code)}</p>
                    </div>
                    <div className='col-6'>
                        <p className='m-0' style={{fontSize:'10px',fontWeight:'lighter'}}>Penalty</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{lbl_amt(row.penalty, get("market").currency_code)}</p>
                    </div>
                    <div className='col-6'>
                        <p className='m-0' style={{fontSize:'10px' ,fontWeight:'lighter'}}>Paid Amount</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{lbl_amt(row.paid_amt, get("market").currency_code)}</p>
                    </div>
                </div>} unit={""} align_center={false} position={"bottom"} notify={true}/>
                </div>

            },
            {
                Header: <p className='text-center'>Overdue Days</p>,
                id: "overdue_day",

                sortable: false,
                accessor: row => <div className='d-flex justify-content-center rms_call_report'>
                <p className='no-margin text-white text-center pr-2' style={{fontSize:'larger'}}  > {row.overdue_day ? row.overdue_day : "-"} Day{row.overdue_day && row.overdue_day > 1 ? 's' : ''}</p>
                <RoundOffSplitter val={<div className='d-flex row justify-content-start'>
                    <div className='col-6'>
                        <p className='m-0 p-0 text-start' style={{fontSize:'10px',fontWeight:'lighter'}}>Disbursal Date</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{moment(row.disbursal_date).format("DD MMM YYYY")}</p>
                    </div>
                    <div className='col-6'>
                        <p className='m-0' style={{fontSize:'10px',fontWeight:'lighter'}}>Repayment Date</p>
                        <p className='m-0' style={{fontSize:'10px'}}>{row.paid_date ? moment(row.paid_date).format("DD MMM YYYY") : 'NA'}</p>
                    </div>
                </div>} unit={""} align_center={false} position={"bottom"} notify={true}/>
                </div>,
            },
            {
                Header: <p className='text-center'>Status</p>,
                id: "loan_status",
                width : 125,
                sortable: false,
                accessor: row => <p className='no-margin text-white text-center' style={{fontSize:'larger'}} >{this.state.fa_status_name[row.loan_status] ? this.state.fa_status_name[row.loan_status] : dd_value(row.loan_status)}</p>,
            },
            {
                Header:<div>
                <p className='mb-2 text-center' >1<sup>st</sup> Visit</p> 
                <p className='text-white text-center' style={{fontWeight:100}}>Day 2-4</p> 
                </div>,
                id: "1st_visit",
                // sortable: false,
                // width :200,

                accessor: row =>  
                <>
                    {row.frr_visit && row.frr_visit.visit_status != null ? 
                        <div> 
                            <p className='no-margin  text-center ' style={{color: row.frr_visit.visit_status == 'Visited' || row.frr_visit.visit_status == "FA Recovered" || row.frr_visit.visit_status == "Unavailable" ? '#0FD53BCC' : 'red',fontSize:'14px',fontWeight:400}} > {capitalize_first_letter(row.frr_visit.visit_status)}</p>
                            {row.frr_visit.visit_status != 'Not Visited' &&
                                <p className='no-margin text-center'  >{row.frr_visit.visit_day == 0 ? 1 : row.frr_visit.visit_day}
                                <sup>{row.frr_visit.visit_day == 0 || row.frr_visit.visit_day == 1 ? 'st' : row.frr_visit.visit_day == 2 ? 'nd' : row.frr_visit.visit_day == 3 ? 'rd' : 'th'}</sup> day
                                </p>
                            }
                        </div>
                    :
                        <p className='no-margin text-center' >NA</p>
                    }
                 </>
               

            },

            {
                Header: <div>
                    <p className='mb-2 text-center' >2<sup>nd</sup> Visit</p> 
                    <p className='text-white text-center' style={{fontWeight:100}}>Day 5-10</p> 
                </div>,
                id: "2nd_visit",
                sortable: false,
                // width :200,
                accessor: row => 
                <>
                {row.wl_visit && row.wl_visit.visit_status != null ? 
                  <div>
                    <p className='no-margin  text-center ' style={{color: row.wl_visit.visit_status == 'Visited' || row.wl_visit.visit_status == "FA Recovered" || row.wl_visit.visit_status == "Unavailable" ? '#0FD53BCC' : 'red',fontSize:'14px',fontWeight:400}} > {capitalize_first_letter(row.wl_visit.visit_status)}</p>
                    {row.wl_visit.visit_status != 'Not Visited' &&
                        <p className='no-margin text-center'  >{row.wl_visit.visit_day}
                        <sup>th</sup> day
                        </p>
                    }
                  </div>
                :
                    <p className='no-margin text-center' >NA</p>
                }  
                </>    


            },
            {
                Header: <div>
                    <p className='mb-2 text-center' >3<sup>rd</sup> Visit</p> 
                    <p className='text-white text-center' style={{fontWeight:100}}>Day 11-20</p> 
                </div>,
                id: "3rd_visit",
                sortable: false,
                // width :200,
                accessor: row =>
                <>
                {row.dn_visit && row.dn_visit.visit_status != null ? 
                  <div>
                    <p className='no-margin  text-center ' style={{color: row.dn_visit.visit_status == 'Visited' || row.dn_visit.visit_status == "FA Recovered" || row.dn_visit.visit_status == "Unavailable" ? '#0FD53BCC' : 'red',fontSize:'14px',fontWeight:400}} > {capitalize_first_letter(row.dn_visit.visit_status)}</p>
                    {row.dn_visit.visit_status != 'Not Visited' &&
                        <p className='no-margin text-center'  >{row.dn_visit.visit_day}
                        <sup>th</sup> day
                        </p>
                    }
                  </div>
                :
                <p className='no-margin text-center' >NA</p>
                } 
                </>

            },
            {
                Header: <div>
                <p className='mb-2 text-center' >4<sup>th</sup> Visit</p> 
                <p className='text-white text-center' style={{fontWeight:100}}>Day 30-40</p> 
                </div>,
                id: "4th_visit",
                sortable: false,
               
                accessor: row => 
                <> 
                {row.nis_visit && row.nis_visit.visit_status != null ? 
                    <div>
                        <p className='no-margin  text-center ' style={{color: row.nis_visit.visit_status == 'Visited' || row.nis_visit.visit_status == "FA Recovered" ||  row.nis_visit.visit_status == "Unavailable" ? '#0FD53BCC' :'red',fontSize:'14px',fontWeight:400}} > {capitalize_first_letter(row.nis_visit.visit_status)}</p>
                        {row.nis_visit.visit_status != 'Not Visited' &&
                            <p className='no-margin text-center'  >{row.nis_visit.visit_day}
                            <sup>{row.nis_visit.visit_day == 31 ? 'st' : row.nis_visit.visit_day == 32 ? 'nd' : row.nis_visit.visit_day == 33 ? 'rd' : 'th'}</sup> day
                            </p>
                        }
                    </div>
                :
                <p className='no-margin text-center' >NA</p>
                }
                </>

            },
            {
                Header: <p className='text-center'>Recovery File</p>,
                id: "call_log",
                sortable: false,
                accessor: row => <div className=' d-flex justify-content-center'> <button className='btn product_takeup_submit px-4  py-3' onClick={() => this.call_tasks_recovery_file(row)} >View File</button> </div>,


            },
           

        ]
    }

    submitHandler = () => {
        this.handleRequest()
    }

    handle_change = (key, value)=>{
        this.setState({[key]:value})
    }


  render() {
    const rm_label_short = getValueFromLangJson("rm_label_short")
    return (
      <div className=''>
        <div>
            <p className='text-white text-center' style={{fontSize : '35px', fontWeight:400,paddingTop:'10%' }}>{this.props.report_type == "rm_rms_calls" ? `${rm_label_short}/${rm_label_short}S Overdue Calls` : `${rm_label_short} Visits`}</p>
        </div>
        <div className='d-flex align-items-center justify-content-center'>
            <div className='mx-3 my-5' style={{width:'18%'}}>
                <fieldset className='' style={{border:'2px solid white',borderRadius:'5px'}}>
                    <legend className="w-auto pipeline_legend_text mb-0 ml-2 px-2" style={{fontSize:'15px'}}>Start Date</legend>
                        <div className="form-group  mt-0 pb-1 pr-1  d-flex align-items-center" >    
                            <div className='col py-1 pr-0'>
                                <DatePicker closeOnSelect={true} 
                                    isValidDate={(current) => {
                                        const previousDay = moment().subtract(1, 'day');
                                        return current.isSameOrBefore(previousDay, 'day');  }} 
                                    value={ this.state.start_date}   
                                    onChange={(value)=>this.monthHandler(moment(value).format("DD MMM YYYY"),'start_date')} 
                                    inputProps={{ id: 'rms_start_date', name: 'Referral_from',autoComplete:'off' }}  
                                    dateFormat="DD MMM yyyy" 
                                    timeFormat={false} required={false}/>
                            
                            </div>

                            <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='rms_start_date'>
                                <p className='m-0 pr-1'><BiCalendar size={30} /></p> 
                            </label>
                        </div>

                </fieldset>
            </div>
        <div className='mx-3 my-5' style={{width:'18%'}}>
            <fieldset className='' style={{border:'2px solid white',borderRadius:'5px'}}>
                <legend className="w-auto pipeline_legend_text mb-0 ml-2 px-2" style={{fontSize:'15px'}}>End Date</legend>
                    <div className="form-group  mt-0 pb-1 pr-1  d-flex align-items-center" >    
                        <div className='col py-1 pr-0'>
                            <DatePicker closeOnSelect={true} 
                                isValidDate={(current) => {
                                    const previousDay = moment().subtract(1, 'day');
                                    return current.isSameOrBefore(previousDay, 'day');  }} 
                                value={ this.state.end_date}  
                                onChange={(value)=>this.monthHandler(moment(value).format("DD MMM YYYY"),'end_date')}   
                                inputProps={{ id: 'rms_end_date', name: 'Referral_from',autoComplete:'off' }}  
                                dateFormat="DD MMM yyyy" 
                                timeFormat={false} required={false}/>
                                
                        </div>

                        <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='rms_end_date'>
                            <p className='m-0 pr-1'><BiCalendar size={30} /></p> 
                        </label>
                    </div>
            </fieldset>
        </div>
        <div className='mx-3 my-5' style={{width:"18%"}}>
            <fieldset className='' style={{border:'2px solid white',borderRadius:'5px'}}>
                <legend className="w-auto pipeline_legend_text mb-0 ml-2 px-2" style={{fontSize:'15px'}}>{rm_label_short}</legend>
                    <div className="form-group  mt-0 p-0 d-flex align-items-center" >    
                        <div className='col p-0 rm_visit_m_select'>
                            
                            <Select styles={this.style_for_react_select(false, '#4F5167')}  isMulti options={this.state.flow_rel_mgrs? this.state.flow_rel_mgrs : []} onChange={(option)=>this.handle_change('selected_rm_ids', option)} hideSelectedOptions={true}/>
                           
                                
                        </div>
                    </div>
            </fieldset>
        </div>
        <div className='mx-3 my-5' style={{width:"18%"}}>
            <fieldset className='' style={{border:'2px solid white',borderRadius:'5px'}}>
                <legend className="w-auto pipeline_legend_text mb-0 ml-2 px-2" style={{fontSize:'15px'}}>Status</legend>
                    <div className="form-group  mt-0 p-0 d-flex align-items-center" >    
                        <div className='col p-0 rm_visit_m_select'>
                            
                            <Select styles={this.style_for_react_select(false, '#4F5167')} isMulti options={this.state.fa_status? this.state.fa_status : []} onChange={(option)=>this.handle_change('loan_status', option)} hideSelectedOptions={true}/>
                           
                                
                        </div>
                    </div>
            </fieldset>
        </div>
        
        </div>
        <div className='d-flex align-items-center justify-content-center'  >
            <button type="button" class="btn btn-primary  no-margin " onClick={()=>this.submitHandler()} >Submit</button>

        </div>
        {!this.state.loader ? 
        <div className='mt-3 row m-0 rms_calls'>
           <>
            {this.state.row_data && this.state.row_data.length > 0 ?
                <div className='cust_next_follow_up mt-2 w-100 col-lg-12 col-md-12 col-sm-12 p-0' >
                    {/* <CommonReactTable className='border' row_data={this.state.row_data} background_header={true} column_data={this.state.column_data} FName={this.state.report_type == 'get_rm_visit_report' ? 'RM Visits' : 'RM/RMs Calls'} exportCSV={true} csvFile={this.state.report_type == 'get_rm_visit_report' ? `RM_Visit_Report_${this.state.current_date}` : `RM_RMS_Call_Report_${this.state.current_date}`} csvData={this.state.csvData ? this.state.csvData : []} global_search={false} minRow={0} defaultPageSize={5} addBackground={true} /> */}

                    <BasicReactTable  row_data={this.state.row_data} column_data={this.state.column_data} FName={this.state.report_type == 'get_rm_visit_report' ? `RM_Visit_Report_${this.state.current_date}` : `RM_RMS_Call_Report_${this.state.current_date}`} csv_file={true} export_csv_datas={this.state.csvData ? this.state.csvData : []} default_page_size={5} header_class='table_background_header' className="border"  pagination={true} searchData={[{title:this.state.report_type == 'get_rm_visit_report' ? `${rm_label_short} Visit Report-${this.state.current_date}` : `${rm_label_short} RMS Call Report-${this.state.current_date}`}]}/>
                </div>
                :
                <p className='text-white text-center mb-0 mt-5 col-12 '>NO DATA</p>
            } 
            </>
        </div>
        :
        <div style={{marginTop:"15%"}} > 
            <p className='text-light mt-3 text-center'>Loading...</p>
            <div className="product_takeup_loader"></div>
        </div>}
      </div>
    )
  }
}
