import {  Link, Navigate } from 'react-router-dom';
import React from 'react';
import FlowContainer from "../../common/container/core/FlowContainer";
import FlowComponent from "../../common/component/core/FlowComponent";
import Person from "../../common/component/Person";
import '../../../styles/flow.css';
import {createPerson, get_person_email, viewPerson} from "../../../actions/person_actions";
import {get,get_item} from "../../../helpers/storage_helper";
import {getValueFromLangJson, title_case} from "../../../helpers/common_helper";
import withRouter from '../../../Router/Withrouter';

class CreateUserContainer extends FlowContainer {

  state = {
    person: {},
    address : {},
    is_validate : true

  }

  componentWillMount(){
      var person = null;
      if(this.props.mode === 'view'){
          const person_id = this.props.params.person_id;
          const request = {person_id};
          viewPerson(this.req(request))
            .then((response) => {
              if(!response){return };
                person = response.data;
                this.setState({person: person,
                                address : person.address
                });
              }
            );
        }
      if(this.props.invstr_key =='investor'){
        var bcc_email;
        get_person_email(this.req()).then((response)=>{
          this.resp(response)
          if(!response){
            return;
          }
          
          if(response.status=='success'){
            bcc_email = response.data
            this.setState({person:{...this.state.person,bcc_email:[response.data]}})
          }
        })

        if(this.props.mode == 'create'){
          this.setState({person:{...this.state.person,role:'bond_investor',create_user:true}})
        }

        
      }

  }

 

  handleUserCheckboxClick = (event) => {
  if(event.target.checked){
    this.setState({create_user: true});
  }else{
    this.setState({create_user: false});
  }
}

filteredObj = async (obj) => {
    var filtered={}
   filtered=Object.entries(obj).reduce((newobj, [key, value]) => {
    if (value !== null && value !== undefined) {
      newobj[key] = value;
    }
    return newobj;
  }, {});
  this.setState({person:filtered})
  
}

    handleSubmit =async (event,bond_investor,id) => {
      event.preventDefault();
    
    const { first_name, last_name, gender, email_id ,role} = this.state.person || {};

    const errorMessages = [];

    
    const fieldValidations = {
      first_name: 'The first name field is required.',
      last_name: 'The last name field is required.',
      gender: 'The gender field is required.',
      email_id: 'Email is a required field.',
      role: 'Role is a required field.'
    };
  
    
    Object.keys(fieldValidations).forEach((key) => {
      if (!this.state.person[key]) {
        errorMessages.push(fieldValidations[key]); 
      }
    });

    
    if (errorMessages.length > 0) {
      alert(errorMessages.join(' | '));
      return; 
    }

  if (first_name && last_name && gender && email_id && role) {


    if (this.state.person.role && this.state.person.role === 'sales_representative' && !this.state.person.report_to) {
      alert(`Please select the ${getValueFromLangJson("rm_label_short")} name whom the Sales Rep will come under`);
      return;
    }
    if (this.state.person.role && this.state.person.role === 'relationship_manager'  && this.state.is_validate && !this.state.person.report_to  ){
      alert(`Please select the TM name whom the ${getValueFromLangJson("rm_label_short")} will come under`);
      return;
    }
    if (this.state.person.create_user && !this.state.person.role) {
      alert('Please select the role of the user');
      return;
    }
  }
    if(bond_investor){
     const update_user= { ...this.state.person, role: 'investor',create_user:true ,id}
      await this.filteredObj(update_user)
    }
    var person = this.state.person;
    person.country_code = this.country_code;
    person.address = this.state.address;
    person.associated_with = 'FLOW';
    person.designation = title_case(this.state.person.role)
    person.create_user = true
       createPerson(this.req({person}))
        .then((response) => {
            this.resp(response)
          if(!response){return };
              if(this.props.invstr_key == 'investor'){
                alert("New Investor created successfully");
                window.location.assign(`/investor/view/${response.data}`)
              }
              else{
                alert("New Person created successfully");
                window.location.assign(`/user/view/${response.data}`)
              }
              
          }
        );
  }

  handleRoleSelect = (event) => {
      this.state.person.role = event.target.value
  }

    changeRole = (checked) =>{
      
      
      if(checked){
        this.setState({person : {...this.state.person,report_to : ""}})
      }else {
        
        
        let keys = ['role', 'report_to'];
        keys.forEach((key) => {
            
            let customEvent = {
                target: {
                    id: key,   
                    value: ""  
                }
            };
            this.handleComponentChange(customEvent, this.props.id);
        });
    }
  };


  render(){

      return (

      <div className="container text-labelblue mt-3">
         <h5>{this.props.title + (this.state.person.first_name? " : " + this.state.person.first_name : "")}</h5>
        <form id="borrower_form" onSubmit={this.handleSubmit}>
            <div id="" style={{position:'relative'}}>

            
             <Person id="person" is_validate ={this.state.is_validate} title={this.props.title} entity="user" designation='list' data={this.state.person}
                    mode={this.props.mode}
                    onComponentChange={this.handleComponentChange} changeRole = {(val) =>this.changeRole(val)} chk_validation = {()=>this.setState({is_validate : false}) } changeComponentName="person" invstr_key={`${this.props.invstr_key=='investor' ? 'investor':''}`}/>
            {this.state.person.investor_type && this.state.person.investor_type == 'bond_investor'&&
            <div class=' update_user_position mt-2' style={{top:this.props.invstr_key == 'investor' ? '65%': '70%'}} >
            <button onClick={(e)=>this.handleSubmit(e,true,this.state.person.id)} className='btn btn-success font'>Update as a App User</button>
            </div>}
              <br/>
              
              
            <div className='d-flex justify-content-center'>
            <input type="button" className={`btn btn-primary  ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value={`${this.props.invstr_key == "investor" ? "Create Investor" : "Create User"}`} disabled={this.state.calling_api}/>
            </div>

          </div>

        </form>
        <br/><br/>
      </div>

      );
  }
}

export default  withRouter(CreateUserContainer);
