import React, { useEffect, useState } from 'react';
import { Box, IconButton, Slide, Typography, useTheme } from '@mui/material';
import AutoModeTwoToneIcon from '@mui/icons-material/AutoModeTwoTone';
import { useForm } from 'react-hook-form';
import CustomButton from '../../MUI/Components/CustomButton';
import FormProvider from '../CommonComponent/rhfComponents/formProvider';
import FixedLabelRhfTextField from '../CommonComponent/rhfComponents/fixedLabelRhfTextField';
import { check_priv } from '../../helpers/storage_helper';
import { validateCustomer } from '../../actions/borrower_actions';
import { dd_value } from '../../actions/common_actions';
import { GiClick } from 'react-icons/gi';
import { CgProfile } from 'react-icons/cg';
import { BsPersonCheck } from 'react-icons/bs';
import { VscCallIncoming, VscCallOutgoing } from 'react-icons/vsc';
import { submitCheckin } from '../../actions/field_visit_actions';
import { req } from '../../helpers/common_helper';
import { StartCallLog } from '../../actions/call_log_actions';
import { useLocation } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { quick_action_btn } from '../../styles/common_styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateValuesIntoRedux } from '../../Redux/Slices/CommonSlice';
import { useMemo } from 'react';
const QuickActions = () => {
    const [showItems, setShowItems] = useState(false);
    const theme = useTheme();
    const location = useLocation();

    const methods = useForm();
    const { handleSubmit, watch, reset, formState: {errors, defaultValues, dirtyFields} } = methods;
    const dispatch = useDispatch()
    const {quick_action} = useSelector((state: any) => state.common_slice);

    useEffect(() => {
        setShowItems(false);
    }, [location.pathname]); 

      
    const handleValidateCustomer = (action: string) => {
        const custToken = watch('cust_id');
        if (!custToken) {
            alert('Please Enter Customer ID / Mobile Number / National ID');
            return;
        }

        validateCustomer({action, cust_token: custToken, incl_profile: true })
            .then((response) => {
                if (response?.status === 'success') {
                    const custData = response.data;
                    if (!custData) return;
                    const { cust_id, cust_name } = custData;
                    console.log('fcustDatairst', custData)
                    
                    switch (action) {
                        case 'apply_fa':
                            window.open(`/fa_appl_for/${cust_id}`, '_blank');
                           
                            break;
                        case 'view_profile':
                            window.open(`/borrower/indiv/view/${cust_id}`, '_blank');
                            break;
                        case 'CheckIn':
                            handleCheckIn(action, cust_id, cust_name);
                            break;
                        case 'log_incoming_call':
                        case 'log_outgoing_all':
                            handleCallLog(action, cust_id, cust_name)
                            break;
                        default:
                            break;
                    }
                    reset({cust_id: ''});
                    setShowItems(false)

                    
                } else {
                    alert(`${response.message}\n${JSON.stringify(response.data)}`);
                }
            });
    };

    const handleCheckIn = (action: string, cust_id: string, cust_name: string) => {
        const isConfirmed = window.confirm(`Are you sure, right now you are at ${cust_name}'s business location and would like to check-in?`);
        if (!isConfirmed) return;
        const checkin_req = { checkin_req: {cust_id: cust_id} };
        // @ts-ignore
        submitCheckin(req(checkin_req)).then((response) => {
            if (!response) return;
            if (response.status === "success") {
                const { data: visit } = response;
                let alert_msg =  true
                if(visit.action == "lastvisit"){
                    alert_msg = window.confirm(visit.message);
                }
                if (alert_msg && visit.visit_id) {
                    window.open(
                        `/checkout/${visit.cust_id}/${visit.visit_id}?type=${'log_visit'}`,
                        "_blank"
                    );
                }
            } else {
                alert(`${response.message}\n${JSON.stringify(response.data)}`);
            }
        });
    };

    const handleCallLog = (target: string, cust_id: string, cust_name: string) => {
        const isConfirmed = window.confirm(
            target === "log_incoming_call"
                ? `Are you sure right now you have received a call from ${cust_name} and are speaking with the customer?`
                : `Are you sure right now you have dialed ${cust_name} and are speaking with the customer?`
        );
        if (!isConfirmed) return;
        console.log('target', target)
        const action = (target == "log_incoming_call") ? 'incoming' : 'outgoing'
        const call_log = { cust_id: cust_id, call_type: action , from : 'quick_action'
        };
        //  @ts-ignore
        StartCallLog(req(call_log)).then((response) => {
            if (!response) return;
            if (response.status === "success") {
                const { data: log } = response;
                const proceedWithLog = log.action === "lastcall"
                    ? window.confirm(log.message)
                    : true;
                
                if (proceedWithLog && log.call_log_id) {
                    window.open(
                        `/compl_call_log/${log.cust_id}/${log.call_log_id}?type=${log.call_type}`,
                        "_blank"
                    );
                }
            }
            //  else {
            //     alert(`${response?.message}\n${JSON.stringify(response.data)}`);
            // }
        });
    };

    const ActionButton = ({ text, action, icon }: { text: string; action: string; icon: JSX.Element }) => (
      
        <CustomButton
            //  @ts-ignore
            variant="full_width_inter_sm"
            text={dd_value(text)}
            startIcon={icon}
            onClick={() => handleValidateCustomer(text)}
            style={quick_action_btn(theme,text)}
        />
    );

    const quickActions = [
        
        { priv: 'loan', action: 'apply', text: 'apply_fa', icon: <GiClick fontSize="small" /> },
        { priv: 'application', action: 'apply', text: 'view_profile', icon: <CgProfile fontSize="small" /> },
        { priv: 'field_visit', action: 'checkin', text: 'CheckIn', icon: <BsPersonCheck fontSize="small" /> },
        { priv: 'call_log', action: 'post', text: 'log_incoming_call', icon: <VscCallIncoming fontSize="small" /> },
        { priv: 'call_log', action: 'post', text: 'log_outgoing_all', icon: <VscCallOutgoing fontSize="small" /> },
    ];

    const renderedButtons = useMemo(() => {
      return quickActions
          .filter(({ priv, action }) => check_priv(priv, action))
          .map(({ action, text, icon }) => (
              <ActionButton key={action} text={text} action={action} icon={icon} />
          ));
       }, [quickActions]);

    return (
        <Box>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ pl: 2 }}>
          <IconButton
            sx={{ borderRadius: '0px !important', borderBottom: '0.3px solid #595d6c' }}
            onClick={() => {setShowItems(true), dispatch(updateValuesIntoRedux({"quick_action": true}))}}
          >
            <AutoModeTwoToneIcon sx={{ fontSize: '1.3rem', color: theme.palette.text.primary }} />
            <Typography
              variant="h6"
              sx={{ pl: 1, color: 'white', textTransform: 'capitalize', fontWeight: 500, fontSize: '12px' }}
            >
              Quick Actions
            </Typography>
          </IconButton>
        </Box>
       { showItems && quick_action &&
        <Box
          className="zoomIn_animation"
          sx={{
            backgroundColor: '#111936',
            width: '500px',
            position: 'absolute',
            top: '78px',
            left: '150px',
            borderRadius: '0px',
            border: '2px solid #293049',
            p: 2,
            // display: showItems ? 'block' : 'none',
          }}
        >
          <Typography variant="h4" sx={{ textAlign: 'center', mt: 2, fontWeight: 500, fontSize: '18px' }}>
            Quick Actions
          </Typography>
      
          <IconButton
            sx={{ position: 'absolute', right: '10px', top: '10px' }}
            onClick={() => setShowItems(false)}
          >
            <HighlightOffIcon sx={{ fontSize: '1.3rem', color: theme.palette.text.primary }} />
          </IconButton>
      
          <FormProvider methods={methods}>
            
              <FixedLabelRhfTextField
                variant="standard"
                name="cust_id"
                autoComplete="undefined"
                placeholder="Any Customer ID / Mobile No / National ID"
                sx={{
                  '& .MuiInputBase-input': {
                    py: 1,
                    color: '#fff',
                    fontFamily: 'Montserrat',
                    fontSize: '13px !important',
                  },
                }}
              />
          
          </FormProvider>
          <Box display="flex" flexWrap="wrap" justifyContent="space-around" mt={2}>
              {renderedButtons}
          </Box>
        </Box>
      }

      </Box>
      
    );
};

export default QuickActions;
