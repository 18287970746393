import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Link} from 'react-router-dom';
//import {getAccountTxnsType} from '../../../actions/account_txn_actions';
import {getMasterData} from '../../../actions/common_actions';
import {Button } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import moment from 'moment';
import '../../../styles/flow.css';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import $ from 'jquery';
import {check_priv, get,get_item} from '../../../helpers/storage_helper';
import {rt_algn,lbl_amt} from '../../../helpers/common_helper';
import {format_date_tbl} from '../../../helpers/common_helper';
import { list_lender_txn_stmts } from '../../../actions/lender_actions';
import CommonReactTable from '../../../helpers/commonReact_table';
import { AccountTransactionToolTipLabel } from '../../common/partial';
import ReconStatus from '../component/ReconStatus';
import { BasicReactTable } from '../../../helpers/react_table';
class StatementSearch extends FlowComponent {

  state = {
    section1: "show_div", 
    account : {},
    rowdatas:[],
    columndatas:[],
    copy:[],
    showtable:false,
    disable_btn:true,
    // account_list:''
  }

componentWillMount()
{
    const lender_code = this.lender_code; 
    const lender_account = {lender_code, acc_purpose : "disbursement", status:'enabled'};
    this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);
  
  }

  

  handleFields = (event) =>{
    
    if(event.target.value!= "")
    {
      this.setState({section1: "disable_div"});
    }
    else if(event.target.value == "")
    {
    this.setState({section1:"show_div"}); 
    }

    this.handleChange(event);
  }
handleChange = (e) =>{
    this.setState({
        ...this.state,
        'account_id':e.target.value,
        disable_btn:false
    })

}
columndatas(){
    return[
      // {
      //   Header : "SI No.", 
      //   id: "row",	
      //   width:60,			       
      //   Cell: (row) => {
      //     return <div>{row.index+1}
       
      //     </div>;
      //   }
      // },
    //   {
    //     Header : "Stmt Txn Type",
    //     id: "stmt Txn",
    //     accessor:row => {return this.chk_txn_type(row)}
    //   },
      {
        Header : "Cust ID",
        id: "cust_id",
        width:160,
        accessor:row => {return(<div> <Link className='d-block' style={{color:'#149cb2'}} to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.cust_id}</Link></div>)}
       
      },
      {
        Header : "FA ID",
        id: "loan_doc_id",
        width:160,
        accessor:row => {return(<div> <Link className='d-block' style={{color:'#149cb2'}} to={"/fa/view/"+row.loan_doc_id} target="_blank">{row.loan_doc_id}</Link></div>)}
      },
      {
        Header : <div className='text-right'>FA Amount</div>,
        id: "dr_amt",
        accessor:row => {return rt_algn(lbl_amt(row.dr_amt, this.currency_code))}
      },
      {
        Header : <div className='text-right'>Disbursed Time</div>,
        id: "stmt_txn_time",
        width:100,
        accessor:row => {return rt_algn(<div>{row.stmt_txn_date.substr(11,20)}</div>)}
      },
    
      // {
      //   Header : <div className='text-right'>Debit</div>,
      //   id: "dr_amt",
      //   accessor:row => {return rt_algn(lbl_amt(row.dr_amt, this.currency_code))},
      //   // width:160
      // },
      {
        Header : "Stmt Txn ID",
        id: "stmt_txn_id",
        // width:150,
        accessor:row => {return row.stmt_txn_id}

      },
      {
        Header : <div className=''>Description</div>,
        id: "descr",
        accessor: row => <div className=''><AccountTransactionToolTipLabel full_text={row.descr} no_reduce={true} />
        </div>
      },
      {
        Header : "Matching FA ID",
        id: "chk_fa_id",
        accessor:row => {return this.chk_fa_id(row)}
      },
      {
        Header : "Recon Status",
        id: "recon",
        accessor:row => <ReconStatus data={row} onSync = {this.handleTransactionlist}/>
      },
    ]
  }
  chk_fa_id(record){

    const loan_doc_id = record.loan_doc_id
    const recon_status = record.recon_status
    
  if(loan_doc_id){
    if (recon_status == "10_capture_payment_pending"){
      return <span ><Link style = {{color:"grey"}} to={"/fa/view/"+loan_doc_id} target="_blank" >{loan_doc_id}</Link></span>
    }else if(recon_status == "80_recon_done"){
      return <span ><Link to={"/fa/view/"+loan_doc_id} style = {{color:"#007bff"}} target="_blank" >{loan_doc_id}</Link></span>
    }
    return <Link to={"/fa/view/"+loan_doc_id} style = {{color:"grey"}}  target="_blank" >{loan_doc_id}</Link>
  }else{
    return "NA"
  } 
  }
  changeHandler = (e) => {		
    const toFilter = e
    const filterfrom = this.state.rowdatas
    const filteredData = filterfrom.filter(value => {
        return(
            value.stmt_txn_type !==null && value.stmt_txn_type.toLowerCase().includes(toFilter.toLowerCase()) ||
            value.stmt_txn_id !==null && value.stmt_txn_id.includes(toFilter) ||
    value.loan_doc_id !==null && value.loan_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
    value.descr !==null && value.descr.toLowerCase().includes(toFilter.toLowerCase())         				
        )
    })
    this.setState(state=>state.copy = filteredData)		
}
SearchController=()=>{
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
   var today = yyyy + '-' + mm + '-' + dd;
   this.setState({showtable:true})
const data={
  account_id:this.state.account_id,
    status: null,
    stmt_txn_date__from: today,
    stmt_txn_date__to:today,
    stmt_txn_type: "debit",
}

list_lender_txn_stmts(this.req({statement_search:data}, false, 'statement_search'))
.then((response) => {
this.resp(response)

     if(!response)
    {
        return null;
    }
    else if(response.status === "success"){
        this.setState(state=>state.rowdatas = response.data.account_txns)
        this.setState(state=>state.copy = response.data.account_txns)
        this.setState(state=>state.columndatas=this.columndatas())
        // this.setState({account_result:"show_div"});
                     
              }
})
}


    render(){
 
      return(
          
        <div className=''>
          
            <h2 className='text-white text-center'>Today Disbursed FAs</h2>
        <div className="date-range " style={{padding:15}}>          
          <div className = "form-row d-flex align-items-center">
              <div className = "form-group col-md-3">
                <span className = "form-label">Accounts</span><font className="text-danger">   *  </font>
                  <select id="from_acc_id" className="form-control" defaultValue={this.selected.lender_accounts} ref={select => this.from_acc = select} required="required" onChange={(e)=>this.handleChange(e)} >
                      {this.state.lender_accounts_dd}                             
                  </select>
         
              </div>
              <div className="" style={{paddingLeft:'30px '}}>
              <Button className="btn btn-secondory " onClick={()=>this.SearchController()} disabled={this.state.disable_btn}    >Search</Button> &nbsp;
             </div>
          </div>        
          </div>  
          {(this.state.rowdatas.length > 0 || this.state.rowdatas.length === 0) && this.state.showtable &&
          <>
          <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
									    <p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
                      <div style={{position:'relative'}}>
                        <i class="fa-solid fa-magnifying-glass search_pos"></i>
                        <input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
                        {/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
                      </div>
            </div>

                    {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} defaultSorted={[{id:"stmt_txn_date",desc:false}]} exportCSV={true} csvFile={"Today_disbursed_FA"} csvData={this.state.copy}/>  */}

                    <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} FName={"Today_disbursed_FA"} csv_file={true} pagination={true} default_page_size = {15}  defaultSorted={[{ id: "stmt_txn_date", desc: false }]} />                                          
            </>
          }
    </div> 
    )
    }

}
export default StatementSearch;
