import React from "react";
import { useSelector } from "react-redux";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import AddressKycCommon from "../component/AddressKycCommon"; // Import your rekycVerify method
import { Config, get } from "../../../helpers/storage_helper";
import { rekycVerify } from "../../../actions/lead_actions";
import GoogleMapSetup from "../../../helpers/googleMap";
import TextField from "../../CommonComponent/field/TextField";
import { dd_value } from "../../../actions/common_actions";
import { req } from "../../../helpers/common_helper";
import withRouter from "../../../Router/Withrouter";
import { Link, useParams } from "react-router-dom";

interface RekycGPSAddressProps {
  gps_addr: any;
  checkbox_verification: (checked: boolean, checkboxId: string) => void;
  view_lead: () => void;
}
interface RouteParams {
  [key: string]: string | undefined; // Fix: index signature
}
const RekycGPSAddress: React.FC<RekycGPSAddressProps> = ({
  gps_addr,
  checkbox_verification,
  view_lead,
}) => {
  const addl_addr_field = [
    { label: "verified by", key: "verification_method" },
  ];

  const audit_kyc = useSelector((state: any) => state.common_slice);
  const rekyc_verify_handler = (verify_request_key: string) => {
    const request = {
      lead_id: lead_id.id,
      path: verify_request_key,
    };
    // @ts-ignore
    rekycVerify(req(request)).then((response) => {
      if (response && response.status === "success") {
        view_lead();
      }
      if (!response) {
        return;
      }
    });
  };
  const lead_id = useParams<RouteParams>();
  const addl_fields = (fields: { label: string; key: string }[], data: any) => {
    return (
      <Box sx={{ border: "1px solid  #2FB8D8", my: 2, py: 2, px: 5 }}>
        {fields?.length > 0 &&
          fields.map((_item, idx) => {
            return (
              <TextField
                key={idx}
                label={_item.label}
                val={
                  data?.[_item.key]?.value
                    ? dd_value(data[_item.key].value)
                    : "-"
                }
                direction={"row"}
              />
            );
          })}
      </Box>
    );
  };

  const check_box_field = (
    checked_condition: boolean,
    checkbox_id: string,
    label?: string | null
  ) => {
    const { active_section, responseData, cust_reg_json } = gps_addr;
    const userId = get("user_id");
    const ref_cust_id = cust_reg_json?.gps_address?.owner_address?.ref_cust_id?.value;

    const check_box_disable_cond =
      active_section?.verify ||
      active_section.to_reassign === true ||
      responseData.audited_by !== userId ||
      responseData.audited_by !== null ||
      active_section.pending_with === "rm";


    return (
      <Box>
        <FormControlLabel
          sx={{
            color: "white", fontSize: "20px",mt:1,mb:2
          }}

          control={
            <Checkbox
              disabled={check_box_disable_cond}
              color="secondary"
              sx={{ fontSize: "20px" }}
              checked={checked_condition} // Pass dynamic checked value
              onChange={(e) =>
                checkbox_verification(e.target.checked, checkbox_id)
              }
            />
          }
          label={
            ref_cust_id && checkbox_id != "gps_address_match" ? (
              <span>
                {label}  <Link className="audit_cust_url" to={`/borrower/indiv/view/${ref_cust_id}`} >{ref_cust_id}</Link>
              </span>
            ) : label
          }
        />
      </Box>
    );
  };

  const { cust_reg_json } = gps_addr;
  const visit_type = cust_reg_json?.gps_address?.owner_address?.verification_method?.value;
  const ref_mob_num = cust_reg_json?.gps_address?.owner_address?.ref_mobile_num?.value;
  let owner_addr_text = visit_type ? Config('cont_for_kyc_addr')[visit_type] : null;
  const valuesToAppend = [ref_mob_num];
  valuesToAppend.forEach((value) => {
    if (value) {
      owner_addr_text += " " + value;
    }
  });

  return (
    <div>
      {gps_addr.audit_kyc_wizard_menus?.gps_address && (
        <div className="row no-margin justify-content-center">
          {gps_addr.View_lead_section_loader ? (
            <div className="d-flex justify-content-center">
            </div>
          ) : (
            <>
              <div
                className="col-lg-12 py-3 mt-5"
                style={{ background: "#01062c" }}
              >
                <h2 className="text-white text-center">
                  <b>
                    {gps_addr.cust_reg_json?.same_as_owner_person ? "2" : "3"} -
                    Map Location Verification
                  </b>
                </h2>
                <h2 className="d-flex mt-1 text-center justify-content-center align-items-center">
                  <img
                    src="/img/pending_with_rm.png"
                    height={"50"}
                    alt="pending with rm"
                  />{" "}
                  <b
                    className="ml-2 align-self-center"
                    style={{ color: "#F3EA0B" }}
                  >
                    Pending with{" "}
                    {gps_addr.active_section?.pending_with === "rm"
                      ? "RM"
                      : "YOU"}
                  </b>
                </h2>
              </div>
              <div className="col-lg-10  px-5">
                <div className="third_party_bg p-5">
                  <div
                    className="col-md-12 w-100 p-0"
                    style={{ margin: "auto" }}
                  >
                    {/* @ts-ignore */}
                    <GoogleMapSetup
                      current_coordinates={
                        gps_addr.new_cust_reg_json?.gps_address?.gps?.value
                      }
                      coordinates={
                        gps_addr.old_cust_reg_json?.gps_address?.gps?.value
                      }
                      height={"400px"}
                      color={true}
                    />
                  </div>
                  <p
                    className="no-margin text-white pt-3 pb-3"
                    style={{ fontSize: "15px" }}
                  >
                    <b style={{ color: "red" }}>Note :</b> Zoom In/Zoom Out to
                    verify if the GPS location pointed by the RM matches the
                    business address captured.
                  </p>

                  {gps_addr.cust_reg_json?.gps_address?.biz_address && (
                    <div className="col-lg-12 mt-4 m-auto ">

                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.old_cust_reg_json.gps_address.biz_address
                            }
                            title="Business Address (As per KYC)"
                            usedFor="re-kyc"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.cust_reg_json.gps_address.biz_address
                            }
                            title="Business Address (As per Re-KYC)"
                            addr_update_path="gps_address.biz_address"
                            usedFor="re-kyc"
                            verify_handler={(req_path) =>
                              rekyc_verify_handler(req_path)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        {check_box_field(
                          audit_kyc?.gps_address?.gps_address_match,
                          "gps_address_match",
                          " The location in the Business Address matches with the gps location"
                        )}
                      </Grid>
                    </div>
                  )}
                  {gps_addr.cust_reg_json?.gps_address?.owner_address && (
                    <div className="col-lg-12 mt-4 m-auto ">
                      {/* address re-kyc */}
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.old_cust_reg_json.gps_address.owner_address
                            }
                            title="Current Residential Address             (As per KYC)"
                            usedFor="re-kyc"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.cust_reg_json.gps_address.owner_address
                            }
                            title="Current Residential Address (As per Re-KYC)"
                            addr_update_path="gps_address.owner_address"
                            usedFor="re-kyc"
                            verify_handler={(verify_request_key) =>
                              rekyc_verify_handler(verify_request_key)
                            }
                          />
                          {/* {addl_fields(
                            addl_addr_field,
                            gps_addr.cust_reg_json.gps_address.owner_address
                          )} */}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          {addl_fields(
                            addl_addr_field,
                            gps_addr.old_cust_reg_json.gps_address.owner_address
                          )}
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          {addl_fields(
                            addl_addr_field,
                            gps_addr.cust_reg_json.gps_address.owner_address
                          )}
                        </Grid>
                      </Grid>
                      {owner_addr_text &&
                      <Grid container>

                        {check_box_field(
                          audit_kyc?.gps_address?.confirm_cur_res_address,
                          "confirm_cur_res_address",
                          owner_addr_text
                        )}
                      </Grid>}
                    </div>
                  )}
                  {gps_addr.cust_reg_json?.gps_address?.permanent_res_addr && (
                    <div className="col-lg-12  m-auto  ">
                      {/* address re-kyc */}
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.old_cust_reg_json.gps_address
                                .permanent_res_addr
                            }
                            title="Permanent Address (As per KYC)"
                            usedFor="re-kyc"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.cust_reg_json.gps_address
                                .permanent_res_addr
                            }
                            addr_update_path="gps_address.permanent_res_addr"
                            title="Permanent Address (As per Re-KYC)"
                            usedFor="re-kyc"
                            verify_handler={(req_path) =>
                              rekyc_verify_handler(req_path)
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RekycGPSAddress;
