import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import {getValueFromLangJson, person_name} from '../../../helpers/common_helper';
import {get} from '../../../helpers/storage_helper';
import RelationshipManager from '../component/RelationshipManager';
import {listRelationshipManager} from '../../../actions/relationship_manager_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import StatusButton from '../../common/component/core/StatusButton';
import {ViewEdit} from '../../common/partial';
import $ from 'jquery';

class ListAccprvdrRelationshipManagerContainer extends FlowContainer {

  state = {
     relationshipManagerList: null,
     toView: false,
     relationshipManager_id: null ,
     data_prvdr: {}
    
  }

  handleClick = (event) => {
        this.setState({relationshipManager_id: event.target.id, toView: true});
    }
 /* loanOnChange = (event) =>{*/
    componentWillMount(){

    //const dp_code = event.target.value
    const dp_code = get('acc_prvdr_code');
    this.setState({table_id: dp_code, relationshipManagerList: null});
    const request = {"associated_entity_code": dp_code};
    listRelationshipManager(this.req(request))
        .then((response) => {
          if(!response){return };
              const relationshipManagerList = response.data.map((relationshipManager,i)=>
              <tr key={i}>

                <td > {person_name(relationshipManager)}</td>
                <td > {relationshipManager.whatsapp}</td>
                <td > {relationshipManager.mobile_num}</td>
                <td > {relationshipManager.email_id}</td>
                <td > {relationshipManager.designation}</td>
                <StatusButton id={relationshipManager.id} parent="data_prvdr_rel_mgr" entity="persons" status={relationshipManager.status}/>  
                <td><ViewEdit id={relationshipManager.id} entity="data_prvdr_rel_mgr"  onClick={this.handleClick}/></td>
                {/*<td > <input id={relationshipManager.id} type='submit' className='' onClick={this.handleClick} value='View / Edit'  class='btn btn-primary' /></td>*/}
              </tr>
                                              );
          this.setState({table_id: dp_code, relationshipManagerList: relationshipManagerList});
                 $('#'+this.state.table_id).removeAttr('width').dataTable({
                    "order" : [],
                  "columns": [
                       {"width" : "15%"}, 
                       {"width": "15%"},
                       {"width": "15%"},
                       {"width": "15%"},
                       {"width": "10%"},
                       {"width": "10%"},
                       {"width": "10%"},
                       {"width": "10%"}
                     ],
                  dom:'Bfrtip',
                  buttons: [
                  'csv','excel','print'
                  ]
              });
        });
  }

  render(){
  
     if (this.state.toView === true) { 
           return <Navigate to={`/relationship_manager/dprlspmanager/view/${this.state.relationshipManager_id}`} />
    }

    return(

    <div className="container">
           <RelationshipManager id="data_prvdr" data={this.state.data_prvdr} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange} loanOnChange={this.loanOnChange}/>
       {(this.state.relationshipManagerList != null) && 
      
          <div id='datatable data_prvdr_table' className={"table-responsive"}>
            <h4>{getValueFromLangJson("rm_label")} Manager List</h4>
                 <table id ={this.state.table_id}  className="table">
                          <thead className={"text-info"}>
                          <tr>                    
                          <th>Name</th>
                          <th>WhatsApp Number</th>
                          <th>Mobile Number</th>
                          <th>Email ID</th>
                          <th>Designation</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th>Action</th></tr></thead>
                          <tbody> 
                          {this.state.relationshipManagerList}
                          </tbody>
                 </table>
                        
            </div> 
       }
      
        </div>
    );
}
}
export default ListAccprvdrRelationshipManagerContainer;
