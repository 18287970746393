import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import CustomFlag from '../../common/component/CustomFlag';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { bg_box, login_box, wrapper_box } from '../../../styles/common_styles';
import { common_login_header } from '../../../helpers/common_helper';
import { switch_market } from '../../../helpers/common_apicalls';

const ListMarket = () => {

    const { markets } = useSelector(state => state.HeaderSlice)
  
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const theme = useTheme()
    const market_details = {
        border: '1px solid',
        borderColor: theme.palette.custom_colors.secondary_a_400,
        // padding: '10px 30px',
        paddingLeft:'55px',
        borderRadius: '12px',
        width: "250px",
        height: "48px"
    }
    const selectMarket = (country) => {
     
        switch_market(country,dispatch,navigate,"/")
    }

    const wrapper_box = {
        padding: '40px'
      }
   
    return (
        <Box  sx={[bg_box,{backgroundColor: theme.palette.custom_colors.old_primary_900}]}>
        <Box sx={[login_box, { backgroundColor: theme.palette.primary.main }]}>
                <Box sx={wrapper_box}>
                    {common_login_header()}
                    <Box>
                        <Typography variant="s_inter_medium_gray" component={'p'} sx={{ textAlign: 'center', marginTop: "54px" }}>  Choose the market you would like to access</Typography>
                        <Box sx={{width:'300px',margin:'0 auto'}}>
                            {markets && markets.length > 0 && markets.map((item, idx) => {
                                return (
                                    <Box  key={idx} className=' d-flex align-items-center justify-content-center row mx-0 my-4 pointer' onClick={() => selectMarket(item)}>
                                        <Box display={'flex'} >
                                            <Box sx={market_details} display={'flex'}  alignItems={"center"}>
                                                <CustomFlag  country_code={item.country_code} height={19} width={30} />
                                                <Typography variant="m_inter_regular_white" component={'p'} sx={{ ml: 2 }}> {item.country} </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                )
                            })}
                        </Box>

                    </Box>
                </Box>
            </Box>

        </Box>
    );
};

export default ListMarket;