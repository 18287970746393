import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {Form, Col , Button ,Checkbox} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import moment from 'moment';
import {get,get_item,check_priv} from '../../../helpers/storage_helper';
import Select from "react-select";
import { connect } from 'react-redux';
import { getValueFromLangJson } from '../../../helpers/common_helper';


class SearchFieldVisit extends FlowComponent{

	state = {
			start_date :null,
			visit_purpose:[],
			is_mapped:true

		}
	

componentWillMount(){

	this.load_flow_rel_mgr(null,"multiselect_rm_name");



	// var associated_with =  "flow";
	// var associated_entity_code =  this.data_prvdr_code;
 //    this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with, associated_entity_code});	
	// }
	
	// start_date = (event) =>{
	// 	const value = moment(event).format("DD-MM-YYYY");
	// 	this.setState({start_date : value});

		}

		componentDidUpdate(){
			if(this.props.priv_users_dd && this.state.is_mapped){
				var arr=[]
				this.props.priv_users_dd.map((item,idx)=>{
					if(item.role_codes == "operations_auditor" || item.role_codes == "risk_compl_officer"){
						arr.push(item.id)
					}
				})
				this.setState({is_mapped:false,auditors_id:arr})
			}
		}


render(){
    const Visitor =  this.props.remove_flow_label ? "Visiter" :"Flow Visiter"
	return(

		<div className="mb-3">
	        <div className='row no-margin'>
	        	{this.props.field_visit.cust_visit_history && check_priv("field_visit","self_search")&&
			    	<div className="col-1" style={{paddingBottom:15}}>
			       		<div>
		           			<Form.Label>{Visitor}</Form.Label>
		           		</div>
		           		<Form.Label className = "ml-3"><h5>All</h5></Form.Label>
			       </div>
		       }
			    <div className="col-3" style={{paddingBottom:15}}>
			    	<Form.Label>From </Form.Label><font className="text-danger">   *  </font>
			    	<DatePicker  closeOnSelect={true} defaultValue={this.props.field_visit.visit_start_time__from} dateFormat="DD MMM YYYY"   timeFormat={false} required="required" onChange={(value) => {this.handleDateChange("visit_start_time__from", value)}} />
			    </div>
			    <div className="col-3" style={{paddingBottom:15}}>
			    	<Form.Label>To </Form.Label><font className="text-danger">   *  </font>
			    	<DatePicker    closeOnSelect={true} defaultValue={this.props.field_visit.visit_start_time__to}    dateFormat="DD MMM YYYY" timeFormat={false} required="required" onChange={(value) => {this.handleDateChange("visit_start_time__to", value)}} />
			    </div>
			    {check_priv("field_visit","all_search")&&
		            <div className="col-3" style={{paddingBottom:15}}>			
		             	<Form.Label> </Form.Label>
						 <Select id="visitor_id" onChange={(e) =>{this.props.handlePurposeChange(e,"visitor_id")}}
                              value = {this.state.select_value}
                              options={this.props.priv_users}
							  placeholder= {Visitor}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" 
							  isMulti/>
			       	</div>
		       	}
		       	
			    <div className="col-3 mt-4" style={{paddingBottom:15}}>
					<Select onChange={(e) =>{this.props.handlePurposeChange(e,"visit_purpose");}}
                              value = {this.state.select_value}
                              options={this.getMultiSelectOptions('visit_purpose')} 
							  placeholder="Purpose"
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  isMulti/>
		       	</div>

				   {this.state.auditors_id && this.state.auditors_id.length>0 && this.props.datas.state_data&& this.props.datas.state_data.visitor_id&& this.state.auditors_id.includes(this.props.datas.state_data.visitor_id[0]) && 
				   <div className="col-3 mt-4" style={{paddingBottom:15}}>


						<Select onChange={(e) =>{this.props.handlePurposeChange(e,"flow_rel_mgr_id");}}
                              value = {this.state.select_value}
							  options={this.state.flow_rel_mgr}
							  placeholder={getValueFromLangJson("rm_label")}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  isMulti/>
		       	</div>
				}
		       	<div className="col-3" style={{display:'flex',alignItems:'center'}}>
			    	<Form.Label for="individual_visits" className='no-margin'>Individual Visits
			    		<Form.Check type="checkbox"  defaultChecked={this.props.field_visit.individual_visits} id = "individual_visits"  className= "float-left no-margin" onChange={(e) =>{this.props.onComponentElementChange(e);this.props.onCheck(e)}} />
					</Form.Label>
		       	</div>
	        </div>
	        <div className="text-center">
	        	<button type="button" className="btn btn-primary " id="searchbtn" disabled = {this.props.search_btn} onClick={()=>this.props.onSearch('search')} >Search</button>
	        </div>
	    </div >)
	}
}


const mapStateToProps = (state) => {
	return {
    remove_flow_label: state.common_slice?.brand_info?.footer
	};
  };


export default connect(mapStateToProps)(SearchFieldVisit);
