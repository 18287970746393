import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import { get } from '../../helpers/storage_helper';

const initialState = {

    default_val : 0,
    mode : "dark",
    show_bg:true,
    value: 0,
    admin_roles : ['super_admin', 'market_admin', 'it_admin','ops_analyst'],
    brand_info:[],
    acc_prvdr_list:[],

}

const CommonReducer = createSlice({
  name: 'common_slice', 
  initialState,

  reducers: {
    updateValuesIntoRedux : (state,action) =>{
     const data = action.payload
     for (const values in data){
        state[values] = data[values]
     }
    }

  },
});

export const { updateValuesIntoRedux} = CommonReducer.actions;

export default CommonReducer.reducer;
