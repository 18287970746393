import React   from 'react';
import FlowComponent from "../../common/component/core/FlowComponent";
import {get,Config} from '../../../helpers/storage_helper';
import Accordion from 'react-bootstrap/Accordion';
import {dd_value} from '../../../actions/common_actions';
import {format_date_tbl, get_path, getValueFromLangJson} from '../../../helpers/common_helper';
import { Card,Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Link} from 'react-router-dom';
import { SiGooglemaps } from 'react-icons/si';
import { BsChevronDoubleLeft, BsChevronDown } from 'react-icons/bs';
import { auditName, bypassAuditName, submit_holder_name_proof } from '../../../actions/kyc_actions';
import { check_priv } from '../../../helpers/storage_helper';
import { FaCheckCircle } from 'react-icons/fa';
import FileUpload from '../../common/component/FileUpload';
import moment from 'moment';
import {AssignAuditor} from '../../../actions/lead_actions'

import {
    capitalize_first_letter,
    title_case,
    get_dir_path,
    multi_acc_pur_tostring,
    Homeacc_prvdrLogoselector
} from "../../../helpers/common_helper";
import DisplayImage from "../../common/component/DisplayImage";

class LeadAuditComponent extends FlowComponent
{
    state = {
        lead : {},
        isActive: false,
        account_arr:false,
        bizInfor_arr:false,
        bizOwner_arr:false,
        bizAddress_arr:false,
        ownerAddress_arr:false,
        bizIdentity_arr:false,
        contactAddress_arr:false,
        ownerPerson_arr:false,
        partnerKYC_arr:false,
        contactPerson_arr:false,
        references_arr:false,
        consent_arr:false,
        aggreements_arr:false,
        reassignModal:false,
        rejectModal:false,
        pdf_modal:false ,
        third_party_owner_arr : false,
        audit_lead: {},
        holder_name_mismatch : false,
        owner_name : "",
        third_party_owner_name : "",
        tp_holder_name_n_owner_match : false,
        retrieve_holder_name_successful : true
        
    }

    componentWillReceiveProps(props){
        this.setState({reassignModal:props.reassignModal, rejectModal:props.rejectModal, showModal : this.props.showModal})

    }
   
    componentWillMount(){
        const audit_kyc_line = Config("audit_kyc_line")
        this.setState({audit_kyc_line , rm_label_short : getValueFromLangJson("rm_label_short")})
        this.setOptionsToStateFromApi("auditor", {...this.def_req}, null, 'kyc')
        
    }

    handleModalAssignAuditor = (event) =>{
        
        AssignAuditor(this.req({"auditor_id" : this.props.assign_audit_id.auditor, 'lead_id' : this.props.lead_id}))
        .then((response) =>{
            this.resp(response)
            if(!response){return };
            if(response.status === "success"){
                alert(response.message); 
              window.location.reload()
            }
        })
 }

    handleToggle = (e) => 
    {                
        // this.setState({ isActive: !this.state.isActive });         
        switch(e){
            case("Account"):
                this.setState(state=>state.account_arr = !this.state.account_arr);
                break;
            case("BizInfo"):
                this.setState(state=>state.bizInfor_arr = !this.state.bizInfor_arr);
                break;
            case("BizOwner"):
                this.setState(state=>state.bizOwner_arr = !this.state.bizOwner_arr);
                break;
            case("BizAddress"):
                this.setState(state=>state.bizAddress_arr = !this.state.bizAddress_arr);
                break;
            case("OwnerAddress"):
                this.setState(state=>state.ownerAddress_arr = !this.state.ownerAddress_arr);
                break;
            case("BizIdentity"):
                this.setState(state=>state.bizIdentity_arr = !this.state.bizIdentity_arr);
                break;
            case("PartnerKYC"):
                this.setState(state=>state.partnerKYC_arr = !this.state.partnerKYC_arr);
                break;
            case("ContactAddress"):
                this.setState(state=>state.contactAddress_arr = !this.state.contactAddress_arr);
                break;
            case("OwnerPerson"):
                this.setState(state=>state.ownerPerson_arr = !this.state.ownerPerson_arr);
                break;
            case("ContactPerson"):
                this.setState(state=>state.contactPerson_arr = !this.state.contactPerson_arr);
                break;
            case("Consent"):
                this.setState(state=>state.consent_arr = !this.state.consent_arr);
                break;
            case("Agreements"):
                this.setState(state=>state.aggreements_arr = !this.state.aggreements_arr);
                break;
            case("References"):
                this.setState(state=>state.references_arr = !this.state.references_arr);
                break;
            case("ThirdAcOwner"):
                this.setState(state=>state.third_party_owner_arr = !this.state.third_party_owner_arr);
                break;
        }
    };

   addressCardData = (dataObj) => {
    return Object.keys(dataObj)
      .sort()
      .map((addressField, index) => (        
        <div className="col-md-3">            
            <p class="no-margin viewCus_label">{dd_value(addressField)}</p>
            <p class="no-margin viewCus_labelVal">{dataObj[addressField].value}</p>
        </div>                  
      ));
   };
 
   handleNameMismatchReason = (event) => {
    this.setState({holder_name_mismatch_reason : event.target.value},() => {
        this.props.onAuditDataChange({holder_name_mismatch_reason: this.state.holder_name_mismatch_reason})
    })
  }

  handleHolderName = (event) => {
    this.setState({holder_name_entered_by_auditor : event.target.value})
  }

   handlePdfModalClose = () => {       
    this.setState({pdf_modal:false})
   }

   handlePdfModal = (pdf_path,pdf_modal_title) =>{
    this.setState({pdf_modal:true,pdf_path,pdf_modal_title})
    }

    checkNameEquality = ( national_id_name, acc_num_name) => {
        // biz_name = biz_name.replace(/\s+/g, '').toLowerCase()
        national_id_name = national_id_name.replace(/\s+/g, '').toLowerCase()
        acc_num_name = acc_num_name.replace(/\s+/g, '').toLowerCase()
        if(this.props.data.is_rented_line){
            if((this.state.owner_name.replace(/\s+/g, '').toLowerCase() === acc_num_name) ){
                if(this.state.tp_holder_name_n_owner_match !== true){
                    this.setState({tp_holder_name_n_owner_match: true}, () => {
                        this.props.onAuditDataChange({tp_holder_name_n_owner_match: this.state.tp_holder_name_n_owner_match})
                    })
                }
            }
            if((national_id_name === acc_num_name)){
                if (this.state.holder_name_mismatch == true){
                    this.setState({holder_name_mismatch: false},() => {
                        this.props.onAuditDataChange({holder_name_mismatch: this.state.holder_name_mismatch})
                    })
                }
            }
            else if (this.state.holder_name_mismatch == false){
                this.setState({holder_name_mismatch: true},() => {
                    this.props.onAuditDataChange({holder_name_mismatch: this.state.holder_name_mismatch})
                })
            }
            return
        }
        if( (national_id_name === acc_num_name)){
            if (this.state.holder_name_mismatch == true){
                this.setState({holder_name_mismatch: false},() => {
                    this.props.onAuditDataChange({holder_name_mismatch: this.state.holder_name_mismatch})
                })
            }
        }
        else if (this.state.holder_name_mismatch == false){
            this.setState({holder_name_mismatch: true},() => {
                this.props.onAuditDataChange({holder_name_mismatch: this.state.holder_name_mismatch})
            })
        }
        return
    }

    handleBypassHolderNameClick = () => {
        const resp = window.confirm("Are you sure if you want to bypass the Holder Name Audit for this lead?");
        if(resp == false){
            return;
	    }
        bypassAuditName(this.req({id: this.props.lead_id}))
        .then((response) => {
            this.resp(response)
            setTimeout(function(){
                window.location.reload();
            },4000)
            
        })
    }

    handleHolderNameProofSubmit = () => {
        if(!this.state.audit_lead.account_holder_name_proof || !this.state.holder_name_entered_by_auditor){
            alert("Please enter the necessary information in the above fields.")
            return
        }
        this.setState({holder_name: this.state.holder_name_entered_by_auditor},
            () => {
                // this.checkNameEquality(this.props.data.license_proof.biz_name.value, this.state.owner_name, this.state.holder_name)
                submit_holder_name_proof(this.req({lead_id: this.props.lead_id, account_holder_name_proof: this.state.audit_lead.account_holder_name_proof, holder_name: this.state.holder_name, national_id_name: `${this.props.data.id_proof.first_name.value} ${this.props.data.id_proof.last_name.value}`}))
            })
            setTimeout(function(){
                window.location.reload();
            },2000)
        
        return
    }

    setAuditLeadState = (event) => {
        this.setChangeToState(event)
        this.props.onAuditDataChange({audit_lead: this.state.audit_lead})
    }

    determineLabelColor = (label_name) => {
        if (label_name == "owner_name"){
            return (!this.state.holder_name_mismatch && !this.props.data.is_rented_line && this.props.data.biz_accounts.accounts[0].holder_name && (this.state.owner_name.toLowerCase() == this.props.data.biz_accounts.accounts[0].holder_name.toLowerCase()))
        }
        else if (label_name == "tp_acc_holder_name"){
            return (!this.state.holder_name_mismatch && this.props.data.biz_accounts.accounts[0].holder_name && (this.state.third_party_owner_name.toLowerCase() == this.props.data.biz_accounts.accounts[0].holder_name.toLowerCase()))
        }
        // else if (label_name == "biz_name"){
        //     return (!this.state.holder_name_mismatch && !this.props.data.is_rented_line && this.props.data.biz_accounts.accounts[0].holder_name && (this.props.data.license_proof.biz_name.value.toLowerCase() == this.props.data.biz_accounts.accounts[0].holder_name.toLowerCase()))
        // }
    }   

    handleRetrieveHolderNameClick = () => {
        const acc_prvdr_code = this.props.data.biz_accounts.accounts[0].acc_prvdr_code.value
        const acc_number = this.props.data.biz_accounts.accounts[0].acc_number.value
        let branch = null
        if(this.props.data.biz_accounts.accounts[0].hasOwnProperty('branch')){
            branch = this.props.data.biz_accounts.accounts[0].branch.value
        }
        auditName(this.req({acc_prvdr_code, acc_number, branch}), true)
        .then((response) => {
            this.resp(response)
            if(response.data){
                window.location.reload();
            }
            else if(!response.data){
                this.setState({retrieve_holder_name_successful : response.data})
            }
            return
        })
    }

    getHint(key , acc_prvdr_code){

        let hint = Config(key)[acc_prvdr_code]
    
        if(hint){
         return hint
        }
    
    }

    handleCloseModal = (event) => {
        this.setState({showModal:false})
    }
    


   ViewPdfModal = ()=>{
           
    return(    
      <Modal show={this.state.pdf_modal} onHide={this.handlePdfModalClose} backdrop="static" size="xl" style={{position: "fixed"}}>
          <Modal.Header closeButton show={this.state.pdf_modal} onHide={this.handlePdfModalClose}>
             <Modal.Title>{this.state.pdf_modal_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row justify-content-center"> 
                <object data={get_path("view_file")+this.state.pdf_path+"#toolbar=0"} type="application/pdf" style={{width:"803px",height:"560px"}}>
                </object>

            </div>
        
          </Modal.Body>
             <Modal.Footer>
                <Button variant="secondary" onClick = {this.handlePdfModalClose} >Close</Button>
             </Modal.Footer>
          </Modal> 
         
    )
  }

  ViewReassignOrRejectModal = () => {
    const showModal = this.state.reassignModal || this.state.rejectModal
    if (showModal) {
        var [modalTitle, selectId, submitFn] = this.state.reassignModal ? ['Reassign Reason', 'reassign_reason', this.props.handleKYCEdit] : ['Reject Reason', 'reject_reason', this.props.handleRejectKYC]
    }
    return(
        <Modal show={showModal} onHide={this.props.handleModalClose} size="l">
            <Modal.Header closeButton show={showModal} onHide={this.props.handleModalClose}>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="form-group row mx-0 mb-2 ">
                    <label for=""  className="col-sm-5 font-weight-bold label__name reset-padding">Reason<font className="text-danger">   *  </font></label>
                    <div className="col-sm-7 reset-padding">
                        <div>

                        <select id="reason" onChange={this.handleChange} className="form-control" type="text" >
                            {this.getSelectOptions(selectId)}
                        </select>

                        </div>
                        <div className="control-me"></div>
                    </div>
                </div>
                <div className="rule-form-group form-group row reset-margin">
                    <label for="" className="col-sm-5 font-weight-bold label__name reset-padding">Remarks<font className="text-danger">   *  </font></label>
                    <div className="col-sm-7 reset-padding">
                        <div>
                            <textarea type="text" id="remarks" className="form-control" onChange={this.handleChange}  maxLength='256'/>
                        </div>
                    </div>
                </div>         
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled = {this.props.submit_btn} onClick={submitFn}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
  }
    render()
    {
      
        // const isActive = this.state.isActive;
        if(this.props.data)
        {

            if(this.props.data.id_proof.hasOwnProperty('middle_name') && this.props.data.id_proof.middle_name.value){
                if(this.state.owner_name != `${this.props.data.id_proof.first_name.value} ${this.props.data.id_proof.middle_name.value} ${this.props.data.id_proof.last_name.value}`){
                    this.setState({owner_name: `${this.props.data.id_proof.first_name.value} ${this.props.data.id_proof.middle_name.value} ${this.props.data.id_proof.last_name.value}`})
                }
            }
            else{
                if(this.state.owner_name != `${this.props.data.id_proof.first_name.value} ${this.props.data.id_proof.last_name.value}`){
                    this.setState({owner_name: `${this.props.data.id_proof.first_name.value} ${this.props.data.id_proof.last_name.value}`})
                }
            }

            if(this.props.data.hasOwnProperty('third_party_owner') && this.props.data.third_party_owner.hasOwnProperty('middle_name') && this.props.data.third_party_owner.middle_name.value){
                if(this.state.third_party_owner_name != `${this.props.data.third_party_owner.first_name.value} ${this.props.data.third_party_owner.middle_name.value} ${this.props.data.third_party_owner.last_name.value}`){
                    this.setState({third_party_owner_name: `${this.props.data.third_party_owner.first_name.value} ${this.props.data.third_party_owner.middle_name.value} ${this.props.data.third_party_owner.last_name.value}`})
                }
            }
            else if (this.props.data.hasOwnProperty('third_party_owner')){
                if(this.state.third_party_owner_name != `${this.props.data.third_party_owner.first_name.value} ${this.props.data.third_party_owner.last_name.value}`){
                    this.setState({third_party_owner_name: `${this.props.data.third_party_owner.first_name.value} ${this.props.data.third_party_owner.last_name.value}`})
                }
            }

        return(
            <>

            {this.ViewPdfModal()}
          
            <div className='kyc_auditBox'>
                {this.ViewReassignOrRejectModal()}

                <div className = "kyc-card row">
                    <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style = {{backgroundColor: "#3D3E53",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("BizInfo")}>Lead Name Info<BsChevronDown className = {this.state.bizInfor_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className='no-padding'>
                                        <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:5}}> 
                                            <div style={{marginLeft:20}}>                                            
                                                <ul class="list-unstyled">
                                                    <li>
                                                        <ul class="list-unstyled" style={{marginBottom:10}}>                                    
                                                        <li><p class="viewCus_label" style={{textTransform:"none"}}>Owner Name (As per National ID)</p></li>
                                                        <li><p className={this.determineLabelColor("owner_name") ? 'match-label-color' : 'viewCus_labelVal'}>{this.state.owner_name}</p></li>
                                                        </ul>
                                                    </li>
                                                    {(this.props.data.is_rented_line) && <li>
                                                        <ul class="list-unstyled" style={{marginBottom:10}}>
                                                        <li><p class="viewCus_label" style={{textTransform:"none"}}>Third Party A/C Holder Name (As per National ID)</p></li>
                                                        <li><p className={this.determineLabelColor("tp_acc_holder_name") ? 'match-label-color' : 'viewCus_labelVal'}>{this.state.third_party_owner_name}</p></li>
                                                        </ul>
                                                    </li>}
                                                    <li>
                                                        {/* <ul class="list-unstyled" style={{marginBottom:10}}>
                                                        <li><p class="viewCus_label" style={{textTransform:"none"}}>Biz Name (As entered by RM)</p></li>
                                                        <li><p className={this.determineLabelColor("biz_name") ? 'match-label-color' : 'viewCus_labelVal'}>{this.props.data.license_proof.biz_name.value}</p></li>
                                                        </ul> */}
                                                    </li>
                                                    <li>
                                                        <ul class="list-unstyled" style={{marginBottom:10}}>{(this.props.data.biz_accounts.accounts[0].holder_name || this.state.holder_name) ? <div>                                       
                                                            <li><p class="viewCus_label" style={{textTransform:"none"}}>Account Holder Name (As per N/W Prvdr Records)</p></li>
                                                            <li style={{display:"flex"}}><p className={this.state.holder_name_mismatch ? 'mismatch-label-color' : 'match-label-color'}>{this.state.holder_name ? this.state.holder_name : this.props.data.biz_accounts.accounts[0].holder_name}</p>
                                                            {!this.state.holder_name_mismatch && <div style={{marginLeft:15}}><FaCheckCircle color="green" size={20}/></div>}</li>
                                                            {!this.state.tp_holder_name_n_owner_match && this.state.holder_name_mismatch && this.props.data.biz_accounts.accounts[0].holder_name && this.props.lead_data.status == "50_pending_audit" && <li><div style={{marginBottom:20}}><label>Account Holder Name Mismatch Reason   </label><font className="text-danger">   *  </font>
                                                            <select id="holder_name_mismatch_reason" className = "form-control col-md-3" value = {this.state.txn_type} onChange={(e) => {this.handleNameMismatchReason(e);}} type="text" required="required">
                                                                    {this.getSelectOptions("holder_name_mismatch_reason")}
                                                            </select></div>
                                                            </li>}
                                                            </div> : this.props.lead_data.status == "42_retrieve_holder_name" ?
                                                            <div>
                                                                <li><p class="viewCus_label">Account Holder Name</p></li>
                                                                <li><span >
                                                                    <Button onClick={() => {this.handleRetrieveHolderNameClick()}} disabled={this.state.calling_api}><p style={{marginBottom:0, fontSize:7.5}}>Retrieve Holder Name</p></Button>
                                                                    {check_priv("lead", "bypass_audit_name") && <Button variant="danger" onClick={() => {this.handleBypassHolderNameClick()}} disabled={this.state.calling_api}><p style={{marginBottom:0, fontSize:7.5}}>Bypass Audit Name</p></Button>}
                                                                    {!this.state.retrieve_holder_name_successful && <p style={{color: "red"}}>Unable to Retrieve Account Holder Name. Please try again later after refreshing. If the attempt still fails contact app support.</p>}
                                                                </span></li>
                                                            </div> :
                                                            (!this.state.holder_name || !this.state.audit_lead.account_holder_name_proof) && this.state.audit_kyc_line[this.props.data.biz_accounts.accounts[0].acc_prvdr_code.value] != 'skip' && <div>
                                                            <FileUpload is_mandatory = {true} lead_id = {this.props.lead_id} id="account_holder_name_proof" mode = "create" entity ='leads' type="image"          
                                                            onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.setAuditLeadState} label="Holder Name Proof" />
                                                            <div style={{marginTop:20, marginBottom:20}}><label>Account Holder Name   </label><font className="text-danger">   *  </font>
                                                                <input type="text" id="holder_name" placeholder='Enter the holder name' onChange={(e) => {this.handleHolderName(e)}} className="form-control"/></div>
                                                                <Button onClick={() => {this.handleHolderNameProofSubmit()}} disabled={this.state.calling_api}><p style={{marginBottom:0, fontSize:7.5}}>Submit</p></Button>
                                                            </div>}
                                                        </ul>
                                                    </li>
                                                    {this.props.data.biz_accounts.accounts[0].holder_name && this.state.holder_name_mismatch &&
                                                    <li>
                                                        <div className = "row justify-content-center" style={{ marginTop:20,backgroundColor :"white"}}>
                                                            <div className="col-md-12" style={{backgroundColor :"rgba(255, 0, 0, .4)" }}> 
                                                                <ul class="list-unstyled" style={{marginBottom:10}}>
                                                                    <li><p style={{color : "#01062c",fontSize : "14px", marginBottom: 5 ,padding:5}} class="text-center">NOTE</p>
                                                                        <p style = {{color : "#01062c",fontSize : "12px"}} class=" text-center">The name of the customer does not match neither with National ID nor business name. If you still think this KYC can be verified go ahead and approve KYC.
                                                                        <b> Operations Admin will be notified about this case.</b></p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    }
                                                    {/* {this.state.holder_name_mismatch && <li>
                                                        <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:5}}> 
                                                            <div className="col-md-3"><input type="text" id="holder_name" placeholder='Enter the holder name' onChange={(e) => {this.handleHolderName(e)}} className="form-control"/></div>
                                                            <div className="col-md-3"><label>Holder Name Mismatch Reason   </label><font className="text-danger">   *  </font>
                                                            <select id="holder_name_mismatch_reason" className = "form-control" value = {this.state.txn_type} onChange={(e) => {this.handleNameMismatchReason(e);}} type="text" required="required">
                                                                    {this.getSelectOptions("holder_name_mismatch_reason")}
                                                            </select></div>
                                                        </div>
                                                    <Button onClick={() => {this.handleRetrieveHolderNameClick()}} disabled={this.state.calling_api}><p style={{marginBottom:0, fontSize:7.5}}>Retrieve Holder Name</p></Button>
                                                    </li>} */}
                                                    {/* {this.state.holder_name_mismatch && <li>
                                                        <FileUpload lead_id = {this.props.data.lead_id} id="holder_name_proof" mode = "create" entity ='leads' type="image"          
                                                        onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.setChangeToState} label="Holder Name Proof" />
                                                    <div className = "row justify-content-center" style={{ marginTop:20,backgroundColor :"white"}}>
                                                        <div className="col-md-12" style={{backgroundColor :"rgba(255, 0, 0, .4)" }}> 
                                                            <ul class="list-unstyled" style={{marginBottom:10}}>
                                                                <li><p style={{color : "#01062c",fontSize : "14px", marginBottom: 5 ,padding:5}} class="text-center">NOTE</p>
                                                                    <p style = {{color : "#01062c",fontSize : "12px"}} class=" text-center">The name of the customer as per MTN record doesnot match neither with National ID nor business name. If you still think this KYC can be verified go adhead and approve KYC.
                                                                    <b> Operations Admin will be notified about this case.</b></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    </li>} */}
                                                </ul>
                                                {/* {this.state.holder_name_mismatch && <div>
                                                        <div className="" style={{marginRight:0,marginLeft:0,marginBottom:5}}> 
                                                            <div style={{marginBottom:20}}><label>Holder Name   </label><font className="text-danger">   *  </font>
                                                                <input type="text" id="holder_name" placeholder='Enter the holder name' onChange={(e) => {this.handleHolderName(e)}} className="form-control"/></div>
                                                            <div style={{marginBottom:20}}><label>Holder Name Mismatch Reason   </label><font className="text-danger">   *  </font>
                                                            <select id="holder_name_mismatch_reason" className = "form-control" value = {this.state.txn_type} onChange={(e) => {this.handleNameMismatchReason(e);}} type="text" required="required">
                                                                    {this.getSelectOptions("holder_name_mismatch_reason")}
                                                            </select></div>
                                                        </div>
                                                    <Button onClick={() => {this.handleSubmit()}} disabled={this.state.calling_api}><p style={{marginBottom:0, fontSize:7.5}}>Submit</p></Button>
                                                    </div>} */}
                                            </div>
                                            
                                        </div>                                        
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                     <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>Account<BsChevronDown className = {this.state.account_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body className='no-padding' >
                                    <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>                                    
                                        <div class="col-md-3">
                                            <div>
                                                <p class="no-margin viewCus_label">Account Provider</p>
                                                <p class="no-margin viewCus_labelVal"><Homeacc_prvdrLogoselector logotype={this.props.data.biz_accounts.accounts[0].acc_prvdr_code.value} /></p>
                                            </div> 
                                        </div>
                                        <div className="col-md-3">                                       
                                            <p class="no-margin viewCus_label">A/C Number / {this.getHint('ap_ac_num_name', this.props.data.biz_accounts.accounts[0].acc_prvdr_code.value)}</p>
                                            <p class="no-margin viewCus_labelVal">{this.props.data.biz_accounts.accounts[0].acc_number.value}</p>
                                        </div>
                                        {this.props.data.biz_accounts.accounts[0].alt_acc_num && this.props.data.biz_accounts.accounts[0].alt_acc_num.value &&

                                            <div className="col-md-4">                                       
                                                <p class="no-margin viewCus_label">Alternate A/C Number / {this.getHint('ap_alt_ac_num_name',this.props.data.biz_accounts.accounts[0].acc_prvdr_code.value)} </p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.biz_accounts.accounts[0].alt_acc_num.value}</p>
                                            </div>
                                        }       
                                        {this.props.data.biz_accounts.accounts[0].branch && this.props.data.biz_accounts.accounts[0].branch.value &&
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Branch</p>
                                                <p class="no-margin viewCus_labelVal">{dd_value(this.props.data.biz_accounts.accounts[0].branch.value)}</p>
                                            </div>
                                        }
                                        <div className="col-md-3">                                       
                                            <p class="no-margin viewCus_label">Account Purpose</p>
                                            <p class="no-margin viewCus_labelVal">{multi_acc_pur_tostring(this.props.lead_data.acc_purpose)}</p>
                                        </div>                                    
                                    </div>                                                                                            
                                    {this.props.lead_data.type == 're_kyc' && this.props.lead_data.kyc_reason == 'new_account' &&                                    
                                        <div className='row no-margin'>
                                            <div className='col-md-3'></div>
                                            <div className='col-md-6'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">New A/C Request Letter Photo</p>
                                                    {this.props.data.biz_accounts.accounts[0].hasOwnProperty("photo_new_acc_letter_full_path") && this.props.data.biz_accounts.accounts[0].photo_new_acc_letter_full_path.value  ?
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.biz_accounts.accounts[0].photo_new_acc_letter_full_path.value, this.props.data.biz_accounts.accounts[0].photo_new_acc_letter.value)} file_name={this.props.data.biz_accounts.accounts[0].photo_new_acc_letter.value}></DisplayImage>
                                                            :"-"}
                                                </div>
                                            </div>
                                            <div className='col-md-3'></div>
                                        </div>                                          
                                    }
                                {(this.props.data.is_rented_line == true) &&

                                    <Accordion defaultActiveKey="0" style={{top:"20px"}}>
                                <Card style = {{width:"95%"}}>
                                    <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("ThirdAcOwner")}>Third Party A/C Owner<BsChevronDown className = {this.state.third_party_owner_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body className='no-padding'>

                                    <div className = "row" style={{ marginRight:0,marginLeft:0,marginBottom:10}}>
                                            
                                        
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">First Name</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.third_party_owner.first_name.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Last Name</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.third_party_owner.last_name.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">DOB</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.third_party_owner.dob.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Gender</p>
                                                <p class="no-margin viewCus_labelVal">{capitalize_first_letter(this.props.data.third_party_owner.gender.value)}</p>
                                            </div>
    
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">National ID</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.third_party_owner.id_proof.value}</p>
                                            </div>                                                                                                                                    
                                        </div>
                                        <div className='row no-margin justify-content-center'>
                
                                            <div className='col-md-3'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">National ID Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.third_party_owner.photo_national_id_full_path.value, this.props.data.third_party_owner.photo_national_id.value)} file_name={this.props.data.third_party_owner.photo_national_id.value}></DisplayImage>
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">National ID Back Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.third_party_owner.photo_national_id_back_full_path.value, this.props.data.third_party_owner.photo_national_id_back.value)} file_name={this.props.data.third_party_owner.photo_national_id_back.value}></DisplayImage>
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">Consent Letter Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.third_party_owner.photo_consent_letter_full_path.value, this.props.data.third_party_owner.photo_consent_letter.value)} file_name={this.props.data.third_party_owner.photo_consent_letter.value}></DisplayImage>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.data.allow_tp_ac_owner_manual_id_capture && (this.props.lead_data.status == "50_pending_audit" || this.props.lead_data.status == "42_retrieve_holder_name") &&
                                        <div className = "row justify-content-center" style={{ marginTop:20,backgroundColor :"white"}}>
                                            <div className="col-md-12" style={{backgroundColor :"rgba(255, 0, 0, .4)" }}> 
                                                <p style={{color : "#01062c",fontSize : "14px", marginBottom: 5 ,padding:5}} class="text-center">NOTE</p>
                                                <p style = {{color : "#01062c",fontSize : "12px"}} class=" text-center"> App was not able to extract text from the National ID and {this.state.rm_label_short} typed the data fields from National ID manually. <br/>  If both are not matching, please re-assign back to {this.state.rm_label_short} and ask to correct it.</p>
                                            </div>
                                        </div>
                                        }
                                    
                                        
                                    </Card.Body>
                                    </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                }  
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>                        
                    </div>                    
                    <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style = {{backgroundColor: "#3D3E53",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("BizInfo")}>Biz Info<BsChevronDown className = {this.state.bizInfor_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className='no-padding'>
                                        <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>                                            
                                            {/* <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Biz Name</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.license_proof.biz_name.value}</p>
                                            </div> */}
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Business Distance</p>
                                                <p class="no-margin viewCus_labelVal">{title_case(this.props.data.gps_address.business_distance.value)}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Territory</p>
                                                <p class="no-margin viewCus_labelVal">{title_case(this.props.data.gps_address.territory.value)}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Ownership</p>
                                                <p class="no-margin viewCus_labelVal">{title_case(this.props.data.shop_photo.ownership.value)}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Business Address Type</p>
                                                <p class="no-margin viewCus_labelVal">{title_case(this.props.data.shop_photo.biz_addr_prop_type.value)}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                {/* <p class="no-margin viewCus_label">Partner RM Name</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.license_proof.dp_rel_mgr_name}</p> */}
                                            </div>
                                        </div>                                        
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>                    
                </div>
                <div className = "kyc-card row">
                    <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header}  eventKey="0" onClick = {()=>this.handleToggle("BizOwner")}>Biz Owner<BsChevronDown className = {this.state.bizOwner_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='no-padding'>
                                        <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">First Name</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.first_name.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Last Name</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.last_name.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">National ID</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.id_proof_num.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">DOB</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.dob.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Gender</p>
                                                <p class="no-margin viewCus_labelVal">{capitalize_first_letter(this.props.data.id_proof.gender.value)}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Email ID</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.email_id.value}</p>
                                            </div>
                                            {/* <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Whatsapp</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.whatsapp.value}</p>
                                            </div>                                             */}
                                        </div>                                                                                                                                                    
                                        <div className='row no-margin'>
                                            <div className='col-md-3'></div>
                                            <div className='col-md-6'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">Selfie Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.kyc_photos.photo_selfie_full_path.value, this.props.data.kyc_photos.photo_selfie.value)} file_name={this.props.data.kyc_photos.photo_selfie.value}></DisplayImage>
                                                </div>
                                            </div>
                                            <div className='col-md-3'></div>
                                        </div>                                                                                
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                    <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("BizAddress")}>Biz Address<BsChevronDown className = {this.state.bizAddress_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className='no-padding'>
                                        <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>
                                            {this.addressCardData(this.props.data.gps_address.biz_address)}
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                    {(this.props.data.same_as_biz_address == false) &&
                        <div className = "col-md-12">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("OwnerAddress")}>Owner Address<BsChevronDown className = {this.state.ownerAddress_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body className='no-padding'>
                                        <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>
                                            {this.addressCardData(this.props.data.gps_address.owner_address)}
                                        </div>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    }
                </div>
                <div className = "kyc-card row">
                    <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("BizIdentity")}>Biz Identity<BsChevronDown className = {this.state.bizIdentity_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className='no-padding'>
                                        <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>
                                            <div className="col-md-3">
                                                <p class="no-margin viewCus_label">GPS</p>
                                                <span> 
                                                    <a target = "blank" href = {"http://maps.google.com/maps?t=k&q=loc:"+this.props.data.gps_address.gps.value}>View on Map</a>
                                                </span>
                                                <a target = "blank" href = {"http://maps.google.com/maps?t=k&q=loc:"+this.props.data.gps_address.gps.value}><SiGooglemaps size="20"/></a>                                            
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Mobile No.</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.mobile_numbers.mobile_num.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Alternate biz Mobilenumber 1</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.mobile_numbers.alt_biz_mobile_num_1.value}</p>
                                            </div>  
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Alternate biz Mobilenumber 2</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.mobile_numbers.alt_biz_mobile_num_2.value}</p>
                                            </div>
                                        </div>                                           
                                        <div className='row no-margin'>
                                            {/* <div className='col-md-4'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">Business License Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.license_proof.photo_license_proof_full_path.value, this.props.data.license_proof.photo_license_proof.value)} file_name={this.props.data.license_proof.photo_license_proof.value}></DisplayImage>
                                                </div>
                                            </div> */}
                                            <div className='col-md-4'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">Shop Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.shop_photo.photo_shop_full_path.value, this.props.data.shop_photo.photo_shop.value)} file_name={this.props.data.shop_photo.photo_shop.value}></DisplayImage>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">Visit Selfie Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={this.props.lead_data.photo_visit_selfie_path} file_name={this.props.lead_data.photo_visit_selfie}></DisplayImage>
                                                </div>
                                            </div>
                                        </div>                                                                                
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>                   
                </div>
                <div className = "kyc-card row">
                    {(this.props.data.same_as_owner_person == false) &&
                        <div className = "col-md-12">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("ContactAddress")}>Contact Address<BsChevronDown className = {this.state.contactAddress_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='no-padding'>
                                            <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>
                                                {this.addressCardData(this.props.data.contact_persons[0]['contact_address'])}
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    }
                    {this.props.lead_data.acc_purpose.includes("terminal_financing") &&
                        <div className = "col-md-12">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("PartnerKYC")}>Partner KYC<BsChevronDown className = {this.state.partnerKYC_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='no-padding'>
                                            <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>                                                    
                                                {this.props.data.partner_kyc.UEZM.UEZM_MainContent_txtLC1 &&                                                
                                                    <div className="col-md-3">                                       
                                                        <p class="no-margin viewCus_label">LC1</p>
                                                        <p class="no-margin viewCus_labelVal">{this.props.data.partner_kyc.UEZM.UEZM_MainContent_txtLC1.value}</p>
                                                    </div>                                                
                                                }
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Operated by</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.partner_kyc.UEZM.UEZM_MainContent_ddOperatedBy.value}</p>
                                                </div>
                                                {this.props.data.partner_kyc.UEZM.UEZM_MainContent_ddWallet &&
                                                    <div className="col-md-3">                                       
                                                        <p class="no-margin viewCus_label">Wallet Type</p>
                                                        <p class="no-margin viewCus_labelVal">{this.props.data.partner_kyc.UEZM.UEZM_MainContent_ddWallet.value}</p>
                                                    </div>                                                
                                                }
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Abbreviation Name</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.partner_kyc.UEZM.UEZM_MainContent_txtAbbreviationName.value}</p>
                                                </div> 
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">CMP Registration No.</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.partner_kyc.UEZM.UEZM_MainContent_txtCompanyRegistrationNo.value}</p>
                                                </div> 
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Recruiter ID</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.partner_kyc.UEZM.UEZM_MainContent_txtRecruiterID.value}</p>
                                                </div> 
                                            </div>                                                                                                                                                                                                                           
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    }
                </div>
                <div className = "kyc-card row">
                    <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("OwnerPerson")}>Owner Person<BsChevronDown className = {this.state.ownerPerson_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="no-padding">

                                        <div className = "row" style={{ marginRight:0,marginLeft:0,marginBottom:10}}>
                                            
                                        
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">First Name</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.first_name.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Last Name</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.last_name.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">DOB</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.dob.value}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Gender</p>
                                                <p class="no-margin viewCus_labelVal">{capitalize_first_letter(this.props.data.id_proof.gender.value)}</p>
                                            </div>
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Email ID</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.email_id.value}</p>
                                            </div>
                                            {/* <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">Whatsapp</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.whatsapp.value}</p>
                                            </div> */}
                                            <div className="col-md-3">                                       
                                                <p class="no-margin viewCus_label">National ID</p>
                                                <p class="no-margin viewCus_labelVal">{this.props.data.id_proof.id_proof_num.value}</p>
                                            </div>                                                                                                                                    
                                        </div>
                                        <div className='row no-margin'>
                                            <div className='col-md-3'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">Passport Size Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.kyc_photos.photo_pps_full_path.value, this.props.data.kyc_photos.photo_pps.value)} file_name={this.props.data.kyc_photos.photo_pps.value}></DisplayImage>
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">National ID Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.id_proof.photo_id_proof_full_path.value, this.props.data.id_proof.photo_id_proof.value)} file_name={this.props.data.id_proof.photo_id_proof.value}></DisplayImage>
                                                </div>
                                            </div>
                                            {(this.props.data.id_proof.id_proof_type=="national_id" )&&
                                            <div className='col-md-3'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">National ID Back Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.id_proof.photo_id_proof_back_full_path.value, this.props.data.id_proof.photo_id_proof_back.value)} file_name={this.props.data.id_proof.photo_id_proof_back.value}></DisplayImage>
                                                </div>
                                            </div>
                                             }
                                            <div className='col-md-3'>
                                                <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                    <p class="no-margin viewCus_label no-padding">Selfie Photo</p>
                                                    <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.kyc_photos.photo_selfie_full_path.value, this.props.data.kyc_photos.photo_selfie.value)} file_name={this.props.data.kyc_photos.photo_selfie.value}></DisplayImage>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.data.allow_biz_owner_manual_id_capture && (this.props.lead_data.status == "50_pending_audit" || this.props.lead_data.status == "42_retrieve_holder_name") &&
                                        <div className = "row justify-content-center" style={{ marginTop:20,backgroundColor :"white"}}>
                                            <div className="col-md-12" style={{backgroundColor :"rgba(255, 0, 0, .4)" }}> 
                                                <p style={{color : "#01062c",fontSize : "14px", marginBottom: 5 ,padding:5}} class="text-center">NOTE</p>
                                                <p style = {{color : "#01062c",fontSize : "12px"}} class=" text-center"> App was not able to extract text from the National ID and {this.state.rm_label_short} typed the data fields from National ID manually. <br/>  If both are not matching, please re-assign back to {this.state.rm_label_short} and ask to correct it.</p>
                                            </div>
                                        </div>
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
                {(this.props.data.same_as_owner_person == false) &&
                    <div className = "kyc-card row">
                        <div className = "col-md-12">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("ContactPerson")}>Contact Person<BsChevronDown className = {this.state.contactPerson_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='no-padding'>
                                            <div className = "row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">First Name</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].first_name.value}</p>
                                                </div>
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Last Name</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].last_name.value}</p>
                                                </div>
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">DOB</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].dob.value}</p>
                                                </div>
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Gender</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].gender.value}</p>
                                                </div>
                                                {/* <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">National ID</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].id_proof.value}</p>
                                                </div> */}
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Email ID</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].email_id.value}</p>
                                                </div>
                                                {this.props.data.contact_persons[0].mobile_num &&
                                                    <div className="col-md-3">                                       
                                                        <p class="no-margin viewCus_label">Mobile Number</p>
                                                        <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].mobile_num.value}</p>
                                                    </div>
                                                }
                                                {this.props.data.contact_persons[0].alt_biz_mobile_num_1 &&
                                                    <div className="col-md-3">                                       
                                                        <p class="no-margin viewCus_label">Mobile Number 1</p>
                                                        <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].alt_biz_mobile_num_1.value}</p>
                                                    </div>
                                                }
                                                {this.props.data.contact_persons[0].alt_biz_mobile_num_2 &&
                                                    <div className="col-md-3">                                       
                                                        <p class="no-margin viewCus_label">Mobile Number 2</p>
                                                        <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].alt_biz_mobile_num_2.value}</p>
                                                    </div>
                                                }
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Whatsapp</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].whatsapp.value}</p>
                                                </div>
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Handling Biz Since</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].handling_biz_since.value}</p>
                                                </div>
                                                <div className="col-md-3">                                       
                                                    <p class="no-margin viewCus_label">Relation with Owner</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.contact_persons[0].relation_with_owner.value}</p>
                                                </div>
                                            </div> 
                                            <div className='row no-margin'>
                                                <div className='col-md-3'>
                                                    <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                        <p class="no-margin viewCus_label no-padding">National ID Photo</p>
                                                        <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.contact_persons[0].photo_id_proof_full_path.value, this.props.data.contact_persons[0].photo_id_proof.value)} file_name={this.props.data.contact_persons[0].photo_id_proof.value}></DisplayImage>
                                                    </div>
                                                </div>                                                
                                                <div className='col-md-3'>
                                                    <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                        <p class="no-margin viewCus_label no-padding">National ID Back Photo</p>
                                                        <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.contact_persons[0].photo_id_proof_back_full_path.value, this.props.data.contact_persons[0].photo_id_proof_back.value)} file_name={this.props.data.contact_persons[0].photo_id_proof_back.value}></DisplayImage>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                        <p class="no-margin viewCus_label no-padding">Selfie Photo</p>
                                                        <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.contact_persons[0].photo_selfie_full_path.value, this.props.data.contact_persons[0].photo_selfie.value)} file_name={this.props.data.contact_persons[0].photo_selfie.value}></DisplayImage>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                        <p class="no-margin viewCus_label no-padding">Passport Size Photo</p>
                                                        <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.contact_persons[0].photo_pps_full_path.value, this.props.data.contact_persons[0].photo_pps.value)} file_name={this.props.data.contact_persons[0].photo_pps.value}></DisplayImage>
                                                    </div>
                                                </div>
                                            </div>                                                                                                                                                            
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                }
                {(this.props.lead_data.acc_purpose.includes("terminal_financing")) &&
                    <div className = "kyc-card row">
                        <div className = "col-md-12">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white",fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("References")}>References<BsChevronDown className = {this.state.references_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='no-padding'>
                                            <div className="row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>
                                                <div className="col-md-4">                                       
                                                    <p class="no-margin viewCus_label">Guarantor 1 Name</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.references[0].guarantor1_name.value}</p>
                                                </div>
                                                <div className="col-md-4">                                       
                                                    <p class="no-margin viewCus_label">Guarantor 2 Name</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.references[1].guarantor2_name.value}</p>
                                                </div>
                                                <div className="col-md-4">                                       
                                                    <p class="no-margin viewCus_label">LC Name</p>
                                                    <p class="no-margin viewCus_labelVal">{this.props.data.references[2].lc_name.value}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                        <p class="no-margin viewCus_label no-padding">Guarantor 1 Doc</p>
                                                        <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.references[0].guarantor1_doc_full_path.value, this.props.data.references[0].guarantor1_doc.value)} file_name={this.props.data.references[0].guarantor1_doc.value}></DisplayImage>
                                                    </div>                                                                                                                                               
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                        <p class="no-margin viewCus_label no-padding">Guarantor 2 Doc</p>
                                                        <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.references[1].guarantor2_doc_full_path.value, this.props.data.references[1].guarantor2_doc.value)} file_name={this.props.data.references[1].guarantor2_doc.value}></DisplayImage>
                                                    </div>                                                                                                                                               
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='d-flex align-items-center justify-content-around pAlign_mid'>                                    
                                                        <p class="no-margin viewCus_label no-padding">Letter from LC</p>
                                                        <DisplayImage className="img-fluid" image_path={get_dir_path(this.props.data.references[2].lc_doc_full_path.value, this.props.data.references[2].lc_doc.value)} file_name={this.props.data.references[2].lc_doc.value}></DisplayImage>
                                                    </div>                                                                                                                                               
                                                </div>                                                
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                }

                <div className = "kyc-card row">
                    <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white", fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Consent")}>Data Consent<BsChevronDown className = {this.state.consent_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className='no-padding'>
                                        <div className = "row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>                                            
                                                <div className='col-md-12'>
                                                        <div className="row no-margin justify-content-around">                                                                                                                                                                                                                                   
                                                            <div className="col-md-4">                                       
                                                                <p class="no-margin viewCus_label">Consent Signed Date</p>
                                                                <p class="no-margin viewCus_labelVal">{format_date_tbl(this.props.lead_data.consent_signed_date)}</p>
                                                            </div> 
                                                
                                                            <div className="col-md-4">                                       
                                                                <p class="no-margin viewCus_label">View PDF</p>
                                                                <span><Button   onClick={() => {this.handlePdfModal(this.props.lead_data.consent_json.signed_consent_path,"Data Consent PDF")}} >View</Button></span>
                                                            </div>                                                        
                                                        </div> 
                                                </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>                     
                </div>
                </div>

                <div className = "kyc-card row">
                    <div className = "col-md-12">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle style={{backgroundColor: "#3D3E53" , color: "white", fontSize:18}} as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Agreements")}>Agreements<BsChevronDown className = {this.state.aggreements_arr ? "arrow-icon down" : "arrow-icon"} size="15"/></Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className='no-padding'>
                                        <div className = "row" style={{marginRight:0,marginLeft:0,marginBottom:15}}>                                                                                    
                                       
                                        {this.props.data.agreements.map((agreement,i) =>{
                                            return (<div className='col-md-12'>
                                            <fieldset class="fieldStyle_auditKYC">
                                                <legend class="w-auto text-labelblue no-margin" style={{fontSize: 15}}>{dd_value(agreement.acc_purpose,"account_purpose")+" "+"Agreement"}</legend>
                                                {/* <h5 className='no-margin' style={{fontSize:"14px",paddingTop:23}} >Float Adavnce Agreement</h5>                                                                                                                                                                             */}
                                                <div className="row no-margin">                                                                                                                                                                                                                                   
                                                    <div className="col-md-4">                                       
                                                        <p class="no-margin viewCus_label">Agreement Doc ID</p>
                                                        <p class="no-margin viewCus_labelVal">{agreement.aggr_doc_id}</p>
                                                    </div> 
                                                    <div className="col-md-4">                                       
                                                        <p class="no-margin viewCus_label">Agreement type</p>
                                                        <p class="no-margin viewCus_labelVal">{agreement.aggr_type}</p>
                                                    </div>
                                                    <div className="col-md-4">                                       
                                                        <p class="no-margin viewCus_label">View PDF</p>
                                                        <span><Button   onClick={() => {this.handlePdfModal(agreement.aggr_file_path,"Agreement PDF")}} >View</Button></span>
                                                    </div>                                                        
                                                </div> 
                                            </fieldset>                                                                                                       
                                        </div>)


                                        })
                                                
                                            }
                                       
                                                                                                                                                                                                              
                                        </div>                                                        
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>                
            </div>
            {this.props.lead_data.status == "42_retrieve_holder_name" &&
            <div className = "row justify-content-center" style={{ marginTop:20,backgroundColor :"white"}}>
                <div className="col-md-12" style={{backgroundColor :"rgba(255, 0, 0, .4)" }}> 
                    <p style={{color : "#01062c",fontSize : "14px", marginBottom: 5 ,padding:5}} class="text-center">NOTE</p>
                    <p style = {{color : "#01062c",fontSize : "12px"}} class=" text-center">Please click on the RETRIEVE HOLDER NAME button under Agent Info section and then proceed with the KYC process</p>
                </div>
            </div>
            }
            </>
        )}
        else
        {
            return (<div></div>)
        }
    }
}

export default LeadAuditComponent;
