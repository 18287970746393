import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import $ from 'jquery';	
import SearchFieldVisit from '../component/SearchFieldVisit';
import {getFieldVisitData} from '../../../actions/field_visit_actions';
import {format_date, format_date_tbl, set_id_for_rows, table_tooltip} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import {get,get_item,check_priv, Config} from '../../../helpers/storage_helper';
// import 'react-table-6/react-table.css' ;
import moment from 'moment';
import CommonReactTable from '../../../helpers/commonReact_table';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import ExportCsv from '../../common/component/ExportCsv';
import DataGridTable from '../../CommonComponent/table';



class FieldVisitContainer extends FlowContainer{
	constructor(props){
    super(props)
		this.state = {
		field_visit : {'individual_visits' : false},
		customer_visit : "hide_div",
		visits: [],
      	loading:true,
		columns : [],
		from_to_dates :{},
		paginate : Config('paginate') ? Config('paginate') : null,
		load_table : false,
		export_data : [],
		pagination :false,
		total :0,
		loader:false,
		priv_users_datas:null,
		priv_users:null,
		page: { page: 0, pageSize: 10 },
		}
	}

	componentWillMount(){
		
		const field_visit = this.state.field_visit

		if(check_priv("field_visit","all_search")){
			//var associated_with =  "flow";
			//var associated_entity_code =  this.data_prvdr_code;
    		//this.setOptionsToStateFromApi("rel_mgr", {...this.def_req, associated_with, associated_entity_code});	
    		const status="enabled";
			const priv_code =  "field_visit/checkin"

		if(get("role_codes") == "relationship_manager"){
			this.load_flow_rel_mgr(null,"multiselect_rm_name");
		}else{
			this.setOptionsListToStateFromAppApi("common","priv_users",this.req({priv_code,status}), ['id' , 'name'],null,"multiselect");
		}
		}else if(check_priv("field_visit","self_search")){
			field_visit.search_type = "self_search"
		}

		if(this.props.params.cust_id){
			field_visit.visit_start_time__to = moment(new Date())
			field_visit.visit_start_time__from = moment().subtract(3, 'months')
			field_visit.individual_visits = true
			field_visit.cust_visit_history = true
			field_visit.cust_id = this.props.params.cust_id
		}
		this.setState({field_visit})

	}
	componentDidMount(){
		if(this.props.params.cust_id){
			var field_visit = this.state.field_visit
			field_visit.visit_start_time__to = field_visit.visit_start_time__to.format("YYYY-MM-DD")
			field_visit.visit_start_time__from = field_visit.visit_start_time__from.format("YYYY-MM-DD")
			this.handleSearch()
		}


	}
	validate_search(field_visit) {
		if(field_visit.visit_start_time__from && field_visit.visit_start_time__to ){
			return true
  		}else{
  			alert("Please enter valid search criteria")
			this.setState({loader : false})
  			return false
  		}
	}

	pagination = (page, count, sorted)=>{
			
		this.setState((state) => {
			return {
			  paginate: {
				...state.paginate,
				pagination_count: this.state.page.pageSize,
				pagination_page: this.state.page.page ,
				sorting:sorted,
				total_count : this.state.total
			  },
			};
		  },()=>this.handleSearch());
	
	}

	page_count = ()=>{
		
		const total = this.state.total
		const page_count_mod = (total%this.state.paginate.pagination_count)
		
		if(page_count_mod > 0){
			const page_count = (total/this.state.paginate.pagination_count)
			this.setState({'page_count' : Math.trunc(page_count)+1}, ()=>console.log(this.state.page_count))

		}else{
			const page_count = (total/this.state.paginate.pagination_count)
			this.setState({'page_count' : page_count})
		}
	}

	handleExportCSV = (event) => {
		let req = this.removeKey('paginate')
		let visit = JSON.parse(JSON.stringify(req));
		let resp = null;
		if(event == 'email') {
			visit.export = 'email'
			resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
		}

		if(event == 'email' && !resp){
			return
		}
		else{
			this.handleCSVapi(visit, event)
		}

		
	}

	handleCSVapi(visit, action){
		let calling_api = true
		if (action == 'email') calling_api = false 
		getFieldVisitData(this.req(visit, true, null , calling_api))
			.then((response) => {
				this.resp(response)
				if(action == 'email'){
					return
				}
				this.setState(state => state.export_data = response.data.visits)
			})
	}

	removeKey = (key) => {
		const { [key]: deletedKey, ...updatedObject } = this.state.field_visit;
		this.setState({ field_visit: updatedObject }, ()=>console.log(this.state.field_visit,"#####"));
		return updatedObject
	  };


	handleSearch = (event) => {
		var request = this.state.field_visit
		this.setState({loader:true})
		if(this.state.field_visit.individual_visits == true && this.state.paginate){
			if(event == 'search'){
				this.setState({load_table : false,visits : [], total : 0})
				request.paginate =  Config('paginate')
			}
			else{
				request.paginate = this.state.paginate
			}
		}
		
		var is_valid = this.validate_search(request)
		if(is_valid){
			this.setState({search_criteria:request})
			getFieldVisitData(this.req(request))
			    .then((response) => {
					this.resp(response)
			        	if(!response){return this.setState({loader : false})};
	        				if(response.status === "success"){
	        					this.resp(response)
								if(this.state.paginate != null && response.data.individual_visits === true){								
									this.setState(state=>state.total= response.data.total_counts)	
									this.page_count()
								}
								else{
									this.setState(state=>state.total=response.data.visits.length)
								}

								var columns = []
	        					if(response.data.individual_visits === true){

									columns = [
										{ headerName: "Visitor Name", field: "visitor_name"  , 
											align: 'center',headerAlign: 'center',flex: 1},
										{ headerName: "Cust Name", field: "cust_name", align: 'center',headerAlign: 'center',flex: 1},

										{ headerName: "Cust ID", field: "cust_id",
										align: 'center',headerAlign: 'center',flex: 1,
										renderCell: params => {return <Link to={"/borrower/indiv/view/"+params.row.cust_id} target="_blank"
										 />},
										 minWidth:200

										},
										{ headerName: "Visit Time", field: "visit_start_time", 
											align: 'center',headerAlign: 'center',flex: 1,
											renderCell:params => {return params.row.visit_start_time ?
												<span>{format_date(params.row.visit_start_time)}</span> : "NA"},
												minWidth:200
										},


										{ headerName: "Purpose", field: "visit_purpose", 
											minWidth:200,
											align: 'center',headerAlign: 'center',flex: 1,

											renderCell:params => {return <span>
												{Array.isArray(params.value)
													? params.value.map(purpose => 
														purpose ? (Array.isArray(dd_value(purpose)) ? dd_value(purpose).join(", ") : dd_value(purpose))
														: "-"
														).join(", ")
													: "-"}
												</span>}},
											
										{ headerName: "Remarks", field: "remarks",
											minWidth:230,
											align: 'center',headerAlign: 'center',flex: 1,

											renderCell:params => {return params.row.remarks ?
												<span>{table_tooltip(params)}</span> : "NA"}
											
											
											},
									]
	        					}
        						if(response.data.individual_visits === false) {  
									
									columns = [
										{ headerName: "Visitor Name", field: "visitor_name",
										align: 'center',headerAlign: 'center',flex: 1,


										},
										{ headerName: "Visit date", field: "visit_date",
											align: 'center',headerAlign: 'center',flex: 1,

											renderCell:params => {return params.row.visit_date ?
												<span>{format_date(params.row.visit_date)}</span> : "NA"}
											
											
											 },
										{ headerName: "Visits", field: "visits",
											align: 'center',headerAlign: 'center',flex: 1,

										},
									]
									this.setState({page :{ page: 0, pageSize: 10 }})
									

	        					}	

								this.setState({customer_visit:"show_div"})
								this.setState({load_table : true})

								const data = set_id_for_rows(response.data.visits)
								
								
								this.setState((state) => {
									return { columns,visits  : data, page_count: response.data.total_counts??0 };
								}, ()=>this.setState({loader : false}));
	        				}
	        
	        			})

				}
	}

	handlevisitchange =(event) =>{
	 	var field_visit = this.state.field_visit
 		const value = event.target.id === 'individual_visits' ? event.target.checked : event.target.value;
 		field_visit[event.target.id] = value
 		this.setState({field_visit,customer_visit:"hide_div",visits: [], load_table : false})	
	}
	handleCheck=(e)=>{
		console.log(e.target.checked);
		this.setState({pagination:true, load_table : false})
		if(e.target.checked === false){
			let req_data = this.removeKey('paginate')
			this.setState({pagination:false})
		}
	}

	handlePurposeChange = (selected_options,id) =>{
		this.setState({load_table : false})
		this.setMultiselectOptionOnState(selected_options,id,this.state.field_visit)
    }
	componentDidUpdate(prevProps, prevState) {
		if ( prevState.page !== this.state.page && this.state.page_count) {
		  this.pagination();
		}
	  }
	render(){
		const is_server_side = this.state.page_count?true: this.state.paginate
    return(
    	<div className="container max-width containerTopmargin no-padding">
    		<div className="row no-margin">
	    		<h3 className="field_visit no-margin headerTitle headerPadvert">
	    		{ this.state.field_visit.cust_visit_history ? "Search Customer Visit" + " ("+this.state.field_visit.cust_id+")" 
	    		: "Search Customer Visit" }</h3>    		 
	    	</div>
	    	<SearchFieldVisit  id="searchfieldvisit"  
					onSearch={this.handleSearch} 
					onComponentChange={this.handlevisitchange} 
					onComponentReset={this.handleComponentReset} 
					search_btn = {this.state.calling_api}
					onComponentElementChange={this.handlevisitchange}
					priv_users 	= {get("role_codes") != "relationship_manager" ? this.state.priv_users : this.state.flow_rel_mgr}
					priv_users_dd	= {this.state.priv_users_datas}
					datas={this.state}
					field_visit = {this.state.field_visit}
					onCheck={this.handleCheck}
					handlePurposeChange={(options,id)=>{this.handlePurposeChange(options,id)}}

			/>
			{this.state.visits.length > 0 ? 
				<>
					<b className='text-white mb-2 mt-2'>Total Entries : {this.state.paginate ? this.state.total : this.state.visits.length}</b>
					{this.state.field_visit.individual_visits === true && this.state.load_table &&
					<div>{
						<div  className='flagg_cust_table' style={{display: !this.state.loader ?'':'none'}}>
							{/* {this.state.paginate == null ? */}
									{/* // <CommonReactTable showPag={true} minRow={0} row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns} resizable={false} sortable={false} exportCSV={true} csvFile={"field_visits"} csvData={this.state.visits !== undefined ? this.state.visits : []} searchData={[{...this.state.field_visit,title:"Search Customer visit"}]}/>

								// 	<BasicReactTable csv_file={true} row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns} FName={"field_visits"}  pagination={this.state.visits.length>9? true:false} default_page_size = {10} export_csv_datas={this.state.visits ? this.state.visits : []}  searchData={[{...this.state.field_visit,title:"Search Customer visit"}]}/>  
								// :
								// 	<BasicReactTable csv_file={true} pagination={true}  row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns}  FName={"field_visits"} export_csv_datas={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.field_visit,title:"Search Customer visit"}]} filter_data = {this.pagination} server_side_pagination = {this.state.paginate} total_page_count = {this.state.page_count} defaultPageSize={10} handleExport = {this.handleExportCSV} /> */}
                                   
								

								<Box display={'flex'} justifyContent={'end'}>
                                <ExportCsv
                                // total_count={this.state.total ?? 0} 
                                handleExport={this.handleExportCSV} 
                                export_csv_datas={this.state.export_data ?? []}
                                csvFileName = {'call_list'}
                                paginationMode= { is_server_side ? "server" :"client"}
                                columns={this.state.columns??[]}

                                />
                                </Box>
                                
                                <DataGridTable
                                        rows={this.state.visits??[]}
                                        columns={this.state.columns}
                                        paginationMode= { is_server_side ? "server" :"client"}
                                        sortingMode={"client"}
                                        page={this.state.page}
										rowCount={ is_server_side ?this.state.page_count : this.state.visits?.length}
                                        setpage={(newPage) => this.setState({ page: newPage })}
                                        isLoading={ this.state.loader }
                                        
                                    />
							{/* } */}
						</div> }

					</div>
					}
				</> 
			: 
				this.state.load_table &&
				<p className='text-white text-center' style={{marginTop:"15%"}}>NO DATA FOUND</p>
			}
			<div style={{display:this.state.loader ?'':'none'}}>
					<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
						<div className="product_takeup_loader"></div>
					</div>
			</div>

			{this.state.visits.length > 0 && this.state.field_visit.individual_visits === false && !this.state.loader &&
			<div className={`${this.state.customer_visit} flagg_cust_table `}  >	

				{/* <BasicReactTable csv_file={true} row_data={this.state.visits !== undefined ? this.state.visits : []} column_data={this.state.columns} FName={"field_visits"}  pagination={this.state.visits.length>9? true:false} default_page_size = {10} export_csv_datas={this.state.visits ? this.state.visits : []}  searchData={[{...this.state.field_visit,title:"Search Customer visit"}]}/>   */}
              
				
				             <Box display={'flex'} justifyContent={'end'}>
                                <ExportCsv
                                // total_count={this.state.total ?? 0} 
                                export_csv_datas={this.state.visits ?? []}
                                csvFileName = {'call_list'}
                                paginationMode= { is_server_side ? "server" :"client"}
                                columns={this.state.columns??[]}

                                />
                                </Box>
                                
                                <DataGridTable
                                        rows={this.state.visits??[]}
                                        columns={this.state.columns}
                                        paginationMode= { "client"}
                                        sortingMode={"client"}
                                        page={this.state.page}
										rowCount={this.state.visits?.length}
                                        setpage={(newPage) => this.setState({ page: newPage })}
                                        isLoading={ this.state.loader }
                                        
                                    />


				
    		</div>
			}
    	</div>
    	)
	}
}

export default  withRouter (FieldVisitContainer);