import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import Drawer from "@mui/material/Drawer";
import { AppBar, keyframes, MenuItem, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuList from "../home/menuList";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { changeHeaderSlice } from "../../Redux/Slices/HeaderSlice";
import withRouter from "../../Router/Withrouter";
import {
  active_menu_item,
  active_side_menu,
  Drawer_style,
  drawer_toggle,
  list_item_button,
  sm_display_none,
} from "../../styles/common_styles";
import { getValueFromLangJson } from "../../helpers/common_helper";
import { TbBackground } from "react-icons/tb";
import { BiBorderRadius } from "react-icons/bi";
import { updateValuesIntoRedux } from "../../Redux/Slices/CommonSlice";
const MenuDrawer = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [active_menu, setactive_menu] = React.useState("home");
  const [showDropDownItems, setshowDropDownItems] = React.useState({});
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const dispatch = useDispatch();
  const [active_sub_menu, set_active_sub_menu] = React.useState(false);
  const {brand_info} =  useSelector(state => state.common_slice)
  const sideMenuItems = {
    borderLeft: "0.1px solid ",
    borderColor: "#262d45",
    width:
      open && active_menu == "audit"
        ? "240px"
        : open && active_menu != "home"
        ? "215px"
        : "0px !important",
    transition: "width 300ms ease-in-out",
    overflowY: "auto",
  };

  const showSubmenus = (sub_menu_item, id) => {
    let state_name = `${sub_menu_item.key}_${id}`;
    setshowDropDownItems((prevState) => {
      const isCurrentlyActive = prevState[state_name];
      return {
        [state_name]: !isCurrentlyActive,
      };
    });
    set_active_sub_menu(sub_menu_item.key);
    if (!sub_menu_item.sub_menus) {
      if (sub_menu_item.url == "modal" || sub_menu_item.modal_type) {
        dispatch(
          changeHeaderSlice({
            [sub_menu_item.modal_type]: true,
            showavatar: false,
          })
        );
      } else if (sub_menu_item.url != "modal") {
        dispatch(changeHeaderSlice({ showavatar: false }));
        navigate(sub_menu_item.url);
        handle_menu(false);
      }
    }
  };

  const handle_menu = (value) => {
    setOpen(value);
    dispatch(changeHeaderSlice({ is_drawer_open: value }));
  };

  const handleSubmenuClick = (submenu) => {
    navigate(submenu.url);
    dispatch(changeHeaderSlice({ showavatar: false }));
    handle_menu(false);
  };

  React.useEffect(() => {
    if (props.location.pathname == "/") {
      setactive_menu("home");
      set_active_sub_menu(false);
    }
    let selected_sub_menus = null;
    Object.keys(props.sub_menus).map((menu_key, idx) => {
      selected_sub_menus =
        menu_key &&
        props.sub_menus[menu_key].filter(
          (val) =>
            val.url == props.location.pathname &&
            val.prev == true &&
            active_sub_menu == val.key
        );
      if (selected_sub_menus && selected_sub_menus.length > 0) {
        set_active_sub_menu(selected_sub_menus[0].key);
        setactive_menu(menu_key);
        return;
      }
    });
  }, [props.location]);

  const fadding = keyframes`0% {opacity: 0;}80% {opacity: 1}`;

  const handle_menu_click = (item) => {                       
    if( item.key == "home") {
      navigate("/")
    }else{
    setactive_menu(item.key)
    handle_menu(true)
   }
    dispatch(updateValuesIntoRedux({"quick_action":false}))

  };

  const sub_menu_prev = (item) => {
    return (item.sub_menus == undefined || item.sub_menus && item.sub_menus.filter(sub=>sub. prev).length>0)
  }

  const show_main_menu = (item) => {
    return item.key !== "home"  ? props.sub_menus[item.key]?.filter(sub=>sub.prev).length>0 : true
  } 

  const custom_icon ={
    width: '49px',
    height: '33px',
    margin: '7px 2px',
   
  }
  const flow_icon = {
    width:'40px',
    height:'39px'
  }

  return (
    <Box sx={{ display: "flex" }}>
      {(isMobile || isTablet) && (
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={() => handle_menu(!open)}
        >
          {!open ? (
            <MenuIcon sx={drawer_toggle} onClick={() => handle_menu(!open)} />
          ) : (
            <CloseIcon sx={drawer_toggle} onClick={() => handle_menu(!open)} />
          )}
        </IconButton>
      )}
      <Drawer
        variant={isMobile || isTablet ? "temporary" : "permanent"}
        sx={Drawer_style(theme)}
        open={open}
        disableScrollLock={false}
        onMouseLeave={() => (!isMobile || !isTablet) && handle_menu(false)}
      >
        <Box className="d-flex h-100">
          <Box sx={{ width: "94px" }}>
            <List className="mui_menu_list">
              <ListItem
                disablePadding
                className="my-4"
                sx={sm_display_none}
                onClick={() => {
                  navigate("/");
                }}
              >
                <ListItemButton
                  className="drawer_menu_icon"
                  sx={list_item_button}
                >
                  <ListItemIcon
                    sx={[{ minWidth: 0, justifyContent: "center" }]}
                  >
                    <Box sx={brand_info ?.footer ? {background:'white',borderRadius:'50%'}:{}}>
                    <Box
                      component="img"
                      src={brand_info?.global_icon}
                      alt="Drawer Logo"
                      sx={brand_info ?.footer ? custom_icon : flow_icon}
                       
                    />
                    </Box>

                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              {props.main_menus.map((item, index) => (
                <> 
                  {item.prev && show_main_menu(item) &&(
                    <ListItem
                      key={index}
                      disablePadding
                      className="my-2"
                      sx={{ display: "block" }}
                      onClick={() => { handle_menu_click(item, true);
                      }}
                    >
                      <ListItemButton
                        className="drawer_menu_icon"
                        sx={list_item_button}
                      >
                        <ListItemIcon
                          sx={[
                            { minWidth: 0, justifyContent: "center" },
                            active_menu == item.key
                              ? active_menu_item(theme)
                              : "",
                          ]}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </ListItemButton>
                      <Box>
                        <Typography
                          variant="s_inter_regular_white"
                          className="pointer text-center mx-1 -margin_top"
                          component={"p"}
                        >
                          {/* {item.label} */}
                          {getValueFromLangJson(item.key)}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                </>
              ))}
            </List>
          </Box>

          <Box sx={sideMenuItems}>
            <Box sx={{ mt: 7 }}>
              {props.sub_menus[active_menu] &&
                props.sub_menus[active_menu].map((item, idx) => (
                  <>
                    {item.prev &&  sub_menu_prev(item) && (
                      <React.Fragment key={idx}>
                        <Box
                          className="px-2"
                          sx={{ animation: `${fadding} 300ms ease-in-out` }}
                        >
                          <MenuItem
                            className="pointer my-2 py-2 d-flex align-items-center justify-content-between side_menu_list"
                            sx={
                              active_sub_menu == item.key
                                ? active_side_menu(theme)
                                : ""
                            }
                            onClick={() => showSubmenus(item, idx)}
                          >
                            {!item.sub_menus && item.url !== "modal" ? (
                              <Link to={`${item.url}`}>
                                <Typography
                                  variant={
                                    active_sub_menu === item.key
                                      ? "m_inter_semi_bold_white"
                                      : "s_inter_regular_white"
                                  }
                                  className="pointer"
                                  component="p"
                                >
                                  {getValueFromLangJson(item.key)}
                                </Typography>
                              </Link>
                            ) : ( 
                              <Typography
                                variant={
                                  active_sub_menu === item.key
                                    ? "m_inter_semi_bold_white"
                                    : "s_inter_regular_white"
                                }
                                className="pointer"
                                component="p"
                              >
                                {getValueFromLangJson(item.key)}
                              </Typography>
                            )}

                            {item.sub_menus && item.prev && (
                              <IconButton sx={{ padding: 0 }}>
                                {showDropDownItems[`${item.key}_${idx}`] ? (
                                  <ArrowDropUpOutlinedIcon
                                    sx={{ fontSize: 20 }}
                                  />
                                ) : (
                                  <ArrowDropDownOutlinedIcon
                                    sx={{ fontSize: 20 }}
                                  />
                                )}
                              </IconButton>
                            )} 
                          </MenuItem>
                          {showDropDownItems[`${item.key}_${idx}`] &&
                            item.sub_menus &&
                            item.sub_menus.map((_menus, index) => (
                              <Link
                                to={`${
                                  _menus.url != "modal" ? _menus.url : null
                                }`}
                              >
                                {_menus.prev && (
                                  <MenuItem
                                    key={index}
                                    className="pointer pl-3"
                                    onClick={() => handleSubmenuClick(_menus)}
                                  >
                                    <Typography
                                      variant="s_inter_regular_white"
                                      className="pointer text-center pl-4"
                                      component="p"
                                    >
                                      {_menus.label}
                                    </Typography>
                                  </MenuItem>
                                )}
                              </Link>
                            ))}
                        </Box>
                      </React.Fragment>
                    )}
                  </>
                ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default withRouter(MenuList(MenuDrawer));