import { Box, Grid, useTheme } from '@mui/material';
import React from 'react';
import FormProvider from '../../CommonComponent/rhfComponents/formProvider';
import { useForm } from 'react-hook-form';
import DateFilter from './DateFilter';
import CustomButton from '../../../MUI/Components/CustomButton';
import moment from 'moment';

interface FilterProps {
    get_report:(data: any) => void;
    date_fields: { 
        key: string;
        label: string;
      }[]
    }

const ReportFilters: React.FC<FilterProps> = ({ get_report,date_fields }) => {
 
    const theme = useTheme()
    const methods = useForm({
        defaultValues: {
          from_date: moment().startOf('month'),
          to_date: moment(),
        }
      });
    const { handleSubmit, watch, reset, formState: {errors, defaultValues, dirtyFields} } = methods;
    const onSubmit = handleSubmit((formData) => {
        get_report(formData)
      });

    return (
       <Box>
          <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box sx={{ my: 3 }}>
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item xs={12} lg={6}>
                          
                                <DateFilter fields={date_fields} />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <CustomButton
                                //  @ts-ignore
                                variant="full_width_inter_sm"
                                text={'Submit'}
                                type={"submit"} component="button"
                                style={{
                                    borderRadius: '10px',
                                    background: theme.palette.secondary.main
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                </FormProvider>
       </Box>
    );
};

export default ReportFilters;