import { Box, Button, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { searchHeldLoan } from '../../../actions/loan_actions';
import { get_disburse_manually_alert, Homeacc_prvdrLogoselector, lbl_amt, req, set_id_for_rows, title_case } from '../../../helpers/common_helper';
import { HeaderVarientH3Typo } from '../../CommonComponent/typography';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { ExcesstimeAction, TooltipLabel } from '../../common/partial';
import { dd_value } from '../../../actions/common_actions';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FcInfo } from 'react-icons/fc';
import { check_priv } from '../../../helpers/storage_helper';
import MUIAccordion from '../../../MUI/Components/MUIAccordion';
import { capture_fields } from './FasCaptureColumnFields';
import DataGridTable from '../../CommonComponent/table';
import CustomModal from '../../../MUI/Components/CustomModal';
import DisburseLoan from './DisburseLoan';
import ManualDispCapture from './ManualDispCapture';
import AccProviderFilter from '../../common/component/AccProviderFilter';
import { list_acc_prvdr } from '../../../helpers/common_apicalls';
interface Column {



    field: string;
    headerName: string;
    headerAlign?: "left" | "center" | "right";
    align?: "left" | "center" | "right";
    flex?: number;
    resizable?: boolean;
    width?: number;
    renderCell?: (params: any) => JSX.Element;
}
const PendingManualDisbursal = () => {
    const theme = useTheme()
    useEffect(() => {
        get_pending_list()
    }, [])

    const [rows, setrows] = useState([])
    const [server_time, setserver_time] = useState([])
    const [columns, setcolumns] = useState<GridColDef[]>([]);
    const column_fields = ['loan_doc_id', 'channel', 'acc_prvdr_code', 'cust_mobile_num', 'loan_appl_date', 'status']
    const [dialog, setdialog] = useState(false)
    const [loan, setLoan] = useState(null);


    const get_pending_list = () => {
        let loan_pending_capture_search = { status: "pending_mnl_dsbrsl", disburse_attempt: false, mode: 'search' };
        // @ts-ignore
        searchHeldLoan(req({ loan_search_criteria: loan_pending_capture_search }))
            .then((response) => {
                if (response?.status == "success") {
                    console.log('response.data', response)
                    setrows(set_id_for_rows(response.data.results))
                    setserver_time(response.server_time)
                    setcolumns(set_columns(column_fields))
                }
            })
    }

    const manual_capture = (loan: any) => {
        handleDisburseManually(loan)
    }



    const set_columns = (column_fields: string[]): GridColDef[] => {
        const fields = capture_fields(column_fields, server_time)
        console.log('fields', fields)
        const action_button: GridColDef = {
            field: "action",
            headerName: "Action",
            headerAlign: "left",
            align: "left",
            flex: 1,
            resizable: true,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    onClick={() => manual_capture(params.row)}
                    sx={{ textTransform: 'capitalize' }}>
                    Disbursal Capture
                </Button>
            ),
        };
        const cols = [...fields, action_button];
        // @ts-ignore
        return cols;
    };
    const [page, setpage] = useState({ page: 0, pageSize: 10 });
    const body_content = () => {
        return (
            <Box>

                <DataGridTable
                    rows={rows}
                    columns={columns}
                    paginationMode={"client"}
                    sortingMode={"client"}
                    page={page}
                    isLoading={false}
                    isError={false}
                    errorText={''}
                    noOfColumnsToLoad={10}
                    setpage={setpage}
                    rowHeight={65}
                />

            </Box>
        )
    }

    const handleClose = () => {
        setdialog(false)
    };


    const handleDisburseManually = (loan: any) => {
        if (window.confirm(get_disburse_manually_alert(loan))) {
            {
                let value
                setdialog(true)
                const loan_data = loan;
                loan_data.amount = loan.loan_principal;
                setLoan(loan_data)
            }
        } else {
            setdialog(!true)
        }

    }

    { console.log('setdialog', dialog) }

    const acc_prvdr_list =  list_acc_prvdr();
    console.log('acc_prvdr_list', acc_prvdr_list)

    
    return (
        <Box>

            <MUIAccordion
                total_count={rows?.length}
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <h4 className="card-title text-white tableHead_font ">FAS Pending Manual Disbursal</h4>
                        {/* <AccProviderFilter /> */}
                    </Box>
                }
                body={body_content()}
            />
            {dialog &&
                <>
                    <ManualDispCapture
                        loan={loan}
                        open={dialog}
                        onClose={handleClose}
                        user_list={get_pending_list}
                    />
                </>

            }

        </Box>
    );
};

export default PendingManualDisbursal;