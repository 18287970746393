import * as React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Box } from '@mui/material';
import { useTheme } from '@emotion/react';


interface CommonAccordionProps {
    title: string |  React.ReactNode;
    body: React.ReactNode; 
    total_count?: number | null
  }
  
  const MUIAccordion: React.FC<CommonAccordionProps> = ({ title, body,total_count }) => {
    const theme = useTheme();
  
    return (
      <Accordion
      // className={ (!total_count ) ? 'disable_div':''}
      sx={{
        // @ts-ignore
        background: theme.palette.custom_colors.old_primary_400,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        "&.MuiPaper-root": {
          marginY: "25px !important"
        },}}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{fontSize:'28px',fontWeight:'400'}} />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Box display={'flex'} alignItems={'center'}>
            <Typography>
            {title}
          </Typography>
          {total_count &&total_count >0  ?
          <Box component={'span'} sx={
            {ml:2,
            // @ts-ignore
            backgroundColor: theme.palette.secondary.main,
            borderRadius:'50%',
            padding:'4px 10px',fontSize:'13px' }}> {total_count}</Box>:''
          }
       </Box>
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
      </Accordion>
    );
  };
  
  export default MUIAccordion;