import React, { Component, useEffect } from "react";
import {userService} from "./../../../actions/auth_actions";
// import {withRouter}  from  'react-router-dom';
import {Navigate, useNavigate} from 'react-router-dom';

import {storageChange} from "./../../../helpers/storage_helper";

const Logout = () => {
  const navigate = useNavigate()
  useEffect(()=>{
    userService.logout()
     setTimeout(() => {
      navigate('/login')
      window.location.reload()
      }, 10); 
  },[])
  
  return (
    <div>
       {/* <Navigate to={{ pathname: '/login' }} /> */}

    </div>
  );
};
export default Logout;