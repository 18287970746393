
import React,{useState,useEffect, Children} from 'react';
// import Flag from "react-flags";
import { Config,get } from '../../../helpers/storage_helper';
import { listAccountProvider } from '../../../actions/account_provider_actions';
import ReactApexChart from "react-apexcharts";
import {getValueFromLangJson, RoundOffSplitter} from '../../../helpers/common_helper';
import { getReport,getReportDate } from '../../../actions/report_actions';
import { Link } from 'react-router-dom';
import { IoIosGlobe } from "react-icons/io";
import moment from "moment";
import {listMarkets} from '../../../actions/common_actions';
import DatePicker from 'react-datetime';
import { BiCalendar, BiCheckCircle, BiGlobe } from "react-icons/bi";
import LenderCode from '../component/LenderCode';
import { CSVLink } from 'react-csv';
import CustomFlag from '../../common/component/CustomFlag';
import { useSelector } from 'react-redux';


const MonthlyReport = () => {
    const lender_list = useSelector(state=>state.common_slice?.markets_with_lender)
    const [country, setcountry] = useState(
        !get('market_list') || get('market_list')?.length<=1 ? get('market'):
        { country_code: '*', country: 'Global' , currency_code:"USD"}
    )
    var conversion_currencies = ['USD','EUR']
    const [first, setfirst] = useState(null)
    const[data,setdata]=useState([])
    const [currencyDetails,setcurrencyDetails] = useState({})  
    const [cursorPointerStatus,setcursorPointerStatus] = useState(true)   
    const [accPrvdr,setaccPrvdr] = useState(null)
    const [allAccPrvdr,setallAccPrvdr] = useState([]) 
    const [switch_indicate,set_switch_indicate]=useState(false) 
    const [switch_data,setSwitchDataState]=useState(false) 
    const [month,setmonth]=useState({
      from:'',
      to:'',
    })
    const [activeCurrency, setactiveCurrency] = useState({
        currency_change:false,
        active_currency:'',
        active_currency_val:''
    })
    const [filteredCountrydata, setfilteredCountrydata] = useState([]) 
    const [currentCountryactiveCurrency, setcurrentCountryactiveCurrency] = useState({
        active_currency:get('market')?.currency_code,
    })
    const [marketList,setmarketList]=useState([])
    const [current_country,setcurrent_country] = useState(null)
    const [showResults, setShowResults] =useState(false)
    const [max_month,setMaxmonth]=useState('')
    const[token,settoken]=useState()
    const[reportdate,setReportdate]=useState()
    const[lender,setLender] = useState(null)
    const[csvData, setCsvData] = useState(null)

    useEffect(() => {
        if(get('market_list')){
            setmarketList(get('market_list'))
        }else{
        
            listMarkets().then((response)=>{
                if(!response){return}
                if(response){
                 setmarketList(response.data)
                 get_country_name(response.data)
                }
            }) 
        }
      getDate()
      setShowResults(true)
    //   setcountry({
    //   ["country"]:"Global",
    //   ["country_code"]:"*"
    //   })
       setactiveCurrency({
        ["currency_change"] : false ,
        ["active_currency"] : 'USD',
        ["active_currency_val"] : ''
      })
      var request = {country_code: get('market').country_code, time_zone: get('market').time_zone, status: ""};      
      listAccountProvider({...request, status: 'enabled', biz_account: true})
		.then((response)=>{
			if(!response){return };
			if(response.status == "success"){
                let acc_prvdrs = []
                response.data.list.map(each=>
                    acc_prvdrs.push(each.acc_prvdr_code)
                )
                setallAccPrvdr(acc_prvdrs) 
                }                  
		});  
        getMonthReport()
        get_report_date()
    }, [listAccountProvider])
    
    // useEffect(()=>{
    //     if(token){
    //     setCurrencyHandler("USD")
    //     }
    // },[token, country])

    useEffect(()=>{
        if(country.country_code != '*'){
            submitHandler()
        }
    },[lender])

    const prvdrSelectHandler =(accPrvdr) => {
        setaccPrvdr(accPrvdr)
        setcursorPointerStatus(false)   
        filterData(data,country['country_code'],accPrvdr)   
    }
    const get_country_name = (data) => {
        const current_country = data.filter((item,idx)=>{ return item.country_code == country.country_code})
         setcurrent_country(current_country)
      }

    const setCurrencyHandler = (curren, country_details = country) => {
        setcurrentCountryactiveCurrency({
            ["active_currency"] :country_details.currency_code
           }) 

        if(country_details.country_code !="*"){
            if(curren === "USD" || curren === "EUR" ){
                setactiveCurrency({
                    ["currency_change"] : true,
                    ["active_currency"] : curren,
                    ["active_currency_val"] : currencyDetails[country_details.country_code].forex_rates[curren]
                })
            }
            else if (curren === country_details.currency_code ) {
                setactiveCurrency({
                    ["currency_change"] : false,
                    ["active_currency"] : curren,
                    ["active_currency_val"] : ""
                })
            }
           
        }else{
           
            setactiveCurrency({
                ["currency_change"] : true,
                ["active_currency"] : curren,
                ["active_currency_val"] : 1
            })
           
 
        }
       
    }

    const getMonthReport = () => {
        const req = {
        "country_code": country.country_code,
        "report_type": "management_dashboard_monthly",
        "time_zone":get('market').time_zone
       
    } 
            getReport(req)  
          .then((response)=>{

              if(response){
              if(response.status == "success"){
                setdata(response.data.records)   
                filterData(response.data.records,country.country_code,accPrvdr)
                const currency_details = response.data.currency_data  ;  
                setcurrencyDetails(currency_details)    
                settoken(Math.floor(Math.random() * 100) + 1)
                setcurrentCountryactiveCurrency({
                  ["active_currency"] :currency_details[get('market').country_code].currency_code
                 }) 
              }
            }
          })
    }
    const filterData = (data,country,dataprvdr) =>{
        
        // const filter_data = data.filter(e=>e.country_code == country && e.acc_prvdr_code==dataprvdr) 
        const filter_country = data.filter(e=>e.country_code == country) 
        
        if( filter_country.length>0){
            const filter_prvdr=filter_country.filter(e=> e.acc_prvdr_code==dataprvdr) 
            setfilteredCountrydata(filter_prvdr) 
        }else{
            setfilteredCountrydata([]) 
        }
       
        
    }
    const switchCountries = Config("switch_supported_countries") ?? [];
    

    useEffect(() => {
        if (marketList && switchCountries ){
            const marketCodes = marketList.map(country => country.country_code);
            if (switchCountries.includes(country.country_code) || (switchCountries.some(countryCode => marketCodes.includes(countryCode)) && country.country_code === '*')) {
                setSwitchDataState(true);
                set_switch_indicate(true)
            }
            else {
                setSwitchDataState(false);
                set_switch_indicate(false)
            }
        }
      }, [country, switchCountries, marketList]); 

    const countrySelecthandler = (selected_country) => {
        setcountry(selected_country)
        // setcountry(country)
        setLender(null)
        setcursorPointerStatus(true)
        setaccPrvdr(null)
    
        let countryCode = selected_country.country_code
        if(countryCode!="*"){
        var req = {country_code: countryCode, status: "",time_zone:get('market').time_zone};
            setactiveCurrency({
                ['currency_change'] : (countryCode == country.country_code )? false : true ,
                ["active_currency"] : currencyDetails[countryCode] ? currencyDetails[countryCode].currency_code :'',
                ["active_currency_val"] : ''
            })
            const currentCountryactiveCurrency = currencyDetails[countryCode] ?currencyDetails[countryCode].currency_code:''
        setcurrentCountryactiveCurrency({
            ["active_currency"] : currentCountryactiveCurrency
        })
        set_switch_indicate(false)  
        }
        else{
            setactiveCurrency({
                ['currency_change'] : countryCode == country.country_code ? false : true ,
                ["active_currency"] : 'USD',
                ["active_currency_val"] : ''
            }) 
        }
        setCurrencyHandler("USD")
        var req = {country_code: countryCode, time_zone: countryCode === "UGA" ? "EAT" : "CAT", status: ""};
        listAccountProvider({...req, status: 'enabled', biz_account: true})
            .then((response)=>{
                if(!response){return };
                if(response.status === "success"){
                    let acc_prvdrs = []
                    response.data.list.map(each=>
                        acc_prvdrs.push(each.acc_prvdr_code)
                    )
                    setallAccPrvdr(acc_prvdrs)
                }
            });
        
            setCurrencyHandler("USD",selected_country)
            filterData(data,countryCode,null) ;
    }
        
    

  

    const [chartcomponents, setchartcomponents] = useState({
        series: [68, 32],
        options: {
          chart: {
            width: 380,
            type: 'donut',
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 270
            }
          },
          dataLabels: {
            enabled: true
          },
          stroke:{
            colors:['#20233f']
           },
          fill: {
            type: 'gradient',
          },
          labels: ["Female", "Male"],
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8
          }
        },
         
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
   
    
   })
   
const getDate = (date) =>{
    
    var date= date ? new Date(date) : new Date()
    const role = get('role_codes');
    if (role !== 'investor') {
        var fm = date.getMonth()+1 ; //Month from 0 to 11
        var tm = date.getMonth(); //Month from 0 to 11
    }
    else{
        var fm = date.getMonth() ; //Month from 0 to 11
        var tm = date.getMonth() -1;
    }

    var y = date.getFullYear() 
    const  from = '' + y + '-' + (fm<=9 ? '0' + fm : fm);
    const  to = '' + y + '-' + (tm<=9 ? '0' + tm : tm);
    setmonth({
        ...month,
        from:from,
        to:to
    })
    setMaxmonth(from)
}
    const monthHandler=(val,type)=>{
            setShowResults(false)
            const date= new Date(val) ;
            const fm = date.getMonth() + 1; //Month from 0 to 11
            const tm = date.getMonth() +1; //Month from 0 to 11
            const fy =  date.getFullYear() ;
            const ty =  date.getFullYear() ;
            const  from = '' + fy + '-' + (fm<=9 ? '0' + fm : fm);
            const  to = '' + ty + '-' + (tm<=9 ? '0' + tm : tm);
            let updated=''
            if(type=='from'){
           
            updated={
                ...month,
                from: from,
            
            }
        }else{
            updated={
                ...month,
                to: to,
            }
        }
        setmonth(updated)
        // getMonthReport(from.replace(/-/g, ""),to.replace(/-/g,""))
    }
    const submitHandler=()=>{
        const req = {"country_code": country.country_code,
        "report_type": "management_dashboard_monthly",
        "report_month": month.from.replace(/-/g, ""),
        "vs_month" : month.to.replace(/-/g, ""),
        "time_zone":get('market').time_zone,
        "lender":lender
       
    }     
           getReport(req)  
          .then((response)=>{
              if(response){
              if(response.status == "success"){
                setdata(response.data.records)  
                setShowResults(true)
                filterData(response.data.records,country.country_code,accPrvdr)
                const currency_details = response.data.currency_data  ;    
                setcurrencyDetails(currency_details)    
                setcurrentCountryactiveCurrency({
                  ["active_currency"] : currency_details[country.country_code] ? currency_details[country.country_code].currency_code : 'USD'
                 })
                let current_active_currency = activeCurrency.active_currency
                 if(conversion_currencies.includes(current_active_currency)){            
                    setactiveCurrency({
                        ["currency_change"] : true,
                        ["active_currency"] : current_active_currency,
                        ["active_currency_val"] : currency_details[country.country_code] ? currency_details[country.country_code].forex_rates[current_active_currency] : 1
                    })
                }
                 setaccPrvdr(null)                  
              }
             
            }else{
               
                getDate()

            }
          })
    }

    const handleLenderCode = (res) =>{
        setLender(res.value)
      }

    const get_report_date = () => {
        getReportDate({ "time_zone":get('market').time_zone,"country_code": country.country_code})
        .then(
            (response) => {
                if(!response) {return;}
                if(response.status === "success") {
                    let reportDate = response.data
                    reportDate = moment(reportDate).format("DD MMM YYYY")
                    setReportdate(reportDate)
                }
                }
        )

    }

    const headers =[
        {
            label:'Title',key:'title'
        },
        {
            label:'Value',key:'value'
        },
        {
            label:'Percentage',key:'percentage'
        }
    ]

    const calculatePercentage = (month, vsMonth) =>{
        const percentageChange = ((month - vsMonth) / vsMonth) * 100;
        const formattedPercentage = percentageChange.toFixed(1);
        let displayPercentage = `${formattedPercentage} %`;
        return (formattedPercentage && formattedPercentage !== 'NaN' ) ? displayPercentage.toString().padStart(20, ' ') : '0.0%';
    }
    const formatNumber = (n) => {
        if (!activeCurrency.currency_change){
            return n+" "+activeCurrency.active_currency
        }else{
            return n*activeCurrency.active_currency_val+" "+activeCurrency.active_currency
        }
      }
    const getCsvData =() =>{
        const csvFormatedData = filteredCountrydata[0];
        const Startmonth = month.from
        
        const csvDatafordownload = [
            {
                title : "Total Invested Amount",
                value :formatNumber(csvFormatedData.tot_inves_amount.value),
                percentage : calculatePercentage(csvFormatedData.tot_inves_amount.value, csvFormatedData.tot_inves_amount.vs_value)
            },
            {
                title : "Amount Disbursed",
                value :formatNumber(csvFormatedData.tot_disb_val.value),
                percentage : calculatePercentage(csvFormatedData.tot_disb_val.value, csvFormatedData.tot_disb_val.vs_value)
            },
            {
                title : "Loans Disbursed",
                value : csvFormatedData.tot_disb_count.value,
                percentage : calculatePercentage(csvFormatedData.tot_disb_count.value, csvFormatedData.tot_disb_count.vs_value)
            },
            {
                title : "Loans Settled",
                value : csvFormatedData.tot_fa_settled_count.value,
                percentage : calculatePercentage(csvFormatedData.tot_fa_settled_count.value, csvFormatedData.tot_fa_settled_count.vs_value)   
            },
            {
                title : "Gross Transaction Value",
                value : formatNumber(csvFormatedData.gross_txn_val.value),
                percentage : calculatePercentage(csvFormatedData.gross_txn_val.value, csvFormatedData.gross_txn_val.vs_value)   
            },
            {
                title : "Revenue",
                value : formatNumber(csvFormatedData.revenue.value),
                percentage : calculatePercentage(csvFormatedData.revenue.value, csvFormatedData.revenue.vs_value)   
            },
            {
                title : "Average Loan Size",
                value : formatNumber(csvFormatedData.avg_fa_amount.value),
                percentage : calculatePercentage(csvFormatedData.avg_fa_amount.value, csvFormatedData.avg_fa_amount.vs_value)   
            },
            {
                title : "Outstanding Count",
                value : csvFormatedData.os_count_eom.value ,
                percentage : calculatePercentage(csvFormatedData.os_count_eom.value, csvFormatedData.os_count_eom.vs_value)   
            },
            {
                title : "Outstanding Amount",
                value : formatNumber(csvFormatedData.os_val_eom.value),
                percentage : calculatePercentage(csvFormatedData.os_val_eom.value, csvFormatedData.os_val_eom.vs_value)   
            },
            {
                title : "Outstanding Fee",
                value : formatNumber(csvFormatedData.os_fee_eom.value),
                percentage : calculatePercentage(csvFormatedData.os_fee_eom.value, csvFormatedData.os_fee_eom.vs_value)   
            },
            {
                title : "Overdue Count",
                value : csvFormatedData.od_count.value ,
                percentage : '-'
            },
            {
                title : "Overdue Amount",
                value : formatNumber(csvFormatedData.od_amount.value),
                percentage : '-'  
            },
            {
                title : "New Overdue Count",
                value : csvFormatedData.new_overdue_count.value ,
                percentage : '-'
            },
            {
                title : "New Overdue Amount",
                value : formatNumber(csvFormatedData.new_overdue_val.value),
                percentage : '-'  
            },
            {
                title : "Ontime Repayment Rate",
                value : '-',
                percentage :  ((csvFormatedData.ontime_repayment_rate.value * 100).toFixed(1) + '%').toString().padStart(20, ' ')
            },
            {
                title : "Fee earned per FA",
                value : formatNumber(csvFormatedData.fee_per_fa.value),
                percentage : '-'  
            },
            {
                title : "Fee earned per customer",
                value : formatNumber(csvFormatedData.fee_per_cust.value),
                percentage : '-'  
            },
            {
                title : "New Overdue Rate Percentage",
                value : '-',
                percentage :  (((csvFormatedData.due_perc.value) * 100).toFixed(1) + ' %').toString().padStart(20, ' ')
            },
            {
                title : "New Overdue vsMonth Rate Percentage",
                value : '-',
                percentage : (((csvFormatedData.due_perc.value-csvFormatedData.due_perc.vs_value)*100).toFixed(1) + ' %').toString().padStart(20, ' ')
            },
            {
                title : "Registered Customers",
                value : csvFormatedData.cust_reg_count.value,
                percentage : calculatePercentage(csvFormatedData.cust_reg_count.value, csvFormatedData.cust_reg_count.vs_value)   
            },
            {
                title : "Enabled Customers",
                value : csvFormatedData.cust_enabled_count.value,
                percentage : calculatePercentage(csvFormatedData.cust_enabled_count.value, csvFormatedData.cust_enabled_count.vs_value)   
            },
            {
                title : "Active Customers",
                value : csvFormatedData.cust_active_count.value,
                percentage : calculatePercentage(csvFormatedData.cust_active_count.value, csvFormatedData.cust_active_count.vs_value)   
            },
            {
                title : "Revenue Per Customer",
                value : formatNumber(csvFormatedData.rev_per_cust.value),
                percentage : calculatePercentage(csvFormatedData.rev_per_cust.value, csvFormatedData.rev_per_cust.vs_value)   
            },
            {
                title : `Revenue Per ${getValueFromLangJson("rm_label_short")} `,
                value : formatNumber(csvFormatedData.rev_per_rm.value),
                percentage : calculatePercentage(csvFormatedData.rev_per_rm.value, csvFormatedData.rev_per_rm.vs_value)   
            },
            {
                title : "Retention Rate ",
                value : ((csvFormatedData.cust_retention_perc.value)*100).toFixed(1) + ' %' + ` (${moment(Startmonth).format("MMMM")} Month Percentage)`,
                percentage : calculatePercentage(csvFormatedData.cust_retention_perc.value, csvFormatedData.cust_retention_perc.vs_value)   
            },
            {
                title : "Female percentage",
                value : '-',
                percentage : (((csvFormatedData.female_perc.value)*100).toFixed(1) + ' %').toString().padStart(20, ' ')   
            },
            {
                title : "Male percentage",
                value : '-',
                percentage : ((100-(csvFormatedData.female_perc.value)*100).toFixed(1) + ' %').toString().padStart(20, ' ')
            },
            {
                title : "Switch Gross Transaction",
                value : csvFormatedData.sw_gross_txn_val.value,
                percentage : calculatePercentage(csvFormatedData.sw_gross_txn_val.value, csvFormatedData.sw_gross_txn_val.vs_value)   
            },
            {
                title : "Average Switch Amount",
                value : csvFormatedData.avg_sw_amount.value,
                percentage : calculatePercentage(csvFormatedData.avg_sw_amount.value, csvFormatedData.avg_sw_amount.vs_value)  
            },
            {
                title : "Switch Customers",
                value : csvFormatedData.cust_switch_count.value,
                percentage : calculatePercentage(csvFormatedData.cust_switch_count.value, csvFormatedData.cust_switch_count.vs_value)   

            },
            {
                title : "Switch Revenue Per Customer",
                value : csvFormatedData.sw_rev_per_cust.value,
                percentage : calculatePercentage(csvFormatedData.sw_rev_per_cust.value, csvFormatedData.sw_rev_per_cust.vs_value)   
            },
            {
                title : "Switch Revenue",
                value : csvFormatedData.sw_revenue.value,
                percentage : calculatePercentage(csvFormatedData.sw_revenue.value, csvFormatedData.sw_revenue.vs_value)   
            },
        ]
        const csvLink ={
            headers : headers ,
            data:csvDatafordownload,
            filename:'MonthlyReport.csv'
        }
        setCsvData(csvLink)

        
    }
    
 
    const show_lender = lender_list ?.length>0 && lender_list.includes(country.country_code)

    return (
        <div className='containerTopmargin max-width no-padding' id='convertPDF' style={{backgroundColor:'#1a2035'}}> 
            
              <div style={{padding: window.location.pathname === '/monthly-report/print'? '0px 15px' : '0px'}}>
            <h2 className='text-white' style={{fontSize:'32px'}}>Monthly Report <span>({reportdate})</span></h2>
            <div style={{marginTop:'20px'}}>
                <div className='d-flex justify-content-between flex-wrap no-margin'>
                    <div className='col-md-12 col-sm-12 col-lg-6 no-padding'>
                        <div className='d-flex align-items-center flex-wrap justify-content-between responsive_date_picker'>
                            <p className='text-white no-margin'>Month</p>
                            <div className="form-group monthly_report_input-lg col-md-4 col-lg-4 col-sm-10 px-2 py-0  d-flex align-items-center  churn_report_date_picker" >

                                            <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='churn_report_start_date'>
                                                <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                                <div className="churn_report_vl pr-2" > <b className="pl-1" style={{ color: 'red' }}>*</b></div>
                                            </label>

                                            <div className='col  p-0 py-1 pr-0'>
                                                
                                            <DatePicker
                                                closeOnSelect={true}
                                                isValidDate={(current) => {
                                                    const role = get('role_codes');
                                                    if (role !== 'investor') {
                                                        return current.isSameOrBefore(moment().endOf('month'), 'month');
                                                    }
                                                    return current.isBefore(moment().startOf('month'), 'month');
                                                }} 
                                            value={ month.from ? moment(month.from).format('MMMM YYYY'):''}  onChange={(value)=>monthHandler(  moment(value).format("YYYY-MM-DD"),"from")}  inputProps={{ id: 'churn_report_start_date', name: 'Referral_from',autoComplete:'off' }}  dateFormat="MMMM yyyy" 
                                            showMonthYearPicker timeFormat={false} required={false}/>
                                             
                                            </div>
                            </div>
                            {/* <input className='monthly_report_input' type="month" value={month.from} max={max_month}  onChange={(e)=>monthHandler(e.target.value,"from")}/> */}
                            <p className='text-white no-margin' >V/S</p>
                            <div className="form-group monthly_report_input-lg col-md-4 col-lg-4 col-sm-10 px-2 py-0  d-flex align-items-center  churn_report_date_picker" >

                                <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='churn_report_end_date'>
                                    <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                    <div className="churn_report_vl pr-2" > <b className="pl-1" style={{ color: 'red' }}>*</b></div>
                                </label>

                                <div className='col  p-0 py-1 pr-0 '>
                                    
                                <DatePicker closeOnSelect={true} 
                                isValidDate={(current) => {
                                    const role = get('role_codes');
                                    if (role !== 'investor') {
                                        return current.isSameOrBefore(moment().endOf('month'), 'month');
                                    }
                                    return current.isBefore(moment().startOf('month'), 'month');
                                }} 
                                value={ month.to ? moment(month.to).format('MMMM YYYY'):''}  onChange={(value)=>monthHandler(  moment(value).format("YYYY-MM-DD"),"to")}  inputProps={{ id: 'churn_report_start_date churn_report_end_date', name: 'Referral_from',autoComplete:'off' }}  dateFormat="MMMM yyyy" 
                                showMonthYearPicker timeFormat={false} required="required"/>
                                
                                </div>
                                </div>
                            {/* <input className='monthly_report_input' type="month" max={month.from} value={month.to} onChange={(e)=>monthHandler(e.target.value,"to")} style={{marginRight:'20px'}}/> */}
                            <div className='mt-3'  >
                            <button type="button" class="btn btn-info  align-items-center no-margin " onClick={()=>submitHandler()} style={{padding:'7px 18px'}}>Submit</button>

                            </div>
                        </div>
                    </div>{console.log('country.country_code', country.country_code)}
                    <div className='col-md-12 col-sm-12 col-lg-6 mt-sm-3 mt-lg-0 no-padding monthly_report_currency_selector' >
                       <div className='d-flex w-100 justify-content-end align-items-center'>
                           {country.country_code != "*"  &&
                               <> 
                                   {/* <div className='d-flex align-items-center pr-1' >

                                       <p className='no-margin text-white'
                                          style={{fontWeight: 600}}>{activeCurrency.active_currency_val === "" ? "" : `1 ${activeCurrency.active_currency} =`}&nbsp;</p>
                                       <p className='no-margin text-white'
                                          style={{fontWeight: 600}}>{activeCurrency.active_currency_val === "" ? "" : Number(1 / activeCurrency.active_currency_val).toFixed(0)}&nbsp;{activeCurrency.active_currency_val === "" ? "" : currencyDetails[country.country_code].currency_code}</p>
                                   </div> */}
                                     <div
                                        className="no-margin text-white d-flex justify-content-center align-items-center pr-1"
                                        style={{ fontWeight: 600 }}
                                        >
                                        {activeCurrency.active_currency_val !== "" && (
                                            <>
                                            <div className="no-margin text-white" style={{ fontWeight: 600 }}>
                                                {`1 ${activeCurrency.active_currency} =`}
                                            </div>
                                            &nbsp;
                                            <div>
                                                {Number(1 / activeCurrency.active_currency_val).toFixed(0)}&nbsp;
                                                {currencyDetails[country.country_code]?.currency_code}
                                            </div>
                                            </>
                                        )}
                                    </div>
                                   <ul className='monthly_report_currencySelector no-padding no-margin justify-content-end align-items-center'>
                                       {conversion_currencies.map((val, idx) => {
                                           return (

                                               <li className={activeCurrency.active_currency === val ? ' active-currency' : " "}
                                                   style={{cursor: cursorPointerStatus ? "pointer" : 'default'}}
                                                   onClick={() => setCurrencyHandler(val)}>
                                                   <p className='no-margin'>{val}</p>
                                               </li>


                                           )
                                       })}

                                       <li className={activeCurrency.active_currency === currentCountryactiveCurrency.active_currency ? ' border_right active-currency' : " border_right"}
                                           style={{cursor: cursorPointerStatus ? "pointer" : 'default'}}
                                           onClick={() => setCurrencyHandler(currentCountryactiveCurrency.active_currency)}>
                                           <p className='no-margin'>{currentCountryactiveCurrency.active_currency}</p>
                                       </li>
                                   </ul>
                               </>
                           }

                           <div className='px-1 pt-1 d-flex justify-content-end'  >
                            <CSVLink   headers={csvData ? csvData.headers : []} data={csvData ? csvData.data : []} filename={csvData ? csvData.filename : ''} onClick ={()=>getCsvData()} className='btn-std' style={{borderRadius:5,border:'none',backgroundColor:'#fff',marginLeft:'10px'}}>Export as CSV</CSVLink>
                           </div>
                            
                            {/* <div className='no-padding d-flex justify-content-end'>                                         
                            {window.location.pathname === '/monthly-report/print' ?
                                <div>
                                    <button className='btn-std' style={{borderRadius:5,border:'none',backgroundColor:'#fff',marginLeft:'10px'}} onClick={()=>window.print()}>Export to PDF</button>
                                    <Link to={"/monthly-report"} className='btn-std' style={{marginLeft:15,borderRadius:5,border:'none',backgroundColor:'#fff'}}>Back</Link>
                                </div>
                                 :
                                <Link className='btn-std' style={{marginLeft:15,borderRadius:5,border:'none',backgroundColor:'#fff'}} to={"/monthly-report/print"}>Export To PDF</Link>                                                                    
                            }                            
                        </div>  */}
                      </div>
                    </div>
                </div>
                <div className='row no-margin  d-flex justify-content-between   '>
                    <div className='col-md-6 col-sm-12 col-lg-5 no-padding'>
                    <div className='d-flex align-items-center    mx-0 p-0' style={{marginTop:'20px'}}>
                              {marketList?.length>1 &&
                               <div className='d-flex align-items-center    mx-0 p-0'>
                                <div className='col-1 px-0'>
                                    <label className='no-margin'>Market</label>    
                                </div>                                                                           
                                <div  className="dropdown country_dropDownbg  col-4 ml-5 px-0 "  >
                                    <div className="dropdown-toggle py-1 country_list_drop_down d-flex align-items-center dropdown_trig justify-content-between" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                    {country.country_code !="*" ? 
                                      <>
                                      {/* <Flag id="market" name={country.country_code} format="png" pngSize={48} shiny={false} alt={country.country_code} basePath="/img/flags"/> */}
                                      <CustomFlag country_code={country.country_code} height={30} width={30} alt={country.country_code}/>

                                      <span >{country.country ? country.country : ((current_country !=null) ? current_country[0].country :'')}</span>
                                      <span><i className="fa-solid fa-chevron-down"></i></span>
                                      </> 
                                      :
                                      <>
                                      <IoIosGlobe size={20} style={{margin:'4px 0px'}}/>
                                      <span>{country.country}</span>
                                      <span><i className="fa-solid fa-chevron-down"></i></span>

                                      </>
                                     }
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{width:200,zIndex:1}}>
                                        <li className="country_selector" onClick={()=>countrySelecthandler({country:"Global",country_code:"*",currency_code:"USD"})}>
                                            <IoIosGlobe size={20} style={{margin:'4px 0px'}}/>
                                            <span style={{paddingLeft:15}}>Global</span>
                                        </li>
                                        { marketList && marketList.map((each,idx)=>
                                            <li key={idx} className="country_selector" onClick={()=>countrySelecthandler(each)}>                                    
                                                {/* <Flag id="market" name={each.country_code} format="png" pngSize={48} shiny={false} alt={each.country_code} basePath="/img/flags"/> */}
                                            <CustomFlag country_code={each.country_code} height={30} width={30} alt={each.country_code}/>

                                                <span style={{paddingLeft:5}}>{each.country}</span>
                                            </li>
                                        )}                            

                                    </ul>                        
                                </div>
                                </div>}

                                {country.country_code!='*' && get('role_codes')!=="investor" && show_lender &&
                                <div className='ml-4 col-4'>
                                    <LenderCode country_code={country.country_code} onChange = {(res)=>handleLenderCode(res) }/>
                                </div>}
                     </div>
                    </div>
                    {allAccPrvdr?.length>1 &&
                    <div className='col-md-6 col-sm-12 col-lg-6 no-padding '>
                        {country.country_code !="*" && lender == null &&
                        <div className='d-flex justify-content-end align-items-center '>
                                <label className='no-margin' >Provider</label>     
                                <button className={`filterBtns allBtn_size ${accPrvdr === null  ? "" : "opac_50"}`} style={{height:30,width:60,cursor : cursorPointerStatus ? "pointer" : 'default',marginRight:15}} key={0} onClick={()=>prvdrSelectHandler(null)}>ALL</button>					               
                                {allAccPrvdr.map((each,idx)=>                                    
                                    <div style={{height:30,width:60,cursor : cursorPointerStatus ? "pointer" : 'default',marginRight:'10px'}} className={accPrvdr === null || accPrvdr === each ? "" : "opac_50"} key={idx} onClick={()=>prvdrSelectHandler(each)}>			
                                        <img  className="img-fluid" src={`/img/${each}_logo_color.png`} alt={each}/>	
                                    </div>
                                )}                                                    
                        </div> 
                        }
                    </div>
                    }

                </div>
               { showResults  && filteredCountrydata.length>0 ?
                <>
                <div className='row no-margin'>
                <div className='col-md-12 col-sm-12 col-lg-5 no-padding '>
                    <div className='monthly_report_business_volume h-100'>
                        <div className='d-flex align-items-center monthly_report_busiess_volume_innerheader'>
                            <img src={`/img/businessvolume.png`} style={{height:'27px'}} alt='portfilo'></img>
                            <h2 className='text-white no-margin' style={{paddingLeft:'10px'}}>Business Volume</h2>
                        </div>
                    <div className='row no-margin'style={{padding:'20px 0 '}}>
                        {accPrvdr === null && lender === null &&
                        <div class='col-md-6'>
                                    <div className='business_volume_blueborder' >
                                            <div className='d-flex  monthly-report-churnrate' >
                                                <div className='d-flex'>
                                                    <p className='text-white ' style={{fontSize:'15px',marginRight:'20px'}}>Total Invested Amount</p>
                                                </div>
                                                <RoundOffSplitter val={<p className='no-margin' style={{fontSize:'15px'}} > Amount invested in the lending business for the current month.</p>} unit={""} align_center={true} position={"top"} notify={true} />
                                            </div> 
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex roundoffvalue'>
                                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].tot_inves_amount.value :  (filteredCountrydata[0].tot_inves_amount.value * activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"top"}/>
                                                </div>
                                                {filteredCountrydata[0].tot_inves_amount.vs_value!=0 && filteredCountrydata[0].tot_inves_amount.vs_value &&
                                                <RoundOffSplitter  from='monthly' currency={activeCurrency.active_currency} vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].tot_inves_amount.vs_value :  (filteredCountrydata[0].tot_inves_amount.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].tot_inves_amount.value-filteredCountrydata[0].tot_inves_amount.vs_value)/filteredCountrydata[0].tot_inves_amount.vs_value)*100)} unit={'%'} position={"top"}  />                             
                                                }
                                            </div>           
                                    </div>
                            </div> 
                        }
                        <div class={`${(accPrvdr === null && lender === null) ? "col-md-6" : "col-md-12"}`}>
                                    <div className='business_volume_blueborder' >
                                            <div className='d-flex  monthly-report-churnrate' >
                                                <div className='d-flex'>
                                                    <p className='text-white ' style={{fontSize:'16px'}}>Loans Settled</p>
                                                </div>
                                            </div> 
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex roundoffvalue'>
                                                    <b><p className='no-margin text-white'>{filteredCountrydata[0].tot_fa_settled_count.value} </p></b> 
                                                    {/* <RoundOffSplitter val={filteredCountrydata[0].tot_fa_settled_count.value} unit={''} position={"top"} style={{color:'white'}} /> */}
                                                </div>
                                                {filteredCountrydata[0].tot_fa_settled_count.vs_value!=0 && filteredCountrydata[0].tot_fa_settled_count.vs_value &&
                                                    <RoundOffSplitter   from='monthly'  vs_value={filteredCountrydata[0].tot_fa_settled_count.vs_value} val={(((filteredCountrydata[0].tot_fa_settled_count.value-filteredCountrydata[0].tot_fa_settled_count.vs_value)/filteredCountrydata[0].tot_fa_settled_count.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}}  />
                                                }
                                            </div>           
                                    </div>
                            </div> 
                        {switch_data &&
                        <>
                            <div className="col-md-12" >
                                <div className='business_volume_blueborder d-flex' style={{paddingLeft:'0px'}}>
                                    <div className=' col-md-6 boderSeprator' style={{borderColor:'rgba(84, 135, 156, 1)'}}>
                                        <p className='text-white ' style={{fontSize:'16px',}}>FA Revenue</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].revenue.value :  (filteredCountrydata[0].revenue.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                            </div>  
                                            {filteredCountrydata[0].revenue.vs_value!=0 && filteredCountrydata[0].revenue.vs_value &&
                                                <RoundOffSplitter from='monthly' currency={activeCurrency.active_currency}   vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].revenue.vs_value :  (filteredCountrydata[0].revenue.vs_value *activeCurrency.active_currency_val)}  val={(((filteredCountrydata[0].revenue.value-filteredCountrydata[0].revenue.vs_value)/filteredCountrydata[0].revenue.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                            }
                                        </div>                           
                                    </div>
                                    <div className='col-md-6' >
                                        <p className='text-white' style={{fontSize:'16px',}}>Switch Revenue{switch_indicate && (<span style={{ fontSize:'10px', color: 'rgb(206, 206, 206)' }}> (Uganda)</span>)}</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].sw_revenue.value :  (filteredCountrydata[0].sw_revenue.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                            </div>  
                                            {filteredCountrydata[0].sw_revenue.vs_value!=0 && filteredCountrydata[0].sw_revenue.vs_value &&
                                                <RoundOffSplitter from='monthly' currency={activeCurrency.active_currency}   vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].sw_revenue.vs_value :  (filteredCountrydata[0].sw_revenue.vs_value *activeCurrency.active_currency_val)}  val={(((filteredCountrydata[0].sw_revenue.value-filteredCountrydata[0].sw_revenue.vs_value)/filteredCountrydata[0].sw_revenue.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                            }
                                        </div>                           
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className='business_volume_blueborder d-flex' style={{paddingLeft:'0px'}}>
                                    <div className=' col-md-6 boderSeprator' style={{borderColor:'rgba(84, 135, 156, 1)'}}>
                                        <p className='text-white ' style={{fontSize:'16px',}}>FA Disbursed Count</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                                <b><p className='no-margin text-white'>{filteredCountrydata[0].tot_disb_count.value} </p></b>
                                                {/* <RoundOffSplitter val={''} unit={''} position={"top"} style={{color:'white'}} /> */}
                                            </div>
                                                {filteredCountrydata[0].tot_disb_count.vs_value!=0 && filteredCountrydata[0].tot_disb_count.vs_value &&
                                                    <RoundOffSplitter from='monthly' vs_value={ filteredCountrydata[0].tot_disb_count.vs_value} val={(((filteredCountrydata[0].tot_disb_count.value-filteredCountrydata[0].tot_disb_count.vs_value)/filteredCountrydata[0].tot_disb_count.vs_value)*100)} unit={'%'} position={"top"}  />
                                                }
                                        </div>                        
                                    </div>
                                    <div className='col-md-6' >
                                        <p className='text-white ' style={{fontSize:'15px',}}>Amount Disbursed</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                                {/* <RoundOffMonth  data={filteredCountrydata[0]} type='tot_disb_val' unit={activeCurrency.active_currency}  activeCurrency={activeCurrency}  /> */}
                                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].tot_disb_val.value :  (filteredCountrydata[0].tot_disb_val.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"top"}/>
                                            </div>
                                            {filteredCountrydata[0].tot_disb_val.vs_value!=0 && filteredCountrydata[0].tot_disb_val.vs_value &&
                                                //   {/* <RoundOffMonth  data={filteredCountrydata[0]} type='tot_disb_val' vs_val={true}  unit='%' activeCurrency={activeCurrency}  /> */}
                                                <RoundOffSplitter  from='monthly' currency={activeCurrency.active_currency} vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].tot_disb_val.vs_value :  (filteredCountrydata[0].tot_disb_val.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].tot_disb_val.value-filteredCountrydata[0].tot_disb_val.vs_value)/filteredCountrydata[0].tot_disb_val.vs_value)*100)} unit={'%'} position={"top"}  />                             
                                            }
                                        </div>                          
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className='business_volume_blueborder d-flex' style={{paddingLeft:'0px'}}>
                                    <div className=' col-md-6 boderSeprator' style={{borderColor:'rgba(84, 135, 156, 1)'}}>
                                        <p className='text-white ' style={{fontSize:'16px',}}>Average FA Loan Amount                                    </p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].avg_fa_amount.value :  (filteredCountrydata[0].avg_fa_amount.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                            </div>
                                                {filteredCountrydata[0].avg_fa_amount.vs_value!=0 && filteredCountrydata[0].avg_fa_amount.vs_value &&
                                                    <RoundOffSplitter from='monthly' currency={activeCurrency.active_currency}   vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].avg_fa_amount.vs_value :  (filteredCountrydata[0].avg_fa_amount.vs_value *activeCurrency.active_currency_val)}  val={(((filteredCountrydata[0].avg_fa_amount.value-filteredCountrydata[0].avg_fa_amount.vs_value)/filteredCountrydata[0].avg_fa_amount.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                                }
                                        </div>                           
                                    </div>
                                    <div className='col-md-6' >
                                        <p className='text-white ' style={{fontSize:'15px',}}>Average Switch Amount{switch_indicate && (<span style={{ fontSize:'10px', color: 'rgb(206, 206, 206)' }}> (Uganda)</span>)}</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].avg_sw_amount.value :  (filteredCountrydata[0].avg_sw_amount.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"top"}/>
                                            </div>
                                            {filteredCountrydata[0].avg_sw_amount.vs_value!=0 && filteredCountrydata[0].avg_sw_amount.vs_value &&
                                                <RoundOffSplitter  from='monthly' currency={activeCurrency.active_currency} vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].avg_sw_amount.vs_value :  (filteredCountrydata[0].avg_sw_amount.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].avg_sw_amount.value-filteredCountrydata[0].avg_sw_amount.vs_value)/filteredCountrydata[0].avg_sw_amount.vs_value)*100)} unit={'%'} position={"top"}  />                             
                                            }
                                        </div>                          
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" >
                                <div className='business_volume_blueborder d-flex' style={{paddingLeft:'0px'}}>
                                    <div className=' col-md-6 boderSeprator' style={{borderColor:'rgba(84, 135, 156, 1)'}}>
                                        <p className='text-white ' style={{fontSize:'16px',}}>FA Gross Transaction</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                                <RoundOffSplitter  val={!activeCurrency.currency_change ? filteredCountrydata[0].gross_txn_val.value :  (filteredCountrydata[0].gross_txn_val.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                            </div>  
                                            {filteredCountrydata[0].gross_txn_val.vs_value!=0 && filteredCountrydata[0].gross_txn_val.vs_value &&

                                                <RoundOffSplitter   from='monthly' currency={activeCurrency.active_currency}  vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].gross_txn_val.vs_value :  (filteredCountrydata[0].gross_txn_val.vs_value *activeCurrency.active_currency_val)}  val={(((filteredCountrydata[0].gross_txn_val.value-filteredCountrydata[0].gross_txn_val.vs_value)/filteredCountrydata[0].gross_txn_val.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                            }
                                        </div>                            
                                    </div>
                                    <div className='col-md-6' >{console.log(filteredCountrydata[0].sw_gross_txn_val.vs_value)}
                                        <p className='text-white ' style={{fontSize:'15px',}}>Switch Gross Transaction {switch_indicate && (<span style={{ fontSize:'10px', color: 'rgb(206, 206, 206)' }}> (Uganda)</span>)}</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                                {/* <RoundOffMonth  data={filteredCountrydata[0]} type='tot_disb_val' unit={activeCurrency.active_currency}  activeCurrency={activeCurrency}  /> */}
                                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].sw_gross_txn_val.value :  (filteredCountrydata[0].sw_gross_txn_val.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"top"}/>
                                            </div>
                                            {filteredCountrydata[0].sw_gross_txn_val.vs_value!=0 && filteredCountrydata[0].sw_gross_txn_val.vs_value &&
                                                //   {/* <RoundOffMonth  data={filteredCountrydata[0]} type='tot_disb_val' vs_val={true}  unit='%' activeCurrency={activeCurrency}  /> */}
                                                <RoundOffSplitter  from='monthly' currency={activeCurrency.active_currency} vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].sw_gross_txn_val.vs_value :  (filteredCountrydata[0].sw_gross_txn_val.vs_value *activeCurrency.sw_gross_txn_val)} val={(((filteredCountrydata[0].sw_gross_txn_val.value-filteredCountrydata[0].sw_gross_txn_val.vs_value)/filteredCountrydata[0].sw_gross_txn_val.vs_value)*100)} unit={'%'} position={"top"}  />                             
                                            }
                                        </div>                          
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                        {!switch_data &&
                        <>
                            <div class="col-md-6">
                                    <div className='business_volume_blueborder' >
                                    <p className='text-white ' style={{fontSize:'15px',}}>Amount Disbursed</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                            {/* <RoundOffMonth  data={filteredCountrydata[0]} type='tot_disb_val' unit={activeCurrency.active_currency}  activeCurrency={activeCurrency}  /> */}
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].tot_disb_val.value :  (filteredCountrydata[0].tot_disb_val.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"top"}/>
                                            </div>
                                            {filteredCountrydata[0].tot_disb_val.vs_value!=0 && filteredCountrydata[0].tot_disb_val.vs_value &&
                                        //   {/* <RoundOffMonth  data={filteredCountrydata[0]} type='tot_disb_val' vs_val={true}  unit='%' activeCurrency={activeCurrency}  /> */}
                                        <RoundOffSplitter  from='monthly' currency={activeCurrency.active_currency} vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].tot_disb_val.vs_value :  (filteredCountrydata[0].tot_disb_val.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].tot_disb_val.value-filteredCountrydata[0].tot_disb_val.vs_value)/filteredCountrydata[0].tot_disb_val.vs_value)*100)} unit={'%'} position={"top"}  />                             
                                            }
                                        </div>
                                    </div>
                                    <div className='business_volume_blueborder' >
                                    <p className='text-white ' style={{fontSize:'16px',}}>FA Disbursed Count</p>
                                    <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                            <b><p className='no-margin text-white'>{filteredCountrydata[0].tot_disb_count.value} </p></b>
                                            {/* <RoundOffSplitter val={''} unit={''} position={"top"} style={{color:'white'}} /> */}
                                            </div>
                                            {filteredCountrydata[0].tot_disb_count.vs_value!=0 && filteredCountrydata[0].tot_disb_count.vs_value &&
                                            <RoundOffSplitter from='monthly' vs_value={ filteredCountrydata[0].tot_disb_count.vs_value} val={(((filteredCountrydata[0].tot_disb_count.value-filteredCountrydata[0].tot_disb_count.vs_value)/filteredCountrydata[0].tot_disb_count.vs_value)*100)} unit={'%'} position={"top"}  />
                                            }
                                            </div>
                                    </div>
                                    <div className='business_volume_blueborder '>
                                        <p className='text-white ' style={{fontSize:'16px',}}>Average FA Loan Amount</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                            <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].avg_fa_amount.value :  (filteredCountrydata[0].avg_fa_amount.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                            </div>
                                            {filteredCountrydata[0].avg_fa_amount.vs_value!=0 && filteredCountrydata[0].avg_fa_amount.vs_value &&
                                                <RoundOffSplitter from='monthly' currency={activeCurrency.active_currency}   vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].avg_fa_amount.vs_value :  (filteredCountrydata[0].avg_fa_amount.vs_value *activeCurrency.active_currency_val)}  val={(((filteredCountrydata[0].avg_fa_amount.value-filteredCountrydata[0].avg_fa_amount.vs_value)/filteredCountrydata[0].avg_fa_amount.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                            }</div>                           
                                    </div>
                            </div>
                            <div class="col-md-6">
                                    <div className='business_volume_blueborder' >
                                    <p className='text-white ' style={{fontSize:'16px',}}>FA Gross Transaction</p>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex roundoffvalue'>
                                        <RoundOffSplitter  val={!activeCurrency.currency_change ? filteredCountrydata[0].gross_txn_val.value :  (filteredCountrydata[0].gross_txn_val.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                        </div>  
                                        {filteredCountrydata[0].gross_txn_val.vs_value!=0 && filteredCountrydata[0].gross_txn_val.vs_value &&

                                            <RoundOffSplitter   from='monthly' currency={activeCurrency.active_currency}  vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].gross_txn_val.vs_value :  (filteredCountrydata[0].gross_txn_val.vs_value *activeCurrency.active_currency_val)}  val={(((filteredCountrydata[0].gross_txn_val.value-filteredCountrydata[0].gross_txn_val.vs_value)/filteredCountrydata[0].gross_txn_val.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                        }</div>  
                                    </div>
                                    <div className='business_volume_blueborder' >
                                    <p className='text-white ' style={{fontSize:'16px',}}>FA Revenue</p>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex roundoffvalue'>
                                        <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].revenue.value :  (filteredCountrydata[0].revenue.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                        </div>  
                                        {filteredCountrydata[0].revenue.vs_value!=0 && filteredCountrydata[0].revenue.vs_value &&

                                            <RoundOffSplitter from='monthly' currency={activeCurrency.active_currency}   vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].revenue.vs_value :  (filteredCountrydata[0].revenue.vs_value *activeCurrency.active_currency_val)}  val={(((filteredCountrydata[0].revenue.value-filteredCountrydata[0].revenue.vs_value)/filteredCountrydata[0].revenue.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                        }
                                    </div>                           
                                </div>                                
                            </div>
                        </>
                        }
                    </div>
                    </div>
                </div>
                <div className='col-md-12 col-sm-12 col-lg-7 no-padding monthly_report_porfolio'>
                    <div className='monthly_report_business_volume h-100' style={{marginLeft:'15px'}}>
                        <div className='d-flex align-items-center  monthly_report_busiess_volume_innerheader'>
                            <img src={`/img/portfolioperformance.png`} style={{width:'27px'}} alt='portfilo'></img>
                            <h2 className='text-white no-margin ' style={{paddingLeft:'10px'}}>Portfolio Performance </h2>
                        </div>
                        <div className='row no-margin'style={{padding:'10px 0'}}>
                            <div className='col-md-7 mt-4'>
                                <div  className='monthly_report_outstanding_Figures  ' style={{overflow:'scroll'}}>
                                <p className='text-white no-margin' style={{fontSize:'17px',padding:' 10px 0px 0px 10px'}}>Outstanding Figures <p className='text-white no-margin' style={{fontSize:'15px'}} >( as on {moment(month.from).format("MM Y") ==moment().format("MM Y") ? reportdate: "last day of " +new Date(month.from).toLocaleDateString(undefined, { month: 'long'})} <span>)</span></p>
                                </p>
                                <table className='monthly_report_outstanding_table ' style={{background:'#20233f',width:'100%'}}>
                                        <thead className='no-margin' style={{paddingBottom:'0px',lineHeight:'initial',background:'#20233f'}}>
                                            <tr className='no-margin'>
                                            <th scope="col"></th>
                                            <th scope="col" style={{color:'white'}}>Count</th>
                                            <th scope="col" style={{color:'white'}} >Amount</th>
                                            <th scope="col" style={{color:'white'}}>Fee</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{background:'#353650'}}>
                                            <th scope="row" style={{color:'#bec2c2',paddingLeft:'10px'}}>Outstanding</th>
                                            <td style={{padding:'10px'}}>
                                            < div className='roundoffvalue' >
                                             {/* <RoundOffSplitter val={filteredCountrydata[0].os_count_eom.value} unit={''} align_center={false} position={"top"}/> */}
                                            <p className='text-white no-margin'>{filteredCountrydata[0].os_count_eom.value}</p>
                                             </div>
                                            {filteredCountrydata[0].os_count_eom.vs_value!=0 && filteredCountrydata[0].os_count_eom.vs_value &&

                                                <RoundOffSplitter from='monthly'    vs_value={ filteredCountrydata[0].os_count_eom.vs_value} val={(((filteredCountrydata[0].os_count_eom.value-filteredCountrydata[0].os_count_eom.vs_value)/filteredCountrydata[0].os_count_eom.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                            }</td>
                                            <td> 
                                             <div className='roundoffvalue' >
                                                <RoundOffSplitter  val={!activeCurrency.currency_change ? filteredCountrydata[0].os_val_eom.value :  (filteredCountrydata[0].os_val_eom.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                            </div>
                                            {filteredCountrydata[0].os_val_eom.vs_value!=0 && filteredCountrydata[0].os_val_eom.vs_value &&

                                                <RoundOffSplitter from='monthly'  currency={activeCurrency.active_currency}  vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].os_val_eom.vs_value :  (filteredCountrydata[0].os_val_eom.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].os_val_eom.value-filteredCountrydata[0].os_val_eom.vs_value)/filteredCountrydata[0].os_val_eom.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                            }</td>
                                            <td> 
                                            <div className='roundoffvalue' >
                                                <RoundOffSplitter   val={!activeCurrency.currency_change ? filteredCountrydata[0].os_fee_eom.value :  (filteredCountrydata[0].os_fee_eom.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency}  align_center={false} position={"left"}/>
                                            </div>
                                            {filteredCountrydata[0].os_fee_eom.vs_value!=0 && filteredCountrydata[0].os_fee_eom.vs_value &&

                                                <RoundOffSplitter from='monthly' currency={activeCurrency.active_currency}  vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].os_fee_eom.vs_value :  (filteredCountrydata[0].os_fee_eom.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].os_fee_eom.value-filteredCountrydata[0].os_fee_eom.vs_value)/filteredCountrydata[0].os_fee_eom.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} />
                                            }</td>
                                            </tr>
                                            {get('role_codes') !== 'investor' &&
                                            <tr style={{background:'#353650'}}>
                                            <th scope="row" style={{color:'#bec2c2',paddingLeft:'10px'}}>Overdue</th>
                                            <td style={{padding:'10px'}}>
                                            <div className='roundoffvalue' >
                                                 {/* <RoundOffSplitter  val={filteredCountrydata[0].od_count.value} unit={''} align_center={false} position={"left"}/> */}
                                            <p className='text-white no-margin'>{filteredCountrydata[0].od_count.value}</p>
                                            </div>
                                             {/* <RoundOffSplitter from='monthly'  check="check"   negativesign={true}  currency={''}   vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].od_count.vs_value :  (filteredCountrydata[0].od_count.vs_value *activeCurrency.active_currency_val)}  val={((filteredCountrydata[0].od_count.value-filteredCountrydata[0].od_count.vs_value)/filteredCountrydata[0].od_count.vs_value)*100} unit={'%'} position={"top"} style={{color:'white'}} /> */}
                                            </td>
                                            <td> 
                                            <div className='roundoffvalue' >
                                             <RoundOffSplitter   val={!activeCurrency.currency_change ? filteredCountrydata[0].od_amount.value :  (filteredCountrydata[0].od_amount.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                             </div>
                                            {/* <RoundOffSplitter from='monthly' negativesign={true} currency={activeCurrency.active_currency}   vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].od_amount.vs_value :  (filteredCountrydata[0].od_amount.vs_value *activeCurrency.active_currency_val)}   val={(((filteredCountrydata[0].od_amount.value-filteredCountrydata[0].od_amount.vs_value)/filteredCountrydata[0].od_amount.vs_value)*100)} unit={'%'} position={"top"} style={{color:'white'}} /> */}
                                            </td>
                                            <td>
                                                <p className='no-margin'></p></td>
                                            </tr>
                                            }
                                            <tr style={{background:'#353650'}}>
                                            <th scope="row" style={{color:'#bec2c2',paddingLeft:'10px'}}>New Overdue</th>
                                            <td style={{padding:'10px'}}> 
                                            <div className='roundoffvalue' >
                                                {/* <RoundOffSplitter  val={filteredCountrydata[0].new_overdue_count.value} unit={''} align_center={false} position={"left"}/> */}
                                            <p className='no-margin text-white'>{filteredCountrydata[0].new_overdue_count.value} </p>
                                             </div>


                                            {/* <RoundOffSplitter from='monthly' negativesign={true}  currency={''}  vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].new_overdue_count.vs_value :  (filteredCountrydata[0].new_overdue_count.vs_value *activeCurrency.active_currency_val)}    val={((filteredCountrydata[0].new_overdue_count.value-filteredCountrydata[0].new_overdue_count.vs_value)/filteredCountrydata[0].new_overdue_count.vs_value)*100}  unit={'%'} position={"top"} style={{color:'white'}} /> */}
                                            </td>
                                            <td>
                                            <div className='roundoffvalue' > 
                                            <RoundOffSplitter   val={!activeCurrency.currency_change ? filteredCountrydata[0].new_overdue_val.value :  (filteredCountrydata[0].new_overdue_val.value *activeCurrency.active_currency_val)}   unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                           </div>
                                            {/* <RoundOffSplitter from='monthly' negativesign={true} currency={activeCurrency.active_currency}  vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].new_overdue_val.vs_value :  (filteredCountrydata[0].new_overdue_val.vs_value *activeCurrency.active_currency_val)}  val={(((filteredCountrydata[0].new_overdue_val.value-filteredCountrydata[0].new_overdue_val.vs_value)/filteredCountrydata[0].new_overdue_val.vs_value)*100)}  unit={'%'} position={"top"} style={{color:'white'}} /> */}
                                            </td>
                                            <td> 
                                                <p></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                </div>
                            </div>
                            <div className='col-md-5 ' style={{alignSelf:'center'}} >
                                 <div className='month_report_impact '>
                                 <h2 className='text-white' style={{fontSize:'20px'}}>Portfolio Metrics</h2>
                                 <div style={{margin:'15px 0px'}}>   
                                <div className='d-flex row  justify-content-between' style={{marginBottom:'15px'}}>
                                <div className='d-flex col-7  align-items-center '>
                                    <span className='monthly_report_imact_list' ></span>
                                    <p className='no-margin text-white' > Ontime Repayment Rate</p>
                                </div>
                                <div className='d-flex  col-5  justify-content-between align-items-center roundoffvalue'>
                                    <RoundOffSplitter val={(filteredCountrydata[0].ontime_repayment_rate.value)*100} unit={filteredCountrydata[0].ontime_repayment_rate.unit} position={"top"} style={{color:'white'}} />
                                </div>
                                </div>
                                <div className='d-flex row justify-content-between' style={{marginBottom:'15px'}}>
                                <div className='d-flex  col-7 align-items-center'>
                                    <span className='monthly_report_imact_list' ></span>
                                    <p className='no-margin text-white ' > Fee earned per FA</p>
                                </div>
                                <div className='d-flex col-5 align-items-center roundoffvalue'>
                                        <RoundOffSplitter noRoundoff={true} val={!activeCurrency.currency_change ? filteredCountrydata[0].fee_per_fa.value :  (filteredCountrydata[0].fee_per_fa.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                                        </div>
                                </div>
                                <div className='d-flex row justify-content-between' style={{marginBottom:'15px'}}>
                                <div className='d-flex  col-7 align-items-center'>
                                     <span className='monthly_report_imact_list' ></span>           
                                     <p className='no-margin text-white ' >  Fee earned per <br/>customer</p>
                                </div>
                                <div className='d-flex col-5 align-items-center roundoffvalue '>
                                <RoundOffSplitter noRoundoff={true} val={!activeCurrency.currency_change ? filteredCountrydata[0].fee_per_cust.value :  (filteredCountrydata[0].fee_per_cust.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency}  />
                                </div>
                                </div>
                                <div className='d-flex row'>
                                <div className='d-flex align-items-center col-7'>
                                    <span className='monthly_report_imact_list' ></span>       
                                    <p className='no-margin text-white' >New Overdue Rate</p>
                                    <div style={{color:'#4bb6e6',fontSize:'18px',marginLeft:'10px'}}>
                                         <RoundOffSplitter  val={<div className='no-padding no-margin'>
                                             <p className='no-margin no-padding'>Number Of NewOverdues</p>
                                             <hr className='no-margin no-paddon' style={{borderBottom:'1px solid green'}}/>
                                             <p className='no-margin no-padding'>Total FAs due this month</p></div>} unit={''} align_center={true} position={"bottom"} notify={true}/>    
                                    </div>    
                                </div>
                                <div className='d-flex justify-content-between align-items-center col-5 row'>
                                <div className='d-flex align-items-center col-md-6 roundoffvalue'>
                                    <RoundOffSplitter val={(filteredCountrydata[0].due_perc.value)*100} unit={filteredCountrydata[0].due_perc.unit} position={"top"} style={{color:'white'}} />
                                </div>
                                <div className='col-md-6'>
                                {filteredCountrydata[0].due_perc.vs_value!=0 && filteredCountrydata[0].due_perc.vs_value &&

                                    <RoundOffSplitter from='monthly' negativesign={false} vs_value={filteredCountrydata[0].due_perc.vs_value} val={((filteredCountrydata[0].due_perc.value-filteredCountrydata[0].due_perc.vs_value)*100)} unit={filteredCountrydata[0].due_perc.unit} position={"top"} style={{color:'white'}} />
                               
                                }</div>
                                
                                </div>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
               <div className='monthly_report_business_volume ' style={{marginTop:'50px'}}>
                <div className='d-flex align-items-center monthly_report_busiess_volume_innerheader'>
                     <img src={`/img/customermetrics.png`} style={{width:'27px'}} alt='portfilo'></img>
                     <h2 className='text-white no-margin' style={{paddingLeft:'10px'}} >Customer Metrics</h2>
                </div>
                <div className='row no-margin'>
                 <div className='col-sm-12 col-lg-6 col-md-6'>
                     <div style={{padding:'20px 15px 10px'}} >
                        <label>Registered Customers</label>
                        <div className="progress" style={{height:'7px '}}> 
                          <div className="progress-bar progress-bar-striped "  role="progressbar" style={{width:'100%',background:'#fd209b'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                                    <div className='roundoffvalue'>
                                    {/* <RoundOffSplitter val={filteredCountrydata[0].cust_reg_count.value} unit={''} position={"top"} style={{color:'white'}} /> */}
                                 <p className='no-margin text-white'>{filteredCountrydata[0].cust_reg_count.value}</p>
                                    </div>
                                {filteredCountrydata[0].cust_reg_count.vs_value!=0 && filteredCountrydata[0].cust_reg_count.vs_value &&

                                    <RoundOffSplitter from='monthly'  vs_value={filteredCountrydata[0].cust_reg_count.vs_value}   val={(((filteredCountrydata[0].cust_reg_count.value-filteredCountrydata[0].cust_reg_count.vs_value)/filteredCountrydata[0].cust_reg_count.vs_value)*100)} unit={'%'} position={"top"} />
                                
                                }</div>
                     </div>
                     <div style={{padding:'20px 15px 10px'}} >
                        <label>Enabled Customers</label>
                        <div className="progress" style={{height:'7px '}}> 
                          <div className="progress-bar progress-bar-striped "  role="progressbar" style={{width:`${(!isNaN((filteredCountrydata[0].cust_enabled_count.value)/(filteredCountrydata[0].cust_reg_count.value)*100)) ? (filteredCountrydata[0].cust_enabled_count.value)/(filteredCountrydata[0].cust_reg_count.value)*100 : 0}%`,background:'#F4A215'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                                    <div className='roundoffvalue'>
                                 <p className='no-margin text-white'>{filteredCountrydata[0].cust_enabled_count.value}</p>
                                    </div>
                                {filteredCountrydata[0].cust_enabled_count.vs_value!=0 && filteredCountrydata[0].cust_enabled_count.vs_value &&

                                    <RoundOffSplitter from='monthly'  vs_value={filteredCountrydata[0].cust_enabled_count.vs_value}   val={(((filteredCountrydata[0].cust_enabled_count.value-filteredCountrydata[0].cust_enabled_count.vs_value)/filteredCountrydata[0].cust_enabled_count.vs_value)*100)} unit={'%'} position={"top"} />
                               
                                } </div>
                     </div>
                     <div style={{padding:'20px 15px 10px'}} >
                        <div className='d-flex'>
                        <label>FA Active Customers</label>
                            <div style={{color:'#4bb6e6',fontSize:'18px',marginLeft:'10px'}}>
                                <RoundOffSplitter val={"These are customers who have taken an FA in this month"} unit={""} align_center={true} position={"top"} notify={true}/>    
                           </div>  
                        </div>
                        <div className="progress" style={{height:'7px'}}> 
                          <div className="progress-bar progress-bar-striped"  role="progressbar" style={{width:`${(!isNaN((filteredCountrydata[0].cust_active_count.value)/(filteredCountrydata[0].cust_enabled_count.value)*100)) ? (filteredCountrydata[0].cust_active_count.value)/(filteredCountrydata[0].cust_enabled_count.value)*100 : 0}%`,background:'#9bfd1d'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                          </div>
                        </div>
                         <div className='d-flex justify-content-between align-items-center'>
                                    <div className='roundoffvalue'>
                                    {/* <RoundOffSplitter val={filteredCountrydata[0].cust_active_count.value} unit={''} position={"top"} style={{color:'white'}} /> */}
                                   <p className='no-margin text-white'>{filteredCountrydata[0].cust_active_count.value} </p>
                                    </div>
                                {filteredCountrydata[0].cust_active_count.vs_value!=0 && filteredCountrydata[0].cust_active_count.vs_value &&

                                    <RoundOffSplitter from='monthly'   vs_value={ filteredCountrydata[0].cust_active_count.vs_value}  val={(((filteredCountrydata[0].cust_active_count.value-filteredCountrydata[0].cust_active_count.vs_value)/filteredCountrydata[0].cust_active_count.vs_value)*100)} unit={'%'} position={"top"} />
                                }</div>
                     </div>
                     { switch_data &&
                     <div style={{padding:'20px 15px 10px'}} >
                        <label>Switch Customers</label>
                        <div className="progress" style={{height:'7px '}}> 
                          <div className="progress-bar progress-bar-striped "  role="progressbar" style={{width:`${(!isNaN((filteredCountrydata[0].cust_switch_count.value)/(filteredCountrydata[0].cust_active_count.value)*100)) ? (filteredCountrydata[0].cust_switch_count.value)/(filteredCountrydata[0].cust_active_count.value)*100 : 0}%`,background:'rgba(102, 153, 255, 1)'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                                    <div className='roundoffvalue'>
                                 <p className='no-margin text-white'>{filteredCountrydata[0].cust_switch_count.value}</p>
                                    </div>
                                {filteredCountrydata[0].cust_switch_count.vs_value!=0 && filteredCountrydata[0].cust_switch_count.vs_value &&

                                    <RoundOffSplitter from='monthly'  vs_value={filteredCountrydata[0].cust_switch_count.vs_value}   val={(((filteredCountrydata[0].cust_switch_count.value-filteredCountrydata[0].cust_switch_count.vs_value)/filteredCountrydata[0].cust_switch_count.vs_value)*100)} unit={'%'} position={"top"} />
                               
                                } </div>
                     </div>
                    }
                     <div style={{padding:'20px 15px 10px'}} >
                        <div className='row'>
                            <div className='col-12'>
                                <div className='business_volume_blueborder' >
                                        <div className='d-flex justify-content-between monthly-report-churnrate' >
                                            <p className='text-white ' style={{fontSize:'15px'}}>Retention Rate</p>
                                            <RoundOffSplitter val={<div className='no-padding no-margin'>
                                             <p className='no-margin no-padding'>Number of customers who have taken FAs last month and also this month</p>
                                             <hr className='no-margin no-paddon' style={{borderBottom:'1px solid green'}}/>
                                             <p className='no-margin no-padding'>Active customers last month</p></div>} unit={""} align_center={true} position={"top"} notify={true} />

                                        </div>  
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex roundoffvalue'>
                                            <RoundOffSplitter val={(filteredCountrydata[0].cust_retention_perc.value)*100} unit={filteredCountrydata[0].cust_retention_perc.unit} position={"top"} style={{color:'white'}} /> 
                                            </div>
                                        {filteredCountrydata[0].cust_retention_perc.vs_value!=0 && filteredCountrydata[0].cust_retention_perc.vs_value &&

                                            <RoundOffSplitter from='monthly'  negativesign={false} vs_value={filteredCountrydata[0].cust_retention_perc.vs_value*100}   val={(((filteredCountrydata[0].cust_retention_perc.value-filteredCountrydata[0].cust_retention_perc.vs_value)/filteredCountrydata[0].cust_retention_perc.vs_value)*100)} unit={filteredCountrydata[0].cust_retention_perc.unit} position={"top"} />
                                        }</div>
                                </div>
                            </div>
                            {/* <div className='col-4'></div> */}
                        
                         </div>
                     </div>
                 </div>
                 <div className='col-sm-12 col-lg-6 col-md-6 '>
                    <div style={{padding:'130px 25px 0px'}}>
                        <div className='d-flex justify-content-center' style={{position:'relative'}}>
                        <div className='monthly_report_piechart_inner'>
                            <h2 className='text-white monthly_report_piechart_gender'>Gender</h2>
                        </div>
                        <ReactApexChart options={chartcomponents.options} series={[(filteredCountrydata[0].female_perc.value)*100,100-(filteredCountrydata[0].female_perc.value)*100]} type="donut" width={320} />
                        </div>
                        <p className='text-white  monthly_report_piechart_gender' style={{fontSize:'15px',marginTop:'10px'}}>On Registered Customers</p>
                        </div>
                 </div>
                        {/* <div className='col-md-12 col-lg-4 col-sm-12 ' style={{alignSelf:'center'}} >
                            <div className='month_report_impact' >
                             <h2 className='text-white' style={{marginBottom:'20px'}}>Impact</h2>
                          <div className='d-flex justify-content-between row' style={{marginBottom:'15px'}}>
                           <div className='d-flex align-items-center col-7'>
                             <span className='monthly_report_imact_list_circle' style={{background:'#fd209b'}}></span>
                             <p className='no-margin text-white' > Amount of Retail Transactions</p>
                           </div>
                           <div className='d-flex align-items-center col-5 roundoffvalue'>
                           <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].retail_txn_val.value :  (filteredCountrydata[0].retail_txn_val.value *activeCurrency.active_currency_val)}   unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                           </div>
                          </div>
                          <div className='d-flex justify-content-between row' style={{marginBottom:'15px'}}>
                           <div className='d-flex align-items-center col-7'>
                             <span className='monthly_report_imact_list_circle' style={{background:'#047efa'}}></span>
                             <p className='no-margin text-white' > Count of Retail Transactions</p>
                           </div>
                           <div className='d-flex align-items-center roundoffvalue col-5'>
                           <RoundOffSplitter val={filteredCountrydata[0].retail_txn_count.value } unit={''} align_center={false} position={"left"}/>
                           </div>
                          </div>
                          <div className='d-flex justify-content-between row' style={{marginBottom:'15px'}}>
                           <div className='d-flex align-items-center col-5'>
                             <span className='monthly_report_imact_list_circle' style={{background:'#2fd987'}}></span>
                             <p className='no-margin text-white' > Retail Users Benefited</p>
                           </div>
                           <div className='d-flex align-items-center roundoffvalue col-5'>
                           <RoundOffSplitter val={ filteredCountrydata[0].people_benefited.value} unit={''} align_center={false} position={"left"}/>
                           </div>
                          </div>
                          <div className='d-flex justify-content-between row' style={{marginBottom:'15px'}}>
                           <div className='d-flex align-items-center col-7'>
                             <span  className='monthly_report_imact_list_circle' style={{background:'#6342ff'}}></span>
                             <p className='no-margin text-white' >Customer Revenue Generated</p>
                           </div>
                           <div className='d-flex align-items-center roundoffvalue col-5'>
                           <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].revenue_by_small_biz.value :  (filteredCountrydata[0].revenue_by_small_biz.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"left"}/>
                           </div>
                          </div>
                            </div>
                         
                        </div> */}
                </div>
                
               </div>

               <div className='monthly_report_business_volume'>
                <div className='d-flex align-items-center monthly_report_busiess_volume_innerheader'>
                     <img src={`/img/revenue.png`} style={{width:'27px'}} alt='portfilo'></img>
                     <h2 className='text-white no-margin' style={{paddingLeft:'10px'}} >Revenue</h2>
                </div>
                <div className='row no-margin'>

                    <div className='col-md-6' style={{marginTop:'3%'}}>
                        <div className='business_volume_blueborder' >
                            <div className='d-flex justify-content-between monthly-report-churnrate' >
                                <div className='d-flex'>
                                <p className='text-white ' style={{fontSize:'15px',marginRight:'20px'}}>FA Revenue Per Customer ({filteredCountrydata[0].cust_count_for_rev_calc.value} Customers )</p>
                                </div>
                                <RoundOffSplitter val={<p className='no-margin' style={{fontSize:'15px'}} > Average revenue per customer for the {moment(month.from).format("MM Y") ==moment().format("MM Y") ? "current month ( " + moment(reportdate).format("MMM")  + " ) ": "last month ( " + new Date(month.from).toLocaleDateString(undefined, { month: 'long'}) + " )"} <span> </span></p>} unit={""} align_center={true} position={"top"} notify={true} />
                                {/* <RoundOffSplitter  from='monthly'  vs_value={ filteredCountrydata[0].cust_count_for_rev_calc.vs_value } val={(((filteredCountrydata[0].cust_count_for_rev_calc.value-filteredCountrydata[0].cust_count_for_rev_calc.vs_value)/filteredCountrydata[0].cust_count_for_rev_calc.vs_value)*100)} unit={'%'} position={"top"}  />                              */}

                            </div>  
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex roundoffvalue'>
                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].rev_per_cust.value :  (filteredCountrydata[0].rev_per_cust.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"top"}/>
                                </div>
                                {filteredCountrydata[0].rev_per_cust.vs_value!=0 && filteredCountrydata[0].rev_per_cust.vs_value &&
                                
                                <RoundOffSplitter  from='monthly' currency={activeCurrency.active_currency} vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].rev_per_cust.vs_value :  (filteredCountrydata[0].rev_per_cust.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].rev_per_cust.value-filteredCountrydata[0].rev_per_cust.vs_value)/filteredCountrydata[0].rev_per_cust.vs_value)*100)} unit={'%'} position={"top"}  />                             
                                }</div>
                        </div>
                    </div>
                
                    <div className='col-md-6' style={{marginTop:'3%'}}>
                        <div className='business_volume_blueborder' >

                            <div className='d-flex justify-content-between monthly-report-churnrate' >
                                    <div className='d-flex'>
                                    <p className='text-white ' style={{fontSize:'15px',marginRight:'20px'}}>FA Revenue Per {getValueFromLangJson("rm_label_short")} ({filteredCountrydata[0].rm_count_for_rev_calc.value} RMs )</p>
                                    </div>
                                    <RoundOffSplitter val={<p className='no-margin' style={{fontSize:'15px'}} > Average revenue per {getValueFromLangJson("rm_label_short")} for the {moment(month.from).format("MM Y") ==moment().format("MM Y") ? "current month ( " + moment(reportdate).format("MMM")  + " ) ": "last month ( " + new Date(month.from).toLocaleDateString(undefined, { month: 'long'}) + " )"} <span> </span></p>} unit={""} align_center={true} position={"top"} notify={true} />
                                    {/* <RoundOffSplitter  from='monthly'  vs_value={ filteredCountrydata[0].rm_count_for_rev_calc.vs_value } val={(((filteredCountrydata[0].rm_count_for_rev_calc.value-filteredCountrydata[0].rm_count_for_rev_calc.vs_value)/filteredCountrydata[0].rm_count_for_rev_calc.vs_value)*100)} unit={'%'} position={"top"}  />                              */}

                                
                            </div>  
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex roundoffvalue'>
                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].rev_per_rm.value :  (filteredCountrydata[0].rev_per_rm.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"top"}/>
                                </div>
                                {filteredCountrydata[0].rev_per_rm.vs_value!=0 && filteredCountrydata[0].rev_per_rm.vs_value &&

                                <RoundOffSplitter  from='monthly' currency={activeCurrency.active_currency} vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].rev_per_rm.vs_value :  (filteredCountrydata[0].rev_per_rm.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].rev_per_rm.value-filteredCountrydata[0].rev_per_rm.vs_value)/filteredCountrydata[0].rev_per_rm.vs_value)*100)} unit={'%'} position={"top"}  />                             
                                }</div> 
                        </div>
                    </div>
                    { switch_data &&            
                    <div className='col-md-6'>
                        <div className='business_volume_blueborder' >

                            <div className='d-flex justify-content-between monthly-report-churnrate' >
                                    <div className='d-flex'>
                                    <p className='text-white ' style={{fontSize:'15px',marginRight:'20px'}}>Switch Revenue Per Customer ({filteredCountrydata[0].cust_switch_count.value} Customers )</p>
                                    </div>
                                    <RoundOffSplitter val={<p className='no-margin' style={{fontSize:'15px'}} > Average Switch revenue per {getValueFromLangJson("rm_label_short")} for the {moment(month.from).format("MM Y") ==moment().format("MM Y") ? "current month ( " + moment(reportdate).format("MMM")  + " ) ": "last month ( " + new Date(month.from).toLocaleDateString(undefined, { month: 'long'}) + " )"} <span> </span></p>} unit={""} align_center={true} position={"top"} notify={true} />
                                    {/* <RoundOffSplitter  from='monthly'  vs_value={ filteredCountrydata[0].rm_count_for_rev_calc.vs_value } val={(((filteredCountrydata[0].rm_count_for_rev_calc.value-filteredCountrydata[0].rm_count_for_rev_calc.vs_value)/filteredCountrydata[0].rm_count_for_rev_calc.vs_value)*100)} unit={'%'} position={"top"}  />                              */}

                                
                            </div>  
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex roundoffvalue'>
                                <RoundOffSplitter val={!activeCurrency.currency_change ? filteredCountrydata[0].sw_rev_per_cust.value :  (filteredCountrydata[0].sw_rev_per_cust.value *activeCurrency.active_currency_val)} unit={activeCurrency.active_currency} align_center={false} position={"top"}/>
                                </div>
                                {filteredCountrydata[0].sw_rev_per_cust.vs_value!=0 && filteredCountrydata[0].sw_rev_per_cust.vs_value &&

                                <RoundOffSplitter  from='monthly' currency={activeCurrency.active_currency} vs_value={!activeCurrency.currency_change ? filteredCountrydata[0].sw_rev_per_cust.vs_value :  (filteredCountrydata[0].sw_rev_per_cust.vs_value *activeCurrency.active_currency_val)} val={(((filteredCountrydata[0].sw_rev_per_cust.value-filteredCountrydata[0].sw_rev_per_cust.vs_value)/filteredCountrydata[0].sw_rev_per_cust.vs_value)*100)} unit={'%'} position={"top"}  />                             
                                }</div> 
                        </div>
                    </div>
                    } 
                </div>
                
               </div>
              
               </>
               : <div style={{color:'white',textAlign:'center'}}> {filteredCountrydata.length ==0 ? 'No data found' : ' '}</div>
               }
            </div>
              </div>
            
        </div>
    );
   
};

export default MonthlyReport;