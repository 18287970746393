import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal , Button, OverlayTrigger} from 'react-bootstrap';
import { disburseLoan, handleTransactionID, changeDisbursalStatus, cancelCaptureDisb } from '../../../actions/loan_actions';
import { get } from '../../../helpers/storage_helper';
import {getAppUsers} from '../../../actions/common_actions';
//import {getCustomerAccounts} from '../../../actions/common_actions';
import '../../../styles/flow.css';
import { Navigate } from 'react-router-dom';
import {lbl_amt} from '../../../helpers/product_helper';
import {title_case,rt_algn} from '../../../helpers/common_helper';
import {check_priv} from '../../../helpers/storage_helper';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {Tooltip } from 'react-bootstrap';
import FileUpload from '../../common/component/FileUpload';


class DisburseLoan extends FlowComponent {
  state = {
    view_loan: false,
    modalBox: false,
    send_sms: true,
    disable: "disable_div",
    capture_btn: 'disable_div',
    txn_id_style: 'txn_id_style',
    txn_id_style : 'txn_id_style',
    is_skip_trans_id_check:false,
    txn_data : '',
    disbursal_txn :{}
  }

  handleSkipTransIDChekCheckboxClick = (event) => {
    this.setState({is_skip_trans_id_check:event.target.checked}, this.handleTxnId);
  }
  
  handleTxnId(){
    let txn_id = this.disbursal_txn.txn_id;

    // this.setState({txn_data : '', txn_failed_msg : ''});
    if(txn_id.length > 5){

      handleTransactionID(this.req({'loan_doc_id' : this.disbursal_txn.loan_doc_id, 'acc_id' : this.disbursal_txn.from_ac_id ?? this.from_acc?.value, 'txn_id' : txn_id, 'is_skip_trans_id_check' : false, 'mode' : 'debit', 'txn_date' : this.disbursal_txn.txn_date}, true))
      .then((response) => {
        this.resp(response);
        if(!response){return };

          if(response.status === "success"){
              this.setState({capture_btn : '', txn_data :'', txn_failed_msg: '',is_skip_trans_id_check:true});

              if(response.data){
                if(response.data.hasOwnProperty('ignore_check')){
                  this.setState({txn_failed_msg : response.data.message, capture_btn : '',is_skip_trans_id_check:true});
                }
                else if(response.data.hasOwnProperty('message')){
                  this.setState({txn_failed_msg : response.data.message});
                }
                else if(response.data.total_amount == this.disbursal_txn.amount){
                  this.setState({txn_data : response.data.acc_stmt_records, capture_btn : '',is_skip_trans_id_check:false});
                }
                else if(response.data.total_amount != this.disbursal_txn.amount){
                  this.setState({txn_data : response.data.acc_stmt_records,is_skip_trans_id_check:false});      
                }

              }
              else{
                this.setState({txn_failed_msg : 'Please check the transaction ID. No payment received to the account with this transaction ID.'});
              }

          }else{
            alert(response.message + "\n" + JSON.stringify(response.data));  
          }
        }
          );

        }
      }
  disb_attempt_failed_msg =  "##The last instant disbursement attempt for this FA got failed. If you think RETRY instant disbursement can not be done, proceed manual disbursement here.##\n\n";

get_disburse_manually_alert(){

  let alert_msg = "You are going to start the manual disbursement. Follow the below steps always to avoid duplicate disbursement.\n\n1. After you click OK, 'Disburse Manually' screen will open. Don't close this screen during the entire disbursement process.\n2. Send FA to customer manually using web portal, USSD etc., (If you do not know how to do this, discuss with Tech Support)\n3. Capture the disburement details on the 'Disburse Manually' screen.\n\nClick 'Cancel' if you are not ready to do the above steps."; 
  let mnl_disb_acc_prvdr_msg = `##For this Account Provider (${this.props.data.acc_prvdr_code}), Instant Disbursal has not been configured##\n\n`;

  if(this.props.data.is_manual_disb_ap_code == true){
    return mnl_disb_acc_prvdr_msg + alert_msg;
  }
  else{
    return this.disb_attempt_failed_msg + alert_msg;
  }

}

handleDisburseManually = async() => {

  let manual_disb_status = "pending_mnl_dsbrsl";

  if(window.confirm(this.get_disburse_manually_alert())){

    if(this.props.data.status != manual_disb_status  || this.props.data.manual_disb_user_id == null)
    { 
      const request = {loan_doc_id: this.props.data.loan_doc_id, loan_status: manual_disb_status, manual_disb_user_id: get('user_id')}
      changeDisbursalStatus(this.req(request)).then(async(res) => {
        this.resp(res)
        if(res && res.status == 'success') {
          // await this.props.onStatusChange();
          this.handleDisburseClick();
        }
      })
    }
    else{
      this.handleDisburseClick();
    }
  }
}

handleDisburseClick = async(event) => {
  await this.props.onDisburseClick(false);
  const cust_id = this.props.data.cust_id;
  const status="enabled";
  const lender_code = this.props.data.lender_code;
  const network_prvdr_code = this.props.data.acc_prvdr_code;
  let acc_purpose;
  if(this.props.parent == "home"){
    acc_purpose = this.props.data.loan_purpose;
  }else{
    acc_purpose = this.props.data.acc_purpose;
  }

  const customer_account = {cust_id,status,acc_purpose};
  const lender_account = {lender_code, network_prvdr_code, acc_purpose : "disbursement", status, cust_id};
  // const lender_account = {lender_code, acc_purpose : "disbursement", status};

  this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);
  this.setOptionsListToStateFromAppApi("common","customer_accounts", this.req({customer_account}), ["account_id", "acc_num_disp_txt"]);
  // this.setOptionsListToStateFromAppApi("common","disbursers",this.req({status}), ['id' , 'name_email']);
     
  /*const can_disburse = check_priv("loan","disburse");
  this.setState({can_disburse});*/
  this.disbursal_txn = {amount: this.props.data.amount,loan_doc_id: this.props.data.loan_doc_id };
  this.setState({modalBox: true});


}

handleClose = async(event) => {
  
  const request = {loan_doc_id: this.props.data.loan_doc_id, loan_status: "pending_mnl_dsbrsl", manual_disb_user_id: null}
  cancelCaptureDisb(this.req(request)).then(async(res) => {
    this.resp(res)
    if(res && res.status == 'success') {
      this.setState({ modalBox: false, txn_data: '', capture_btn : 'disable_div', txn_id_style : " ", txn_failed_msg : ''});
      await this.props.onDisburseClick(true);
    }
 })
 
}

handleTxnModeChange = changeEvent => {
  this.setState({txn_mode : "(" + title_case(changeEvent.target.value)+ ")"});
  this.handleSelfChange(changeEvent);
};

handleCheckboxClick = (event) => {
  if(event.target.checked){
    this.setState({checkbox: true});
  }
}

handleSMSCheckboxClick = (event) => {
  if(event.target.checked){
    this.setState({send_sms: true});
  }else{
    this.setState({send_sms: false});
  }
}

handleSubmit = (event)=>{
    event.preventDefault();
  
    if(this.state.checkbox === true || this.state.is_skip_trans_id_check){

      if(this.state.is_skip_trans_id_check){
        if(this.disbursal_txn.reason_for_skip == null ){
          alert("Please select required fields")
          return
        }else if (!this.state.disbursal_txn.photo_disbursal_proof){
          alert("Please upload disbursal proof photo")
          return
        }
      }

        const disbursal_txn = this.disbursal_txn;
        if(this.props.parent == "home"){
          disbursal_txn.amount = this.props.data.loan_principal
          disbursal_txn.capture_only = true;
        }
        
        disbursal_txn.send_sms = this.state.send_sms;
        disbursal_txn.from_ac_id = this.from_acc.value;
        disbursal_txn.to_ac_id = this.to_acc.value;
        disbursal_txn.is_skip_trans_id_check = this.state.is_skip_trans_id_check;
        if(this.state.is_skip_trans_id_check){
          disbursal_txn.reason_for_skip = this.disbursal_txn.reason_for_skip
          disbursal_txn.photo_disbursal_proof = this.state.disbursal_txn.photo_disbursal_proof
        }


       // disbursal_txn.txn_exec_by = this.txn_exec.value;

         disburseLoan(this.req({"disbursal_txn":disbursal_txn}))
            .then((response) => {
              this.resp(response)
              if(!response){return };
                if(response.status === "success"){
                   this.setState({ modalBox: false });
                   if(this.props.parent == "home"){
                      this.setState({view_loan: false});
                   }else{
                      this.setState({view_loan: true}); 
                   }
                   window.location.reload();
                }else{
                  alert(response.message + "\n" + JSON.stringify(response.data));
                  this.setState({capture_btn : ''});

                }
              }
            );
    }else{
      alert("Please select the checkbox to agree and disburse");
    }
  }

  
enableTxnID = (event) => {
  const disbursal_txn = this.disbursal_txn;
  
  if(disbursal_txn.amount > 0 && disbursal_txn.from_ac_id != null && disbursal_txn.txn_date != null){
    this.setState({txn_id_style :'' });
  }
}
  render(){
     if (this.state.view_loan === true) { 
        return (
           <Navigate to={`/fa/view/${this.props.data.loan_doc_id}#disbursed`} />
          );
    }
    return (
    <div id="" >
        <div className="row">

          { this.props.parent == "home" ? 

            <>
              { (this.props.data.manual_disb_user_id != get('user_id') && this.props.data.manual_disb_user_id != null) ?

                  <div className="col-md-12 mt-1" > 
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{`Manual disbursement initiated by ${this.props.data.manual_disb_user_name}. No other user will be able to capture this manual disbursement`}</Tooltip>}>
                      <span className="d-inline-block">
                        <input type="submit" disabled = {true} className="btn btn-primary btn-sm" value="Disburse Manually" />&nbsp;&nbsp;&nbsp; 
                      </span>
                    </OverlayTrigger>          
                  </div>
              : 
                  <div className="col-md-12 mt-1" > 
                    <input type="submit" className="btn btn-primary btn-sm" disabled={this.state.calling_api} value="Disburse Manually" onClick={this.handleDisburseManually} />&nbsp;&nbsp;&nbsp; 
                  </div>
              }
            </>
          :        
              <div className="col-md-12">
                <input type="button" className="btn btn-primary disburse" disabled={this.state.calling_api} value="Capture Disbursal" onClick={this.handleDisburseClick} />&nbsp;&nbsp;&nbsp;
              </div>
          }
          
        </div>
        <br/>
        <Modal show={this.state.modalBox} onHide={this.handleClose} size="xl">
              <Modal.Header closeButton className="modal-close-btn" show={this.state.modalBox} onHide={this.handleClose}>
                  <Modal.Title>FA Disbursal</Modal.Title>
              </Modal.Header>
              <Modal.Body>        
               <div className="container">
                 { (this.props.parent == "home") &&
                    <div className="row">
                      <div className="col-md-12">
                        <label>{ (this.props.data.is_manual_disb_ap_code == true ? `##For this Account Provider (${this.props.data.acc_prvdr_code}), Instant Disbursal has not been configured##` : this.disb_attempt_failed_msg)} 
                          <br/><br/>Follow the below steps always to avoid duplicate disbursement.<br/><br/>
                          1. Don't close this screen during the entire disbursement process.<br/>
                          2. Send FA to customer manually using web portal, USSD etc., (If you do not know how to do this, discuss with Tech Support)<br/>
                          3. Capture the disbursement details on this screen and click "Submit".<br/><br/>
                        </label>
                      </div>
                    </div>
                  }

                  <div className="row">
                    <div className="col-md-6">
                      <h4>Float Advance ID: {this.props.data.loan_doc_id} </h4>
                    </div>
                    <div className="col-md-6">
                      <h4>Float Advance Amount: {rt_algn(lbl_amt(this.props.data.amount, this.currency_code))} </h4>
                    </div>  
                  </div>

                  <div className="row date-range">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-5">
                          <span>Disburse From A/C<br/>( {this.props.data.lender_name} )<font className="text-danger">   *  </font></span>
                        </div>
                        <div className="col-md-7">
                            <select id="from_ac_id" defaultValue={this.selected.lender_accounts} ref={select => this.from_acc = select} onChange={(e) => { this.handleSelfChange(e);this.enableTxnID(e)}} className="form-control" type="text">
                           {this.state.lender_accounts_dd}
                        </select>
                        </div>
                      </div>
                      <br/>

                      <div className="row">
                        <div className="col-md-5">
                          <span>Amount Disbursed</span><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">
                           <div id="" className={`${this.state.disable}`} >
                          <input type="text" id="amount" onChange={this.handleSelfChange} value={this.props.data.amount} className="form-control"/>
                          </div>    
                        </div>
                      </div>
                      <br/>

                      <div className="row">
                        <div className="col-md-5">
                          <span>Disbursal Date</span><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">
                            <DatePicker closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("txn_date", value);this.enableTxnID(value)}} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                          </div>
                      </div>
                      <br/>
                        <div className="row">
                        <div className="col-md-5">
                          <span>Disbursal Mode</span><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">
                            <select id="txn_mode" onChange={this.handleTxnModeChange} className="form-control" type="text" required="required">
                        {this.getSelectOptions("transaction_mode")}
                    </select>
                        </div>
                      </div>
                      <br/>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-5">
                          <span>Customer's A/C</span><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">
                        <select id="to_ac_id" defaultValue={this.selected.customer_accounts} ref={select => this.to_acc = select} onChange={(e) => { this.handleSelfChange(e);this.enableTxnID(e)}} className="form-control" type="text" required="required">
                        {this.state.customer_accounts_dd}                
                    </select>
                        </div>
                      </div>
                      <br/>

                      {/* <div className="row">
                        <div className="col-md-5">
                          <label>Disbursed By</label><font className="text-danger">   *  </font>
                        </div>
                        <div className="col-md-7">
                          <select id="txn_exec_by" ref={select => this.txn_exec = select} onChange={this.handleSelfChange} className="form-control" type="text" required="required">
                            {this.state.disbursers_dd}                
                          </select>
                        </div>
                      </div>
                      <br/> */}

                      <div className="row">
                        <div className="col-md-5">
                          <span>Remarks</span>
                        </div>
                        <div className="col-md-7">
                          <input type="text" id="remarks" onChange={this.handleSelfChange} className="form-control" required="required"/>
                        </div>
                      </div>
                      <br/>
                      <div className="row">
                        <div className="col-md-5">
                          <span>Disbursal Transaction ID</span><font className="text-danger">   *  </font>
                        </div>
                        <div className={`col-md-7 ${this.state.txn_id_style}`}>
                          {/* <input type="text" id="txn_id" onChange={this.handleSelfChange} className="form-control spacing" required="required"/> */}
                          <input type="text" id="txn_id" onChange={(e) => { this.handleSelfChange(e);this.handleTxnId(e)}} className="form-control spacing" tabIndex = '-1' required="required"/>
                        </div>
                      </div>
                      <br/>
                      {/* {check_priv('loan', 'skip_txn_id_check') &&
                      <div className="row">
                        <div className="col-md-5">
                        </div>
                        <div className={`col-md-7 ${this.state.txn_id_style}`}>
                          <label><input type="checkbox" onChange={(e) => this.handleSkipTransIDChekCheckboxClick(e)}/>&nbsp;
                          <b className="red_color_font">Skip transaction ID check</b></label>
                        </div>
                      </div>
                      } */}

<br/>
                      <div className="row">
                        {this.state.txn_data &&
                          <>
                            <br/>
                            <div class="alert alert-success">
                            {this.state.txn_data.map((data)  =>
                                <h6>Amount : {data.dr_amt} | Txn Date : {data.stmt_txn_date} | Description : {data.descr}</h6>
                              )
                            }
                            </div>
                          </>
                        }
                        {this.state.txn_failed_msg &&
                          <div class="alert alert-danger">
                            <h6>{this.state.txn_failed_msg}</h6>
                          </div>
                        }
                      </div>

                      {/* {this.state.txn_failed_msg && check_priv('loan', 'skip_txn_id_check') &&


                     <>
                     <fieldset className = "border border-dark p-2">
                      <legend className="w-auto text-labelblue" style ={{fontSize : "15px"}} >Transaction Evidence</legend>
                        <div className="row  align_ver_center">
                           <label className="col-md-5 "><b>Reason for Skip</b><font className="text-danger">   *  </font></label>
                           <div className="col-sm-7">
                              <select id="reason_for_skip" onChange={(e) => { this.handleSelfChange(e);}} className={`form-control ${this.state.credit_style}`} type="text" required="required">
                                 {this.getSelectOptions("skip_txn_id_check")}
                              </select>
                           </div> 
                           
                        </div>
                        <div className="row labelTopgap align-items-center ml-1">
                              <FileUpload id="photo_disbursal_proof" mode = "create" entity ='loan_txns' type="image" txn_id = {this.disbursal_txn.txn_id}      
                                    onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.setChangeToState} label="Disbursal Proof Photo" />
                        </div>
                        </fieldset>
                     </>
                     } */}
                     
                      <br/>
                    </div>
                  </div>

                  <div className="row">
                    <label><input type="checkbox" onChange={this.handleSMSCheckboxClick} defaultChecked/>&nbsp;
                    Send Disbursement confirmation notification SMS</label>
                  </div>
                  <div className="row">
                          <label><input type="checkbox" onChange={this.handleCheckboxClick} />&nbsp;
                          I have transferred the above Float Advance to the Customer's Account using Third Party Platform {this.state.txn_mode}.</label>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>

                <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
                         <input type="button" className={`btn btn-primary ${this.state.capture_btn}`} disabled = {this.state.calling_api} onClick={this.handleSubmit} value="Submit"/>
              </Modal.Footer>
          </Modal>
    </div>

      );
  }
}
export default DisburseLoan;