import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
//import {getAccountTxnsType} from '../../../actions/account_txn_actions';
import {getMasterData} from '../../../actions/common_actions';
import {Button } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import moment from 'moment';
import '../../../styles/flow.css';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import $ from 'jquery';
import {check_priv, Config, get,get_item} from '../../../helpers/storage_helper';
class StatementSearch extends FlowComponent {

  state = {
    section1: "", 
    account : {}
  }

componentWillMount()
{
        /*  const request = {data_key: "account_transaction_type"};
        getAccountTxnsType(this.req(request))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){
                this.getSelectOptionsFromResult(response.data.list, "account_txn_type", ["data_code", "data_value"]);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );*/
      const lender_code = this.lender_code;   
      let acc_prvdr_code = get('acc_prvdr_code');
      const status = "enabled" ;
      const lender_account = {lender_code, status, acc_prvdr_code};

      this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);
      this.setOptionsToStateFromApi("lender");
      this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled"}, ['acc_prvdr_code', 'name'], "acc_prvdrs");
      const request = {"status": 'enabled', data_key: "account_transaction_type"}
      const request_data = {"master_data": request};

      getMasterData(this.req(request_data))
         .then((response) => {
          if(!response){return };
            if(response.status === "success"){
                this.getSelectOptionsFromResult(response.data, "account_txn_type", ["data_code", "data_value"]);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );

        //event.target.value
    
      
        const account = {lender_code, "country_code": this.country_code,to_recon : true, network_prvdr_code : acc_prvdr_code }
        
        if(acc_prvdr_code != undefined){
          getRefAccounts(this.req({account},true))
            .then((response) => {
              if(!response){return };
              if(response.status === "success"){
                if(response.data != "")
                {
                  this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
                }     
              }else{ 
                alert(response.message + "\n" + JSON.stringify(response.data));  
              }
            }
          );
        } 
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.data != this.props.data){
      this.handleSection();
    }

  }

  handleResetClick = (event) => {
    this.setState({account : {}})
    this.handleReset();
  }


    handleSection = () =>{
    let prop = this.props.data;
    let session_1 = false
    let session_2 = false

    Object.keys(prop).map((key, i) => {
      if(prop[key] != null && prop[key] != "" && prop[key] != 'Invalid date' && key != 'req_parameter'){
        session_1 = true;
      }else if(prop[key] != null && prop[key] != "" && key == 'req_parameter'){
        session_2 = true;
      }

      if (prop[key] == 'Invalid date') {
        this.handleChange({target : {id : [key] , value: ''}}); 
      }
    });
    this.setState({section1: session_2 ? "disable_div" : ''});
    this.setState({section2: session_1 ? "disable_div" : ''});
    
  }

  handleValue = (key) => {
    let prop = this.props;
    let value = '';
   
    if(prop.data?.[key] && prop.data[key] != 'Invalid date'){
      if(key == 'stmt_txn_date__from' || key == 'stmt_txn_date__to'){
        let date = new Date(prop.data[key])
        value = moment(date).format("DD MMM YYYY")

      }else{
        value = prop.data[key];
      }
      
    }
    
    return value;
  }

  


  // handleFields = (event) =>{
    
  //   if(event.target.value!= "")
  //   {
  //     this.setState({section1: "disable_div"});
  //   }
  //   else if(event.target.value == "")
  //   {
  //   this.setState({section1:"show_div"}); 
  //   }

  //   this.handleChange(event);
  // }

  loadAccounts = (event) => {

    const lender_code = this.lender_code;
    var data = this.state.account;
    data[event.target.id] =  event.target.value;
    let acc_prvdr_code = get('acc_prvdr_code') ? get('acc_prvdr_code') : data.acc_prvdr_code;

    const account = {lender_code, "country_code": this.country_code,to_recon : true, network_prvdr_code : acc_prvdr_code, ...data }

    if(acc_prvdr_code != undefined){
      getRefAccounts(this.req({account},true))
        .then((response) => {
          if(!response){return };
          if(response.status === "success"){
            if(response.data != "")
            {
              this.getSelectOptionsFromResult(response.data, "lender_accounts", ['id','name']);
            }     
          }else{ 
            alert(response.message + "\n" + JSON.stringify(response.data));  
          }
        }
      );
    }
  }
 
    render(){
      const show_switch = Config('switch_supported_countries')?.includes(get('market').country_code)
      return(
        <div className="date-range" style={{padding:15}}>          
          <div className = {`form-row ${this.state.section1}`}>
              {/* <div className = "form-group col-md-3">
                <span className = "form-label">Lender</span><font className="text-danger">   *  </font>
                  <select id="lender_code" defaultValue="UFLW" onChange={this.loadAccounts} className="form-control" type="text" required="required">
                    {this.state.lender_dd}
                  </select>
              </div>   */}
              {!this.admin_roles.includes(get('role_codes')) &&
                <div className={`col-md-3 mt-4 form-group `}>
                    <span>Account Provider</span><font className="text-danger">   *  </font>
                    <select id="acc_prvdr_code"  value = {this.handleValue('acc_prvdr_code') != '' ? this.handleValue('acc_prvdr_code') : this.state.account?.acc_prvdr_code ? this.state.account.acc_prvdr_code : '' } onChange={(e) => {this.handleChange(e);this.handleSection(e); this.loadAccounts(e);}} className="form-control" type="text" required="required">
                    {this.state.acc_prvdrs_dd}
                    </select>
                </div> 
              }
              <div className = {`form-group mt-4 col-md-3`}>
                <span className = "form-label">Accounts</span><font className="text-danger">   *  </font>
                  <select id="account_id" value={this.handleValue('account_id')} className="form-control" required="required" onChange={(e)=>{this.handleChange(e);this.handleSection(e);}} >
                      {this.state.lender_accounts_dd}                               
                  </select>
              </div>
              <div className = {`form-group mt-4 col-md-3`}>
                <span className = "form-label">Start Date</span><font className="text-danger">   *  </font>
                <DatePicker 
                  closeOnSelect={true} 
                  value={this.handleValue('stmt_txn_date__from')} 
                  onChange={(value) => {this.handleDateChange("stmt_txn_date__from", value);this.handleSection(value,'date');}}  
                  dateFormat="DD MMM YYYY" 
                  timeFormat={false} 
                  required="required"
                />

              </div>
              <div className = {`form-group mt-4 col-3`}>
                <span className = "form-label">End Date</span><font className="text-danger">   *  </font>
                <DatePicker 
                  closeOnSelect={true} 
                  value={this.handleValue('stmt_txn_date__to')} 
                  onChange={(value) => {this.handleDateChange("stmt_txn_date__to", value);this.handleSection(value,'date');}}  
                  dateFormat="DD MMM YYYY" 
                  timeFormat={false} 
                  required="required"
                />
              </div>
              <div className = {`form-group mt-4 col-md-3`}>
                  <span className = "form-label">Statement Transaction Type</span>
                    <select id="stmt_txn_type" value={this.handleValue('stmt_txn_type')} className = "form-control" onChange={(value)=>{this.handleChange(value);this.handleSection(value);}}>
                    {this.getSelectOptions("stmt_txn_type")}
                    </select>
              </div>

              {check_priv('recon','recon_status')&&
                <div className = {`form-group mt-4 col-md-3`}>
                    <span className = "form-label">Matching Status</span>
                      <select id="recon_status" value={this.handleValue('recon_status')}  className = "form-control" onChange={(value)=>{this.handleChange(value);this.handleSection(value);}} >
                        {this.getSelectOptions("recon_status")}
                      </select>
                </div>
              }
             
              <div className = {`form-group mt-4 col-md-3`}>
                  <span className = "form-label">FA  {show_switch ? "/ Switch" :''} ID</span>
                  <input id ="loan_doc_id" value={this.handleValue('loan_doc_id')} className = "form-control" onChange={(e) => {this.handleChange(e);this.handleSection(e);}} required/>
              </div>
              

              <div className = {`form-group mt-4 col-md-3`}>
                  <span className = "form-label">Customer ID / Description</span>
                  <input id ="custId_r_descr" value={this.handleValue('custId_r_descr')} className = "form-control" onChange={(e) => {this.handleChange(e);this.handleSection(e);}} required/>
              </div>
          </div>
          
              {/* <div className = {`form-group mt-4 col-md-3`}>
                  <span className = "form-label">Matching Status</span>
                    <select id="recon_status"  className = "form-control" onChange={(value)=>{this.handleChange(value);this.handleSection(value);}} >
                        <option value="">choose</option>
                        <option value = "10_capture_payment_pending">Capture Payment Pending</option>
                        <option value = "80_recon_done">Recon Done</option>
                        <option value = "cant_recon">Can't Recon</option>
                    </select>
              </div> */}
          <div className = {`form-col ${this.state.section2}`}>           
              <div>
                <p className='text-white fw-bold fs-4 py-4 d-flex align-item-center justify-content-center'>OR</p>
              </div>
              <div className = {`form-group col-md-4 px-0`}>
                  <span className = "form-label">Statement Transaction ID </span>
                  <input id ="req_parameter" value={this.handleValue('req_parameter')} className = "form-control" onChange={(e) => {this.handleChange(e);this.handleSection(e);}} required/>
              </div>
             
          </div>        
          <div className="form-row justify-content-md-center mt-3">
              <Button type="reset" className='mr-3 fs-6' onClick={()=>this.handleResetClick()}>Reset</Button>
              <Button className="btn btn-secondory fs-6" onClick={this.props.onlist} disabled={this.props.disable_btn}  >Search</Button> &nbsp;
          </div>
    </div> 
    )
    }

}
export default StatementSearch;
