import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import {listLoanProduct} from '../../../actions/loan_product_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import  {lbl_dur, lbl_flow_fee_obj, lbl_flow_fee_duration} from '../../../helpers/product_helper';
import StatusButton from '../../common/component/core/StatusButton';
import EditProduct from '../component/EditProduct';
import {rt_algn,lbl_amt, create_column} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import {check_priv} from '../../../helpers/storage_helper';
import $ from 'jquery';
import { BasicReactTable } from '../../../helpers/react_table';

class listProductContainer extends FlowContainer {
  state = {
     product_list: null,
     toView: false,
     toEdit: false,
     product_list_div_style:"hide_div",
     product_id: null,
     can_edit:false,
     loader:false
  }

  handleClick = (event) => {
        this.setState({product_id: event.target.id, toView: true});
    }

    handleEditClick = (event, product_details) => {
      this.setState({product_details, toEdit: true});
    }
 
  componentWillMount(){
    this.list_product()
  }

  list_product = ()=>{
    const can_edit = check_priv('product', 'edit');
    this.setState({can_edit:can_edit,column_data:this.column_datas(can_edit)})
    //const data_prvdr_code = this.data_prvdr_code;
    listLoanProduct(this.req(null, true))
        .then((response) => {
          if(!response){ 
            return };
          this.setState({product_list:response.data})

        })
      
  }

  on_status_change = ( id, status) => {
    this.setState({
      product_list: this.state.product_list.map((product) => {
        if (product.id == id) {
          return {
            ...product,
            status
          };
        }
        return product;
      })
    });
    const can_edit = check_priv('product', 'edit');
    this.setState({can_edit:can_edit,column_data:this.column_datas(can_edit)})
  };
  

  column_datas(can_edit){
  

  const  column = [
    {header:"Product Name",id:"product_name"},
    {header:"Product Type",id:"product_type"},
    {header:"Credit Score Model",id:"cs_model_code"},
    {header:"FA Amount",id:"max_loan_amount",is_amount:true},
    {header:"FA Duration",id:"lbl_duration",is_calculation:true},
    {header:" Fee",id:"flow_fee" , is_amount:true},
    {header:"Penalty Amount",id:"penalty_amount",is_amount:true},
  ]
    
    let data =[... create_column(column),  
      {  id:'status',
        Header:<div className='text-center font-for-otp-table'>Status</div>,
        accessor:row=>row.value,
        Cell:(cell)=>{
          return <div className=' d-flex justify-content-center text-center font-for-otp-table' id={cell.row.original.id} >
         		<StatusButton id={cell.row.original.id} entity="loan_products" status={cell.row.original.status} head = {"1"} />
          </div>
        },
       },
        {  id:'action',
        Header:<div className='text-center font-for-otp-table'>Action</div>,
        accessor:row=>row.value,
        Cell:(cell)=>{
          return <div className=' d-flex justify-content-center text-center font-for-otp-table' id={cell.row.original.id} >
         		<StatusButton id={cell.row.original.id} entity="loan_products" status={cell.row.original.status} head={"2"} onStatusChange= {this.on_status_change}/>
          </div>
        },
       },
       {    id:'Edit',
            Header:<div className='text-center font-for-otp-table'>Action</div>,
            show:can_edit,
            accessor:row=>row.value,
            
            Cell:(cell)=>{
               return <div className='text-center font-for-otp-table'><input type="button" id={cell.row.original.id} product={cell.row.original} onClick={(e) => this.handleEditClick(e, cell.row.original)} value="view / edit" className="btn btn-primary btn-sm " /></div>
            },

    }  
    ]
    return data;


  }

  render(){
      
     if (this.state.toView === true) { 
           return <Navigate to={`/fa_product/view/${this.state.product_id}`} />
    }
     else if (this.state.toEdit === true) { 
           return <EditProduct id="product" product={this.state.product_details} onComponentChange={this.handleComponentChange} />
     }

   if (this.state.product_list && this.state.product_list.length>0) { 
      
    return( 
          <div className="card">{console.log('this.state.product_list', this.state.product_list)}
            <div class="card-header card-header-icon card-header-primary"><h3 class="card-title text-white d-inline-block">List of FA Products </h3></div>
            <div id="" className="p-4">{console.log('can_edit', this.state.can_edit)}
                {/* <div id='datatable' className="table-responsive">
                    <table id ='list_product' className="table">
                        <thead className={"text-info"}>
                        <tr>
                        <th>Product Name</th> 
                        <th>Product Type</th>
                        <th>Credit Score Model</th>
                        <th>FA Amount</th>
                        <th>FA Duration</th>
                       
                        <th>Flow Fee</th>
                        <th>Penalty Amount</th>
                       
                       
                        <th>Status</th>
                        <th>Action</th>
                        {this.state.can_edit &&
                          <th>Action</th>
                        }
                        </tr></thead>
                        <tbody> 
                          {this.state.product_list}
                        </tbody>
                    </table>                    
                </div> */}

                <div>

               
               <BasicReactTable  row_data={this.state.product_list} column_data={this.state.column_data} default_page_size = {10} pagination={this.state.product_list.length > 9 ? true : false} csv_file={true}  global_search={true} export_csv_datas={this.state.product_list}/> 
                </div>
            </div>
          </div>
      );
    }
      return (
        <div><p>No FA Products Available ! </p></div>
      );
  }
}
export default listProductContainer;
