import React from 'react';
import { render } from "react-dom"
import { Link} from 'react-router-dom';
import LeadAuditComponent from '../component/LeadAuditComponent';
import FlowContainer from "../../common/container/core/FlowContainer"
import { Card,Modal, NavItem } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { viewLead ,view_remarks,add_remarks} from '../../../actions/lead_actions';
import { approveKYC, rejectKYC } from '../../../actions/kyc_actions';
import {Navigate} from "react-router-dom";
import {check_priv} from "../../../helpers/storage_helper";
import {dd_value} from "../../../actions/common_actions";
import { updateLeadStatus } from '../../../actions/kyc_actions';
import {get, Config} from '../../../helpers/storage_helper';
import { IoMdSend,IoMdReorder,IoIosSearch } from "react-icons/io";
import AuditKycVerificationComponent from '../component/AuditKycVerificationComponent';
import {capitalize_first_letter,get_dir_path, getValueFromLangJson} from '../../../helpers/common_helper';

import {AssignAuditor} from '../../../actions/lead_actions';
import withRouter from '../../../Router/Withrouter';


class LeadAuditContainer extends FlowContainer
{
    state = { lead: {},
              to_edit: false,
              reassignModal:false,
              rejectModal:false,
              audit_lead :{},
              view_cust_id: false,
              add_review:'',
             remarks_arr :[ ],
             holder_name_mismatch: false,
             holder_name_mismatch_reason: "",
             holder_name: "",
             tp_holder_name_n_owner_match: false,
             auditor_name_list : {},
             
              
}
        
        
    componentWillMount()
    {   
      
        this.id = this.props.params.id;     
        viewLead(this.req({id: this.id}))
        .then((response) => 
        {
            this.resp(response)
            if(!response){return };
            if(response.status === "success")
            {
                let lead = response.data
                let view_cust_id = false;

                if(lead.cust_reg_json.cust_id){
                    if((lead.type == 'kyc' && lead.status >= '59') || (lead.type == 're_kyc') || (lead.type == 'self_reg' && lead.self_reg_status == 'self_reg_completed' ) ){
                        view_cust_id = true;
                    }
                }
                this.setState({lead, view_cust_id,auditData:response.data.audit_data})
                if(lead.cust_reg_json.biz_accounts.accounts[0].hasOwnProperty('holder_name')){
                    this.setState({holder_name: lead.cust_reg_json.biz_accounts.accounts[0].holder_name})
                }
                if(response.data.cust_reg_json){
                   this.setState({audit_view:true})
                }
            }
        })
      this.remarks_handler();

      const audit_kyc_line = Config("audit_kyc_line")
      this.setState({audit_kyc_line})
      this.setOptionsToStateFromApi("auditor", {...this.def_req}, null, 'kyc')
    }

    handleComponentDataChange = (data) => {
        this.setState(data)
    }

    remarks_handler=()=>{
        this.id = this.props.params.id;     
        view_remarks(this.req({id: this.id}))
        .then((response) => 
        { 
            
            this.resp(response)
            if(!response){return };
            if(response.status === "success")
            { 
           
             this.setState({remarks_arr:response.data})
            }
        })
     
    }
    input_Handler=(e)=>{
      this.setState({add_review: e.target.value}) 

    }
    cmt_handler=(event)=>{
       if(this.state.add_review ==''){
           alert("enter valid remarks");
       }else{ event.preventDefault()
        this.id = this.props.params.id; 
        add_remarks(this.req({id:this.id,cmt:this.state.add_review}))
        this.remarks_handler()

        // const a=this.state.remarks_arr.push({
        //     "cmt": this.state.add_review,
        //     "action": null,
        //     "cmtr_id": get('user_id'),
        //     "cmtr_name":'' ,
        //     "cmtr_time":'now',
        //     "is_private": false
        // })  
        this.setState({add_review:''}) }
       
    }

    validate_fields(fields) {
        if(fields.remarks && fields.reason){
             return true
        }else{
            alert("Please enter required fields")
        }
        return false   
    }

    handleKYCEdit = (event) =>{
        const lead = this.state.audit_lead
        lead.lead_id =  this.id 
        lead.reassign_reason =  lead.reason 

        var valid = this.validate_fields(lead)
        if(valid){
            updateLeadStatus(this.req(lead))
                .then((response) => {
                    this.resp(response)
                    if(!response){return };
                    if(response.status === "success")
                    {
                        alert(response.message);
                        this.setState({to_edit: true})
                    }
            })
        }
    }

    handleReassignModalOpen = (event) => {
        this.setState({reassignModal:true})
    }

    handleRejectModalOpen = (event) => {
        this.setState({rejectModal:true})
    }   

    handleModalClose = () => {       
        this.setState({reassignModal:false, rejectModal:false})
    }

    handleAudit = (event) => {
        
        if(this.state.tp_holder_name_n_owner_match){
            alert(`The Third party holder name matches with the owner's name. Reassign to the ${getValueFromLangJson("rm_label_short")} and register it as owner account.`)
            return
        }
        else if(this.state.audit_kyc_line[this.state.lead.cust_reg_json.account.acc_prvdr_code.value] != 'skip' && !this.state.holder_name){
            alert("Please enter the required fields")
            return
        }
        else if(this.state.audit_kyc_line[this.state.lead.cust_reg_json.account.acc_prvdr_code.value] != 'skip' && this.state.holder_name_mismatch && !this.state.audit_lead.account_holder_name_proof && !this.state.holder_name_mismatch_reason){
            alert("Please enter the required fields")
            return
        }
        
        event.preventDefault();
        var borrower = this.state.lead.cust_reg_json;
        borrower.lead_id = this.state.lead.id;
        borrower.flow_rel_mgr_id = this.state.lead.flow_rel_mgr_id;
        borrower.acc_purpose = this.state.lead.acc_purpose;
        approveKYC(this.req({lead_id: this.state.lead.id, holder_name_mismatch_reason: this.state.holder_name_mismatch_reason}, true))
        .then((response) => 
        {
            this.resp(response)
            if(!response){return};
            if(response.status === "success")
            {
            this.setState({cust_id: response.data});
            this.setState({toList: true});
                alert(response.message);
                window.open(`/borrower/indiv/view/${this.state.lead.cust_reg_json.cust_id}`, "_blank")
                this.setState({to_edit: true})
            }
            else
            {  
                alert(response.message + "\n" + JSON.stringify(response.data));  
            }
        })
        
    }
   

    handleRejectKYC = (event) => {
        event.preventDefault();
        const lead = this.state.audit_lead
        lead.lead_id = this.id
        lead.reject_reason =  lead.reason 

        var valid = this.validate_fields(lead)
        if (valid) {
            const resp = window.confirm("Are you sure to mark KYC as failed and close the lead?")
            if(resp){
            	rejectKYC(this.req(lead)).
                    then((response) =>{
                        this.resp(response)
                        if(!response){return };
                        if(response.status === "success"){
                            alert(response.message)
                            this.setState({to_edit: true})
                        }
                        else
                        {
                            alert(response.message + "\n" + JSON.stringify(response.data));
                        }
                    })
            }
        }
    }
  

    handleCloseModal = (event) => {
        this.setState({capture_modal:false})
    }


    audit_kyc_list(type){
   if(type=="audit_view"){
       this.setState({[type]:true,list_view:false})
   }else{
    this.setState({[type]:true,audit_view:false})
   }
    }

    render()
        { 
            if(!check_priv("lead","audit_kyc")){
                return <Navigate to='/'/>
        }
        return(
            
        <>  
            {this.state.to_edit ?
                <Navigate to={`/lead/edit/${this.props.params.id}`}/> :                
                <>             
                    <div className="container max-width containerTopmargin no-padding" style = {{maxWidth :"1500px"}}>
                        <Card>
                            <Card.Header style = {{backgroundColor: "#01062c", color: "white"}}>
                            <div className="d-flex justify-content-end  row">
                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                {/* <h4 className="no-margin headerTitle preHeaderpad padL_15">{this.props.title}</h4> */}
                                <h2 className='text-white mt-2 sizem text-center'> {getValueFromLangJson("rm_label_short")} Name : <b>{this.state.lead ? this.state.lead.rm_name :'-'}</b></h2>
                                {this.state.view_cust_id ?
                                   <div className=' col-12 text-center'>
                                    <h2 className='text-white sizem' >Cust ID : <b className='text-white'>  <Link to={"/borrower/indiv/view/"+this.state.lead.cust_reg_json.cust_id} target="_blank" ><span style={{ fontSize: "18px" }}>{this.state.lead.cust_reg_json.cust_id}</span></Link> </b></h2>
                                    </div>
                                :""       
                                }
                                </div>
                               {this.state.lead.cust_reg_json &&
                               <div className='col-sm-12 col-md-6 col-lg-4'>
                               <h2 className='text-white text-center sizem mt-2'> Customer Name: <b>{this.state.lead.hasOwnProperty('cust_reg_json') ? <> <b>{ this.state.lead.cust_reg_json.id_proof.first_name ? capitalize_first_letter(this.state.lead.cust_reg_json.id_proof.first_name.value) :"-"}</b> <b className='pl-2'>{this.state.lead.cust_reg_json.id_proof.last_name ?capitalize_first_letter(this.state.lead.cust_reg_json.id_proof.last_name.value):"-"}</b></>:'-'}</b> </h2>
                              {
                               <h4 className='text-center sizem'>Type : <b> {dd_value(this.state.lead.type, 'lead_type')} </b></h4>
                              }

                               </div>}
                                {this.state.lead.auditor_name && 
                                <div className='col-sm-12 col-md-6 col-lg-4 text-center'>
                                <h2  className="text-white text-center mt-2 sizem">KYC Reviewer :<b className='sizem'> {this.state.lead.auditor_name} </b></h2>
                                  
                                  {this.state.lead.type=='re_kyc' &&
                                    <h2 className='text-white  text-center sizem ' >Reason : <b>{capitalize_first_letter(dd_value(this.state.lead.kyc_reason,"rekyc_reason"))} </b> </h2>
                                  }
                                {/* {(get('role_codes') == 'risk_compl_head) &&
                                <div className="mt-3"> 
                                    <Button disabled = {this.state.calling_api} className='mr-3  text-white' variant='primary' onClick={this.handleCaptureModal} >Reassign Auditor</Button>
                                    </div>
                                }  */}
                                </div>
                                }

                                {/* {(get('role_codes') == 'app_support' || get('role_codes') == 'it_admin') && this.state.lead.auditor_name &&  check_priv("lead","reassign_auditor") && */}
                              
                            
                            </div>
                         
                           
                            </Card.Header>
                            
                           
                            
                            <div className='row '>
                                <div className='col-md-12 col-sm-12 col-lg-12'>
                                <Card.Header className='col-md-12 col-lg-4 list_sticky  sticky_border' >
                                <div className="d-flex   justify-content-end mt-3 list_sticky audit_kyc_wizard_responsive" style={{marginRight:'30px'}}>
                                    {/* <h4 style={{ fontSize: "18px" }}>Type : {dd_value(this.state.lead.type, 'lead_type')}</h4> */}
                                    {/* <div className='d-flex align-items-center audit_kyc_wizard_sticky_header  sticky_border justify-content-center'>
                                    <div className=' d-flex  audit_kyc_border audit_kyc_wizard_sticky_header  sticky_border' style={{padding: '6px 4px'}}> 
                                     <div className={ `d-flex align-items-center  px-2 py-2  ${this.state.list_view? "audit_kyc_active_list ":'text-white' }`}  onClick={()=>this.audit_kyc_list("list_view")} >
                                        <IoMdReorder  size={25} />
                                        <p className='no-margin  px-2' style={{fontSize:'13px'}}> List View</p>
                                     </div>
                                     <div className={ `d-flex align-items-center mx-2 px-2 py-2 ${this.state.audit_view?"audit_kyc_active_list" :'text-white' }`} onClick={()=>this.audit_kyc_list("audit_view")}>
                                        <IoIosSearch  size={25}/>
                                        <p className='no-margin  px-2' style={{fontSize:'13px'}}> Audit View </p>
                                     </div>
                                     </div>
                                    </div> */}

                                   
                                       {/* <h4 style={{ fontSize: "18px" }}>Status : {dd_value(this.state.lead.status)}</h4> */}
                            
                                   

                                </div>
                            </Card.Header>
                                {this.state.audit_view &&
                                   <AuditKycVerificationComponent lead_id={this.props.params.id} id="lead" status={this.state.lead.status} cust_reg_json={this.state.auditData} handleRejectKYC = {this.handleRejectKYC}/>
                                }
                                </div> 
                                {this.state.audit_view==false &&
                                <div className='col-md-8 col-sm-8 col-lg-8'> 
                                <div className='remarks-scroll' >
                                <Card.Body >
                              
                                 {this.state.list_view &&
                                    <LeadAuditComponent onAuditDataChange={(res) => this.handleComponentDataChange(res)} lead_data = {this.state.lead}  submit_btn = {this.state.calling_api} handleKYCEdit = {this.handleKYCEdit} handleRejectKYC = {this.handleRejectKYC} handleModalClose={this.handleModalClose} lead_id ={this.id}  id = "audit_lead" reassignModal = {this.state.reassignModal} rejectModal = {this.state.rejectModal} acc_purpose = {this.state.lead.acc_purpose} data = {this.state.lead.cust_reg_json} onComponentChange={this.handleComponentChange} showModal = {this.state.capture_modal} assign_audit_id = {this.state.audit_lead}/>

                                 }   
                                {( this.state.lead.status === "50_pending_audit" && this.state.list_view && (this.state.auditData && !this.state.auditData.length >0)) &&
                                    <div className="form-group row reset-margin justify-content-center py-4">
                                        {/* {this.state.lead.audited_by == get('user_id') &&
                                            <Button className= "btn btn-danger" disabled = {this.state.calling_api} onClick={this.handleRejectModalOpen}>Reject KYC</Button>
                                        }    */}
                                        {this.state.lead.audited_by == get('user_id') &&
                                        <Button className= "btn btn-primary ml-5" disabled = {this.state.calling_api} onClick={this.handleReassignModalOpen}>Assign to {getValueFromLangJson("rm_label_short")}</Button>
                                        }
                                        {this.state.lead.audited_by == get('user_id') &&
                                            <Button className= "btn btn-primary ml-5" disabled = {this.state.calling_api} onClick={this.handleAudit}>Approve KYC</Button>
                                        }
                                    </div>
                                }                                
                            </Card.Body>
                               </div>
                                </div>
                                }
                                {this.state.audit_view==false &&
                                <div className='col-sm-12 col-lg-4 col-md-4 '>

                                    {this.state.list_view &&

                                    <div className='remark-discussion'>
                                        <div className='remarks-scroll' >
                                        <h5 className='text-center' style={{color:'white'}}>Discussions</h5>
                                    {this.state.remarks_arr.length > 0  ?
                                        <>
                                        { this.state.remarks_arr.map((item,idx)=>{
                                            return(
                                            <div key={idx}>
                                    {item.action == null && item.cmtr_id !== get('user_id') &&
                                        <div key={idx} class="d-flex flex-row">
                                        <div className="p-2 no-padding remarks_audit_chat" >
                                        <div className='d-flex no-padding' style={{width:'250px',}}>
                                        <p className='no-margin' style={{padding:'5px'}}> <span><b>{item.cmtr_name} </b>: </span>{item.cmt}</p>
                                        </div>
                                        <div className="d-flex flex-row-reverse no-padding" >
                                        <div className="p-2 no-padding" style={{fontSize:'9px',marginRight:'3px',opacity:'0.4'}}>{item.cmtr_time}</div>
                                       </div>
                                        </div>
                                        </div>}
                                     
                                        {item.action != null &&
                                        <div key={idx}>
                                        <div className="d-flex justify-content-center" >
                                          <div className='remarks-action'>
                                          <p  className="no-margin no-padding " style={{color:'black'}} >{item.action}
                                           </p>
                                           <p className=' no-margin text-center' style={{fontSize:'9px',marginRight:'3px',opacity:'0.5'}}>{item.cmtr_time}</p>
                                          </div>
                                         
                                       
                                        </div>
                                        
                                         </div>
                                        }

                                      {item.action == null &&  item.cmtr_id == get('user_id')  &&
                                      <div key={idx} class="d-flex flex-row-reverse" >
                                        <div className="p-2 no-padding remarks-rm-chat " style={{color:'white'}}>
                                        <div className='d-flex' style={{width:'250px'}}>
                                        <p className='no-margin' style={{padding:'5px 5px 0px 5px'}}> {item.cmt}</p>
                                        </div>
                                        <div className="d-flex flex-row-reverse" >
                                        <div className="p-2 no-padding" style={{fontSize:'9px',marginRight:'3px',opacity:'0.5'}}>{item.cmtr_time}</div>
                                       </div>
                                        </div>
                                     </div>}
                                            </div>
                                            )
                                        })
                                        
                                     }
                                     
                                     </>:
                                     <div className='text-center' style={{color:'white',}}> No discussions here</div>
                                    }
                                        </div>
                                        <div class="input-group mb-3 d-flex pointer" >
                                            <input type="text"  className='remarks_input' placeholder='Remarks'  value={this.state.add_review} onChange={(e)=>this.input_Handler(e)}/>
                                            <div class="input-group-append">
                                             
                                            <h5 className='no-margin icon-head' style={{}} onClick={(e)=>this.cmt_handler(e)}> <IoMdSend className='send_icon' color='green'  /></h5> 
                                                </div>
                                         </div>
                                    </div>
                                      }
                                </div>}

                               
                            </div>  
                        </Card>    
                    </div>    
                </>
            }
        </>)
    }
}

export default withRouter (LeadAuditContainer);

