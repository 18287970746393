import {  Navigate } from 'react-router-dom';
import React from 'react';
import {createRelationshipManager, viewRelationshipManager} from '../../../actions/relationship_manager_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
//import RelationshipManager from '../component/RelationshipManager';
import Person from '../../common/component/Person';
import '../../../styles/flow.css';
import withRouter from '../../../Router/Withrouter';
import { getValueFromLangJson } from '../../../helpers/common_helper';

class FlowRelationshipManagerContainer extends FlowContainer {

  state = {
    toList: false,
    
    new_relationship_manager_id: null,
    relationship_manager : {},
    address : {}
  }

  componentWillMount() {
   var relationship_manager = null; 
    if(this.props.mode === 'view'){
      const relationshipManager_id = this.props.params.relationshipManager_id;
      const request = {relationshipManager_id};
      viewRelationshipManager(this.req(request))
        .then((response) => {
          if(!response){return };
            relationship_manager = response.data;
            this.setState({relationship_manager: relationship_manager,
                            address : relationship_manager.address
            });
          }
        );
    }else if(this.props.mode  === 'create'){
    }
  } 

  handleSubmit = (event)=>{
    event.preventDefault();
    var relationship_manager = this.state.relationship_manager;
    relationship_manager.country_code = this.country_code;
    relationship_manager.associated_with = "FLOW";
    relationship_manager.address = this.state.address;
    
    createRelationshipManager(this.req({relationship_manager}))
        .then((response) => {
          if(!response){return };
           
              this.setState({new_borrower_id: response.data});
              this.setState({toList: true});
              alert(`New ${getValueFromLangJson("rm_label")} created successfully`);
          
          }
        );
  }

 
  render(){

    if (this.state.toList === true) {
         return <Navigate to={`/relationship_manager/flowrlspmanager/list`}/>
    }
      return (
 
      <div className="container">
         <h4>{this.props.title + (this.state.relationship_manager.first_name? " : " + this.state.relationship_manager.first_name : "")}</h4> 
        <form id="borrower_form" onSubmit={this.handleSubmit}>
            <div id="">
              
             <Person id="relationship_manager" entity="flow_rel_mgr" title={getValueFromLangJson("rm_label")} data={this.state.relationship_manager} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange} changeComponentName="relationship_manager" />
              <br/> 

           
            <input type="button" className={`btn btn-primary ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value={`Create ${getValueFromLangJson("rm_label")}`} />

          </div>

        </form>  
        <br/><br/>
      </div>

      );
  }
}

export default  withRouter(FlowRelationshipManagerContainer);
