import FlowContainer from '../../common/container/core/FlowContainer';
import React from 'react';
import { Link} from 'react-router-dom';
import '../../../styles/flow.css';
import {list_lender_txn_stmts} from '../../../actions/lender_actions';
import {rt_algn,lbl_amt} from '../../../helpers/common_helper';
import {format_date_tbl} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import DatePicker from 'react-datetime';
import {Button,Modal} from 'react-bootstrap';
import {TooltipWithElement,Remarks} from '../../common/partial';
import SyncPayment from '../component/SyncPayment';
import ReconStatus from '../component/ReconStatus';
import moment from 'moment';
import StatementSearch from '../../account/component/StatementSearch';
import {handleResetClick} from '../../account/component/StatementSearch';
import $ from 'jquery';
import {check_priv} from '../../../helpers/storage_helper'
import {ImArrowUpRight,ImArrowDownLeft} from "react-icons/im";
import {get_repayment_summary,create_capture_payment,unlink_payment, reinitiateRecon} from '../../../actions/loan_actions';
import { AccountTransactionToolTipLabel } from '../../common/partial';
import CommonReactTable from '../../../helpers/commonReact_table';
import { BasicReactTable } from '../../../helpers/react_table';
import DataGridTable from '../../CommonComponent/table';
import { Box } from '@mui/material';
import ExportCsv from '../../common/component/ExportCsv';
import { find_id } from '../../../helpers/product_helper';
import CustomTableGridToolbar from '../../CommonComponent/table/components/customTableGridToolbar';

class LenderAccountStmtTransactionsContainer extends FlowContainer {
state = {
  statement_search:{},
  repayment_style: "disable_div",
  lenderTxnStmts: null, 
  account_result:"hide_div",
  modalBox : true,
  modal: null,
  unlink_payment:{},
  reviewed : 0,
  recon_status : true,
  rowdatas:[],
  columndatas:[],
  copy:[],
  show_column : false,
  loader : false,
  page: { page: 0, pageSize: 10 },

}

  componentWillMount(){

    if(check_priv('recon', 'reinitiate_recon')){
      this.setState({show_column : true})
    }

  }


  validate_search(statement_search){
  	if((statement_search.stmt_txn_date__from && statement_search.stmt_txn_date__to && statement_search.account_id) || (statement_search.account_id && statement_search.loan_doc_id) || statement_search.req_parameter){
  		return true
  	}else{
  		alert("Please enter valid search criteria")
      this.setState({loader : false});
  		return false
  	}
  }

  chk_txn_type(record){
    const cr_amt = record.cr_amt
  	if (cr_amt === 0) {
  		return<span >{dd_value(record.stmt_txn_type)} <ImArrowUpRight className="ml-2" color="red"/></span>
  	}
  	else{
  		return <span>{dd_value(record.stmt_txn_type)} <ImArrowDownLeft className="ml-2" color = "green"/></span>
  	}
  }

  chk_fa_id(record){

    const loan_doc_id = record.loan_doc_id
    const recon_status = record.recon_status
    
      if(loan_doc_id){
        if (recon_status == "10_capture_payment_pending"){
          return <span ><Link style = {{color:"grey"}} to={find_id(loan_doc_id)+loan_doc_id} target="_blank" >{loan_doc_id}</Link></span>
        }else if(recon_status == "80_recon_done"){
          return <span ><Link to={find_id(loan_doc_id)+loan_doc_id} style = {{color:"#007bff"}} target="_blank" >{loan_doc_id}</Link></span>
        }
        return <Link to={find_id(loan_doc_id)+loan_doc_id} style = {{color:"grey"}}  target="_blank" >{loan_doc_id}</Link>
      }else{
        return "NA"
      } 
    }

  recon_status(record){
    const recon_status = record.recon_status
    
    if(recon_status == "10_capture_payment_pending" ){
      const id  = record.id
      const loan_doc_id = record.loan_doc_id
       return <Button id={id} variant="danger" onClick={(e) => this.capture_repayment(e,loan_doc_id)}>
       Capture Payment</Button>
    }
    else if(recon_status == "80_recon_done"){
        return <span className="font-weight-bold" style = {{color:"#007bff"}}>{dd_value(recon_status)}</span> 
    }
    else{
        return dd_value(recon_status)
    }
  }


  recon_desc(record){
    const recon_status =record.recon_status
    const recon_desc = record.recon_desc
    const stmt_txn_type = record.stmt_txn_type
    
    if(recon_status == "70_incorrect_amount_in_fa"){
        return dd_value(recon_desc)
    }else if(recon_status == "50_multiple_fas_matched" || recon_status == "05_multiple_fas_captured"){
        var loan_doc_id = recon_desc.split(',')
        return <span><Link to={"/fa/view/"+loan_doc_id[0]} style = {{color:"grey"}} target="_blank" >{loan_doc_id[0]}</Link>&nbsp;|&nbsp;
                <Link to={"/fa/view/"+loan_doc_id[1]} style = {{color:"grey"}} target="_blank" >{loan_doc_id[1]}</Link></span>
    }else if(recon_status == "30_no_matching_fa" ||recon_status == "20_non_match_customers"){
        return dd_value(recon_desc)

    }else if(recon_status == "40_multi_match_customers"){
        var cust_id = recon_desc.split(',')
        return <span> <Link to={"/borrower/indiv/view/"+cust_id[0]} target="_blank">{cust_id[0]}</Link>&nbsp;|&nbsp;
                    <Link to={"/borrower/indiv/view/"+cust_id[1]} target="_blank" >{cust_id[1]}</Link></span>
    }


  }

  handleClose = (event) => {
   this.setState({modalBox: false,selected_payment : null});
  }

  handleSubmit = (event) =>{
    const amount = this.state.selected_payment.amount
    const cust_name = this.state.selected_payment.cust_name

    const resp = window.confirm(`Are you sure the payment of ${lbl_amt(amount, this.currency_code)} was made by ${cust_name}`);
      if(resp == false){
        return;
      }
    const request = {loan_doc_id : this.state.selected_payment.loan_doc_id,
                     acc_stmt_txn_id : this.state.selected_payment.acc_stmt_txn_id, 
                     amount : this.state.selected_payment.amount}
    create_capture_payment (this.req(request,true))
    .then((response)=>{
      
        if(!response){return null;}

        if(response.status === "success"){
            alert('Payment captured successfully')
            this.handleClose()
            this.handleTransactionlist()
        }else{
              alert(response.message)
        }
    })
  }

  capture_repayment = (event, loan_doc_id) => {
  	const request = {acc_stmt_txn_id : event.target.id, loan_doc_id};
    this.setState({unlink_payment:request})
  	get_repayment_summary(this.req(request))
      	.then((response) => {
            if(!response){return null;}
            if(response.status === "success"){
              this.setState({modalBox: true, selected_payment : response.data});
            }else{
              alert(response.message)
            }
  		})
  // loan_doc_id, acc_Stmt_id, amount

  }

  handleUnlinkPayment = (event) =>{
    const amount = this.state.selected_payment.amount
    const cust_name = this.state.selected_payment.cust_name
 
    const resp = window.confirm(`Are you sure this payment of ${lbl_amt(amount, this.currency_code)} is not related ${cust_name}`);
      if(resp == false){
        return;
      }
      const request = this.state.unlink_payment
       unlink_payment (this.req(request,true))
        .then((response)=>{
          if(!response){return null;}
          if(response.status === "success"){
              this.setState({modalBox: false});

            }else{
              alert(response.message)
            }
        })
}
  handleReviewChange = (event) => {
    const target = event.target;
      const value = target.checked;   
      var reviewed = this.state.reviewed;
      var repayment_style = "disable_div"
      if(value){
        reviewed++
      }else{
        reviewed--
      }
      
      if(reviewed == 6){
        repayment_style = ""
      }
      this.setState({
       reviewed, repayment_style
      });
  }

  handleReinitiateRecon = (event, row)=>{
    const amount = row.stmt_txn_type == 'credit' ? row.cr_amt : row.stmt_txn_type == 'debit' && row.dr_amt
    const resp = window.confirm("Are you sure to reinitiate this recon transaction ?")
    if(resp == false){
      return;
     }
     reinitiateRecon(this.req({acc_stmt_id: row.id, txn_id : row.stmt_txn_id, amount : amount}))
    .then((response) => {
      this.resp(response)
      if(!response){return };
      if(response.status === "success"){
        alert(response.message);  
        this.handleTransactionlist()      
      }
    })		
    }




  handleTransactionlist = (event) => { 	
    this.setState({loader : true})
  	let statement_search = this.state.statement_search;

    if (statement_search?.req_parameter != null && statement_search.req_parameter != "") {
    
        statement_search = { req_parameter: statement_search.req_parameter }
    }
  	var is_valid = this.validate_search(statement_search)

  	if(is_valid ){
  		var prev_table = $('#statement_search').DataTable();
    	prev_table.destroy();
  		list_lender_txn_stmts(this.req({statement_search}, false, 'statement_search'))
  			.then((response) => {
          this.resp(response)

   				if(!response)
  				{
  					return this.setState({loader : false});
  				}
  				if(response.status === "success"){
            this.setState({loader : false});
            this.setState(state=>state.rowdatas = response.data.account_txns)
            this.setState(state=>state.copy = response.data.account_txns)
            this.setState(state=>state.columndatas=this.columndatas())

  					// const lenderTxnStmts = response.data.account_txns.map((record,i)=>
            //     <tr key={i}>
            //       <td>{++i}</td>
            //       <td > {this.chk_txn_type(record)}</td>           
            //       <td > {rt_algn(lbl_amt(record.cr_amt, this.currency_code))}</td>
            //       <td > {rt_algn(lbl_amt(record.dr_amt, this.currency_code))}</td>
            //       <td > {rt_algn(lbl_amt(record.balance, this.currency_code))}</td>
            //       <td > {record.stmt_txn_id}</td>
            //       <td > {record.descr}</td>
            //       <td data-sort={record.stmt_txn_date}> {format_date_tbl(record.stmt_txn_date,true)}</td>
            //       <td>{this.chk_fa_id(record)}</td>
            //       <ReconStatus data={record} onSync = {this.handleTransactionlist}/>
            //     </tr>
  					//  );

  					this.setState({account_result:"show_div"});
             		// $('#statement_search').removeAttr('width').dataTable({
                //   "pageLength": 50,
		            //     "order" : [],	                
		            //         "columns": [
		            //           {"width" : "5%"}, 
		            //           {"width": "5%"},
		            //           {"width": "5%"},
		            //           {"width": "5%"},
		            //           {"width": "5%"},
		            //           {"width": "5%"},
		            //           {"width": "5%"},
		            //           {"width": "5%"},
		            //           {"width": "5%"},
		            //           {"width": "5%"}
                     
		            //         ],
		            //         dom:'Bfrtip',
		            //         buttons: [
		            //         'csv','excel','print'
		            //         ]
		            // });        
  				}else{
			        alert(response.message + "\n" + JSON.stringify(response.data));  
			      }
            this.setState({loader : false});
  			});
    }    	
  }  

  columndatas(){
    return[
      // {
      //   headerName : "SI No.", 
      //   field: "row",	
      //   align: 'center',headerAlign: 'center',  

      //   renderCell: (params) => {
      //     return <div>{params.row.index+1}</div>;
      //   }
      // },
      {
        headerName : 'Stmt Txn Type',
        field: "stmt Txn",
        align: 'center',headerAlign: 'center',  
        width:240,resizable: true,
        renderCell:params => {return <div className='d-flex justify-content-center'>{this.chk_txn_type(params.row)}</div>}
      },
      {
        headerName :'Credit',
        field: "cr_amt",
        align: 'center',headerAlign: 'center',  width:240,resizable: true,

        renderCell:params => {return <div className='d-flex justify-content-center'>{lbl_amt(params.row.cr_amt, this.currency_code)}</div>}
      },
      {
        headerName : 'Debit',
        field: "dr_amt",
        align: 'center',headerAlign: 'center',  width:240,resizable: true,

        renderCell:params => {return <div className='d-flex justify-content-center'>{lbl_amt(params.row.dr_amt, this.currency_code)}</div>}
      },
      {
        headerName :'Balance',
        field: "balance",
        align: 'center',headerAlign: 'center',  width:240,resizable: true,

        renderCell:params => {return <div>{lbl_amt(params.row.balance, this.currency_code)}</div>}
      },
      {
        headerName : 'Stmt Txn ID',
        field: "stmt_txn_id",
        width:200,
        align: 'center',headerAlign: 'center',  width:240,resizable: true,

        renderCell:params => {return <div className='d-flex justify-content-center'>{params.row.stmt_txn_id}</div>}
      },
      {
        headerName : 'Description',
        field: "descr",
        align: 'center',headerAlign: 'center',  width:240,resizable: true,
 


        // renderCell:row => {return row.descr}
        renderCell: params => <AccountTransactionToolTipLabel full_text={params.row.descr} no_reduce={true} text_center={true}/>
      },
      {
        headerName : 'Stmt Txn Date',
        field: "stmt_txn_date",
        align: 'center',headerAlign: 'center',  width:300,resizable: true,
        

        renderCell:params => {return <div className='d-flex justify-content-center'>{format_date_tbl(params.row.stmt_txn_date,true)}</div>}
      },
      {
        headerName : 'Ref Acc Number',
        field: "ref_account_num",
        renderCell: params => {return <div className='d-flex justify-content-center'>{params.row.ref_account_num == null ? <span className='mx-5'>NA</span> : params.row.ref_account_num }</div>}
      },
      {
        headerName :'Matching FA ID',
        field: "loan_doc_id",
        align: 'center',headerAlign: 'center',  
        width:300,resizable: true,

        renderCell:params => {return <div className='d-flex justify-content-center'>{this.chk_fa_id(params.row)}</div>}
      },
      {
        headerName :'Recon Status',
        field: "recon",
        align: 'center',headerAlign: 'center',  
        width:240,resizable: true,
        renderCell:params => <div className='d-flex justify-content-center'>{<ReconStatus id = "manual_recon"  show_btn = {false} data={params.row} onSync = {this.handleTransactionlist}/>}</div>
      },
      {
        headerName : 'Action',
        field: "action",
        width:240,resizable: true,
        align: 'center',headerAlign: 'center',  

        renderCell:params => <div className='d-flex justify-content-center'><ReconStatus id = "manual_recon" show_btn = {true} data={params.row} onSync = {this.handleTransactionlist}/></div>
      },
      {
        headerName : 'Action',
        field: "reinitiate",
        width:240,resizable: true,              
        align: 'center',headerAlign: 'center',  
        renderCell: params => <div className='d-flex justify-content-center'> { (params.row.recon_status != "80_recon_done" && params.row.recon_status != "10_capture_payment_pending" ) || (params.row.recon_status != null && (params.row.recon_status != "10_capture_payment_pending" && params.row.loan_doc_id == null)) ?
                                  <input id={params.row.id} type="button" value="Recon Again" onClick={(e)=>{this.handleReinitiateRecon(e, params.row)}} className={`btn btn-primary btn-std}`}/>
                                  :
                                  <input disabled = {true} id={params.row.id} type="button" value="Recon Again" onClick={(e)=>{this.handleReinitiateRecon(e, params.row)}} className={`btn btn-primary btn-std}`}/>
                            }

                          </div>
      },
    ]
  }

  changeHandler = (e) => {		
		const toFilter = e
		const filterfrom = this.state.rowdatas
		const filteredData = filterfrom.filter(value => {
			return(
				value.stmt_txn_type !==null && value.stmt_txn_type.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.stmt_txn_id !==null && value.stmt_txn_id.includes(toFilter) ||
        value.loan_doc_id !==null && value.loan_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
        value.descr !==null && value.descr.toLowerCase().includes(toFilter.toLowerCase())         				
			)
		})
		this.setState(state=>state.copy = filteredData)		
	}

  RepaymentSummaryModal = (summary) => {
    if(!summary){
      return null
    }
    return(
    <Modal show={this.state.modalBox} onHide={this.handleClose} size="l">
          <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
              <Modal.Title>Capture FA Payment ({summary.loan_doc_id})</Modal.Title>
          </Modal.Header>
          <Modal.Body>        
              <div className="container">
              <p>Review the below payment data by clicking over them.</p>
              <form>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Account txn ID</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_1" className="toggle visually-hidden"  onChange={this.handleReviewChange} value="1"/>
                          <label className="select_toogle" for="toggle_1">{summary.stmt_txn_id} </label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>

                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Txn description</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_2" className="toggle visually-hidden"  onChange={this.handleReviewChange} value="1"/>
                          <label className="select_toogle" for="toggle_2">{summary.descr}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Customer</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_3" className="toggle visually-hidden"  onChange={this.handleReviewChange} value="1"/>
                          <label className="select_toogle" for="toggle_3">{summary.cust_name} | {summary.biz_name}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Cust ID</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_4" className="toggle visually-hidden" onChange={this.handleReviewChange} value="2"/>
                          <label className="select_toogle" for="toggle_4">{summary.cust_id} | {summary.acc_number}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Payment amount</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checheckboxckbox" id="toggle_5" className="toggle visually-hidden" onChange={this.handleReviewChange} value="3"/>
                          <label className="select_toogle" for="toggle_5">{lbl_amt(summary.amount, this.currency_code)}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>
                  <div className="rule-form-group form-group row reset-margin">
                      <label for="" className="col-sm-4 label__name reset-padding">Outstanding amount</label>
                      <div className="col-sm-8 reset-padding">
                        <div>
                          <input type="checkbox" id="toggle_6" className="toggle visually-hidden" onChange={this.handleReviewChange} value="3"/>
                          <label className="select_toogle" for="toggle_6">{lbl_amt(summary.current_os_amount, this.currency_code)}</label>
                          <div className="control-me"></div>
                        </div>
                      </div>
                  </div>                   
                   <div className="row">
                      <label><input type="checkbox" onChange={this.handleSMSCheckboxClick} defaultChecked/>&nbsp;
                      Send payment confirmation notification SMS</label>
                    </div>
              </form>
              </div>              
             {this.state.processing && <p style={{color: "red",textAlign: "right"}}>{this.state.processing}</p>}
            </Modal.Body>

          <Modal.Footer>
            <div className="col-md-9 reset-margin reset-padding">
                <button type="button" className= {`btn btn-primary float-right mr-1 ${this.state.repayment_style}`}  onClick={this.handleSubmit} disable>Capture Payment </button>
                <button type="button" className= {`btn btn-danger float-right mr-1`} onClick={this.handleUnlinkPayment}  disable>Unlink Payment </button>
                <button type="button" className="btn btn-outline-primary  mr-1" onClick={this.handleClose} data-dismiss="modal" aria-label="Close">Cancel</button>    
            </div>
          </Modal.Footer>
    </Modal>
         
          )
  }  

  render(){
  	return(
  		<div id="" className='container containerTopmargin max-width no-padding'>
  		{this.RepaymentSummaryModal(this.state.selected_payment)}
  		<div>
  			<div className="form-row no-margin">
  				<h5 className="lender_accounts no-margin headerTitle headerPadvert">Recon (Float) Account Transactions</h5>
  			</div>
  			<StatementSearch 
            id="statement_search"
            data={this.state.statement_search}
            onlist={this.handleTransactionlist}
            disable_btn = {this.state.calling_api}
            onComponentChange={this.handleComponentChange}
            onComponentReset={this.handleComponentReset}  
            onComponentElementChange={this.handleComponentElementChange}/>
  		</div>
  		
  		<div className="container-fluid">
    		<br/>
    		<div className="row" id="float_style">
    			{/*<AccountTransaction id="acc_txn" acc_id={this.acc_id} entity={this.entity} entity_code={this.entity_code} balance={this.balance} />*/}
    		</div>

            <div className={this.state.account_result}>
              {(this.state.rowdatas.length > 0 || this.state.rowdatas.length === 0) && !this.state.loader  &&
						  	<>
                 {this.state.copy.length > 0 ? 
                  <div>
                    <h4>Account Transactions</h4> 
                    {/* <table id ="statement_search" className='table'>
                        <thead className={"text-info"}>
                            <tr>
                                <th>S.No</th>  
                                <th> Stmt Txn Type</th>
                                <th>Credit</th>
                                <th>Debit</th> 
                                <th>Balance</th>
                                <th>Stmt Txn ID</th>
                                <th>Description</th> 
                                <th>Stmt Txn Date</th> 
                                <th>Matching FA ID</th>
                                <th>Recon Status</th>
                            </tr>
                        </thead>
                        <tbody> 

                            {this.state.lenderTxnStmts}

                        </tbody>
                    </table>  */}
                   
                    <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
									    <p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
                      <div style={{position:'relative'}}>
                        <i class="fa-solid fa-magnifying-glass search_pos"></i>
                        <input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
                        {/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
                      </div>
                    </div>
                    {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} defaultSorted={[{id:"stmt_txn_date",desc:false}]} exportCSV={true} csvFile={"Lender_account_statement"} csvData={this.state.copy} searchData={[{...this.state.statement_search,title:'Flow Float Accounts Statement'}]}/>  */}

                     {/* <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} FName={"Lender_account_statement"} csv_file={true} pagination={true} default_page_size = {15}  defaultSorted={[{ id: "stmt_txn_date", desc: false }]} searchData={[{...this.state.statement_search,title:'Flow Float Accounts Statement'}]}/
                     
                     >         */}

                <Box display={'flex'} justifyContent={'end'}>
									<ExportCsv
                  columns={this.state.columndatas??[]}

									total_count={this.state.copy?.length ?? 0} 
									// handleExport={this.handleExportCSV} 
									export_csv_datas={ this.state.copy??[] }
									csvFileName = {'Lender_account_statement'}
									paginationMode= {"client"}

									/>
									</Box>


                    <DataGridTable
                    rows={this.state.copy??[] }
                    columns={this.state.columndatas??[] }
                    paginationMode="client"
                    sortingMode="client"
                    rowCount={this.state.copy?.length  ?? 0}
                    page={this.state.page}
                    setpage={(newPage) => this.setState({ page: newPage })}
                    isLoading={undefined}
                    isError={false}
                    errorText=""
                    noOfColumnsToLoad={10}
                  
              
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      reinitiate: !check_priv('recon', 'reinitiate_recon'),
                    },
                  },
                  }}
                slots={{ toolbar: CustomTableGridToolbar }}
												slotProps={{
												  toolbar: {
													showQuickFilter: true,
												  },
												}}
                    
                />                                              
                  </div>	: <p className='text-center text-white'>No data found</p>}
                </>	
              }
            </div>
            {this.state.loader &&
              <>
                <p className='text-light mt-5 text-center'>Loading...</p>
                <div className="product_takeup_loader"></div>
              </>
            }
          </div>
        </div>
  		)      
  		}
  }
 
export default LenderAccountStmtTransactionsContainer;