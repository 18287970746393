import React from 'react';
import {Form, Col , Row, Button,checkbox } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import {BiCalendar} from "react-icons/bi";
import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import {getPARHealthReport} from '../../../actions/report_actions';
import { BasicReactTable } from '../../../helpers/react_table'
import {dd_value,dd} from '../../../actions/common_actions';
import {format_date_tbl,title_case,Homeacc_prvdrLogoselector, short, get_path, getValueFromLangJson} from '../../../helpers/common_helper';
import Select from "react-select";
// import SelfRegContainer from "../../lead/container/SelfRegContainer";
import { Config, get } from '../../../helpers/storage_helper';
import { call_fetch } from '../../../helpers/fetch_helper';
import withRouter from '../../../Router/Withrouter';
import { v4 as uuidv4 } from 'uuid';
import DataGridTable from '../../CommonComponent/table';
import { Box } from '@mui/material';
import ExportCsv from '../../common/component/ExportCsv';

class PARHealthReport extends FlowComponent {

    state = {
        criteria:{ph_level: ''},
        loader:false,
        par_data:[],
        columndatas:[],
        marrket_level:['super_admin', 'market_admin', 'it_admin'],
        market : get('market'),
        tm_ids : Config('rm_head_person_id'),
        options_country_code : "",
        selected_tm_id:"",
        selected_rm_id:"",
        full_data : false,
        initial_api_call:false,
        page: { page: 0, pageSize: 10 },


    }

    constructor(props) {
        super(props);
        this.get_par_health_data = this.get_par_health_data.bind(this);
    }

    

    
    
//     componentWillMount(){

//         let country = get('market').country_code

//         if(country == "RWA"){
//             this.setState({rwa:true})
//         }

//         this.setState(state=>state.columndatas = this.columndatas())

//         let props_state = this.props.location.state ? this.props.location.state : null;
//         if(props_state){
//             var person_key_id = person_value_id = person_key_name = person_value_name =''
//             if(props_state.message_content.addl_data.ph_level == 'rm'){
//                 var person_key_id = 'rm_id'
//                 var person_key_name = 'rm_name'
//                 var person_value_name = props_state.message_content.addl_data.rm_name
//                 var person_value_id = props_state.message_content.addl_data.person_id
//             } 
//             else if(props_state.message_content.addl_data.ph_level == 'tm'){
//                 var person_key_id = 'tm_id'
//                 var person_value_id = props_state.message_content.addl_data.person_id
//                 var person_key_name = 'tm_name'
//                 var person_value_name = props_state.message_content.addl_data.tm_name
//             }
//             else if(props_state.message_content.addl_data.ph_level == 'overall'){
//                 var person_key_id = 'country_code'
//                 var person_value_id = props_state.message_content.addl_data.market_name
//                 var person_key_name = 'country_code'
//                 var person_value_name = props_state.message_content.addl_data.market_name
//             }
//             this.setState({
//                 from_notification : props_state.from_notification,
//                 ph_level : props_state.message_content.addl_data.ph_level,
//                 [person_key_id] : person_value_id,
//                 [person_key_name] : person_value_name,
//                 person_id : props_state.message_content.addl_data.person_id,

//             })

//             this.setState({criteria:{...this.state.criteria,par_health_id: props_state.message_content.addl_data.par_health_id}}, ()=>this.get_par_health_data())

//          }
//          else{
//             this.get_par_health_data()
//          }
        
    
//             this.load_territory_manager(null)
            

//             let user_id = get('user_id')
//             let tm_id = Config('rm_head_person_id')
//             if(tm_id.includes(user_id)){
//                 this.load_flow_rel_mgrs_under_tm(null)
//                 this.setState({tm_user:true})
//             }
//             else{
//                 this.load_flow_rel_mgr(null);

//             }
        

        

// }



componentWillMount(){
    this.setState(state=>state.columndatas = this.columndatas())

    let user_id = get('user_id')
    let tm_id = Config('rm_head_person_id')
    if(tm_id.includes(user_id)){
        this.setState({tm_user:true})
        this.load_flow_rel_mgrs_under_tm()
        this.setState({criteria:{...this.state.criteria,tm_id:get('user_id')}}, ()=>this.get_par_health_data())
    }
    if(['ops_admin', 'operations_auditor'].includes(get('role_codes')) && !this.props.location.state){
        
        this.load_flow_rel_mgr()
        this.load_territory_manager()
        // this.get_par_health_data()
        if (this.state.criteria.ph_level !== 'rm') {
            this.setState({
                criteria: {
                    ...this.state.criteria,
                    ph_level: 'rm'
                }
            }, () => {
                this.get_par_health_data();
            });
        }
    }

    // let country = get('market').country_code

    // if( Config('tm_access')?.includes(country) ){
    //     this.setState({rwa:true})
    // }

    if(this.state.marrket_level.includes(get('role_codes'))){

        // this.get_par_health_data()
        if (this.state.criteria.ph_level !== 'overall') {
            this.setState({
                criteria: {
                    ...this.state.criteria,
                    ph_level: 'overall'
                }
            }, () => {
                this.get_par_health_data();
            });
        }
    }

        let props_state = this.props.location.state ? this.props.location.state : null;
        if(props_state){
            var person_key_id = person_value_id = person_key_name = person_value_name =''
            if(props_state.message_content.addl_data.ph_level == 'rm'){
                this.load_flow_rel_mgr()
                var person_key_id = 'rm_id'
                var person_key_name = 'rm_name'
                var person_value_name = props_state.message_content.addl_data.rm_name
                var person_value_id = props_state.message_content.addl_data.person_id
            } 
            else if(props_state.message_content.addl_data.ph_level == 'tm'){
                this.load_territory_manager()
                var person_key_id = 'tm_id'
                var person_value_id = props_state.message_content.addl_data.person_id
                var person_key_name = 'tm_name'
                var person_value_name = props_state.message_content.addl_data.tm_name
            }
            else if(props_state.message_content.addl_data.ph_level == 'overall'){
                var person_key_id = 'country_code'
                var person_value_id = props_state.message_content.addl_data.market_name
                var person_key_name = 'country_code'
                var person_value_name = props_state.message_content.addl_data.market_name
            }
            this.setState({
                from_notification : props_state.from_notification,
                ph_level : props_state.message_content.addl_data.ph_level,
                [person_key_id] : person_value_id,
                [person_key_name] : person_value_name,
                person_id : props_state.message_content.addl_data.person_id,

            })

            this.setState({criteria:{...this.state.criteria,par_health_id: props_state.message_content.addl_data.par_health_id}}, ()=>{this.get_par_health_data()})
            window.history.replaceState(null, '', window.location.pathname);

        }

    

}

 load_flow_rel_mgr = ( options_country_code = "" )=>{
        if (options_country_code === "") {
            options_country_code = this.state.market.country_code;
        }
    let req = {country : this.state.market.country_code, time_zone:this.state.market.time_zone, associated_with:'flow',options_country_code : options_country_code }
    var options = []
    call_fetch(get_path('kyc') + '/rel_mgr/name_list', req).then((response)=>{
        if(response && response.status == 'success'){
            if(response.data.length > 0){
                let options_data = response.data.filter((val,idx)=>!this.state.tm_ids.includes(val.id))
                 options = options_data.map((val,idx)=><option key={idx} value={val.id}>{val.name}</option>)
                
                
            }

        }
        this.setState({flow_rel_mgr_dd:options})
    })

}
    
    componentDidUpdate(prevProps, prevState) {

        
        if(this.state.tm_user && this.state.tm_user == true){
            if(prevState.criteria.rm_id != this.state.criteria.rm_id){
                this.get_par_health_data();
            }
        }
    }

    input_handler_portfolio(criteria, key, value, callBack, country_id = null) {

        const deleteKeys = (keys) => keys.forEach(key => delete criteria[key]);
    
        delete criteria['par_health_id'];
    
        let newState = {};

        if (['it_admin', 'super_admin', 'market_admin'].includes(get('role_codes'))) {
            if (country_id !== null && (country_id !== this.state.options_country_code)) {
                delete  criteria['rm_id']
                delete  criteria['tm_id'] 
            } 
        }

        if (key === 'rm_id' || value === 'rm') {

            newState = { selected_tm_id: '', selected_rm_id: value };
            delete  criteria['tm_id']
        } else if (key === 'tm_id' || value === 'tm') {
            newState = { selected_rm_id: '', selected_tm_id: value };
            delete  criteria['rm_id']
        }

        if (value === 'overall') {
            deleteKeys(['par_health_id', 'rm_id', 'tm_id']);
            newState = { selected_tm_id: '', selected_rm_id: '' };
            delete criteria['market_code'];
        }

        if (country_id) {
            if ('market_code' in criteria && value === 'overall') {
                delete criteria['market_code'];
            }
            criteria['market_code'] = country_id;
        }
    

        if (key !== 'market_code' || country_id === null) {
            criteria[key] = value;
        }

        this.setState({
            ...newState,
            criteria,par_data: [] 
        }, () => callBack && callBack());

    }
    
    
    get_input_handler_country(value, key, callBack = null) {
        let criteria = { ...this.state.criteria };
        this.input_handler_portfolio(criteria, key, this.state.criteria.ph_level, callBack,value);
        this.setState({options_country_code:value})
        this.load_flow_rel_mgr(value )
    }


    get_input_handler_portfolio_level(value, key, callBack = null) {
        let criteria = { ...this.state.criteria };

        this.input_handler_portfolio(criteria, key, value, callBack);
        this.load_flow_rel_mgr(this.state.options_country_code)
    }


    // redirect_handler(id){
    //     this.get_data_handler(id)

    // }

    get_par_health_data(){
        this.setState({loader:true})
        var req= { "criteria" : {...this.state.criteria,role_code:get('role_codes')}}
        getPARHealthReport(this.req(req))
        .then((response)=>{
            if(!response){
                this.setState({loader:false})
                return ""}
            if(response.status=="success"){
                let data = response.data?.data.map((row) => ({
                    ...row,
                    id: uuidv4(), 
                })); 
                this.setState({par_data:data,loader:false,full_data:response.data.full_data})
                if(this.state.criteria.ph_level === 'rm'){
                    this.load_flow_rel_mgr(this.state.options_country_code)
                }
                else{
                    this.load_territory_manager()
                }
                 
                if(!this.state.initial_api_call){
                    this.get_parhealth_countries(data??[])
                }
            }
        })
        this.setState({columndatas:this.columndatas()})
    }

    get_par_type = (par_str)=>{
            // Split the input string by underscores
            const parts = par_str.split('_');
        
            const par = parts[1].toUpperCase()
            const day = parts[parts.length-1]
            
           return par + " " + day
                
        
    }

    get_parhealth_countries =(report)=>{
        
        const markets = report.map(item => ({
            country_code: item.country_code,
            country: item.name, 
          }));
          
          const sortedMarkets = markets.sort((a, b) => {
            const targetCountryCode = get('market').country_code; 
            
            if (a.country_code === targetCountryCode) return -1; 
            if (b.country_code === targetCountryCode) return 1;   
            return 0; 
          });
          
          this.setState({ markets: sortedMarkets, initial_api_call:true});
         
      
    }

    columndatas () {
        const columns = [
            {
              headerName : <div className='text-center'>{(get('role_codes')== 'super_admin' || get('role_codes')=='it_admin' || get('role_codes')=='market_admin') && this.state.criteria.ph_level === 'overall' ? 'Market' : 'Name'}</div>,
              field: "name",      
              width:200,   
              align: 'center',headerAlign: 'center',  flex: 1,
              show:true,       
              renderCell : cell =>{return <div className='text-center'>{cell.row.name}</div>}
          },
          {
            headerName :  <div className='text-center'>PAR Type</div> ,
            field: "ph_category",
            maxWidth:150,
            align: 'center',headerAlign: 'center',  flex: 1,

            accessor: "ph_category",  
            renderCell : cell =>{return <div className='text-center'>{this.get_par_type(cell.row.ph_category)}</div>}
  
    
        },
        {
            headerName : <div className='text-center'>Portfolio Level</div>,
            id: "ph_level",
            maxWidth:150,
            align: 'center',headerAlign: 'center',  flex: 1,

            field: "ph_level",  
            renderCell : cell =>{return <div className='text-center'>{/*this.state.marrket_level.includes(get('role_codes')) ? "Market" :*/ cell.row.ph_level.toUpperCase()}</div>}
  
    
        },
          {
            headerName : <div className='text-center'>Comparing Period</div>,
            id: "acc_prvdr",
            maxWidth:150,
            align: 'center',headerAlign: 'center',  flex: 1,

            field: "acc_prvdr_code",  

            renderCell : cell =>{return <div className='text-center'>Weekly</div>}
  
          },
         
          
            { 
                headerName : <div className='text-center'>Previous PAR Value</div>,
                id: "last_week_par",
                maxWidth:150,
                align: 'center',headerAlign: 'center',  flex: 1,

                field: "last_week_par",  
                renderCell : cell =>{return <div className='text-center'><p className='m-0 p-0'>{short(cell.row.last_week_par)} {cell.row.currency_code}</p><p className='m-0 py-1'>{moment(cell.row.last_week_par_date).format("DD MMM YYYY")}</p></div>}
                
          },

          { 
            headerName : <div className='text-center'>As on PAR Value</div>,
            id: "current_par",
            maxWidth:150,
            align: 'center',headerAlign: 'center',  flex: 1,

            field: "current_par",  
            renderCell : cell=>{return <div className='text-center'><p className='m-0 p-0'>{short(cell.row.current_par)} {cell.row.currency_code}</p><p className='m-0 py-1'>{moment(cell.row.identified_unhealthy_at).format("DD MMM YYYY")}</p></div>}
            
      },
         
            
          {
          
            headerName : <div className='text-center'>Increased %</div>,
            id: "par_increase_perc",
            maxWidth:150,
            align: 'center',headerAlign: 'center',  flex: 1,

            field: "par_increase_perc",  
            renderCell : cell =>{return <div className={`text-center ${cell.row.is_unhealthy ? 'text-danger' : ''}`}>{Math.round(cell.row.par_increase_perc)} %</div>}
        },
         
      ]
      return columns.filter((column) => column.show !== false);
    }

    // clear_handler(){
    //     this.setState({criteria:{}})
    // }

    // isValidDate(current) {
    //     const today = moment().startOf('day');
    //     return current.isBefore(today);
    //   }

    render(){
        let country_list = this.state.markets ?? []
       let countries_with_tms =  Config('countries_with_tms')?.includes(get('market').country_code)
       const rm_label_short = getValueFromLangJson("rm_label_short")
          return (
            <div>
             <div className=" max-width no-padding">

                {countries_with_tms? (
                   <div className='d-flex justify-content-center align-items-end' style={{ color: 'white', fontSize: '30px', fontWeight: 700, marginTop: '3%' }}>
                   {`Portfolio Health ${!this.state.from_notification ? "Reports" : "Alerts"}`}
                   {this.state.tm_user && this.state.tm_user ? ` [${rm_label_short}]` : 
                       (['super_admin', 'market_admin','it_admin'].includes(get('role_codes')) && this.state.full_data  ? ` [${rm_label_short} & TM & Market]` : 
                       (get('role_codes') === 'ops_admin' || get('role_codes') === 'operations_auditor') ? ` [${rm_label_short} & TM]` : 
                       (this.state.marrket_level.includes(get('role_codes')) ? " Market" : ''))}
               </div>
               
                ):(
                    <div className='d-flex justify-content-center' style={{color:'white', fontSize:'30px', fontWeight:700, marginTop:'3%'}}>{`Portfolio Health ${!this.state.from_notification ? "Reports" : "Alerts"} [${rm_label_short} ]`}
                     </div>
                )}
             
             
  
            <div>
            <div className='row  py-3 d-flex justify-content-center align-items-end' style={{marginTop:'3%'}}>
  
              
              {/* {this.state.marrket_level.includes(get('role_codes')) && get('market_list') && <div className={'col-md-2 marT-5'}>
                
                <div className="form-group searchInput_height">
                <label className='no-margin'>Market</label>
                {this.state.from_notification ? 
                <select className="form-control" id="market"   onChange={(e)=>{this.get_input_handler_portfolio_level(e.target.value,"country_code")}}  >
                <option selected  value={this.state.country_code}>{this.state.country_code}</option>
                {get('market_list').map((value,idx)=><option value={value.country_code}>{value.country}</option>)}

                </select>
                
                : 
                
                <select className="form-control" id="market"   onChange={(e)=>{this.get_input_handler_portfolio_level(e.target.value,"country_code")}}  >
                    <option selected  value="all">All</option>
                    {get('market_list').map((value,idx)=><option value={value.country_code}>{value.country}</option>)}

                </select>}
                
                </div>
                            
                </div>} */}
  
  
              {this.state.tm_user && this.state.tm_user==true  && <div className={'col-md-3 marT-5'}>
  
                  <div className="form-group searchInput_height py-0 portfolio_select">
                  <label className='no-margin text-white'>{rm_label_short}  Name</label>
                  {this.state.from_notification ? 
                  <select className="form-control" id="rm_id"   onChange={(e)=>{this.get_input_handler_portfolio_level(e.target.value,"rm_id")}} >
                  <option selected value={this.state.person_value_id}>{this.state.rm_name}</option>
                  {/* {this.state[get('user_id') + '_' + 'rel_mgrs_under_tm_dd']} */}

                </select>:
                  <select className="form-control" id="rm_id"   onChange={(e)=>{this.get_input_handler_portfolio_level(e.target.value,"rm_id")}} >
                     
                      {this.state[get('user_id') + '_' + 'rel_mgrs_under_tm_dd']}
  
                  </select>}
                  </div>
                              
              </div>}

  
              { (get('role_codes')=='ops_admin' ||  get('role_codes')=='operations_auditor')   &&
              
              <>
              {this.state.from_notification ? 
              
                    <>   
                    <div className={'col-md-2 marT-5'}>

                    <div className="form-group searchInput_height py-0 portfolio_select">
                        <label className='no-margin text-white'>{this.state.ph_level=='rm'?`${rm_label_short} Name`:'TM Name'}</label>
                        <select className="form-control" id={this.state.ph_level=='rm'?'rm_id':'tm_id'}  onChange={(e)=>{this.get_input_handler_portfolio_level(e.target.value,e.target.id)}} >
                            <option value={this.state.ph_level =='rm'? this.state.rm_id : this.state.ph_level =='tm'? this.state.tm_id:'' } >{this.state.ph_level=='rm'? this.state.rm_name : this.state.ph_level=='tm'? this.state.tm_name:''}</option>
                            {/* {this.state.ph_level=='rm'? this.state.flow_rel_mgr_dd : this.state.flow_territory_mgr_dd} */}

                        </select>
                        </div>
                                    
                        </div>

                        </>
                            :
                            <>
                            {countries_with_tms &&
                            <div className={'col-md-2 marT-5'}>
  
                                    <div className="form-group searchInput_height py-0 portfolio_select">
                                    <label className='no-margin text-white'>Portfolio Level</label>
                                    <select className="form-control" id="ph_level"   onChange={(e)=>{this.get_input_handler_portfolio_level(e.target.value,"ph_level")}}  >
                                        {/* <option selected value="">All</option> */}
                                        <option  value="rm">{rm_label_short}</option>
                                         <option  value="tm">TM</option>
                                        
                                        
                                        

                                    </select>

                                    </div>
                                                
                                    </div>
    }
                                    {this.state.criteria.ph_level && this.state.criteria.ph_level !== 'overall' && 
                                        <div className={'col-md-2 marT-5 '}>
                                        <div className="form-group searchInput_height py-0 portfolio_select">
                                            <label className='no-margin text-white'>{this.state.criteria.ph_level === 'rm' ? `${rm_label_short} Name` : 'TM Name'}</label>
                                            {this.state.criteria.ph_level === 'rm' ? 
                                                <select
                                                    className="form-control"
                                                    id={'rm_id'}
                                                    value={this.state.selected_rm_id} 
                                                    onChange={(e) => { this.input_handler_portfolio(this.state.criteria, 'rm_id', e.target.value, this.callbackFunction); }}>
                                                    <option value="">choose</option> 
                                                    {this.state.flow_rel_mgr_dd}
                                                </select>
                                                :
                                                <select
                                                    className="form-control"
                                                    id={'tm_id'}
                                                    value={this.state.selected_tm_id} 
                                                    onChange={(e) => { this.input_handler_portfolio(this.state.criteria, 'tm_id', e.target.value, this.callbackFunction); }}>
                                                    {this.state.flow_territory_mgr_dd}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                    }

                                    
                            </>
                            }
                            <div className={'col-md-2 marT-5'}>

                        <div className="form-group searchInput_height p-0 d-flex justify-content-start">
                            <button type='button' className='btn btn-primary py-2 px-4' style={{color:'white'}} onClick={()=>this.get_par_health_data()}>Search</button>
                        </div>

                        </div>
               
              </>
              }
              
              { (['super_admin', 'market_admin', 'it_admin'].includes(get('role_codes')))  &&
              
              <>
              {this.state.from_notification ? 
              
                    <>   
                    <div className={'col-md-2 marT-5'}>

                    <div className="form-group searchInput_height portfolio_select">
                        <label className='no-margin text-white'>{this.state.ph_level == 'overall'? '' : this.state.ph_level=='rm'?`${rm_label_short} Name`:'TM Name'}</label>
                        {this.state.ph_level !== 'overall' ? 
                        <select className="form-control" id={this.state.ph_level=='rm'?'rm_id':'tm_id'}  onChange={(e)=>{this.get_input_handler_portfolio_level(e.target.value,e.target.id)}} >
                            <option value={this.state.ph_level =='rm'? this.state.rm_id : this.state.ph_level =='tm'? this.state.tm_id:'' } selected>{this.state.ph_level=='rm'? this.state.rm_name : this.state.ph_level=='tm'? this.state.tm_name:''}</option>
                            {this.state.ph_level=='rm'? this.state.flow_rel_mgr_dd : this.state.flow_territory_mgr_dd}

                        </select>
              : ''}
                        </div>
                                    
                        </div>

                        </>
                            :
                            <> 
                            {this.state.full_data &&
                            <div className={'col-md-2 marT-5 portfolio_select'}>
                             
                                 <div className="form-group searchInput_height py-0">
                                    <label className='no-margin text-white'>Portfolio Level</label>
                                    <select className="form-control" id=""   onChange={(e)=>{this.get_input_handler_portfolio_level(e.target.value,"ph_level")}}  >
                                        {/* <option selected value="">All</option> */}
                                        <option  value="overall">Market</option>
                                        {countries_with_tms &&  <option  value="tm">TM</option>}
                                        <option  value="rm">{rm_label_short}</option>
                                        
                                        
                                    </select>

                                    </div>
                                                
                                    </div>
                                    }
                                    {this.state.criteria.ph_level && this.state.criteria.ph_level !== 'overall' && this.state.full_data &&
                                        <div className={'col-md-2 marT-5'}>
                                        <div className="form-group searchInput_height py-0 portfolio_select">
                                            <label className='no-margin text-white'>{this.state.criteria.ph_level === 'rm' ? `${rm_label_short} Name` : 'TM Name'}</label>
                                            {this.state.criteria.ph_level === 'rm' ? 
                                                <select
                                                    className="form-control"
                                                    id={'rm_id'}
                                                    value={this.state.selected_rm_id} 
                                                    onChange={(e) => { this.input_handler_portfolio(this.state.criteria, 'rm_id', e.target.value, this.callbackFunction); }}>
                                                    <option value="">choose</option> 
                                                    {this.state.flow_rel_mgr_dd}
                                                </select>
                                                :
                                                <select
                                                    className="form-control"
                                                    id={'tm_id'}
                                                    value={this.state.selected_tm_id} 
                                                    onChange={(e) => { this.input_handler_portfolio(this.state.criteria, 'tm_id', e.target.value, this.callbackFunction); }}>
                                                    {this.state.flow_territory_mgr_dd}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                    }

                                    {country_list ?.length>0 &&
                                    <div className={'col-md-2 marT-5'} >
                                    <div className="form-group searchInput_height py-0 portfolio_select">
                                    <label className='no-margin text-white'>Filter by Market</label>
                                    <select className="form-control"
                                    onChange={(e) => this.get_input_handler_country(e.target.value, "market_code")}
                                   
                                    >
                                    {this.state.criteria.ph_level === 'overall' && <option value="">ALL</option>}
                                    
                                    {country_list.map((country) => {

                                        if (country.country_code == this.state.market.country_code && this.state.criteria.ph_level == 'tm'  ) {
                                        return  (
                                            <option className='text-white' key={country.country_code} value={country.country_code}>
                                            {country.country}
                                            </option>
                                        );
                                        } else {
                                        return  this.state.criteria.ph_level !== 'tm' &&(
                                            <option className='text-white' key={country.country_code} value={country.country_code}>
                                            {country.country}
                                            </option>
                                        );
                                        }
                                    })}
                                    </select>
                                    </div>
                                    </div>}

                        {(this.state.full_data || this.state.criteria.ph_level && this.state.criteria.ph_level !== 'overall'  ) &&
                        <div className={'col-md-2 marT-5'}>
                            <div className="form-group searchInput_height p-0 d-flex justify-content-start">
                                <button type='button' className='btn btn-primary px-4 py-2' style={{color:'white'}} onClick={()=>this.get_par_health_data()}>Search</button>
                            </div>

                        </div>}
                                    
                            </>
                            
                            }
                            
               
              </>
              }          </div>
            
       
            </div>
              {/* {this.state.par_data.length>0 &&
            <div className='my-3'>
             <h2 className='text-white'>Self Registered Customer</h2>
             <p className='m-0 text-white'> Total :{this.state.par_data.length}</p>
            
            </div>} */}
             
             
               
            </div>
            {this.state.loader ? 
                  <div class="d-flex justify-content-center">
                      <div class="spinner-border text-info" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
             :
             <div>
    
                {this.state.par_data.length==0 ?<div className='d-flex justify-content-center' style={{color:'white',marginTop:'20vh'}}>No Data Found</div>:
                 <div className='par_health_table'>


                    {/* <BasicReactTable row_data={this.state.par_data} column_data={this.state.columndatas} csv_file={true} FName="PAR Health Report"  pagination={this.state.par_data.length > 9? true:false}   excess_time_action={false} default_page_size={15}  searchData={[{title:'Portfolio Health Reports'}]}/>  */}

                    <Box display={'flex'} justifyContent={'end'}>
						<ExportCsv
						total_count={this.state.par_data?.length ?? 0} 
									// handleExport={this.handleExportCSV} 
						export_csv_datas={ this.state.copy??[] }
						csvFileName = {'PAR Health Report'}
						paginationMode= {"client"}
                        columns={this.state.columndatas??[]}

									/>
					</Box>

                    <DataGridTable
                    rows={this.state.par_data??[] }
                    columns={this.state.columndatas??[] }
                    paginationMode="client"
                    sortingMode="client"
                    rowCount={this.state.par_data?.length  ?? 0}
                    page={this.state.page}
                    setpage={(newPage) => this.setState({ page: newPage })}
                    isLoading={undefined}
                    isError={false}
                    errorText=""
                    noOfColumnsToLoad={10}
                //     columnVisibility={{
                //       reinitiate: check_priv('recon', 'reinitiate_recon'),
                //        traderName: true,  
                // }}
                    
                />  
                </div>
                }
    
             </div>
             }

        </div>
          );
      }
    }
    
    export default withRouter(PARHealthReport);



