import HmeAlrtCtxProvider from "../../context/adminHmeAlrtCtx/hmeAlrtCtxProvider";
import { get } from "../../helpers/storage_helper";
import UserList from "../user/container/UserList";
import AdminHome from "./AdminHome";

const Home = () => {
  console.log('get_rolw_codes', get('role_codes'))
  return (
    <HmeAlrtCtxProvider>
      {get('role_codes') == 'system_admin' ? <UserList /> : <AdminHome />}
    </HmeAlrtCtxProvider>
  );
};

export default Home;
