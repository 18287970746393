import React from 'react';
import {Card,Button} from 'react-bootstrap';

import {format_date_tbl, capitalize_first_letter, title_case, getValueFromLangJson} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';

import _ from 'lodash';

import FlowComponent from '../../common/component/core/FlowComponent';
import {Form, Col} from 'react-bootstrap';
import Select from "react-select";
import DatePicker from 'react-datetime';
class SearchReKYCLeads extends FlowComponent {
    state ={
        lead :{
			profile_status: 'open',
		},
		profile_status:true ,
        columns :[],
        lead_list:[],
        lead_table:"hide_div",
        flow_rel_mgr_dd:null,
    }

       
    componentWillMount(){
        this.load_flow_rel_mgr(null,"multiselect_rm")
        this.setOptionsListToStateFromAppApi('common', 'list',this.req({country_code : this.country_code, status: "enabled",biz_account:true}) , ['acc_prvdr_code', 'name'], "acc_prvdrs");
        this.setOptionsListToStateFromAppApi("common","priv_users",this.req({priv_code:'',status:'enabled',role_codes:["operations_auditor"]}), ['id' , 'name']);

    }

 

    render(){
        return(
            <div className="container containerTopmargin max-width no-padding">            
                <form className="mb-3" autocomplete="off">
                   
                            <Form className="mb-3">
	        	<Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}>
                  
                <div className='col-md-3 marT-5 '>
						<Form.Group className={`${this.state.section1} no-margin searchInput_height pb-0 pt-3`}>
							<Form.Label className='no-margin'>{this.state.action} From</Form.Label>

							<DatePicker inputProps={{disabled:this.state.disable_date}} closeOnSelect={true} value={this.state.__from}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"
							onChange={(value) => {this.handleDateChange('created_from', value); this.setState({_from:value});}} />
						</Form.Group>
					</div>
					<div className='col-md-3 marT-5 pb-0'>
						<Form.Group className={`${this.state.section1} no-margin searchInput_height pb-0 pt-3`} >
							<Form.Label className='no-margin'>{this.state.action} To</Form.Label>
							<DatePicker inputProps={{disabled:this.state.disable_date,}} closeOnSelect={true} value={this.state.__to}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"
							onChange={(value) => {this.handleDateChange('created_to', value); this.setState({__to:value});}}  />

						</Form.Group>
					</div>
                    <div className="col-3">
                        <span> {getValueFromLangJson("rm_label_short")}</span>
                        <Select onChange={(e) =>{this.props.handlePurposeChange(e,"flow_rel_mgr_id");}}
                              value = {this.state.select_value}
							  options={this.state.flow_rel_mgr}
							  placeholder="choose"
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  isMulti/>
                    
                       
                    </div>

                    <div className="col-3">
                        <span>Account Provider</span>
                        <Form.Control as="select" id="acc_prvdr_code" onChange={this.handleChange}>
                            {this.state.acc_prvdrs_dd}
                        </Form.Control>
                    </div>

                    
                                               
                    </Form.Row>
              
                <Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}>

                <div className="col-3">
                        <span>ReKYC Reason</span>
                        <Form.Control as="select" id="kyc_reason"  onChange={this.handleChange}>
                        {this.getSelectOptions('rekyc_reason')}
                        </Form.Control>
                    </div>
                  
                    <div className="col-3">
                        <span>Cust ID</span>
                        <Form.Control id="cust_id" onChange={this.handleChange} >         
                        </Form.Control>   
                    </div>
                    <div className="col-3">
                        <span>National ID</span>
                        <Form.Control id="national_id" onChange={this.handleChange} >         
                        </Form.Control>   
                    </div>

                    <div className="col-3">
                        <span>Account Purpose</span>
                        <Form.Control as="select" id="acc_purpose"  onChange={this.handleChange}>
                            {this.getSelectOptions("account_purpose", true, 'customer')}
                        </Form.Control>
                    </div>

                    <div className="col-3 mt-3">
                    <Select onChange={(e) =>{this.props.handlePurposeChange(e,"status");}}
                      value = {this.state.select_value}
                      options={this.getMultiSelectOptions('lead_status')}
                      placeholder="Lead Status"
                      styles={this.select_custom_styles()}
                      classNamePrefix="my-select" // Add a custom class prefix to target the components
                      isMulti/>
                    </div>   
                   
                    <div className="col-3">
                        <span> Audited By</span>
                        <Form.Control as="select" id="audited_by" onChange={this.handleChange}>
                            {this.state.priv_users_dd}
                        </Form.Control>
                    </div>

                    <div className='col-3' style={{alignSelf:'end'}}>
                        <Form.Group as={Col} className='no-padding'>
                            <Form.Label for="closed_profile" className='no-margin'> Include Closed Profiles
                            <Form.Check type="checkbox"  defaultChecked={this.props.profile_status.closed_profile} id = "closed_profile"  className= "float-left no-margin" onChange={(e) =>{this.props.onComponentElementChange(e)}} style={{paddingRight:5,paddingLeft:5}}/>
                            </Form.Label>
                        </Form.Group>
                    </div>
                                 
                </Form.Row>
                
                  

            </Form>
                </form>
              
            </div>        )
    }
}



export default SearchReKYCLeads;