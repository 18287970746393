import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {  Navigate } from 'react-router-dom';
import {Card,Button} from 'react-bootstrap';
import {submitLead,viewLead} from '../../../actions/lead_actions';
import {Edit} from '../../common/partial';
import {dd_parent} from "../../../actions/common_actions";
import AccPurposeMultiSelect from '../../../helpers/accPurposemulti';
import DisplayImage from '../../common/component/DisplayImage';
import { lbl_amt} from '../../../helpers/product_helper';
import { SiGooglemaps } from 'react-icons/si';
import { event } from 'jquery';
import Modal from 'react-bootstrap/Modal';
import GoogleMapSetup from '../../../helpers/googleMap';
import { getValueFromLangJson } from '../../../helpers/common_helper';
class Lead extends FlowComponent{
   state = {
      comp_name: "lead", 
      to_edit: false, 
      lead : {},
      save_button_style :"hide_div",
      disable_div : false,
      lead_map:false
    }

    

	componentDidUpdate(prevProps, prevState, snapshot) {
      if(!prevProps.editable && this.props.editable){
          this.handleEdit()
      }
  }
    

   componentWillMount(){
      super.componentWillMount()
      this.load_flow_rel_mgr()
      this.setOptionsListToStateFromAppApi('common', 'list',this.req({country_code : this.country_code, status: "enabled",biz_account:true}) , ['acc_prvdr_code', 'name'], "acc_prvdrs");
      this.getSelectOptionsFromResult(dd_parent('account_purpose', 'customer'), 'acc_purpose', ['data_code', 'data_value'])
      if(this.props.data.acc_purpose && this.props.data.acc_purpose.includes('terminal_financing') ){
         this.setState({disable_div : true});
      }
    
   }

   lead_map_location_modal(){
      this.setState({lead_map:true})
   }
   map_handleClose=(event)=>{
      this.setState({lead_map:false})
   }
   disableAccNumber = (event) => {
      var disable_div  = this.state.disable_div
      if(event.target.value == 'terminal_financing'){
         disable_div = true
      }else{
         disable_div = false
      }
      this.setState({disable_div});
    }

   render(){
      if(this.state.to_edit === true) {
        return <Navigate to={`/lead/edit/${this.state.id}`}/> 
      }
      return(
         <div className ="row justify-content-center">
            <div className = {`col-md-12 ${ this.props.lead_status ==="23_pending_statement" ? this.props.div_style: 'show_div'}`}>
               <div className="row  py-2 d-flex justify-content-center align-items-center">
               
                  <div className={`col-md-4 ${this.state.div_style}` }>
                  {/* <span>Account Purpose<font className="text-danger">   *  </font></span>
                     <select id="acc_purpose" value={this.props.data.acc_purpose} onChange={(e) => { this.handleChange(e);this.disableAccNumber(e)}}  className="form-control" type="text" required="required">
                        {this.state.acc_purpose_dd}
                     </select> */}
                     <AccPurposeMultiSelect id="acc_purpose" onChange={this.handleChange} acc_purposes={this.state.acc_purpose_dd} initialAct_purpose={this.props.mode === "view" ? this.props.data.acc_purpose : null} must={false}/>
                  </div>
               </div>
               <fieldset className = "border border-dark my-2 p-2 fieldset">
                  <legend className="w-auto" style ={{font_size : "15px" }} >Biz Info</legend>
                  <div className ={`row  ${this.state.div_style} no-padding-input`}>
                  <div className={"col-md-3"}>
                           <span>Biz Name (or) Cust Name <font className="text-danger">   *  </font></span>
                           <input type="text" value={this.props.data.biz_name} id="biz_name" onChange={this.handleChange} className="form-control"   />
                     </div>
                     <div className={"col-md-3 no-padding-input"}>
                           <span>Account Provider<font className="text-danger">   *  </font></span>
                           <select id="acc_prvdr_code" value={this.props.data.acc_prvdr_code}   onChange={this.handleChange} className="form-control" type="text" >
                              {this.state.acc_prvdrs_dd}
                           </select>
                     </div>
                        {/* <div className="form-group row  justify-content-center py-2 ">
                           <label className="col-md-5"><b>First Name</b><font className="text-danger">   *  </font></label>
                           <input type="text" id="first_name" value={this.props.data.first_name}  onChange={this.handleChange}className="form-control col-md-5"   maxLength='20' required="required"/>
                        </div> */}
                     
                     <div className = "col-md-3 no-padding-input"  >
                           <span><>Account Number</></span>
                           <input disabled = {this.props.data.acc_purpose && this.props.data.acc_purpose.includes('terminal_financing')} type="text" id="account_num" value={this.props.data.account_num} onChange={this.handleChange} className="form-control"   />
                     </div>
                        {/* <div className="form-group row  justify-content-center py-2 ">
                           <label className="col-md-5"><b>Last Name</b><font className="text-danger">   *  </font></label>
                           <input type="text" id="last_name" value={this.props.data.last_name}  onChange={this.handleChange}className="form-control col-md-5"   maxLength='20' required="required"/>
                        </div> */}
                     <div className={"col-md-3 no-padding-input"}>
                           <span>National ID</span>
                           <input type="text" id="national_id" value={this.props.data.national_id}  onChange={this.handleChange}className="form-control"   />
                     </div>
                   

                  </div>
               </fieldset>
               <fieldset className = "border border-dark p-2 my-5 fieldset">
                  <legend className="w-auto" style ={{"font_size" : "15px" }} >Contact Info</legend>
                  <div className ={`row  ${this.state.div_style}`}>
                     <div className = "col-md-3 no-padding-input">
                           <span>Mobile Number<font className="text-danger">   *  </font></span>
                           <input type="text" id="mobile_num" value={this.props.data.mobile_num} onChange={this.handleChange} className="form-control"   />
                        </div>
                        <div className="col-md-3 no-padding-input">
                           <span>Territory</span>
                           <select id="territory" value={this.props.data.territory}   onChange={this.handleChange} className="form-control" type="text" >
                                 {this.getSelectOptions("territory")}
                           </select>
                        </div>
                        <div className="col-md-3 no-padding-input">
                           <span>Location</span>
                           <select id="location" value={this.props.data.location}   onChange={this.handleChange} className="form-control" type="text" >
                              {this.getSelectOptions("location")}
                           </select>
                        </div>
                     <div className = "col-md-3 no-padding-input">
                           <span>Landmark</span>
                           <input type="text" id="landmark" value={this.props.data.landmark} onChange={this.handleChange} className="form-control"/>
                        </div>
                     </div>
                   {this.props.data.gps&&  <div className ={`row d-flex justify-content-end `}>
                       <div className = "col-md-3 mt-5 mb-2 location_right_align ">
                          
                          <p className='no-margin pointer location_right_align view_on_map business_operations_text'  onClick={()=>this.lead_map_location_modal()}> View GPS <SiGooglemaps  className='text-white ml-2 mr-4 hover_style_color' size="20"/> </p>
                        </div>
                     </div>}
               </fieldset>
               {this.props.data.hasOwnProperty("commission") &&
                  <fieldset className = "border border-dark p-2 my-5 fieldset">
                     <legend className="w-auto" style ={{"font_size" : "15px" }} >Commission</legend>
                     <div className ={`row`}>
                        <div className = "col-md-3 no-padding-input">
                              <span>Commission</span>

                              <input disabled type="text" id="mobile_num" value={lbl_amt(this.props.data.commission, this.currency_code)} onChange={this.handleChange} className="form-control"   />
                           </div>
                        <div className = "col-md-3">
                              <span >Commission Proof Photo</span>
                              <DisplayImage className="img-fluid" image_path={this.props.data.photo_commission_proof_path} file_name={this.props.data.photo_commission_proof}></DisplayImage>
                           </div>
                        </div>
                  </fieldset>
               }
               <fieldset className = "border border-dark m4-5 p-2 fieldset">
                  <legend className="w-auto" > Assignment</legend>
                  <div className ={`row ${this.state.div_style}`}>
                     <div className = "col-md-3 no-padding-input">
                           <span style ={{"font_size" : "15px" }}><b> {getValueFromLangJson("rm_label_short")}</b></span>
                           <select id="flow_rel_mgr_id" value={this.props.data.flow_rel_mgr_id}  onChange={this.handleChange} className="form-control" type="text" >
                              {this.state.flow_rel_mgr_dd}
                           </select>
                        </div>
                  </div>
               </fieldset>

              
              {/* <div className='row d-flex justify-content-start'>
                 <div className='col-md-4'>
                 <fieldset className = "border border-dark gps-border my-5 p-2">
                  <legend className="w-auto text-white" > GPS</legend>

                     <button  type="button" class="btn btn-primary" onClick={()=>this.lead_map_location_modal()}>View on Map <SiGooglemaps  className='text-white ml-2' size="15"/>   </button>
                  
               </fieldset>
                 </div>
            
               </div>     */}
            

            {this.props.mode=="view" && this.state.div_style == "" &&
               <div className={` mb-5 d-flex justify-content-center no-padding-input  ${this.state.save_button_style}`}>
               <input onClick={(e) => {this.handleSave(e, "reload")}} type="button" className="btn btn-success" value="Save"/>
            </div>
            }
            </div>

            <Modal show={this.state.lead_map} onHide={this.map_handleClose} className='view_lead_location_modal'  backdrop="static" animation={true}>
               <Modal.Header  style={{paddingTop:'8px'}} closeButton>
                  <Modal.Title> Map Location</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className='col-12'>
                     <GoogleMapSetup coordinates={this.props.data.gps}  />
                  </div>
               </Modal.Body>
            </Modal>
         </div>
      )
   }
}

export default Lead;
