import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import MenuList from "../home/menuList";
import { Autocomplete, TextField, Typography, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const FuzzySearchBox = (props) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [options, setOptions] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.sub_menus) {
      const mergedArray = Object.values(props.sub_menus).flat();
      setOptions(mergeMenus(props.sub_menus));
    }
  }, [props.sub_menus]);

  const mergeMenus = (menuObject) => {
    let mergedArray = [];
    Object.values(menuObject).forEach((menuArray) => {
      menuArray.forEach((menuItem) => {
        if (menuItem.sub_menus && Array.isArray(menuItem.sub_menus && menuItem.prev)) {
          menuItem.sub_menus.forEach((subItem) => {
            if (subItem.prev && menuItem.url !== "modal") {
              mergedArray.push(subItem);
            }
          });
        } else {
          if (menuItem.prev && menuItem.url !== "modal") {
            mergedArray.push(menuItem);
          }
        }
      });
    });
    return mergedArray;
  };

  const fuse = new Fuse(options, {
    keys: ["label", "fuzzy_key"],
    threshold: 0.3,
  });

  const handleInputChange = (event, value) => {
    if (value !== undefined) {
      setQuery(value);
    }
    if (value && value.trim() !== "") {
      const fuseResults = fuse.search(value).map((result) => result.item);
        const uniqueResults = fuseResults.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.label === item.label) 
      );
  
      setResults(uniqueResults);
    } else {
      setResults([]);
    }
  };
  

  const handleOptionSelect = (event, selectedOption) => {
    if (selectedOption && selectedOption.url) {
      navigate(selectedOption.url);
      setQuery("");
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={results}
      getOptionLabel={(option) => option.label}
      inputValue={query}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search"
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon sx={{ fontSize: "20px" }} />,
            style: {
              backgroundColor: theme.palette.custom_colors.primary_500,
              borderRadius: "12px",
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.label}>
          <Typography variant="s_inter_regular_white">
            {option.label}
          </Typography>
        </li>
      )}
      sx={{
        pl: 2,
        borderRadius: "12px",
        width: "334px",
        ".MuiAutocomplete-listbox": {
          maxHeight: "250px",
        },
        ".MuiOutlinedInput-root input": {
          padding: "9.5px 14px",
          // width: '260px',
        },
      }}
    />
  );
};

export default MenuList(FuzzySearchBox);
