import {  Navigate } from 'react-router-dom';
import React from 'react';
import {createRelationshipManager, viewRelationshipManager} from '../../../actions/relationship_manager_actions';
import FlowContainer from '../../common/container/core/FlowContainer';
import RelationshipManager from '../component/RelationshipManager';
import Person from '../../common/component/Person';
import '../../../styles/flow.css';
import {get} from '../../../helpers/storage_helper';
import withRouter from '../../../Router/Withrouter';
import { getValueFromLangJson } from '../../../helpers/common_helper';

class AccprvdrRelationshipManagerContainer extends FlowContainer {

  state = {
    toList: false,
    
    new_relationship_manager_id: null,
    relationship_manager : {},
    data_prvdr: {},
    address:{}
  }

  componentWillMount() {
    
    var relationship_manager = null; 
    if(this.props.mode === 'view'){
    
      const relationshipManager_id = this.props.params.relationshipManager_id;
      const request = {relationshipManager_id};
      viewRelationshipManager(this.req(request))
        .then((response) => {
          if(!response){return };
            relationship_manager = response.data;
            this.setState({relationship_manager: relationship_manager});
            const data_prvdr_dtl = {"associated_entity_code":relationship_manager.associated_entity_code,"id": relationship_manager.id };
            this.setState({data_prvdr:data_prvdr_dtl,
                        address : relationship_manager.address
                      });
          }

        );
    }else if(this.props.mode  === 'create'){
      
 
    }
  } 

  handleSubmit = (event)=>{
    event.preventDefault();
    var relationship_manager = this.state.relationship_manager;
    relationship_manager.country_code = this.country_code;
    relationship_manager.associated_with = "acc_prvdr";
    //relationship_manager.associated_entity_code = this.state.data_prvdr.associated_entity_code;
    // relationship_manager.associated_entity_code = get('acc_prvdr_code');
    relationship_manager.address = this.state.address;
    createRelationshipManager(this.req({relationship_manager}))
        .then((response) => {
          if(!response){return };
            if(response.status === "success"){
              this.setState({new_borrower_id: response.data});
              this.setState({toList: true});
              alert(`New ${getValueFromLangJson("rm_label")} created successfully`);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }

 
  render(){
    if (this.state.toList === true) {
      return <Navigate to={`/relationship_manager/dprlspmanager/list`}/>
    }
      return (
 
      <div className="container">
         <h4>{this.props.title + (this.state.relationship_manager.first_name? " : " + this.state.relationship_manager.first_name : "")}</h4> 
        <form id="borrower_form" onSubmit={this.handleSubmit}>
            <div id="">
              
              <RelationshipManager id="data_prvdr" entity="data_prvdr_rel_mgr" data={this.state.data_prvdr} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
             <Person id="relationship_manager" entity="data_prvdr_rel_mgr" title={getValueFromLangJson("rm_label")} data={this.state.relationship_manager} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
              <br/>

            {/*<hr/>
            <Address id="address" title="Relationship Manager Address" data={this.state.address} 
                    mode={this.props.mode} 
                    onComponentChange={this.handleComponentChange}/>
              <br/><hr/>*/}

            <input type="button" className={`btn btn-primary ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value="Create Relationship Manager" />
          </div>

        </form>  
        <br/><br/>
      </div>

      );
  }
}

export default withRouter (AccprvdrRelationshipManagerContainer);