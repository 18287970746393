import React from 'react';
import CommonReportContainer from "../container/common_report_container";
import InputSelectionHeader from "./InputSelectionHeader";
import { getReport } from "../../../actions/report_actions";
import FlowComponent from "../../common/component/core/FlowComponent";
import moment from 'moment';
import { BasicReactTable } from '../../../helpers/react_table';
import Toggle from '../../common/component/Toggle';
import { delcohort } from '../../../actions/common_actions';
import { check_priv } from '../../../helpers/storage_helper';
import ReportChart from '../component/ReportChart';


export class CohortReport extends FlowComponent{
  state={
    selectedTab:false,
    country_code:"*",
    startDate:moment().subtract('6', 'months').format("YYYYMM"),
    endDate:moment().format("YYYYMM"),
    paid_between:null,
    paid_between_1:"",
    paid_between_2:"",
    paid_after:null,
    dateRange:"monthly",
    column_data:[],
    row_datas:[],
    csv_columns:[],
    activeCurrency :"1",
    activeCurrencyVal : null,
    rowdataRaw:[],
    del_items:null,
    loader:false,
    paid_late:null,
    submitbtn:false,
    radioOption: "",
    cohort_report_csv_data:[],
    chart_wise_report:false,
  }
  componentWillMount(){
    this.generate_report()

  }
  getExport_data (){
    const { row_datas, dateRange} = this.state;
    const data = row_datas;
    if(data && data.length >0){
    const data_keys = Object.keys(data&&data[0]).filter((key) => {return (key !== "country_code" &&( key !=="disbursal_month") &&( key !=="disbursal_week" ));});

    return data_keys;
    
  }

  }

  componentDidUpdate(prevProps,prevState){

    if (prevState.country_code != this.state.country_code||prevState.dateRange!=this.state.dateRange  ){
      this.setState({
        paid_after: "",
        paid_between_1: "",
        paid_between_2: "",
        paid_late:"",
        paid_between: "",
        radioOption:""
      }, () => {
        this.generate_report();
      });
  }
  else if(prevState.selectedTab!=this.state.selectedTab||prevState.activeCurrencyVal!=this.state.activeCurrencyVal||prevState.activeCurrency!=this.state.activeCurrency){
    this.reload_report()
  }
  else if(prevState.startDate!=this.state.startDate||prevState.endDate!=this.state.endDate){
    this.setState({row_datas:[],rowdataRaw:[],submitbtn:true})
  }
  else if(prevState.radioOption!=this.state.radioOption){
    this.setState({
    paid_after: "",
    paid_between_1: "",
    paid_between_2: "",
    paid_late:"",
    paid_between: ""})
  }

  if((JSON.stringify(this.state.row_datas) !== JSON.stringify(prevState.row_datas) || (this.state.activeCurrency !== prevState.activeCurrency)) && !this.state.chart_wise_report){ 
   
    var export_data= this.getExport_data()

    this.setState({cohort_report_csv_data: this.convertExportCsvDatas(this.state.row_datas,this.state.activeCurrencyVal,this.state.activeCurrency, export_data ),
    header_props:this.convert_header_props(this.state.csv_columns,this.state.activeCurrency, export_data)  })
  }
  if(prevState.dateRange!==this.state.dateRange){
    this.setState({
      column_data:this.columnDatas()
    })
  }

 

  }
  handleToggleBtn = (toggle_val) =>{
    if(this.state.selectedTab !== toggle_val){
        this.setState({selectedTab: toggle_val})
        
    }
}
handleToggledaterange = (toggle_val,report_type=null) =>{
  if(report_type!=null){
    this.setState({chart_wise_report:!this.state.chart_wise_report})

  }else{
  if(toggle_val==false){
    this.setState({dateRange:"monthly"})
  }
  else{
    this.setState({dateRange:"weekly"})

    }
    }
}

  generate_report=(report_type=null)=>{
  
    var req = {
    'country_code' : this.state.country_code,
    "start_month":this.state.startDate,
    "end_month":this.state.endDate,
    "paid_between":this.state.paid_between,
    "paid_after":this.state.paid_after,
    "paid_late":this.state.paid_late,
    "date_range":this.state.dateRange,
    "report_type":"cohort_report"}
    
    this.handleCohortReport(req);
  }

  reload_report = () => {
    if(this.state.rowdataRaw &&this.state.rowdataRaw.length>0){
    this.setState({row_datas:this.processData(this.state.rowdataRaw)},()=>this.setState({column_data:this.columnDatas()}))
    }
}



set_attributes = (array) => {
    const newStartDate = moment(array.startDate).format('YYYYMM');
  const newEndDate = moment(array.endDate).format('YYYYMM');

  if (this.state.startDate !== newStartDate || this.state.endDate !== newEndDate || this.state.activeCurrency !== array.activeCurrency || this.state.activeCurrencyVal !== array.activeCurrencyVal || this.state.country_code !== array.country_code) {
    this.setState({
      startDate: newStartDate,
      endDate: newEndDate,
      activeCurrency: array.activeCurrency,
      activeCurrencyVal: array.activeCurrencyVal,
      country_code: array.country_code
    });
  }
}


  formatDateForMonthView = (dateString) => {
    const year = parseInt( dateString&&dateString.toString().substring(0, 4));
    const month = parseInt(dateString&&dateString.toString().substring(4)) - 1;
    const date = new Date(year, month, 1);
    return date.toLocaleString("default", { month: "long", year: "numeric" });
  };
  formatDateForweekview(dateString) {
    const year = dateString&&dateString.toString().substring(0, 4);
    const weekNumber = dateString&&dateString.toString().substring(4);
    const date = new Date(year, 0, 1); 
    const daysToAdd = (weekNumber - 1) * 7; 
    date.setDate(1 + daysToAdd); 
    const month = date.toLocaleString('default', { month: 'long' });
    const weekInMonth = `W${Math.ceil(date.getDate() / 7)}`;
    const formattedOutput = `${year}-${month.charAt(0).toUpperCase()}${month.slice(1)}-${weekInMonth}`; 
    return formattedOutput;
  }
  
  

 formatCurrencyAmount = (amount) => {
  if(this.state.selectedTab){
    return amount
  }
  else{
    const moneyValue = Math.round(amount * (this.state.activeCurrencyVal && this.state.activeCurrencyVal && this.state.country_code != '*' ? this.state.activeCurrencyVal : 1)).toLocaleString();
    return `${moneyValue} ${this.state.activeCurrency}`;
  }
};


  
 columnDatas = (csv = false) => {
  const { row_datas, dateRange} = this.state;
  const data = (row_datas?.length>0) ? row_datas :[];
  const filteredData = data.map(item => {
    const keysToRemove = Object.keys(item).filter(key => item[key] === undefined);
    const filteredItem = { ...item };
  
    keysToRemove.forEach(key => delete filteredItem[key]);
    
    return filteredItem;
  });

  
  if(filteredData && filteredData.length>0){
  var keys = Object.keys(filteredData&&filteredData[0]).filter((key) => {
    return (
      // key !== "disbursal_month" &&
      // key !== "amount_disbursed" &&
      // key !== "paid_on_time" &&
      key !== "country_code"
      // key !== "not_paid_yet" &&
      // key !== "disbursal_week"
    );
  });
  

  let weekOrmonth_index = keys.indexOf(this.state.dateRange=="weekly" ? "disbursal_week": "disbursal_month");

  if (weekOrmonth_index !== -1) {
    keys.unshift(keys.splice(weekOrmonth_index, 1)[0]);
  }
  const columns = [
   
   
    ...keys?.map((key) => {
      
      return {
        Header:<div style={{position: 'relative'}}>
         {
         ((key=="amount_disbursed") || (key=="paid_on_time") || (key=="not_paid_yet")) ?
          <div>
          {
          (key.replace(/_/g, " ")).charAt(0).toUpperCase() + (key.replace(/_/g, " ")).slice(1)
          } 
          </div>  
            :
        <>
        {((key=="disbursal_month") || (key=="disbursal_week") )? 
        <div>
          {(key=="disbursal_month") ? "Month" :'Week'}
        </div>
        :
        <div>
        <div onClick={() => this.toremovecolumns(key)}  style={{position: 'absolute', top: "-10px", right: `${key.includes("paid_between") ? "-10px" : "0px"}`}}>
          <img width="75%" className='pointer' src='/img/close.png' title='Remove'/>
        </div>
        <div >
        {key.split('_')?.map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word).join(' ')}
        </div>
        </div>
      }

        </>
      }
      </div>
       ,
        id: ((key=="disbursal_month") || (key=="disbursal_week"))  ? this.state.dateRange=="weekly" ? "disbursal_week": "disbursal_month" :key, 
        accessor: ((key=="disbursal_month") || (key=="disbursal_week"))  ? this.state.dateRange=="weekly" ? "disbursal_week": "disbursal_month" :key,
        disableSortBy:true,

        show: (key=="not_due_yet" && csv == false) ? false : true,

        Cell: (cell) =>{return <p className='text-center'> {
           (key=="amount_disbursed") ? Math.round(cell.row.original.amount_disbursed*(this.state.activeCurrencyVal && this.state.country_code != '*' ?this.state.activeCurrencyVal:1)).toLocaleString() +" " + this.state.activeCurrency
           :((key=="disbursal_month") || (key=="disbursal_week") )? 
           <div>{(key=="disbursal_month") ? 
           this.formatDateForMonthView(cell.row.original.disbursal_month):
           this.formatDateForweekview(cell.row.original.disbursal_week)
          }

           </div>
           
          : this.formatCurrencyAmount(cell.row.original[key])}</p>},
      };
    }),
  ];
  
  return columns.filter((column) => column.show !== false);
  }
};
       

  processData = (data) => {
    if (this.state.selectedTab) {
      return data&&data.map(item => {
        const { country_code, disbursal_month, amount_disbursed,disbursal_week, ...rest } = item;
        const newData = {
          country_code,
          disbursal_month,
          amount_disbursed,
          disbursal_week,
          ...Object.fromEntries(
            Object.entries(rest)?.map(([key, value]) => {
              if (amount_disbursed === 0 || Object.values(rest).every(v => v === 0)) {
                return [key, 0];
              } else {
                return [key, ((value / amount_disbursed) * 100).toFixed(2) + '%'];
              }
            })
          )
        };
        return newData;
      });
    } else {
      return data;
    }
  }
  
  


  handleCohortReport = (event)=>
    {
      this.setState({loader:true})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {
                  this.setState({loader:false})
                  return
                  ;}
                if(response.status === "success"){
                  if(this.state.chart_wise_report){
                    this.setState({loader:false})
                    const chart_raw_data = response.data;
                    const chart_data = this.make_series_data(chart_raw_data);
                    
                    // this.setState({TotalAndOverdueFARaw,TotalAndOverdueFA,TotalAndOverdueFADiv:true})
    
                    this.setState({chart_data: chart_data})
                  }else{
                    this.setState({paid_after:"",paid_between:"",paid_between_1:"",paid_between_2:"",paid_late:"",radioOption:""})
                    const rowdataRaw = response.data.data
                    const row_datas = this.processData(rowdataRaw);
                    this.setState({loader:false})
                    this.setState({rowdataRaw,row_datas},()=>this.setState({column_data:this.columnDatas()}))
                    this.setState({csv_columns:this.columnDatas(true)})
                  }
                    
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                }
            });
    }
    onAdd=()=>{
     this.generate_report();
     this.setState({paid_after:"",paid_between_1:"",paid_between_2:"",paid_between:"",paid_late:"",radioOption:""})


    }


    handleInputChange = (event) => {
      const value = parseInt(event.target.value)
      const checkedvalue = isNaN(value) || !event.target.value.trim() ? null : value
      const inputName = event.target.name
    
      this.setState({
        [inputName]: checkedvalue
      });
    }
   
    handleInputChangewithin = (event) => {
      const name = event.target.name;
      const value = parseInt(event.target.value);
      const otherValue = parseInt(this.state[name === "paid_between_1" ? "paid_between_2" : "paid_between_1"]);
      
      if (!isNaN(otherValue) && value === otherValue) {
        event.preventDefault();
        alert("Please enter the valid range")
        return
      }
      
      const newArray = [value, otherValue];
      const paidbetween = newArray.sort((a, b) => a - b);
    
      this.setState({
        [name]: value,
        paid_between: (isNaN(paidbetween[0]) || isNaN(paidbetween[1])) ? null : paidbetween
      });
    }
    
    
    
    handleDateRangeChange = (event) => {
      this.setState({dateRange: event.target.value})
    }
  deleteItem = (stateKey, req) => {
      delcohort(this.req(req)).then((response) => {
        this.setState({ loader: true });
        if (!response) {
          this.setState({ loader: false });
          return;
        }
    
        if (response.status === "success") {
          this.setState({ loader: false });
          this.generate_report();
          this.setState({ [stateKey]: null });
        } else {
          alert(response.message + "\n" + JSON.stringify(response.data));
        }
      });
    };
    
    toremovecolumns = (keyToDelete) => {
      const result = [];
      const splitKey = keyToDelete.split('_');
      if (splitKey[0] === 'paid' && splitKey[1] === 'between') {
        const numbers = splitKey.slice(2).filter((s) => !isNaN(s))?.map(Number);
        result.push(numbers);
        const stateKey = 'paid_between';
        const req = {
          [stateKey]: result[0]
        }
        this.deleteItem(stateKey, req);
      } else if (splitKey[0] === 'paid' && splitKey.includes("late")) {
        const value = parseInt(splitKey[1]);
        result.push([value]);
        const stateKey = 'paid_late';
        const req = {
          [stateKey]: result[0]
        }
        this.deleteItem(stateKey, req);
      } else if (splitKey[0] === 'paid' && splitKey.includes("after")) {
        const value = parseInt(splitKey[2]);
        result.push([value]);
        const stateKey = 'paid_after';
        const req = {
          [stateKey]: result[0]
        }
        this.deleteItem(stateKey, req);
      }
    }
    handleKeyDown(event) {
      const target = event.target;
      const min = parseInt(target.min);
      const max = parseInt(target.max);
      const value = parseInt(target.value + event.key);
      if (isNaN(value) &&value==0|| value < min || value > max) {
        event.preventDefault();
        alert(`Enter the value in between ${min}-${max}`)
      }
    }
    handleFormSubmit = () => {
      this.setState({
        paid_after: "",
        paid_between_1: "",
        paid_between_2: "",
        paid_late:"",
        paid_between: "",
        radioOption:""
      }, () => {
        this.generate_report();
        this.setState({submitbtn:false});
      });
    }
    
    handleRadioChange = (event) => {
      this.setState({
        radioOption: event.target.value
      });
    }

  render(){
  
    return (<>
    {check_priv("report","cohort_report")&&

      
    <div className="container cohort">
        <InputSelectionHeader
          set_attr = {(res)=>this.set_attributes(res) }
          default_start_date={ moment(this.state.startDate, "YYYYMM").format("Y-MM")}
          default_end_date={moment(this.state.endDate, "YYYYMM").format("Y-MM")}
          title={"Recovery Cohort Report"}
          showMonthRange={true}
          showSubmitbtn={true}
          onsubmit={()=>this.handleFormSubmit()}
          submitbtnenable={this.state.submitbtn}
          fromCohort={true}
        />
        <>
        <div className="row mt-5">
          <div className="col-6 text-white">
            <fieldset className="border border-white" style={{ borderRadius: "10px",width:"75%" }}>
              <legend className="w-auto ml-3 p-1 fieldset_legend" style={{ fontSize: "15px" ,top:'-12px',background:'#1a2035'}}>Add column</legend>
                <div className="p-2 cohort_input py-4 ">
                  
                    <label for="option1" className={this.state.radioOption=="option1"?"cohort_input_enabled mt-1":"cohort_input_disable mt-1"} > <input type="radio" id="option1" value="option1" name="paid_option"  onChange={this.handleRadioChange} checked={this.state.radioOption=="option1"} /> Paid late between
                    <label onClick={() => this.setState({ radioOption: "option1" })}><input disabled={this.state.radioOption !== "option1"} name="paid_between_1" maxLength="2" type="number" min="1" max="89" onKeyDown={this.handleKeyDown} onChange={this.handleInputChangewithin} value={this.state.paid_between_1} className="form-control shadow-none" /></label>
                      and
                    <label onClick={() => this.setState({ radioOption: "option1" })}> <input disabled={this.state.radioOption !== "option1"} type="number" name="paid_between_2" min="1" max="90"  onKeyDown={this.handleKeyDown} onChange={this.handleInputChangewithin} value={this.state.paid_between_2} maxLength="2" className=" form-control shadow-none" /></label> 
                      days
                    </label>
                    <div className='row m-0 mt-3'>
                    <label for="option2" className={this.state.radioOption=="option2"?"cohort_input_enabled px-0":"cohort_input_disable px-0"} >  <input checked={this.state.radioOption=="option2"} type="radio" id='option2' value="option2" name="paid_option" onChange={this.handleRadioChange} />  Paid 
                          <label onClick={() => this.setState({ radioOption: "option2" })}><input min="1" max="90" disabled={this.state.radioOption != "option2"} onKeyDown={this.handleKeyDown} value={this.state.paid_late} name="paid_late"  type="number" maxLength="2" onChange={this.handleInputChange} className=" form-control shadow-none" /></label>
                          days late
                    </label>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-8'>
                        <label for="option3" className={this.state.radioOption=="option3"?"cohort_input_enabled":"cohort_input_disable"}><input checked={this.state.radioOption=="option3"} type="radio" name="paid_option" id='option3' value="option3"  onChange={this.handleRadioChange}  />   Paid late after
                        <label onClick={() => this.setState({ radioOption: "option3" })}><input disabled={this.state.radioOption != "option3"} min="1" max="90" onKeyDown={this.handleKeyDown} value={this.state.paid_after} name="paid_after"  type="number" maxLength="2" onChange={this.handleInputChange} className="form-control shadow-none" /></label>
                          days
                        </label>
                      </div>
                      <div className='col-4 text-right mt-3'>
                      <button onClick={this.onAdd}  disabled={!(this.state.paid_between_1 && this.state.paid_between_2) && !this.state.paid_after&&!this.state.paid_late} className={`btn btn-sm ${((this.state.paid_between_1 && this.state.paid_between_2 )|| this.state.paid_after||this.state.paid_late) ? 'btn-success' : ' btn-secondary'}`}>Add</button>
                      </div>
                    </div>
                </div>
            </fieldset>
          </div>
          {this.state.row_datas&& this.state.row_datas.length>0&&!this.state.loader&&<div className="col-6 text-right ">
                <div className="btn-group btn-group-md" style={{ margin: 0, padding: 0 }}>
                  <Toggle title="Show figures as"  width="100" leftLabel= "Amount" rightLabel="Percentage" fontSize="15"  val={this.state.selectedTab} aligntext="left" set_tog_val={(res) => this.handleToggleBtn(res)} />
                </div>
                <Toggle id="daterange" width="100" leftLabel= "Monthly" rightLabel="Weekly" fontSize="15"  val={this.state.dateRange==="monthly"?false:true} aligntext="left" set_tog_val={(res) => this.handleToggledaterange(res)} />
          </div>}
      
        </div>
      
          {this.state.row_datas&& this.state.row_datas.length>0 &&!this.state.loader&& this.state.column_data?.length>0 &&
          <div className='Cohortreport mt-3'>
          <BasicReactTable className='border' hidetooltiptitle={true}  export_csv_datas={this.state.cohort_report_csv_data} row_data={this.state.row_datas}  column_data={this.state.column_data} csv_file={true} FName="Cohort Report" global_search={false} pagination={true} excess_time_action={false} default_page_size={10} loader={this.state.row_datas ? false : true} searchData={[{title
          :'Recovery Cohort Report',start_Date:this.state.startDate,end_Date:this.state.endDate,paid_between_1:this.state.paid_between_1,paid_between_2:this.state.paid_between_2,paid_late:this.state.paid_late,paid_after:this.state.paid_after,dateRange:this.state.dateRange}]}  header_props={ !this.state.selectedTab ? this.state.header_props ? this.state.header_props :false :false}  /> 
            </div>
          }
        
  {this.state.loader&&<>
  <p className='text-light mt-5 text-center' >Loading...</p>
  <div className="product_takeup_loader "></div></>}
    </></div>}</>
    );

  }

}
export default CohortReport

