import { Badge, Button, IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useTheme } from "@emotion/react";
import { ButtonProps, BadgeProps } from "@mui/material";

interface CustomButtonProps {
  text?: string;
  variant?: ButtonProps["variant"];
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  loader_size?: number;
  badge_style?: BadgeProps["sx"];
  loader_styles?: React.CSSProperties;
  component?: React.ElementType;
  badge?: boolean;
  badge_content?: React.ReactNode;
  type?: ButtonProps["type"];
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text = "",
  variant = "text",
  onClick,
  isDisabled = false,
  isLoading = false,
  startIcon,
  endIcon = "",
  style = {},
  loader_size = 30,
  badge_style = {},
  loader_styles,
  component = "button",
  badge = false,
  badge_content = "",
  type,
}) => {
  const theme = useTheme();

  return (
    <Button
      sx={style}
      variant={variant}
      onClick={onClick}
      startIcon={startIcon ?? ""}
      endIcon={endIcon}
      disabled={isDisabled || isLoading}
      component={component}
      type={type}
    >
      {isLoading ? (
        <CircularProgress
          variant="indeterminate"
          thickness={4}
          value={100}
          size={loader_size}
          sx={loader_styles}
        />
      ) : badge ? (
        <Badge
          color="secondary"
          sx={badge_style}
          badgeContent={badge_content}
        >
          <NotificationsNoneIcon
            sx={{
              // @ts-ignore
              color: theme.palette.custom_colors.secondary_a_50,
              fontSize: "25px",
            }}
          />
        </Badge>
      ) : (
        text
      )}
    </Button>
  );
};

export default CustomButton;
