import zIndex from "@mui/material/styles/zIndex";

export const login_box = {
    borderRadius: '12px',
    width: '508px',
    // height:"481px",
  };

export const bg_box = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    padding: '15px',
  };

 export const input_style = {
    width: '100%',
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderRadius: '10px',
        },
    },
}

export const mail_badge = {
  padding: '8px',
  borderRadius: '8px',
  '& .MuiBadge-badge': {
    backgroundColor: 'red',
  },
 
}
export const market_dropdown = (theme) => ({
  backgroundColor: theme.palette.custom_colors.primary_500 ,
  borderRadius: '5px',
  minWidth: 200,
  mx: 2,
  [theme.breakpoints.down('sm')]: {
    minWidth:  90,
  }
});

export const side_bar = {
  position:'fixed',
  top:'0px',
  zIndex:2000
}

export const sm_display_none= {
   display: { xs: 'none',md:'block' } 
}

export  const drawer_toggle ={
  fontSize:'24px',
  position:'absolute',
  top:'10px',
  left: {lg:'46px', xs: '46px' } 
}
 

export const sign_in_button = (theme) => ({
  backgroundColor: theme.palette.custom_colors.secondary_500,
  color: theme.palette.custom_colors.secondary_a_50,
  borderRadius: '12px',
  fontSize: "0.875rem", fontWeight: '500'

});

export const  list_item_button = {
  minHeight: 48,
  px: 2.5,
  fontSize: '25px',
  justifyContent: 'center',
}

export const active_side_menu = (theme) => ({
  background: theme.palette.custom_colors.secondary_500,
  borderRadius: '10px',
  "&:hover": {
    backgroundColor: theme.palette.custom_colors.secondary_500
  }
})

export const Drawer_style = (theme) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.custom_colors.primary_500,
    borderRadius: open ? '0px 20px 20px 0px' : '0px'
  }
})

export const active_menu_item = (theme) => ({
  background: theme.palette.custom_colors.secondary_500,
  borderRadius: '20px',
  padding: '5px 10px'
})

export const quick_action_btn =(theme,text) => ({
  margin:'8px 0px',
   width: '202px',
   height: '34px',
   padding: '0px',
   color: 'white',
   fontSize: '0.7rem',
   fontWeight: '400',
   borderRadius: '10px',
   background: text.includes('log') ? '#7a8289' : '#3f63f48a',
})

export const login_box_padding = (footer) =>( {
  padding:footer ? `40px 40px  12px 40px` : '40px'
 })