import React, { forwardRef } from 'react';
import {Card,Button} from 'react-bootstrap';
import {GetSMSVendors,SwitchSMSVendor} from '../../../actions/sms_action';
import {capitalize_first_letter, title_case} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import CommonReactTable from '../../../helpers/commonReact_table';
import Toggle from '../../common/component/Toggle';
import {Form} from 'react-bootstrap';
import FlowComponent from '../../common/component/core/FlowComponent';
import { BasicReactTable } from '../../../helpers/react_table';
import { get } from '../../../helpers/storage_helper';


class Switch_sms_container extends FlowContainer{


    state ={
        columns: [],
        lists: [],
        copy: [],
        resourse : null,
        sms_vendor_status : null,
        edited_status : {} ,
        edit :true   

    }

    componentWillMount(){
        this.get_sms_vendor()
    }

    get_sms_vendor = () =>{

        GetSMSVendors(this.req({purpose : "list"}))
        .then((response) => {
            if (!response) { return };
            if (response.status === "success") {
                this.setState(state => state.columns = this.columndatas(response.data.headers))
                if (response.data.records_arr) {
                    this.setState({row_datas: response.data.records_arr, copy: response.data.records_arr })
                    
                }
            }
        })
    }

    handlesave = (e, row) => {

    
        if(!window.confirm("Are you sure want to switch the status of the SMS Vendor "+dd_value(row.vendor_name)+" to "+dd_value(this.state.status)+" ?")) return;
      

        SwitchSMSVendor(this.req({ purpose : "switch", status : this.state.status, id :this.state.id}))
            .then((response) => {
                this.resp(response)
                window.location.reload()     
            })
    }

    
    handleEditValues = (e,row) =>{
        this.setState({editable : true , edit_id : row.id, save : true, edit : false} , () =>{this.setState({columns : this.columndatas()})

        })
    }

    handleChange = (e, row) => {
        
        
        this.setState({id : row.id, status : e})
    }

    cell_editable = (row) =>{

        if(this.state.editable == true && this.state.edit_id == row.id){
        let id = row.id;
          return  <div>
            <select  id="status" style={{color : "black"}} defaultValue={row.status} onChange={(e) => this.handleChange(e.target.value, row)} >    

                <option style={{color : "white"}} value = ''>choose</option>
                <option style={{color : "white"}} value = 'primary'>Primary</option>
                <option style={{color : "white"}} value = 'secondary'>Secondary</option>
                <option style={{color : "white"}} value = 'inactive'>Inactive</option>
                
           </select>
           </div> 
        
        }else{
            
           
            
            if( row.status == 'primary'){
                return <span class="badge bg-success" style={{fontSize:"12px",minWidth : 90}}>{dd_value(row.status)}</span>
            }else if(row.status == 'secondary'){
               return <span class="badge  bg-secondary" style={{fontSize:"12px",minWidth : 90}} >{dd_value(row.status)}</span>
            }else if(row.status == 'inactive'){
              return  <span class="badge bg-danger" style={{fontSize:"12px",minWidth : 90}} >{title_case(row.status)}</span>
            }
            
       
        }
       
         
    }

     
    columndatas() {
        
        return [
            {
                
                Header:  <div className='text-center' style={{fontSize:"15px"}}>Vendor Name</div>,
                id: "name",
                accessor: row => <div className='d-flex justify-content-center'>{dd_value(row.vendor_name)}</div>,
                style : {textAlign:"center", fontSize:"13px"} ,
                minWidth:100,
                maxWidth:200  

                
            },
            {
                Header:  <div className='text-center' style={{fontSize:"15px"}}>Vendor Code</div>,
                id: "code",
                accessor: row => <div className='d-flex justify-content-center'>{capitalize_first_letter(row.vendor_code)}</div>,
                style : {textAlign:"center", fontSize:"13px"} ,
                minWidth:100,
                maxWidth:200  
                
            },
            {
                Header:  <div className='text-center' style={{fontSize:"15px"}}>Balance</div>,
                id: "balance",
                accessor: row => <div className='d-flex justify-content-center'>{ row.vendor_code == 'AIT' ? row.balance == 0 ? "NA": row.balance+ get('market').currency_code : row.balance == 0 ? "NA": row.balance+" "+'credits' }</div>,
                style : {textAlign:"center", fontSize:"13px"} ,
                minWidth:100,
                maxWidth:200  

            },
            
            {
                    Header:  <div className='text-center' style={{fontSize:"15px"}}>Status</div>,
                    id: "status",
                    accessor : row => <div className='d-flex justify-content-center'>{this.cell_editable(row)}</div>,
                    cell : row => <div className='d-flex justify-content-center'>{this.cell_editable(row)}</div>,
                    style : {textAlign:"center", fontSize : "13px"} ,
                    minWidth:100,
                    maxWidth:200  
                   
                    
            },
           
            {
                Header: <p className='text-center' style={{fontSize:"15px"}}>Action</p>,
                id: "cs_status",
                accessor : row=><div className='d-flex justify-content-center'>{(this.state.editable == true && this.state.edit_id == row.id ? <Button size="sm" style={{fontSize : "10px"}}onClick={e => this.handlesave(e,row) }> save </Button>  : <Button size="sm" style={{fontSize : "10px"}} onClick={e => this.handleEditValues(e, row) }> Edit </Button> )}</div>,
                style : {textAlign:"center"} ,
                minWidth:50,
                maxWidth:200  
            },
        ]}

    render(){
        return(
            
            <>
                <div>
                    <h5 className='mt-4' style={{ color: "white" }}>{this.props.title}</h5>
                </div>

                <div className="card-body m-auto" style={{width : "1000px"}}>
                    <div style={{marginTop : '100px'}}>
                    {/* <CommonReactTable showPag={true} defaultPageSize={15} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{ id: "id", asc: true }]} /> */}

                    <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} FName={"sms"} csv_file={true} pagination={this.state.copy !== undefined && this.state.copy.length > 9 ? true : false} default_page_size = {15} defaultSorted={[{ id: "id", asc: true }]}   />  	

                    </div>
                </div>
            </>

            
        )
    }
}
export default Switch_sms_container;