import { append_request } from "./common_helper";
import { get } from "./storage_helper";
import * as Yup from "yup";

interface RequestPayload {
  [key: string]: any; // Allow any key with any type of value
}

export const common_req = (
  request: RequestPayload | null = null,
  send_ap_code: boolean = true,
  key: any = null,
  calling_api: boolean = true
) => {
  return append_request({ ...request }, send_ap_code, key);
};

export const amount_format = (amount: string | number): string => {
  const nf = new Intl.NumberFormat();
  const currency = get("market").currency_code ?? "";

  if (amount) {
    return nf.format(Number(amount)) + " " + currency;
  } else {
    return "-";
  }
};

import moment, { Moment } from "moment";
import { REQUIRED } from "../consts";
import { RhfOptionType, RhfOptionTypeDataKeyOmited } from "../type";

export function format_date(
  date: Moment | string | null,
  incl_time: boolean = false,
  year_digits: 2 | 4 = 4,
  str_date_format: string = "YYYY-MM-DD h:mm a"
): string {
  let out_format = year_digits === 2 ? "DD MMM YY" : "DD MMM YYYY";

  if (incl_time) {
    out_format += " h:mm a";
  }

  if (moment.isMoment(date)) {
    return date.format(out_format);
  } else if (!date) {
    return "";
  } else {
    return moment(date, str_date_format).format(out_format);
  }
}

export const requiredRecordValidation = Yup.mixed<Record<string, string>>()
  .required(REQUIRED)
  .test(
    "not-default-value",
    REQUIRED,
    (value) => value && value.id !== "choose" && value.name !== "Choose"
  );


  export const RhfOptionsTypeAndKeyCheckProperties = {
    getOptionLabel: (option: RhfOptionType | string) =>
      typeof option === "object" ? option.name : option,
    getOptionKey: (option: RhfOptionType | string) =>
      typeof option === "object" ? option.id : option,
    isOptionEqualToValue: (option: any, value: any) =>
      typeof option === "object" && typeof value === "object"
        ? option.id === value.id
        : option === value,
  };
  
  export const RhfOptionsDataKeyOmitedTypeAndKeyCheckProperties = {
    getOptionLabel: (option: RhfOptionTypeDataKeyOmited | string) =>
      typeof option === "object" ? option.name : option,
    getOptionKey: (option: RhfOptionTypeDataKeyOmited | string) =>
      typeof option === "object" ? option.actualId : option,
    isOptionEqualToValue: (option: any, value: any) =>
      typeof option === "object" && typeof value === "object"
        ? option.actualId === value.actualId // updated to use actualId
        : option === value,
  };