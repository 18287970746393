import React from 'react';
import {listPreappr, removePreApproval} from '../../../actions/pre_approval_actions';
import {format_date, getValueFromLangJson} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import CommonReactTable from '../../../helpers/commonReact_table';
import Select from "react-select";
import { get } from '../../../helpers/storage_helper';
import {Button} from 'react-bootstrap';
import { BasicReactTable } from '../../../helpers/react_table';

class PreApprovalContainer extends FlowContainer {
    state ={
        pre_appr :{
		},
        columns :[],
        pre_appr_list:[],
        pre_appr_table:"hide_div",
		copy:[],
		loader:false,
		flow_rel_mgr_id:null
    }

	componentWillMount(){
		this.list_of_pre_appr();
		this.load_flow_rel_mgr(null,"multiselect_rm_name")
	}

	list_of_pre_appr = () => {
		let flow_rel_mgr_id = this.state.flow_rel_mgr_id ? this.state.flow_rel_mgr_id : null;
		this.setState({loader:true})
		listPreappr(this.req({list_pre_appr: true, flow_rel_mgr_id}))
		.then((response) => {
			this.resp(response)
			this.setState({loader:false})
			if(!response){return };
			if(response.status === "success"){
				this.resp(response)
				var columns = this.state.columns
					columns = [{  
									Header: 'Cust ID',  
									id: 'cust_id' ,
									show:true,
									accessor :row =>
										<Link  to={"/borrower/indiv/view/"+row.cust_id} target="_blank" >{row.cust_id}</Link>
								},{  
									Header: 'Pre-approved By',  
									id: 'rm_name' ,
									show:true,
									accessor :row =>row.rm_name
								},{  
									Header: 'Approval Count',  
									id: 'appr_count' ,
									show:true,
									accessor: row => <div className={'tableAmount_centerAlign'}>{row.appr_count}</div>
								},{  
									Header: 'Approval Start Date',
									id: 'appr_start_date',
									show:true,
									accessor:row => format_date(row.appr_start_date)  
								},{
									Header: 'Approval Expiry Date',
									id: 'appr_exp_date' ,
									show:true,
									accessor:row => format_date(row.appr_exp_date)  
								},{
									Header: 'Status',  
									id: 'status' ,
									show:true,
									accessor:row => dd_value(row.status)  

								},{
									Header: <div className='d-flex justify-content-center'>Action</div>,  
									id:"id",
									show:get("role_codes") == "relationship_manager" ? false : true,
									accessor: row => <div className='d-flex justify-content-center'>
										<input id={row.cust_id} type="button" value="Remove Pre-approval" onClick={this.handleRemoveApproval} className={`btn btn-primary btn-std ${this.configure_btn_style}`} style={{fontSize:'10px'}}/>
									</div>,
								}]
							}
					columns.filter((column) => column.show !== false);
					this.setState({columns,pre_appr_list:response.data,copy :response.data,pre_appr_table:"show_div"})

		})
	}


	handleFilter = (event) => {
        let ids = event.map((e)=>e.value);
        this.setState({flow_rel_mgr_id:ids})
    }

	handleSearch = () =>{
		this.list_of_pre_appr()
	}

		handleRemoveApproval = (event)=>{
			const resp = window.confirm("Are you sure to remove the Pre-approval")
			if(resp == false){
				return;
			 }
			removePreApproval(this.req({cust_id: event.target.id}))
			.then((response) => {
				if(!response){return };
				if(response.status === "success"){
					alert(response.message);  
					window.location.reload()
				}
			})		
    	}

	changeHandler = (e) => {      
		const toFilter = e
		const filterfrom = this.state.pre_appr_list      
		const filteredData = filterfrom.filter(value => { 
		  return(    
			value.cust_id !== null && value.cust_id.toLowerCase().includes(toFilter.toLowerCase()) 
		        
		  )
		})
		this.setState(state=>state.copy = filteredData)		
	}

    render(){
        return(
			<div className='container containerTopmargin max-width no-padding'>
			<div>
				<h5 className='no-margin headerTitle'>Pre-approved Customers</h5>  
				{get("role_codes") == "relationship_manager" &&  
				<div className='row mt-4 mb-5'>
					<div className="col-md-3">
						<Select onChange={(e) => {this.handleFilter(e)}}
                        value = {this.state.select_value}
						options={this.state.flow_rel_mgr}
						styles={this.select_custom_styles()}
						classNamePrefix="my-select" 
						placeholder={getValueFromLangJson("rm_label")}
						isMulti/>
                    </div>
					<div className='col-md-2'>
                        <Button disabled={this.state.flow_rel_mgr_id ? false : true} className= "mr-3 btn btn-primary" onClick={this.handleSearch}>SUBMIT</Button>
					</div>
				</div>   
				}        
				{this.state.pre_appr_list.length > 0 && this.state.loader == false?
					<>
						<div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
							<p className='no-margin text-white'><b>Total Entries : {this.state.pre_appr_list.length}</b></p>
							<div style={{position:'relative'}}>
								<i class="fa-solid fa-magnifying-glass search_pos"></i>
								<input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
								{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
							</div>
						</div>
						{/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{id:"name",desc:false}]} exportCSV={true} csvFile={"Pre-approved Customers"} csvData={this.state.copy ? this.state.copy : []} searchData={[{title:'Pre Approved Customer'}]}/> */}

						<BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns}  pagination={this.state.copy.length > 10} default_page_size = {10}  searchData={[{title:'Pre Approved Customer'}]} FName={"Pre-approved Customers"}  csv_file={true}  defaultSorted={[{id:"name",desc:false}]}/>  

					</> : this.state.loader ? 
					<div className='product_takeup_loader'></div> : <div className='text-center'>No results found</div>
				}
			</div>
		  </div>

			 )
    }
}

export default PreApprovalContainer;
