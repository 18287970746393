import  FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {changeDisbursalStatus, getDisburseAttempt, retryDisbursal, searchHeldLoan, cancelLoan} from '../../actions/loan_actions';
import {format_date_tbl, format_date, title_case, lbl_amt, get_time_diff,Homeacc_prvdrLogoselector} from '../../helpers/common_helper';
import CaptureFailedDisbursal from '../loan/component/CaptureFailedDisbursal';
import DisburseLoan from '../loan/component/DisburseLoan';
import '../../styles/flow.css';
import {Link} from "react-router-dom";
import {dd_value, touch} from "../../actions/common_actions";
import { check_priv, Config } from '../../helpers/storage_helper';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FcInfo} from 'react-icons/fc'
import LoanDates from "../common/partials/LoanDates";
import * as moment from "moment";
import {TooltipLabel} from "../common/partial";
import {ExcesstimeAction} from "../common/partial";
import {GrRefresh} from "@react-icons/all-files/gr/GrRefresh";
import { get,set } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';
import CommonReactTable from '../../helpers/commonReact_table';
import { sortAmtcommon, sortBizcommon, sortTimecommon } from '../../helpers/sortingHelpers';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import {Button } from 'react-bootstrap';
import { BasicReactTable } from '../../helpers/react_table';
import { v4 as uuidv4 } from 'uuid';
import MUIAccordion from '../../MUI/Components/MUIAccordion';
import HomeScreenTable from './HomeScreenTable';
import DataGridTable from '../CommonComponent/table';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CustomTableGridToolbar from '../CommonComponent/table/components/customTableGridToolbar';


class OnHoldList extends FlowComponent {
    state = {
        refresh: false,
        rowdatas:[],
        columndatas:[],
        copy:[],                  
        dyn_Btn_selected:[],
		all_count:0,				
		filter_actions:{},
		all_Btn_status:false,
		all_Acc_pvdrs:[],     
        disb_manually: true,
        show_table: false,
        page: { page: 0, pageSize: 10 },

    }

    handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount() {                               
        // setInterval(() => this.refreshList(),60000) 
        const fa_late1 = Config("fa_late1")
        const fa_late2 = Config("fa_late2")
        // this.setState(state=>state.fa_late1 = fa_late1)
        // this.setState(state=>state.fa_late2 = fa_late2)
        this.setState({fa_late1,fa_late2})
        const ls_market = get('market')
        const acc_prvdr_support_ussd = Config("acc_prvdr_support_ussd")
        this.setState({acc_prvdr_support_ussd}) 
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		listAccountProvider({...req, status: 'enabled', biz_account: true})
		.then((response)=>{
			if(!response){return };
			if(response.status === "success"){
				let acc_prvdrs = []            
				response.data.list.map(each=>
				acc_prvdrs.push(each.acc_prvdr_code)
				)  
				this.setState(state=>state.all_Acc_pvdrs = acc_prvdrs) 
                set("acc_pvdrs",acc_prvdrs)                      
			}
		});	
        this.drawTable()                                      
    }    

    drawTable = async() => {        
        const market = get('market')
		const market_name = market.country_code
        // await this.server_time(this.time_zone)
        const loan_search_criteria = {status : "stalled", disburse_attempt: true, mode : 'search'};
        let response = await searchHeldLoan(this.req({loan_search_criteria, home_table : true}, true))
                                                 
            this.resp(response)
            if(!response){return};            
            if(response){
                this.setState(state => state.columndatas = this.columndatas()) 
                this.setState(state=>state.copy = [])
                this.setState(state => state.server_time = response.server_time)
                if (response.data.results.length > 0 || response.data.results.length === 0){
                    this.setState(state=>state.all_count = response.data.results.length)
                    this.setState(state => state.rowdatas = response.data.results)                                                                    
                    const newArr = get([market_name]+'_faPendingdisb') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_faPendingdisb')				
                    this.state.all_Acc_pvdrs&& this.state.all_Acc_pvdrs.map(each=>{																				
                        const count = response.data.results.filter(indiv => indiv.acc_prvdr_code === each)		
                        const length = count.length
                        this.setState(state=>state.filter_actions[each] = [{count:length},{status:newArr.includes(each)}],()=>{
                            this.dynClickhandler(this.state.filter_actions[each][1].status,each)									

                        })

                        this.setState(state=>state.filter_actions[each][0].count = length)
                    })
                }
            }
    }    

    dynClickhandler (key,val) {
        const market = get('market')
		const market_name = market.country_code											
		if(key){												
			if(val !== "ALL"){								
				const find_val = this.state.dyn_Btn_selected.findIndex(each=> each === val)
				if(find_val == -1) {					
					this.state.dyn_Btn_selected.push(val)					
					this.setState(state=>state.filter_actions[val][1].status = key)
					if(this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {												
						this.setState(state=>state.all_Btn_status = true)
					}
				}				
                localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(this.state.dyn_Btn_selected))										
			}else{					
				const newOne = []
				this.state.all_Acc_pvdrs.map(e=>{
					this.state.filter_actions[e][1].status = true
					newOne.push(e)
				})					
				this.setState(state=>state.dyn_Btn_selected = newOne)																															
                localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(newOne))
				this.setState(state=>state.all_Btn_status=key)				
			}					
		}		
		else if(!key){					
			if(val !== "ALL"){											
				const find_val = this.state.dyn_Btn_selected.findIndex(each=>each === val)							
				if(find_val > -1){					
					this.state.dyn_Btn_selected.splice(find_val,1)
					this.setState(state=>state.filter_actions[val][1].status = key)
				}							
				this.setState(state=>state.all_Btn_status=false)													
                localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify(this.state.dyn_Btn_selected))								
			}else{				
				this.state.all_Acc_pvdrs.map(e=>
					this.state.filter_actions[e][1].status = false
				)				
				this.setState(state=>state.dyn_Btn_selected = [])								
                localStorage.setItem([market_name]+'_faPendingdisb',JSON.stringify([]))
				this.setState(state=>state.all_Btn_status=key)
			}
		}					
        const newArr = get([market_name]+'_faPendingdisb')			
		let combinedArr = []
		newArr.map(each=> {			
			const filterArr = this.state.rowdatas.filter(indiv=>indiv.acc_prvdr_code === each)
			combinedArr.push(...filterArr)			
		})		
        let data = combinedArr.map((row) => ({
			...row,
			id: uuidv4(), 
		})); 
		this.setState(state=>state.copy = data)									
	}    

    getOverlayInfo (disb_status, loan) {
        let msg
        if (disb_status === "failed"){
            msg = "Float not transferred. App Support Will Retry"
        }
        else if (disb_status === "pending_disb_capture"){
            msg = "Float Transferred. Not Captured on  App. Customer Success will Capture"
        }
        else if (disb_status === "unknown"){
            msg = "App Support Will Process"
        }
        else if (disb_status === "sent_to_disbursal_queue"){
            msg = "Float will be transferred shortly"
        }
        else if (disb_status === null && !loan.customer_consent_rcvd){
            msg = "Customer has not sent the confirmation code"
        }
        return msg                
    } 

    sortAmt(a,b){          
        const curr_Amt = a.props.children
        const prev_Amt = b.props.children     
        return sortAmtcommon(curr_Amt,prev_Amt)      
    }
    sortBiz(a,b){        
        const currentBiz = a.props.children[0].props.full_text
        const prevBiz = b.props.children[0].props.full_text
        return sortBizcommon (currentBiz,prevBiz)                 
    }
    sortTime(a,b){
        const currentTime = a.props.data.loan_appl_date
        const prevTime = b.props.data.loan_appl_date
        const timeServer = this.state.server_time        
        return sortTimecommon(currentTime,prevTime,timeServer)
    }

    columndatas () {
        return [
            {
                headerName : "FA ID & A/C Number",
                field: 'loan_doc_id',
                width:250,
                headeralign: 'left', flex: 1, align: 'left', resizable: true,
                renderCell: params =>  <div className='vertical_aligned'>
                <div className='column_line_height'>
                                    <Link className='d-block rms_date_picker'  to={"/fa/view/"+params.row.loan_doc_id} target="_blank">{params.row.loan_doc_id}</Link>
                                    <Link className='d-block rms_date_picker' to={"/borrower/indiv/view/"+params.row.cust_id} target="_blank">{params.row.acc_number} {params.row.branch == null ? " " : "("+title_case(params.row.branch)+")"}</Link>
                                 </div>
                                 </div>
            },
            {
                headerName : "Channel",
                field: "channel",  
                  headeralign: 'left', flex: 1, align: 'left',resizable: true,
                           
                renderCell:params => {return <p className='text-light text-uppercase '>{params.row.channel?title_case(params.row.channel):''}</p>}
            },
            {
                headerName : "ACC PRVDR",
                fied: "acc_prvdr_code",  
                headeralign: 'left', flex: 1, align: 'left',resizable: true,
                renderCell:params =>
                <div className='d-flex justify-content-center'>
                     <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code}/>
                </div>
                    
            },
            // {
            //     headerName : "A/C Number",
            //     id: 'acc_number',
            //     renderCell: row =><Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank">{row.acc_number}</Link>
            // },
            {
                headerName :" Biz Name & Mobile Number",
                field: 'cust_mobile_num',resizable: true,
                flex:1,
                align: 'left',
                headeralign: 'left',
                width:220,
                renderCell: params => <div className='vertical_aligned'>
                <div className='column_line_height'>
                                    <TooltipLabel className='d-block' full_text={params.row.biz_name} overlay={`${params.row.cust_name} / ${lbl_amt(params.row.loan_principal, this.currency_code)}`} no_reduce={true} />
                                    <label className='no-margin d-block'>{params.row.cust_mobile_num}</label>                                    
                                 </div>  </div> 
            },
            // {
            //     headerName : "Biz Name",
            //     id:'biz_name',
            //     renderCell: row => <TooltipLabel full_text={row.biz_name} overlay={row.cust_name} no_reduce={true} />
            // },
            // {
            //     headerName : () => (<div className={'tableAmount_centerAlign'}>FA Amount</div>),
            //     id:'loan_principal',
            //     sortMethod: (a,b) => this.sortAmt(a,b),
            //     renderCell:row => <div className='tableAmount_rightAlign'>{lbl_amt(row.loan_principal, this.currency_code)}</div>
            // },
            // {
            //     headerName : "Total Attempts",
            //     id:'attempts',
            //       headeralign: 'left', flex: 1, align: 'left',
            //     renderCell: row => <div className={'tableAmount_centerAlign'}>{row.attempts}</div>
            // },
            
            {
                headerName : "Applied & Approved",
                field: 'loan_appl_date',resizable: true,
                width:230,
                  headeralign: 'left', flex: 1, align: 'left',
                renderCell: params => <div className='vertical_aligned'> 
                <div className='column_line_height'>
                                    <span >Applied :  <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"applied"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span><br/>
                                    <span >Approved :  <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"approved"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span>
                                 </div> </div> 
            },
            {
                headerName : "Attempted",
                field: 'first_attempt_time',resizable: true,
                width:230,    
                headeralign: 'left', flex: 1, align: 'left',
                renderCell: params => <div style={{paddingLeft:15}}>                                    
                                        {params.row.attempts > 1 ?
                                            <>
                                                {params.row.first_attempt_time &&
                                                        <>
                                                            <span >First Attempt : <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"first_attempt_time"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span><br/>
                                                        </>
                                                }
                                                {params.row.attempt_time &&
                                                        <>
                                                            <span >Last Attempt{" ("+params.row.attempts+")"} : <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"last_attempt_time"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span>
                                                        </>
                                                }
                                            </> :
                                            <span><span style={{display:params.row.attempts === 1 ? "inline-flex" : "none"}}>Attempted :</span><ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"first_attempt_time"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span>
                                        }                                    
                                </div>
            },
            {
                headerName : "FA Status Disbursal Status" ,
                field : 'status',resizable: true,
                width:220,     
                  headeralign: 'left', flex: 1, align: 'left',
                renderCell : params => 
                <div className='vertical_aligned'> 
                <div className='column_line_height'>
                    <div>{dd_value(params.row.status)}</div>
                    <div>
                    {(params.row.disbursal_status != null) ?
                        
                        <OverlayTrigger placement='auto' trigger={['hover', 'focus']} overlay={  <Popover style={{fontSize: "15px", padding: "15px", color:"mediumblue", fontFamily: "Montserrat"}}>{this.getOverlayInfo(params.row.disbursal_status, params.row)}</Popover>}><span>{title_case(params.row.disbursal_status || "not_initiated")} <sup><FcInfo size={14}/></sup></span></OverlayTrigger>
                    :
                        <>
                        </>
                    } </div>

                </div> </div>
            },
            {
                headerName : "Action",resizable: true,
                  headeralign: 'left', flex: 1, align: 'left',
                field:'btn',
                renderCell : params => this.buttonName(params.row)                
            },
            {
                headerName : "Action",resizable: true,
                headeralign: 'left', flex: 1, align: 'left',
                field:'bttn',
                renderCell : params => <div className='text-center'>
					{(check_priv('loan', 'home_screen_cancel_btn') && !['sent_to_disbursal_queue', 'in_progress', 'unknown'].includes(params.row.disbursal_status)) &&
						<button className='btn-primary no-padding' style={{margin:'10px !important'}} onClick={()=>this.cancelLoan(params.row.loan_doc_id)}>Cancel this FA</button>
            }
					</div>           
            }
        ]
    }

    cancelLoan=(loan_doc_id)=>{
		// alert("hoo");
		const resp = window.confirm("Are you sure to cancel FA?");
		if(resp == false){
			return;
		}
		const request = {"cancel_loan" : {'loan_doc_id' : loan_doc_id, 'ignore_consent_check' : true}};

		cancelLoan(this.req(request))
			.then((response) => {
				if(!response){return };
					if(response.status === "success"){   
                        this.refreshList()
					}	
			});
	}

    statusChange (event,loan) {


        let value = event.target.value
        let alert_msg = ""
        if (value === 'pending_disb_capture') {
            alert_msg = "Are you sure if the disbursal attempt is successful and there is a debit transaction to the customer found in the Data Provider A/C statement on " + loan.attempt_time+"?"
        } else if (value === 'failed') {
            alert_msg = "Are you sure the disbursal attempt got failed and there is no debit transaction to the customer found in the Data Provider A/C statement?"
        }
        if (window.confirm(alert_msg)) {
            const request = {loan_doc_id: loan.loan_doc_id, disbursal_status: value, loan_datas:loan}
            changeDisbursalStatus(this.req(request)).then((res) => {
                this.resp(res)
                if (res && res.status == 'success') {
                    if (res.message == 'its_already_exists'){
                        alert("Disbursal already exists in account statements.")
                    }
                    this.refreshList()
                }
            })

        }
    }

    refreshList = async() => {
            this.setState({refresh: true})            
            await this.drawTable()
    }


    buttonName = (loan) => {
        let value
        if(loan.disbursal_status == 'pending_disb_capture' && check_priv('loan','home_capture_disbursal')){

           value =  <CaptureFailedDisbursal id="disbursal_txn" data={loan} title="Disbursal Loan" />
        }
        else if(loan.disbursal_status == 'failed' || loan.status == 'pending_mnl_dsbrsl'){
           const loan_data = loan;
           loan_data.amount = loan.loan_principal;
           const ls_market = get('market')
           value =  
            <div className='text-center'> 
                <OverlayTrigger style={{ color: "#559f58" }} placement="top" trigger="click" overlay={(
                    <Popover style={{ textAlign: "center" }}>
                        <Popover.Content>
                            {loan.retry_disb_accs.map((retry_disb_acc) => {
                                console.log("retry_disb_acc")
                                console.log(retry_disb_acc)
                                return (
                                    <div className='d-flex justify-content-center' style={{ marginBottom: '4%', marginTop: '3%' }}>
                                        {check_priv('loan', 'retry_disbursal') && (loan.status !== 'pending_mnl_dsbrsl') && (
                                            <input 
                                                style={{ marginBottom: '3%', backgroundColor: "#202940" }}
                                                id={loan.loan_doc_id}
                                                key={retry_disb_acc}
                                                type='submit'
                                                value={`Retry with ${retry_disb_acc}`}
                                                disabled={this.state.calling_api}
                                                onClick={(event) => { this.retryFA(loan.loan_doc_id, retry_disb_acc) }}
                                                className='btn btn-retry btn-sm'
                                            />
                                        )} 
                                    </div>
                                );
                            })}
                        </Popover.Content>
                    </Popover>
                )}>
                    <Button className={`btn btn-primary btn-sm ${check_priv('loan','disburse_manually') ? "mt-4":""}`} variant="success"> Retry </Button>
                </OverlayTrigger>
                {check_priv('loan','disburse_manually') && <DisburseLoan id="disbursal_txn" data={loan_data} parent="home" disabled={this.state.calling_api} onStatusChange={this.refreshList} onDisburseClick={(is_refresh_enabled) => this.setState({disb_manually: is_refresh_enabled})}/>}
            </div>
        }
        else if(loan.disbursal_status == 'unknown' && check_priv('loan','release') && loan.allow_status_change){
            value = <td><select style={{minHeight:'30px',width:90}} onChange={(event) => {this.statusChange(event,loan)}}>
                    <option disabled selected hidden className='text-white'>choose</option>
                    <option value="pending_disb_capture" className='text-white'>Pending Capture</option>
                    <option value="failed" className='text-white'>Failed</option></select></td>
        }

        return  value
    }

    retryFA = (loan_doc_id, retry_disb_acc = 'primary') => {
        if(this.state.calling_api){return}
        if(!window.confirm("Have you verified in the Data Provider's A/C statement if there is no debit transaction found related to this FA?\n\nPlease note that clicking OK will re-attempt Disbursal")){return}
        retryDisbursal(this.req({'loan_doc_id' : loan_doc_id, ap_n_int_type: retry_disb_acc}, true)).then((response) => {
            this.resp(response)
            this.refreshList()
        })


	}

    componentDidUpdate(prevProps, prevState, snapshot) {                              
        if((prevProps.refresh != this.props.refresh && this.state.disb_manually == true)){
            this.refreshList()
        }                     
    }            

    render(){   
        return (  
            <MUIAccordion
            title={

                <div className='home_header_d-flex_at_response align-items-center'>
                <h4 className="card-title text-white tableHead_font " style={{width:"max-content"}}>FAs Pending Disbursal </h4>
                {this.state.rowdatas.length > 0 && this.state.all_Acc_pvdrs?.length >1 &&
                            <>
                                <div className='countREL card-title'>
                                    <button className={'filterBtns allBtn_size'} onClick={()=>this.dynClickhandler(!this.state.all_Btn_status,"ALL")} style={{filter: !this.state.all_Btn_status ? "opacity(0.5)" : ''}}>ALL</button>
                                    <div className='countABS' style={{backgroundColor : !this.state.all_Btn_status ? "lightgrey" : ''}}>
                                        <label>{this.state.all_count}</label>
                                    </div>
                                </div>									
                                {this.state.all_Acc_pvdrs.map((each,idx)=>									
                                    <div key={idx} className='countREL card-title'>																					
                                            <>
                                                <div style={{width:60,height:30}} className="filterBtns"  onClick={()=>this.dynClickhandler(!this.state.filter_actions[each][1].status,each)}>													  
                                                    <img className='img-fluid' src={`/img/${each}_logo_color.png`} style={{filter: this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "opacity(0.5)" : ''}}></img> 																	
                                                </div>
                                                <div className='countABS' style={{backgroundColor : this.state.filter_actions[each] && !this.state.filter_actions[each][1].status ? "lightgrey" : ''}}>													
                                                    <label>{this.state.filter_actions[each] && this.state.filter_actions[each][0].count}</label>																																
                                                </div>
                                            </>										
                                    </div>																		
                                )}  
                            </>
                        }  
</div>


               
            }
            body={
                     <div className={` react_common_table ${check_priv('loan','disburse_manually') ?"fas_disbursal_table":""}`}>
                                            <DataGridTable
											rows={this.state.copy}
											columns={this.state.columndatas}
											paginationMode="client"
											sortingMode={"client"}
											page={this.state.page}
											rowCount={this.state.copy.length}
											// sort={this.state.sort}
											setsort={(newSort) => this.setState({ sort: newSort })}
											setpage={(newPage) => this.setState({ page: newPage })}
											isLoading={ this.state.loader }
											rowHeight={70}
											// errorText="Error fetching data"
											noOfColumnsToLoad={6}
											slots={{ toolbar: CustomTableGridToolbar }}
                                            slotProps={{
                                                toolbar: {
                                                  showQuickFilter: true,
                                                },
                                              }}
										/>

                                {/* <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} pagination={true}    default_page_size={50}  defaultSorted={[{id:"loan_appl_date",desc:true}]} excessTimeaction={true} timeServer={this.state.server_time} appliedRowblink={false} approveRowblinktime={this.state.fa_late2} />   */}
                      </div>
            }
            total_count = {(this.state.copy?.length)}

            >

            </MUIAccordion>      
         
        );

}


}
export default OnHoldList;
