import { TablePaginationProps } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import {
  DataGrid,
  GridColDef,
  gridPageCountSelector,
  GridPagination,
  GridPaginationModel,
  GridRowsProp,
  GridSlotsComponent,
  GridSortModel,
  GridValidRowModel,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { NO_DATA_FOUND_INFO } from "../../../consts";
import ErrorUI from "./components/errorUI";
import DarkBgCommonStyleContainer from "./container/darkBgCommonStyleContainer";
import DataTableLoadingOrErrorUI from "./dataTableLoadingOrErrorUI";

type Props = {
  columns: GridColDef<GridValidRowModel>[];
  page: GridPaginationModel;
  rows?: GridRowsProp;
  rowCount?: number;
  sort?: GridSortModel;
  setpage?: Function;
  setsort?: Function;
  checkboxSelection?: boolean;
  isLoading: boolean;
  isError: boolean;
  errorText: string;
  sortingMode?: "client" | "server";
  paginationMode?: "client" | "server";
  noOfColumnsToLoad: number;
  rowHeight?:number;
  columnVisibility?: {[key: string]: boolean },
  slots?: Partial<GridSlotsComponent>
};

function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      sx={{ m: "0px !important" }}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

const DataGridTable = ({
  columns,
  page,
  rows,
  rowCount,
  sort,
  setsort,
  setpage,
  checkboxSelection,
  isLoading,
  isError,
  errorText,
  sortingMode,
  paginationMode,
  noOfColumnsToLoad,
  rowHeight,
  columnVisibility,
  slots,
  ...other
  

}: Props) => {
  // cb
  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => setsort && setsort(sortModel),
    []
  );

  const handlePageModelChange = useCallback(
    (pageModal: GridPaginationModel) => setpage && setpage(pageModal),
    []
  );

  return (
    <>
      {!rows || isLoading || isError ? (
        <DataTableLoadingOrErrorUI
          isError={!rows && !isLoading ? true : isError}
          errorText={errorText}
          loadingColumns={noOfColumnsToLoad}
          loadingRows={page.pageSize}
        />
      ) : (
        <DarkBgCommonStyleContainer>
          {!isLoading && rows.length === 0 ? (
            <ErrorUI errorText={NO_DATA_FOUND_INFO} />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              sortingMode={sortingMode}
              paginationMode={paginationMode}
              rowCount={rowCount}
              sortModel={sort}
              paginationModel={page}
              initialState={{
                sorting: {
                  sortModel: sort,
                },
                pagination: {
                  paginationModel: page,
                },
              }}
              onSortModelChange={handleSortModelChange}
              onPaginationModelChange={handlePageModelChange}
              slots={{
                ...(slots && slots ),
                pagination: CustomPagination,
              }}
              checkboxSelection={checkboxSelection}
              disableColumnSorting={!sortingMode}
              pageSizeOptions={[5, 10, 20, 30, 50]}
              disableRowSelectionOnClick
              disableColumnMenu
              hideFooter={!paginationMode}
              rowHeight={rowHeight}
              columnVisibilityModel={columnVisibility}
              {...other}
            />
          )}
        </DarkBgCommonStyleContainer>
      )}
    </>
  );
};

export default DataGridTable;
