import React from 'react'
import {Form} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import FlowComponent from '../../common/component/core/FlowComponent';
import { format } from 'date-fns';
import { dd } from '../../../actions/common_actions';
import Select from "react-select";
import { get } from '../../../helpers/storage_helper';
import { getValueFromLangJson } from '../../../helpers/common_helper';

class DailyAgreement extends FlowComponent{

    state ={

    }
    componentWillMount(){
        this.handleCurdate()
        this.load_flow_rel_mgr(null,"multiselect_rm_name")
    }

    handleCurdate(){
        const date = new Date()
        const cur_date = format(date, 'dd MMM y') 
        return cur_date
    }

    render(){
        return(
            <Form className="mb-3" >
	        	<Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}></Form.Row>
                    <Form.Row>
                    <div className="col-3">
                            <span style={{color:"white"}}>Start Date</span><font className="text-danger"> * </font>
                            <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("start_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                        </div>

                        <div className="col-3">
                            <span style={{color:"white"}}>End Date</span><font className="text-danger"> * </font>
                            <DatePicker defaultValue={this.handleCurdate()} onChange={(value) => {this.handleDateChange("end_date", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                        </div> 
                        {get("role_codes") == "relationship_manager" && 
                        <div className="col-3">
                            <span style={{color:"white"}}>{getValueFromLangJson("rm_label")}</span>
							<Select onChange={(e) => {this.props.handleFilter(e)}}
                              value = {this.state.select_value}
							  options={this.state.flow_rel_mgr}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" 
							  isMulti/>
                        </div>
                        }

                    </Form.Row>
                </Form>
                )
        }

}export default DailyAgreement